<template>
    <div class="relative select-none">
        <progress-bar
            :value="score"
            :max="descriptions.length"
            :color="description.color"
        />
        <p class="absolute mt-1 text-xs italic">
            {{ $t(description.label) }}
        </p>
    </div>
</template>
<script>
import { computed, watch, defineEmits } from 'vue'
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import ProgressBar from './ProgressBar.vue'

export default {
    components: { ProgressBar },
    emits: ['failed', 'passed'],
    setup(props, { emit }) {
        const options = {
            dictionary: {
                ...zxcvbnCommonPackage.dictionary,
                ...zxcvbnEnPackage.dictionary,
            },
            graphs: zxcvbnCommonPackage.adjacencyGraphs,
            translations: zxcvbnEnPackage.translations,
        }
        zxcvbnOptions.setOptions(options)
        const score = computed(() => {
            const hasValue = props.value && props.value.length > 0

            if (!hasValue) {
                return 0
            }

            return zxcvbn(props.value).score + 1
        })
        const descriptions = computed(() => [
            {
                color: 'bg-[#dc2626]',
                label: 'password_strength.weak',
            },
            { color: 'bg-[#fca5a5]', label: 'password_strength.better' },
            { color: 'bg-[#facc15]', label: 'password_strength.nice' },
            {
                color: 'bg-[#bbf7d0]',
                label: 'password_strength.good',
            },
            {
                color: 'bg-[#4ade80]',
                label: 'password_strength.perfect',
            },
        ])
        const description = computed(() =>
            props.value && props.value.length > 0
                ? descriptions.value[score.value - 1]
                : {
                      color: 'bg-transparent',
                      label: 'password_strength.start',
                  }
        )
        const isPasswordStrong = computed(() => score.value >= 4)
        watch(isPasswordStrong, (value) => {
            value ? emit('passed') : emit('failed')
        })
        return { description, descriptions, isPasswordStrong, score }
    },
    name: 'PasswordScore',
    props: {
        value: {
            type: String,
            required: true,
        },
    },
}
</script>
