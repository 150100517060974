import { defineStore, getActivePinia } from 'pinia'

// map through that list and use the **$reset** fn to reset the state
import { useClientStore } from './clientStore'
import { useDriverStore } from './driverStore'


const useUserStore = defineStore('user', {
    state: () => ({
        current: null,
        isLoggedIn: false,
        loginError: null,
        logo: null,
        locale: 'Fr',
        nightMode: false,
        mapMarker: null,
        userAuthorisations: [],
        isB2C: null,
    }),
    actions: {
        login(payload) {
            const activepinia = getActivePinia()
            if (activepinia) {
                Object.entries(activepinia.state.value).forEach(
                    ([storeName, state]) => {
                        if (storeName !== 'user') {
                            const storeDefinition = defineStore(
                                storeName,
                                state
                            )
                            const store = storeDefinition(activepinia)
                            store.$reset()
                        }
                    }
                )
            }
            const clientStore = useClientStore()
            const driverStore = useDriverStore()


            // this.logout(false)
            this.cookies.keys().forEach((cookie) => {
                if (
                    cookie !== 'logo' &&
                    cookie !== 'nightMode' &&
                    cookie !== 'locale'
                ) {
                    this.cookies.remove(cookie)
                }
            })
            this.loginError = null
            payload.Software = 'biiimFront'

            this.axios
                .post('/api/login', payload)
                .then((res) => {
                    this.cookies.set('user_session', res.data.jwt)
                    // const auth = []
                    // res.data.Authorizations.forEach((a) => auth.push(a))

                    this.current = {
                        id: res.data.id,
                        email: res.data.email,
                        type: res.data.type,
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        idClient: res.data.idClient,
                        idDriver: res.data.idDriver,

                        login: res.data.login,
                    }
                    this.cookies.set('user', this.current)

                    if (res.data.type === 'client') {
                        this.isB2C = true
                        this.cookies.set('is_B2C', true)

                        clientStore.fetchOne(res.data.idClient)
                    } else {
                        this.isB2C = false

                        this.cookies.set('is_B2C', false)
                        if (res.data.type === 'driver') {
                            driverStore.fetchOne(res.data.idDriver)

                        }
                    }
                    if (res.data.Authorizations) {
                        res.data.Authorizations.forEach((auth) => {
                            this.userAuthorisations.push(auth.Code)
                        })
                        this.cookies.set(
                            'authorisations',
                            JSON.stringify(this.userAuthorisations)
                        )
                    }

                    this.isLoggedIn = true

                    this.router.push({ name: 'home' })
                })
                .catch((err) => {
                    let { message } = err
                    if (err.response.status === 401) {
                        message = 'bad_credentials' // this.i18n.global.t('bad_credentials')
                    }
                    this.loginError = message
                })
        },
        forgotPassword(payload) {
            this.axios
                .post('/api/v1/user/requestResetPassword', payload)
                .then((res) => {
                    // console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                    console.log('error reset pass mail')
                })
        },
        doResetPassword(payload) {
            this.axios
                .post('/api/v1/user/setNewPassword', payload)
                .then((res) => {
                    this.router.push({ name: 'login' })
                })
                .catch((err) => {
                    console.log(err)
                    console.log('error rp form')
                })
        },
        logout(redirect = true) {
            // getActivePinia()._s.forEach((store) => store.$reset())
            // if (resetPinia === true) {
            const activepinia = getActivePinia()
            if (activepinia) {
                Object.entries(activepinia.state.value).forEach(
                    ([storeName, state]) => {
                        if (storeName !== 'user') {
                            const storeDefinition = defineStore(
                                storeName,
                                state
                            )
                            const store = storeDefinition(activepinia)
                            store.$reset()
                        }
                    }
                )
            }
            // }
            this.axios.get('/api/user/logout').then(() => {
                this.current = null
                this.isB2C = null
                this.isLoggedIn = false
                this.userAuthorisations = []

                this.cookies.keys().forEach((cookie) => {
                    if (
                        cookie !== 'logo' &&
                        cookie !== 'nightMode' &&
                        cookie !== 'locale'
                    ) {
                        this.cookies.remove(cookie)
                    }
                })
                if (redirect === true) {
                    this.router.push({ name: 'logout' })
                    // document.location.href = '/login' // todo to remove when cookie issue is fixed
                }
            })
        },
        userFromStorage() {
            const clientStore = useClientStore()

            if (this.cookies.get('user_session')) {
                this.current = this.cookies.get('user')
                this.isB2C = this.cookies.get('is_B2C')

                const authorisations = JSON.parse(
                    this.cookies.get('authorisations')
                )
                if (authorisations.length) {
                    this.userAuthorisations = authorisations

                    this.isLoggedIn = true
                    this.logo = this.cookies.get('logo')
                    if (this.isB2C === true) {
                        clientStore.fetchOne(this.current.idClient)
                    }
                } else {
                    this.logout()
                }
            }
        },
        fetchOne(payload) {
            const clientStore = useClientStore()

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/user/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        const user = res.data
                        // dispatch('fetchClientContacts', payload).then(
                        // (contactsRes) => {
                        //     const contacts = contactsRes.data
                        const current = {
                            id: +user.Id,
                            firstname: user.FirstName,
                            lastname: user.LastName,
                            mobile: user.MobilePhone,
                            phone: user.Phone,
                            type: user.Type,
                            login: user.Login,
                            authorisations: user.Authorizations,
                            email_address: user.Email,
                            creation_date: user.CreationDate,
                            creation_user_id: +user.CreationUserId,
                        }
                        clientStore.$patch({ currentContact: current })

                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/user/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        updateUserAndClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/userclient/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/userclient`, payload)
                    .then((res) => {
                        // if (res.status === 200 && typeof res.data !== 'string') {
                        resolve(res)
                        //     } else {
                        //         resolve({
                        //             status: 422,
                        //             message: 'unprocessable entity',
                        //         })
                        //     }
                        //     resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
    },
})
export { useUserStore }
