<template>
    <div class="mr-22 bg-white darky">
        <div
            class="darky pt-20 bg-white mb-20 ml-2 pb-20 z-20 w-full fixed"
            :class="marginTop"
        >
            <div class="text-3xl mb-3 items-center">
                <fa-icon icon="screwdriver-wrench" class="fa-md mr-2"></fa-icon
                >{{ $t('biiim_admin_interface') }}
            </div>
            <nav-tabs
                id="navtabs"
                :navTabs="tabs"
                :marginTop="''"
                @navigate="changeContent($event)"
            />
        </div>
        <component
            class="pt-40"
            :is="componentToDisplay"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
        <!-- @create="toCreate()"
            @goToUpdateArea="toUpdate($event)" -->
    </div>
</template>
<script>
import Authorisations from '@/views/superAdmin/AuthorisationManagement.vue'
import Logs from '@/views/superAdmin/LogsViewer.vue'
import NavTabs from '@/components/elements/NavTabs.vue'

export default {
    name: 'BackofficeWrapper',
    components: {
        // Settings,
        Authorisations,
        Logs,
        NavTabs,
    },
    data() {
        return {
            componentToDisplay: this.humanize(this.$route.name),
            tabs: [
                {
                    multiIcons: ['masks-theater', 'user-lock'],
                    name: 'authorisations',
                    names: ['roles', 'authorisations'],
                    disabled: false,
                },
                // {
                //     icon: 'masks-theater',
                //     name: 'roles',
                //     disabled: true,
                // },
                {
                    icon: 'language',
                    name: 'translations',
                    disabled: true,
                },
                {
                    icon: 'envelopes-bulk',
                    name: 'mail_templates',
                    disabled: true,
                },
                {
                    icon: 'database',
                    name: 'logs',
                    disabled: false,
                },
                {
                    icon: ['fab', 'google'],
                    name: 'google_api_use',
                    disabled: true,
                },
            ],
        }
    },
    mounted() {
        this.$cookies.remove('filtered')
        this.changeContent(this.$route.name)
    },

    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
