<template>
    <div
        :class="'bg-' + color + '-100'"
        class="rounded p-4 mt-3 mb-3"
        role="alert"
    >
        <p :class="'text-' + color + '-700'" class="text-base">
            {{ $t(text) }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'AlertInfo',
    props: {
        color: {
            type: String,
            default: 'green',
        },
        text: {
            type: String,
            default: 'login_error',
        },
    },
}
</script>
