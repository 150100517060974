<template>
    <div class="mr-20 bg-white darky">
        <!-- <nav-tabs
            id="navtabs"
            :navTabs="tabs"
            @navigate="changeContent($event)"
        /> -->
        <component
            :is="componentToDisplay"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
        />
    </div>
</template>
<script>
import NavTabs from '@/components/elements/NavTabs.vue'
import Billings from '@/views/billings/List.vue'

export default {
    name: 'BillingsWrapper',
    components: {
        NavTabs,
        Billings,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [
                ['list-ul', 'billings', true],
                // ['users', 'contacts'],
                // ['address-book', 'delivery_addresses'],
                // ['pen', 'order_step_correction'],
            ],
        }
    },
    created() {
        this.$cookies.remove('filtered')
    },
    mounted() {
        this.changeContent(this.$route.name)
    },
    updated() {
        if (this.sideBarNav) {
            // TODO voir quand autres pages accessibles depuis sidebar ne pas remove si on va sur des updates
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            this.changeContent(this.sideBarNav)
            this.$emit('removeSidebarNav')
        }
    },

    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
