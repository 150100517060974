<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md darky h-full"
        :class="creating === true ? 'cursor-progress' : ''"
    >
        <back-to @back="back()" />

        <div
            id="create-order"
            :key="refresh"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div v-if="isB2C !== null && isB2C === false" class="p-5">
                <form class="mb-2" @keydown.enter.prevent @submit.prevent="">
                    <div>
                        <div class="mb-2">
                            <span
                                class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                            >
                                {{
                                    $t(
                                        $route.params.id
                                            ? 'update_order'
                                            : 'new_order'
                                    )
                                }}

                                <span
                                    v-if="$route.params.id"
                                    class="items-center flex ml-1"
                                >
                                    <span
                                        class="cursor-copy custom-link"
                                        :title="copyTitle"
                                        @click="
                                            copyToClipboard(formData.Reference)
                                        "
                                    >
                                        - {{ formData.Reference }}
                                        <fa-icon
                                            icon="copy"
                                            class="fa-xs ml-2"
                                        ></fa-icon>
                                    </span>
                                    <span
                                        v-if="formData.IdExternal"
                                        class="cursor-copy text-base flex ml-2 items-center justify-center"
                                        :title="`${$t(
                                            'external_ref'
                                        )} - ${copyTitle}`"
                                        @click.stop="
                                            copyToClipboard(formData.IdExternal)
                                        "
                                    >
                                        {{ ` - ${formData.IdExternal}` }}

                                        <fa-icon
                                            icon="copy"
                                            class="fa-xs ml-2"
                                        ></fa-icon>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            v-if="$route.name === 'new_order'"
                            class="rounded-md border-2 p-4 col-span-2"
                        >
                            <div
                                class="text-sm italic custom-link items-center pl-6 pt-2 my-2 flex justify-center"
                            >
                                <fa-icon
                                    icon="fa-lightbulb"
                                    class="mr-2"
                                ></fa-icon>
                                {{ $t('delivery_name_info_operator') }}
                            </div>
                            <div
                                class="items-center pl-6 mb-2 flex justify-center"
                            >
                                <label
                                    class="font-semibold custom-link text-2xl text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('delivery_name') + ' *' }}</label
                                >
                                <input
                                    type="name"
                                    v-model="newDeliveryName"
                                    class="border rounded py-2 px-2"
                                />
                                <div
                                    class="text-xs italic mt-1 ml-2 mb-2"
                                    v-for="error of v$.newDeliveryName.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 rounded-md p-2 border-2">
                            <div class="mb-2 flex">
                                <span class="text-lg">
                                    {{
                                        $t('references') +
                                        ' - ' +
                                        $t('comments')
                                    }}
                                    <hr />
                                </span>
                            </div>
                            <div class="grid grid-cols-6 gap-2">
                                <div class="col-span-2 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{
                                            $t('reference') + ' ' + $t('client')
                                        }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.Reference"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                    <!-- @blur="v$.formData.Reference.$touch" -->
                                </div>
                                <!-- <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Reference.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div> -->
                                <div class="col-span-4 items-center">
                                    <label
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('comments') }}</label
                                    >
                                    <textarea
                                        type="textarea"
                                        v-model="formData.CommentClient"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-8 gap-2 mt-2">
                            <div
                                v-if="!isB2C"
                                class="rounded-md border-2 p-4 col-span-2"
                            >
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{
                                            $t('client') + ' - ' + $t('contact')
                                        }}
                                        <hr />
                                    </span>
                                </div>
                                <div
                                    v-if="!$route.params.id"
                                    class="mb-3 items-center px-3"
                                >
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('client') + ' *' }}</label
                                    >
                                    <Multiselect
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        :class="
                                            isDuplicate === true
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        "
                                        v-model="formData.IdClient"
                                        :options="clientOptions"
                                        :loading="clientOptions.length === 0"
                                        label="name"
                                        :key="refreshMS"
                                        track-by="name"
                                        :disabled="isDuplicate === true"
                                        :searchable="true"
                                        :placeholder="$t('select_client_info')"
                                        :allow-empty="false"
                                        :can-clear="false"
                                        @select="
                                            fetchContactsAddressesAndContainers(
                                                $event
                                            )
                                        "
                                    ></Multiselect>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.IdClient
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-if="noClientError"
                                    >
                                        <div class="error-msg">
                                            {{ $t('select_client_info') }}
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <button
                                            @click.stop="toCreateClient"
                                            class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                        >
                                            <span
                                                class="inline-block ml-1 font-bold"
                                                ><fa-icon
                                                    icon="plus"
                                                    class="mr-2"
                                                ></fa-icon
                                                >{{ $t('new_client') }}</span
                                            >
                                        </button>
                                    </div>
                                </div>
                                <div
                                    v-else-if="currentClient"
                                    class="mb-3 flex"
                                >
                                    <span class="text-md">
                                        {{ $t('client') + ': ' }}
                                        {{ currentClient.Name }}</span
                                    >
                                </div>

                                <div
                                    v-if="contacts !== null"
                                    class="mb-3 items-center px-3"
                                >
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('contact') }}</label
                                    >
                                    <Multiselect
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        v-model="formData.IdUserContact"
                                        :options="contactOptions"
                                        :loading="contactOptions.length === 0"
                                        label="name"
                                        :key="refreshMS"
                                        track-by="name"
                                        :searchable="true"
                                        :placeholder="$t('select_contact_info')"
                                        :allow-empty="false"
                                        :can-clear="true"
                                    ></Multiselect>
                                    <!-- <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData
                                            .IdUserContact.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div> -->
                                </div>
                                <div class="px-3">
                                    <div
                                        v-if="selectedContact"
                                        class="rounded-md border-1 p-4"
                                    >
                                        <span>
                                            {{ $t('contact_information') }}
                                            <hr />
                                        </span>
                                        <ul>
                                            <li class="mt-2 mb-2">
                                                {{
                                                    `${$t('name')} : ${
                                                        selectedContact.FirstName
                                                    } ${
                                                        selectedContact.LastName
                                                    }`
                                                }}
                                            </li>
                                            <li class="mb-2">
                                                {{
                                                    `${$t('email_address')} : ${
                                                        selectedContact.Email
                                                    }`
                                                }}
                                            </li>
                                            <li
                                                v-if="selectedContact.Phone"
                                                class="mb-2"
                                            >
                                                {{
                                                    `${$t('phone')} : ${
                                                        selectedContact.Phone
                                                    }`
                                                }}
                                            </li>
                                            <li
                                                v-if="
                                                    selectedContact.MobilePhone
                                                "
                                                class="mb-2"
                                            >
                                                {{
                                                    `${$t('mobile')} : ${
                                                        selectedContact.MobilePhone
                                                    }`
                                                }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    v-if="noContactForClient === true"
                                    class="rounded-md border-1 p-4"
                                >
                                    {{ $t('no_contact_found_for_client') }}
                                </div>
                            </div>

                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('delivery_mode') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <Multiselect
                                        class="darky"
                                        v-model="selectedMethod"
                                        :options="methodOptions"
                                        :key="refreshMS"
                                        label="name"
                                        track-by="name"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                    >
                                        <template #singlelabel="{ value }">
                                            <div
                                                class="multiselect-single-label"
                                            >
                                                <div
                                                    class="select-label-icon-wrap"
                                                    :style="value.style"
                                                >
                                                    <fa-icon
                                                        :title="value.name"
                                                        :icon="value.icon"
                                                        class="fa-sm"
                                                    ></fa-icon>
                                                    <span class="ml-1">
                                                        {{ $t(value.name) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template #option="{ option }">
                                            <div
                                                class="select-option-icon-wrap"
                                                :style="option.style"
                                            >
                                                <fa-icon
                                                    :title="option.name"
                                                    :icon="option.icon"
                                                    class="fa-sm"
                                                ></fa-icon
                                                ><span class="ml-1">
                                                    {{ $t(option.name) }}
                                                </span>
                                            </div>
                                        </template></Multiselect
                                    >
                                </div>
                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('package') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <!-- <span class="mt-2 rounded-md p-4 border-2">  -->
                                    <div
                                        v-if="
                                            ((containerOptions.length === 0 &&
                                                !$route.params.id) ||
                                                containerCreation === true) &&
                                            formData.IdClient !== ''
                                        "
                                    >
                                        <!-- <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{ $t('create_my_container') }}
                                                <hr />
                                            </span>
                                        </div> -->
                                        <div
                                            class="mb-3 items-center flex flex-row"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                >{{
                                                    $t('package_name') + ' *'
                                                }}</label
                                            >
                                            <input
                                                v-model="containerName"
                                                autocomplete="name"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-4"
                                            v-if="containerNameError === true"
                                        >
                                            <div class="error-msg">
                                                {{ $t('value_required') }}
                                            </div>
                                        </div>
                                        <div
                                            class="mb-3 items-center flex flex-row"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                >{{ $t('description') }}</label
                                            >
                                            <textarea
                                                v-model="containerDescription"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>

                                        <div
                                            class="grid grid-cols-3 gap-2 mt-4"
                                        >
                                            <div class="col-span-1">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                    >{{
                                                        $t('width') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    v-model="
                                                        formData
                                                            .OrderPackages[0]
                                                            .Width
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-4"
                                                    v-if="
                                                        packageWidthErrors.length >
                                                        0
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t('value_required')
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-1">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                    >{{
                                                        $t('length') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    v-model="
                                                        formData
                                                            .OrderPackages[0]
                                                            .Length
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-4"
                                                    v-if="
                                                        packageLengthErrors.length >
                                                        0
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t('value_required')
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-1">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                    >{{
                                                        $t('height') + ' *'
                                                    }}</label
                                                >

                                                <input
                                                    type="number"
                                                    min="0"
                                                    v-model="packageHeight"
                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="flex items-center justify-center my-4"
                                        >
                                            <input
                                                id="ref-add-update-checkbox"
                                                type="checkbox"
                                                v-model="containerForAll"
                                                class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                            />
                                            <label
                                                for="ref-add-update-checkbox"
                                                class="ml-2 text-sm font-medium theme-color"
                                                >{{
                                                    $t(
                                                        'create_container_for_all'
                                                    )
                                                }}</label
                                            >
                                        </div>
                                        <button
                                            v-if="containerCreation === true"
                                            @click.stop="unsetContainerCreation"
                                            class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                        >
                                            <span
                                                class="inline-block ml-1 font-bold"
                                                >{{
                                                    $t(
                                                        'back_to_predefined_containers'
                                                    )
                                                }}</span
                                            >
                                        </button>
                                    </div>
                                    <span
                                        v-else-if="
                                            containerOptions.length === 0 &&
                                            $route.params.id
                                        "
                                    >
                                        <div>
                                            <div
                                                v-if="
                                                    containerName &&
                                                    containerName !==
                                                        undefined &&
                                                    containerName !== ''
                                                "
                                            >
                                                {{
                                                    containerName.includes(
                                                        '_'
                                                    ) ||
                                                    containerName === 'pallet'
                                                        ? $t(containerName)
                                                        : containerName
                                                }}
                                            </div>
                                            <button
                                                @click.stop="
                                                    setContainerCreation
                                                "
                                                class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                            >
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t('create_container')
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                    </span>

                                    <span
                                        v-else-if="
                                            containerOptions.length > 0 &&
                                            containerCreation === false
                                        "
                                    >
                                        <span
                                            v-if="containerOptions.length > 10"
                                        >
                                            <div class="mb-3 items-center">
                                                <Multiselect
                                                    :key="refreshMS"
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="pickedContainerId"
                                                    :options="containerOptions"
                                                    :loading="
                                                        containerOptions.length ===
                                                        0
                                                    "
                                                    label="name"
                                                    track-by="name"
                                                    :searchable="true"
                                                    :placeholder="
                                                        $t(
                                                            'select_package_format'
                                                        )
                                                    "
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                    @select="
                                                        formData.OrderPackages
                                                            .length === 0
                                                            ? addPackage()
                                                            : ''
                                                    "
                                                ></Multiselect>
                                            </div>
                                        </span>
                                        <div v-else>
                                            <div class="grid-cols-2 grid gap-2">
                                                <div
                                                    class="flex col-span-1 justify-start items-center"
                                                    v-for="(
                                                        container, index
                                                    ) in containerOptions"
                                                    :key="index"
                                                >
                                                    <button
                                                        type="button"
                                                        class="text-xs ml-2 items-center focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                        :class="
                                                            pickedContainerId ===
                                                            container.value
                                                                ? 'secondary-themed-button'
                                                                : 'themed-button'
                                                        "
                                                        @click="
                                                            pickedContainerId =
                                                                container.value
                                                        "
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                container.icon
                                                            "
                                                            class="mr-2"
                                                        ></fa-icon
                                                        >{{
                                                            container.name.includes(
                                                                '_'
                                                            ) ||
                                                            container.name ===
                                                                'pallet'
                                                                ? $t(
                                                                      container.name
                                                                  )
                                                                : container.name
                                                        }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                @click.stop="
                                                    setContainerCreation
                                                "
                                                class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                            >
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t('create_container')
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                    </span>
                                    <!-- </span> -->

                                    <div class="mt-2 p-4">
                                        <div
                                            v-if="
                                                formData.OrderPackages[0] &&
                                                formData.OrderPackages[0]
                                                    .Reference !== undefined
                                            "
                                            class="mb-3 mt-3 custom-link font-semibold cursor-copy"
                                            :title="copyTitle"
                                            @click="
                                                copyToClipboard(
                                                    formData.OrderPackages[0]
                                                        .Reference
                                                )
                                            "
                                        >
                                            {{
                                                `${$t('reference')} ${
                                                    formData.OrderPackages[0]
                                                        .Reference
                                                }`
                                            }}
                                            <fa-icon
                                                icon="copy"
                                                class="fa-xs ml-2"
                                            ></fa-icon>
                                        </div>

                                        <span
                                            v-if="
                                                (containerName &&
                                                    containerName !==
                                                        undefined &&
                                                    containerName !== '') ||
                                                (formData.OrderPackages[0] &&
                                                    formData.OrderPackages[0]
                                                        .IdContainer !== '')
                                            "
                                        >
                                            <div
                                                v-if="
                                                    formData.OrderPackages[0]
                                                        .IdContainer !== null
                                                "
                                                class="font-semibold mb-2"
                                            >
                                                {{
                                                    containerName.includes(
                                                        '_'
                                                    ) ||
                                                    containerName === 'pallet'
                                                        ? $t(containerName)
                                                        : containerName
                                                }}
                                            </div>

                                            <div class="mb-2">
                                                {{
                                                    `${formData.OrderPackages[0].Length}cm x ${formData.OrderPackages[0].Width}cm x ${formData.OrderPackages[0].Height}cm`
                                                }}
                                            </div>
                                        </span>

                                        <span v-else>
                                            <div class="italic mb-2">
                                                {{ $t('no_package_selected') }}
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-if="
                                                    packageTypeErrors.length > 0
                                                "
                                            >
                                                <div class="error-msg">
                                                    {{
                                                        $t(
                                                            'no_package_type_error'
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </span>
                                        <span
                                            v-if="
                                                formData.OrderPackages.length >
                                                0
                                            "
                                        >
                                            <div
                                                class="grid gap-2 mt-4"
                                                :class="
                                                    currentContainer &&
                                                    +currentContainer.Height ===
                                                        0
                                                        ? 'grid-cols-2'
                                                        : 'grid-cols-3'
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        currentContainer &&
                                                        +currentContainer.Height ===
                                                            0
                                                    "
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('height') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="packageHeight"
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                        :class="
                                                            packageHeightErrors.length >
                                                            0
                                                                ? 'border-red-600 text-red-600'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold whitespace-nowrap basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('unit_weight') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            formData
                                                                .OrderPackages[0]
                                                                .Weight
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                        :class="
                                                            packageWeightErrors.length >
                                                            0
                                                                ? 'border-red-600 text-red-600'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('quantity') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        v-model="
                                                            packageQuantity
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-4"
                                                    v-if="
                                                        packageQuantityErrors.length >
                                                        0
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t('value_required')
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('worth') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            formData
                                                                .OrderPackages[0]
                                                                .Value
                                                        "
                                                        :class="
                                                            packageValueErrors.length >
                                                            0
                                                                ? 'border-red-600 text-red-600'
                                                                : ''
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('temperature') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>

                                    <div
                                        class="flex mb-2 items-center"
                                        v-for="(
                                            tempOption, index
                                        ) in temperatureOptions"
                                        :key="index"
                                    >
                                        <button
                                            type="button"
                                            class="text-xs ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            :class="
                                                selectedTemp ===
                                                tempOption.value
                                                    ? 'secondary-themed-button'
                                                    : 'themed-button'
                                            "
                                            @click="
                                                selectedTemp = tempOption.value
                                            "
                                        >
                                            <fa-layer
                                                v-if="tempOption.noTemp"
                                                class="fa-xl mr-2"
                                            >
                                                <fa-icon
                                                    icon="ban"
                                                    style="color: grey"
                                                ></fa-icon>
                                                <fa-icon
                                                    :icon="tempOption.icon"
                                                    transform="shrink-6"
                                                ></fa-icon>
                                            </fa-layer>
                                            <span
                                                v-else
                                                class="mr-2"
                                                :style="`${tempOption.color}`"
                                            >
                                                <fa-icon
                                                    :icon="tempOption.icon"
                                                    class=""
                                                ></fa-icon> </span
                                            >{{ $t(tempOption.name) }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('steps') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="mt-2">
                                        <div class="mb-3 items-center">
                                            <label
                                                class="font-semibold text-gray-600 pb-1 mr-2"
                                                >{{
                                                    $t('price_charged')
                                                }}</label
                                            >
                                            <input
                                                type="number"
                                                v-model="price"
                                                disabled
                                                placeholder="0 €"
                                                autocomplete="number"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <!-- <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('vehicle_type') }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="vehicleType"
                                                disabled
                                                autocomplete="name"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div> -->
                                    </div>
                                    <div
                                        v-if="
                                            $route.name === 'update_order' &&
                                            formData.OrderSteps.length > 0
                                        "
                                        :key="refreshSteps"
                                        class="mt-10 darky"
                                    >
                                        <ol
                                            v-for="(
                                                step, index
                                            ) in formData.OrderSteps"
                                            :key="index"
                                            class="px-4 border-l-2 border-r-2"
                                            :class="
                                                step.Type === 'pickup'
                                                    ? 'rounded-t-md pt-2 border-t-2'
                                                    : 'rounded-b-md mb-1 border-b-2'
                                            "
                                        >
                                            <div class="flex justify-end">
                                                <icon-button
                                                    v-if="
                                                        step.Type ===
                                                            'pickup' &&
                                                        formData.OrderSteps
                                                            .length > 2
                                                    "
                                                    :icon="'trash-can'"
                                                    class="ml-3"
                                                    :noStyle="true"
                                                    :iconStyle="'fa-md cursor-pointer error-msg'"
                                                    :title="$t('delete')"
                                                    @click="
                                                        deleteStp(
                                                            index,
                                                            step.Id
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div
                                                class="relative border-l border-gray-200"
                                            >
                                                <li class="mb-2 ml-6">
                                                    <span
                                                        class="steps-timeline flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full"
                                                    >
                                                        <fa-layer
                                                            v-if="
                                                                step.Status ===
                                                                'new'
                                                            "
                                                            :title="
                                                                $t(step.Status)
                                                            "
                                                            class="fa-lg"
                                                        >
                                                            <fa-icon
                                                                icon="ban"
                                                                style="
                                                                    color: grey;
                                                                "
                                                            ></fa-icon>
                                                            <fa-icon
                                                                icon="route"
                                                                transform="shrink-6"
                                                            ></fa-icon>
                                                        </fa-layer>
                                                        <fa-icon
                                                            v-else
                                                            :title="
                                                                step.Status !==
                                                                ''
                                                                    ? $t(
                                                                          step.Status
                                                                      )
                                                                    : $t(
                                                                          'no_status_found'
                                                                      )
                                                            "
                                                            :icon="
                                                                stepStatusIcon(
                                                                    step.Status
                                                                )
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                    <span
                                                        class="block max-w-full rounded border border-gray-200 p-3 darky"
                                                    >
                                                        <span
                                                            class="grid grid-cols-4 gap-1 border-b-4 mb-2"
                                                        >
                                                            <h3
                                                                class="mb-1 col-span-3 items-center flex text-lg font-semibold"
                                                            >
                                                                {{
                                                                    `${$t(
                                                                        step.Type ===
                                                                            'delivery'
                                                                            ? 'delivery_label'
                                                                            : step.Type
                                                                    )} - ${
                                                                        step.AddressName
                                                                    }`
                                                                }}
                                                                <span
                                                                    class="italic text-xs ml-2 font-light custom-link"
                                                                >
                                                                    {{
                                                                        step.Status !==
                                                                        ''
                                                                            ? $t(
                                                                                  step.Status
                                                                              )
                                                                            : $t(
                                                                                  'no_status_found'
                                                                              )
                                                                    }}
                                                                    <span
                                                                        class="ml-1"
                                                                        >{{
                                                                            step.ExecutedDateTime &&
                                                                            step.ExecutedDateTime !==
                                                                                null
                                                                                ? new Date(
                                                                                      step.ExecutedDateTime
                                                                                  ).toLocaleString(
                                                                                      $i18n.locale,
                                                                                      {
                                                                                          year: 'numeric',
                                                                                          month: '2-digit',
                                                                                          day: '2-digit',
                                                                                          hour: '2-digit',
                                                                                          minute: '2-digit',
                                                                                      }
                                                                                  )
                                                                                : ''
                                                                        }}
                                                                    </span>
                                                                </span>
                                                                <span
                                                                    v-if="
                                                                        canUpdateStepStatus ===
                                                                        true
                                                                    "
                                                                >
                                                                    <fa-icon
                                                                        :key="
                                                                            refreshToggle
                                                                        "
                                                                        v-if="
                                                                            (step.ExecutedDateTime !==
                                                                                '' &&
                                                                                step.ExecutedDateTime !==
                                                                                    null) ||
                                                                            step.Status ===
                                                                                'executed'
                                                                        "
                                                                        :title="
                                                                            $t(
                                                                                'force_inprogress'
                                                                            )
                                                                        "
                                                                        :icon="'toggle-on'"
                                                                        class="fa-md m-1 cursor-pointer success-msg"
                                                                        @click="
                                                                            updateStepStatus(
                                                                                {
                                                                                    step,
                                                                                    index,
                                                                                    newStatus:
                                                                                        'inProgress',
                                                                                    isLast:
                                                                                        index ===
                                                                                        formData
                                                                                            .OrderSteps
                                                                                            .length -
                                                                                            1,
                                                                                    lastStatus:
                                                                                        formData
                                                                                            .OrderSteps[
                                                                                            formData
                                                                                                .OrderSteps
                                                                                                .length -
                                                                                                1
                                                                                        ]
                                                                                            .Status,
                                                                                }
                                                                            )
                                                                        "
                                                                    ></fa-icon>
                                                                    <fa-icon
                                                                        v-else
                                                                        :title="
                                                                            $t(
                                                                                'force_finish'
                                                                            )
                                                                        "
                                                                        :icon="'toggle-off'"
                                                                        class="fa-md m-1 cursor-pointer"
                                                                        @click="
                                                                            updateStepStatus(
                                                                                {
                                                                                    step,
                                                                                    index,
                                                                                    newStatus:
                                                                                        'executed',
                                                                                    isLast:
                                                                                        index ===
                                                                                        formData
                                                                                            .OrderSteps
                                                                                            .length -
                                                                                            1,
                                                                                    lastStatus:
                                                                                        formData
                                                                                            .OrderSteps[
                                                                                            formData
                                                                                                .OrderSteps
                                                                                                .length -
                                                                                                1
                                                                                        ]
                                                                                            .Status,
                                                                                }
                                                                            )
                                                                        "
                                                                    ></fa-icon>
                                                                </span>
                                                            </h3>
                                                            <div
                                                                class="col-start-4 mt-2 ml-2 flex justify-end items-center"
                                                            >
                                                                <!-- v-if="
                                                                    step.Status ===
                                                                        'new' ||
                                                                    step.Status ===
                                                                        ''
                                                                " -->
                                                                <icon-button
                                                                    :icon="'pen'"
                                                                    :noStyle="
                                                                        true
                                                                    "
                                                                    :iconStyle="'fa-md cursor-pointer '"
                                                                    :title="
                                                                        $t(
                                                                            'edit'
                                                                        )
                                                                    "
                                                                    @click="
                                                                        updateStp(
                                                                            index
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                        </span>
                                                        <p
                                                            class="text-base font-normal"
                                                        >
                                                            {{
                                                                step.Address1 +
                                                                ' '
                                                            }}
                                                            <span
                                                                v-if="
                                                                    step.Address2 !==
                                                                        'null' &&
                                                                    step.Address2 !==
                                                                        null &&
                                                                    step.Address2 !==
                                                                        ''
                                                                "
                                                            >
                                                                {{
                                                                    step.Address2 +
                                                                    ' '
                                                                }}
                                                            </span>

                                                            {{
                                                                step.PostalCode +
                                                                ' ' +
                                                                step.City
                                                            }}
                                                        </p>
                                                        <time
                                                            class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                                                            >{{
                                                                $t('on') +
                                                                ' ' +
                                                                step.stepDate +
                                                                ' ' +
                                                                $t(
                                                                    step.Moment
                                                                ) +
                                                                ' '
                                                            }}{{
                                                                step.Moment ===
                                                                'between'
                                                                    ? `${
                                                                          step.FromDateTime
                                                                      } ${$t(
                                                                          'and'
                                                                      )} ${
                                                                          step.BeforeDateTime
                                                                      }`
                                                                    : step.Moment ===
                                                                      'before'
                                                                    ? step.BeforeDateTime
                                                                    : step.FromDateTime
                                                            }}
                                                        </time>
                                                    </span>
                                                    <span
                                                        v-if="
                                                            !isOdd(index) &&
                                                            formData.OrderSteps
                                                                .length > 1
                                                        "
                                                        class="grid grid-cols-4 gap-1 py-2"
                                                    >
                                                        <div
                                                            class="mb-3 col-span-2 items-center px-3"
                                                            :class="
                                                                driverOptions.length ===
                                                                0
                                                                    ? 'selectMS'
                                                                    : ''
                                                            "
                                                        >
                                                            <span
                                                                v-if="
                                                                    canAssignDriver ===
                                                                    true
                                                                "
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'driver'
                                                                        )
                                                                    }}</label
                                                                >
                                                                <Multiselect
                                                                    :key="
                                                                        refreshMS
                                                                    "
                                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                    v-model="
                                                                        step.IdDriver
                                                                    "
                                                                    :options="
                                                                        driverOptions
                                                                    "
                                                                    :loading="
                                                                        driverOptions.length ===
                                                                        0
                                                                    "
                                                                    label="name"
                                                                    trackBy="name"
                                                                    :searchable="
                                                                        true
                                                                    "
                                                                    :placeholder="
                                                                        driverOptions.length
                                                                            ? $t(
                                                                                  'select_one'
                                                                              )
                                                                            : $t(
                                                                                  'no_available_for_date'
                                                                              )
                                                                    "
                                                                    :allow-empty="
                                                                        false
                                                                    "
                                                                    :can-clear="
                                                                        true
                                                                    "
                                                                ></Multiselect>
                                                            </span>
                                                        </div>
                                                        <div
                                                            class="mb-3 col-span-2 items-center px-3"
                                                            :class="
                                                                vehicleOptions.length ===
                                                                0
                                                                    ? 'selectMS'
                                                                    : ''
                                                            "
                                                        >
                                                            <span
                                                                v-if="
                                                                    canAssignVehicle ===
                                                                    true
                                                                "
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'vehicle'
                                                                        )
                                                                    }}</label
                                                                >
                                                                <Multiselect
                                                                    :key="
                                                                        refreshMS
                                                                    "
                                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                    v-model="
                                                                        step.IdVehicle
                                                                    "
                                                                    :options="
                                                                        vehicleOptions
                                                                    "
                                                                    :loading="
                                                                        vehicleOptions.length ===
                                                                        0
                                                                    "
                                                                    label="name"
                                                                    track-by="name"
                                                                    :searchable="
                                                                        true
                                                                    "
                                                                    :placeholder="
                                                                        vehicleOptions.length
                                                                            ? $t(
                                                                                  'select_one'
                                                                              )
                                                                            : $t(
                                                                                  'no_available_for_date'
                                                                              )
                                                                    "
                                                                    :allow-empty="
                                                                        false
                                                                    "
                                                                    :can-clear="
                                                                        true
                                                                    "
                                                                ></Multiselect>
                                                            </span>
                                                        </div>
                                                    </span>
                                                </li>
                                            </div>
                                        </ol>
                                    </div>
                                    <!-- v-if="
                                            isB2C ||
                                            (formData.IdClient !== '' &&
                                                formData.IdDriver !== '')
                                        " -->
                                    <div v-if="stepsToAdd.length">
                                        <div
                                            class="mt-2 rounded-md p-4 border-2"
                                        >
                                            <div
                                                v-for="(
                                                    stepToAdd, index
                                                ) in stepsToAdd"
                                                :key="index"
                                                class="px-2 border-l-2 border-r-2"
                                                :class="
                                                    stepToAdd.Type === 'pickup'
                                                        ? 'rounded-t-md pt-2 border-t-2'
                                                        : 'rounded-b-md mb-1 border-b-2'
                                                "
                                            >
                                                <div class="px-3">
                                                    <div
                                                        class="flex items-center justify-between mr-4"
                                                    >
                                                        {{
                                                            $t(
                                                                stepToAdd.Type ===
                                                                    'pickup'
                                                                    ? 'pick_up_label'
                                                                    : 'delivery_label'
                                                            )
                                                        }}
                                                        <icon-button
                                                            v-if="
                                                                stepToAdd.Type ===
                                                                    'pickup' &&
                                                                ((stepsToAdd.length >
                                                                    2 &&
                                                                    index >
                                                                        0) ||
                                                                    formData
                                                                        .OrderSteps
                                                                        .length)
                                                            "
                                                            :icon="'trash-can'"
                                                            class="ml-3"
                                                            :noStyle="true"
                                                            :iconStyle="'fa-md cursor-pointer  error-msg'"
                                                            :title="
                                                                $t('delete')
                                                            "
                                                            @click="
                                                                removeTempSteps(
                                                                    index
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                    <hr />

                                                    <div
                                                        v-if="
                                                            !showForm.includes(
                                                                index
                                                            )
                                                        "
                                                        class="mb-2 mt-3 items-center flex flex-row"
                                                    >
                                                        <Multiselect
                                                            :key="refreshMS"
                                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                            v-model="
                                                                stepToAdd.IdClientAddress
                                                            "
                                                            :options="
                                                                addressOptions
                                                            "
                                                            :loading="
                                                                addressOptions.length ===
                                                                    0 &&
                                                                formData.IdClient !==
                                                                    ''
                                                            "
                                                            label="name"
                                                            track-by="name"
                                                            :searchable="true"
                                                            :placeholder="
                                                                $t(
                                                                    'can_select_address'
                                                                )
                                                            "
                                                            :allow-empty="false"
                                                            :can-clear="false"
                                                            @select="
                                                                setAddressInfo(
                                                                    stepToAdd.IdClientAddress,
                                                                    index
                                                                )
                                                            "
                                                        ></Multiselect>
                                                    </div>
                                                    <p
                                                        v-if="
                                                            stepToAdd.IdClientAddress !==
                                                                0 &&
                                                            !showForm.includes(
                                                                index
                                                            )
                                                        "
                                                        class="text-xs flex mb-2 justify-center font-normal"
                                                    >
                                                        {{
                                                            `${
                                                                stepToAdd.Address1
                                                            } ${
                                                                stepToAdd.Address2 !==
                                                                    'null' &&
                                                                stepToAdd.Address2 !==
                                                                    null &&
                                                                stepToAdd.Address2 !==
                                                                    ''
                                                                    ? stepToAdd.Address2
                                                                    : ''
                                                            } 
                                                            ${
                                                                stepToAdd.PostalCode
                                                            } ${
                                                                stepToAdd.City
                                                            } `
                                                        }}
                                                    </p>
                                                </div>
                                                <button
                                                    v-if="
                                                        !showForm.includes(
                                                            index
                                                        )
                                                    "
                                                    type="button"
                                                    @click="
                                                        showAddressForm(index)
                                                    "
                                                    class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                >
                                                    <span
                                                        class="inline-block ml-1 font-bold"
                                                        ><fa-icon
                                                            icon="plus"
                                                            class="mr-2"
                                                        ></fa-icon
                                                        >{{
                                                            $t(
                                                                'new_address_generic'
                                                            )
                                                        }}</span
                                                    >
                                                </button>
                                                <div
                                                    v-if="
                                                        showForm.includes(index)
                                                    "
                                                    class="px-3"
                                                >
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'address_name'
                                                                ) + ' *'
                                                            }}</label
                                                        >
                                                        <input
                                                            v-model="
                                                                stepToAdd.AddressName
                                                            "
                                                            autocomplete="name"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur="
                                                                v$.stepToAdd
                                                                    .AddressName
                                                                    .$touch;
                                                            " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].AddressName"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                            >{{
                                                                $t('address') +
                                                                ' 1 *'
                                                            }}
                                                        </label>
                                                        <input
                                                            v-model="
                                                                stepToAdd.Address1
                                                            "
                                                            :id="`autocompleteAddStep_${index}`"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur="
                                                                v$.stepToAdd
                                                                    .Address1
                                                                    .$touch;
                                                            " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].Address1"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                            >{{
                                                                $t('address')
                                                            }}
                                                            2</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.Address2
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'postal_code'
                                                                ) + ' *'
                                                            }}</label
                                                        >
                                                        <input
                                                            id="postal_code"
                                                            autocomplete="postal-code"
                                                            v-model="
                                                                stepToAdd.PostalCode
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur=" v$.stepToAdd
                                                        .PostalCode .$touch; " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].PostalCode"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                            >{{
                                                                $t('city') +
                                                                ' *'
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.City
                                                            "
                                                            id="locality"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur=" v$.stepToAdd
                                                        .City .$touch; " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].City"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        @click="
                                                            showAddressForm(
                                                                index
                                                            )
                                                        "
                                                        class="transition duration-200 mx-5 mt-2 mb-3 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            ><fa-icon
                                                                icon="undo"
                                                                class="mr-2"
                                                            ></fa-icon
                                                            >{{
                                                                $t(
                                                                    'back_to_predefined_addresses'
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('on') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="date"
                                                        :min="minDate"
                                                        :max="maxDate"
                                                        v-model="
                                                            stepToAdd.stepDate
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                        @blur="
                                                            setExtraInfos(
                                                                index,
                                                                stepToAdd.stepDate
                                                            )
                                                        "
                                                        @focus="
                                                            resetMinMaxDate(
                                                                index
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .stepsToAdd.$each
                                                        .$response.$errors[
                                                        index
                                                    ].stepDate"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        new Date(
                                                            stepToAdd.stepDate
                                                        ) > new Date(maxDate) ||
                                                        new Date(
                                                            stepToAdd.stepDate
                                                        ) < new Date(minDate)
                                                    "
                                                    class="text-xs italic mt-1 mb-3"
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $tc(
                                                                new Date(
                                                                    stepToAdd.stepDate
                                                                ) >
                                                                    new Date(
                                                                        maxDate
                                                                    )
                                                                    ? 'date_error_after'
                                                                    : 'date_error_before',
                                                                1
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        stepToAdd.Hours &&
                                                        openingHoursForDay(
                                                            stepToAdd.Hours,
                                                            findStepDay(
                                                                stepToAdd.stepDate
                                                            )
                                                        ).length > 0
                                                    "
                                                    class="mb-3 text-xs"
                                                >
                                                    <div
                                                        class="mb-1 justify-center flex"
                                                    >
                                                        {{
                                                            `${$t(
                                                                'opening_hours'
                                                            )} ${$t(
                                                                'for'
                                                            )} ${$t(
                                                                days[
                                                                    findStepDay(
                                                                        stepToAdd.stepDate
                                                                    )
                                                                ]
                                                            )} ${
                                                                stepToAdd.stepDate
                                                            } `
                                                        }}
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            hour, index
                                                        ) in openingHoursForDay(
                                                            stepToAdd.Hours,
                                                            findStepDay(
                                                                stepToAdd.stepDate
                                                            )
                                                        )"
                                                        :key="index"
                                                        class="justify-center flex"
                                                    >
                                                        {{
                                                            `${hour.start} - ${hour.end}`
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex justify-center items-center mb-3"
                                                >
                                                    <span class="mr-2 text-lg">
                                                        *
                                                    </span>
                                                    <div id="moment-select">
                                                        <Multiselect
                                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                            v-model="
                                                                stepToAdd.Moment
                                                            "
                                                            :options="
                                                                stepMomentOptions
                                                            "
                                                            :loading="
                                                                stepMomentOptions.length ===
                                                                0
                                                            "
                                                            label="name"
                                                            track-by="name"
                                                            :allow-empty="false"
                                                            :can-clear="false"
                                                        ></Multiselect>
                                                    </div>
                                                    <div
                                                        class="ml-2"
                                                        v-if="
                                                            stepToAdd.Moment ===
                                                                'between' ||
                                                            stepToAdd.Moment ===
                                                                'from'
                                                        "
                                                    >
                                                        <input
                                                            type="time"
                                                            v-model="
                                                                stepToAdd.FromDateTime
                                                            "
                                                            @blur="
                                                                timesAreCorrect(
                                                                    false,
                                                                    index
                                                                )
                                                            "
                                                            @input="
                                                                resetBadTimes()
                                                            "
                                                            class="border text-sm rounded px-3 py-2 w-full"
                                                        />
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .stepsToAdd
                                                                .$each.$response
                                                                .$errors[index]
                                                                .FromDateTime"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ml-2"
                                                        v-if="
                                                            stepToAdd.Moment ===
                                                                'between' ||
                                                            stepToAdd.Moment ===
                                                                'before'
                                                        "
                                                    >
                                                        <input
                                                            type="time"
                                                            v-model="
                                                                stepToAdd.BeforeDateTime
                                                            "
                                                            @blur="
                                                                timesAreCorrect(
                                                                    false,
                                                                    index
                                                                )
                                                            "
                                                            @input="
                                                                resetBadTimes()
                                                            "
                                                            class="border text-sm rounded px-3 py-2 w-full"
                                                        />
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .stepsToAdd
                                                                .$each.$response
                                                                .$errors[index]
                                                                .BeforeDateTime"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-if="
                                                        badTimeRange &&
                                                        badTimeRangeItem ===
                                                            stepToAdd
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t('bad_time_range')
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-if="
                                                        badTimeRangeSteps ||
                                                        delivBeforePickUp
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            `${$t(
                                                                stepToAdd.Type ===
                                                                    'pickup'
                                                                    ? 'bad_time_range_steps_2'
                                                                    : 'bad_time_range_steps'
                                                            )} : ${previousPickUpTimesBis(
                                                                stepToAdd.Type ===
                                                                    'pickup'
                                                                    ? index + 1
                                                                    : index - 1
                                                            )}`
                                                        }}
                                                    </div>
                                                </div>

                                                <span
                                                    v-if="!isOdd(index)"
                                                    class="grid grid-cols-4 gap-1 py-2"
                                                >
                                                    <div
                                                        class="mb-3 col-span-2 items-center px-3"
                                                        :class="
                                                            driverOptions.length ===
                                                            0
                                                                ? 'selectMS'
                                                                : ''
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                canAssignDriver ===
                                                                true
                                                            "
                                                        >
                                                            <label
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                >{{
                                                                    $t('driver')
                                                                }}</label
                                                            >
                                                            <Multiselect
                                                                :key="refreshMS"
                                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                v-model="
                                                                    stepToAdd.IdDriver
                                                                "
                                                                :options="
                                                                    driverOptions
                                                                "
                                                                :loading="
                                                                    driverOptions.length ===
                                                                        0 &&
                                                                    formData.IdClient !==
                                                                        ''
                                                                "
                                                                label="name"
                                                                trackBy="name"
                                                                :searchable="
                                                                    true
                                                                "
                                                                :placeholder="
                                                                    driverOptions.length
                                                                        ? $t(
                                                                              'select_one'
                                                                          )
                                                                        : $t(
                                                                              'no_available_for_date'
                                                                          )
                                                                "
                                                                :allow-empty="
                                                                    false
                                                                "
                                                                :can-clear="
                                                                    true
                                                                "
                                                            ></Multiselect>
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="mb-1 col-span-2 items-center px-3"
                                                        :class="
                                                            vehicleOptions.length ===
                                                            0
                                                                ? 'selectMS'
                                                                : ''
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                canAssignVehicle ===
                                                                true
                                                            "
                                                        >
                                                            <label
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                >{{
                                                                    $t(
                                                                        'vehicle'
                                                                    )
                                                                }}</label
                                                            >
                                                            <Multiselect
                                                                :key="refreshMS"
                                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                v-model="
                                                                    stepToAdd.IdVehicle
                                                                "
                                                                :options="
                                                                    vehicleOptions
                                                                "
                                                                :loading="
                                                                    vehicleOptions.length ===
                                                                        0 &&
                                                                    formData.IdClient !==
                                                                        ''
                                                                "
                                                                label="name"
                                                                track-by="name"
                                                                :searchable="
                                                                    true
                                                                "
                                                                :placeholder="
                                                                    vehicleOptions.length
                                                                        ? $t(
                                                                              'select_one'
                                                                          )
                                                                        : $t(
                                                                              'no_available_for_date'
                                                                          )
                                                                "
                                                                :allow-empty="
                                                                    false
                                                                "
                                                                :can-clear="
                                                                    true
                                                                "
                                                            ></Multiselect>
                                                        </span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            $route.name === 'new_order' ||
                                            (stepsToAdd.length &&
                                                stepsToAdd[
                                                    stepsToAdd.length - 1
                                                ].IdClientAddress !== 0) ||
                                            formData.OrderSteps.length ||
                                            (stepsToAdd.length &&
                                                !v$.stepsToAdd.$silentErrors
                                                    .length)
                                        "
                                        class="flex p-4 items-center justify-center mt-4"
                                    >
                                        <button
                                            type="button"
                                            :disabled="
                                                badTimeRangeSteps ||
                                                delivBeforePickUp ||
                                                badTimeRange ||
                                                v$.stepsToAdd.$silentErrors
                                                    .length
                                            "
                                            class="themed-button items-center text-base mr-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            @click="addStepsToAdd()"
                                        >
                                            <fa-icon
                                                icon="plus"
                                                class="mr-2 fa-sm"
                                            ></fa-icon>
                                            {{ $t('add_steps') }}
                                        </button>

                                        <button
                                            v-if="
                                                stepsToAdd.length &&
                                                formData.OrderSteps.length
                                            "
                                            type="button"
                                            class="text-base focus:outline-none items-center flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                            @click="addStepsToAdd(true)"
                                        >
                                            <fa-icon
                                                icon="xmark"
                                                class="mr-2 fa-sm"
                                            ></fa-icon>
                                            {{ $t('cancel') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange ||
                                        v$.stepsToAdd.$silentErrors.length
                                    "
                                    type="submit"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon icon="spinner" spin></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div> -->

                        <!-- <div class="mt-2 rounded-md p-4 border-2">
                            <div class="mb-5 flex">
                                <span class="text-lg">
                                    {{ $t('pricing') }}
                                    <hr />
                                </span>
                            </div>
                        </div> -->
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange ||
                                        v$.stepsToAdd.$silentErrors.length
                                    "
                                    type="button"
                                    @click.prevent="
                                        $route.params.id
                                            ? updateOrder()
                                            : createOrder()
                                    "
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon icon="spinner" spin></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{
                                            $t(
                                                $route.name === 'new_order'
                                                    ? 'save'
                                                    : 'update'
                                            )
                                        }}
                                    </span>
                                </button>
                                <button
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange ||
                                        v$.stepsToAdd.$silentErrors.length
                                    "
                                    type="button"
                                    @click.prevent="
                                        $route.params.id
                                            ? updateOrder(true)
                                            : createOrder(true)
                                    "
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon icon="spinner" spin></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{
                                            $t(
                                                $route.name === 'new_order'
                                                    ? 'save_and_copy_order'
                                                    : 'update_and_copy_order'
                                            )
                                        }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <modal
                    v-if="show"
                    :headerText="'add_step'"
                    :buttonText="'save'"
                    :mxWidth="'w-2/3'"
                    :buttonAlign="'justify-center'"
                    id="addStepModal"
                    :spin="false"
                    :creating="
                        new Date(newStep.stepDate) > new Date(maxDate) ||
                        new Date(newStep.stepDate) < new Date(minDate)
                    "
                    @closeModal="closeModal()"
                    @sendFromModal="submitModal(true, stepToUpdate)"
                >
                    <template #body>
                        <div class="px-3 mt-5">
                            <div class="mb-3 mt-3items-center flex flex-row">
                                <Multiselect
                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                    v-model="deliveryAddressId"
                                    :options="addressOptions"
                                    :loading="addressOptions.length === 0"
                                    label="name"
                                    track-by="name"
                                    :searchable="true"
                                    :placeholder="$t('can_select_address')"
                                    :allow-empty="false"
                                    :can-clear="false"
                                    @select="setAddressInfo(deliveryAddressId)"
                                ></Multiselect>
                            </div>
                            <hr />
                        </div>
                        <div class="px-3">
                            <div class="mb-3 mt-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('address_name') + ' *' }}</label
                                >
                                <input
                                    v-model="newStep.AddressName"
                                    @blur="v$.newStep.AddressName.$touch"
                                    autocomplete="name"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.AddressName.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="flex justify-center mt-3 mb-3">
                                <div class="flex items-center mr-4">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        v-model="newStep.Type"
                                        value="pickup"
                                        name="inline-radio-group"
                                        class="cursor-pointer darky"
                                    />
                                    <label
                                        for="inline-radio"
                                        class="ml-2 text-base font-medium"
                                        >{{ $t('pick_up_label') + ' *' }}</label
                                    >
                                </div>
                                <div class="flex items-center mr-4">
                                    <input
                                        id="inline-2-radio"
                                        type="radio"
                                        v-model="newStep.Type"
                                        class="cursor-pointer darky"
                                        value="delivery"
                                        name="inline-radio-group"
                                    />
                                    <label
                                        for="inline-2-radio"
                                        class="ml-2 text-base font-medium"
                                        >{{
                                            $t('delivery_label') + ' *'
                                        }}</label
                                    >
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.Type.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>

                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('address') + ' 1 *' }}
                                </label>
                                <input
                                    v-model="newStep.Address1"
                                    id="autocompleteAddStep"
                                    @blur="v$.newStep.Address1.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.Address1.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>

                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('address') }} 2</label
                                >
                                <input
                                    type="text"
                                    v-model="newStep.Address2"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('postal_code') + ' *' }}</label
                                >
                                <input
                                    id="postal_code"
                                    autocomplete="postal-code"
                                    v-model="newStep.PostalCode"
                                    @blur="v$.newStep.PostalCode.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.PostalCode.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('city') + ' *' }}</label
                                >
                                <input
                                    type="text"
                                    v-model="newStep.City"
                                    id="locality"
                                    @blur="v$.newStep.City.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.City.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('on') + ' *' }}</label
                                >
                                <input
                                    type="date"
                                    :min="minDate"
                                    :max="maxDate"
                                    v-model="newStep.stepDate"
                                    @blur="v$.newStep.stepDate.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.stepDate.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    new Date(newStep.stepDate) >
                                        new Date(maxDate) ||
                                    new Date(newStep.stepDate) <
                                        new Date(minDate)
                                "
                                class="text-xs italic mt-1 mb-3"
                            >
                                <div class="error-msg">
                                    {{
                                        $tc(
                                            new Date(newStep.stepDate) >
                                                new Date(maxDate)
                                                ? 'date_error_after'
                                                : 'date_error_before',
                                            1
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    newStep.Hours &&
                                    openingHoursForDay(
                                        newStep.Hours,
                                        findStepDay(newStep.stepDate)
                                    ).length > 0
                                "
                                class="mb-3"
                            >
                                <div class="mb-1 justify-center flex">
                                    {{
                                        `${$t('opening_hours')} ${$t(
                                            'for'
                                        )} ${$t(
                                            days[findStepDay(newStep.stepDate)]
                                        )} ${newStep.stepDate} `
                                    }}
                                </div>
                                <div
                                    v-for="(hour, index) in openingHoursForDay(
                                        newStep.Hours,
                                        findStepDay(newStep.stepDate)
                                    )"
                                    :key="index"
                                    class="justify-center flex"
                                >
                                    {{ `${hour.start} - ${hour.end}` }}
                                </div>
                            </div>
                            <div class="flex justify-center items-center mb-3">
                                <span class="mr-2 text-lg"> * </span>
                                <div id="moment-select">
                                    <Multiselect
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        v-model="newStep.Moment"
                                        :options="stepMomentOptions"
                                        :loading="
                                            stepMomentOptions.length === 0
                                        "
                                        label="name"
                                        track-by="name"
                                        :allow-empty="false"
                                        :can-clear="false"
                                    ></Multiselect>
                                </div>
                                <div
                                    class="ml-2"
                                    v-if="
                                        newStep.Moment === 'between' ||
                                        newStep.Moment === 'from'
                                    "
                                >
                                    <input
                                        type="time"
                                        v-model="newStep.FromDateTime"
                                        @blur="v$.newStep.FromDateTime.$touch"
                                        @input="resetBadTimes()"
                                        class="border text-sm rounded px-3 py-2 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.newStep.FromDateTime
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="ml-2"
                                    v-if="
                                        newStep.Moment === 'between' ||
                                        newStep.Moment === 'before'
                                    "
                                >
                                    <input
                                        type="time"
                                        v-model="newStep.BeforeDateTime"
                                        @blur="v$.newStep.BeforeDateTime.$touch"
                                        @input="resetBadTimes()"
                                        class="border text-sm rounded px-3 py-2 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.newStep
                                            .BeforeDateTime.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="badTimeRange"
                            >
                                <div class="error-msg">
                                    {{ $t('bad_time_range') }}
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="badTimeRangeSteps"
                            >
                                <div class="error-msg">
                                    {{
                                        `${$t(
                                            'bad_time_range_steps'
                                        )} : ${previousPickUpTimes(
                                            stepToUpdate
                                        )}`
                                    }}
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="delivBeforePickUp"
                            >
                                <div class="error-msg">
                                    {{
                                        `${$t(
                                            'bad_time_range_steps_2'
                                        )} : ${previousPickUpTimes(
                                            stepToUpdate
                                        )}
                                        `
                                    }}
                                </div>
                            </div>
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                    </template>
                </modal>
                <modal
                    v-if="showDeleteConfirmModal"
                    :headerText="deleteModalHeader"
                    :buttonText="'confirm'"
                    id="deletemodal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="stepsDeleteConfirmation(false)"
                    @sendFromModal="stepsDeleteConfirmation(true)"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center mt-8">
                                {{ $t(deleteModalText) }}
                            </div>
                            <div
                                class="flex text-sm italic justify-center mt-4"
                            >
                                {{ $t(elementToDelete) }}
                            </div>
                        </div></template
                    ></modal
                >
            </div>
            <!-- B2C -->
            <B2C
                v-else-if="isB2C !== null && isB2C === true"
                :reference="formData.Reference"
                class="darky"
                @toUpdate="$emit('goToUpdateOrder', $event)"
                @packageTracking="$emit('packageTracking', $event)"
            />
        </div>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf, required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

import keys from '@/resources/keys'
import IconButton from '@/components/atoms/IconButton.vue'
import Modal from '@/components/atoms/Modal.vue'
import prefixes from '@/resources/prefixes'
import BackTo from '@/components/elements/BackTo.vue'
import B2C from './B2CCreateOrUpdate.vue'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import { useOrderStore } from '@/stores/orderStore'
import googleScriptLoader from '@/mixins/googleScript'
import IconSelect from '@/components/elements/IconSelect.vue'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/
)

const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const dateIsValid = helpers.regex(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
)

export default {
    name: 'CreateOrUpdateOrder',
    components: { IconButton, Multiselect, Modal, BackTo, B2C, IconSelect },
    mixins: [copyToClipboard, googleScriptLoader],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]
        const prefix =
            prefixes[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]
        return { key, prefix, v$: useVuelidate() }
    },
    watch: {
        $route(to, from) {
            if (from.name === 'update_order' && to.name === 'new_order') {
                this.newDeliveryName = `${this.$t(
                    'delivery_title'
                )} ${new Date().toISOString().substr(0, 10)}`

                if (this.orderToDuplicate !== null && this.isB2C === false) {
                    this.isDuplicate = true
                    this.isEditPackage = false

                    this.contactOptions = []

                    const contactInterval = setInterval(() => {
                        if (this.contacts) {
                            this.contacts.forEach((contact) => {
                                clearInterval(contactInterval)
                                this.noContactForClient = false
                                const option = {
                                    name: `${contact.FirstName}  ${contact.LastName}`,
                                    value: +contact.IdUser,
                                }
                                this.contactOptions.push(option)
                            })
                            this.refreshMS += 1
                        }
                    }, 200)
                    this.containerOptions = []
                    const containerInt = setInterval(() => {
                        if (this.containers) {
                            clearInterval(containerInt)
                            this.containers.forEach((container) => {
                                this.containerOptions.push({
                                    name:
                                        container.Name.includes('_') ||
                                        container.Name === 'pallet'
                                            ? this.$t(container.Name)
                                            : container.Name,
                                    value: container.Id,
                                    icon: container.Name.includes('pallet')
                                        ? 'pallet'
                                        : container.Name.includes('letter')
                                        ? 'envelope'
                                        : container.Name.includes('box')
                                        ? 'box'
                                        : 'star',
                                })
                                this.refreshMS += 1
                            })
                        }
                    }, 200)
                    this.orderToDuplicate.order_steps.forEach((step) => {
                        step.Status = 'new'
                        step.ExecutedDateTime = null
                        step.DeliveryCode = null
                        step.Id = ''
                        step.CreationUserId = this.user.id
                        step.ModificationUserId = this.user.id
                        step.ModificationDate = ''
                        step.CreationDate = ''
                        step.IdOrder = null
                        step.IdInvoice = null
                        step.IdClientAddress = +step.IdClientAddress

                        // delete (step.clientAddresses)

                        step.IdVehicle = +step.IdVehicle
                        step.IdDriver = +step.IdDriver

                        this.stepsToAdd.push(structuredClone(step))
                    })
                    this.minDate = this.stepsToAdd[0].stepDate
                    this.minMax =
                        this.stepsToAdd[this.stepsToAdd.length - 1].stepDate

                    const newPackages = []
                    this.orderToDuplicate.order_packages.forEach((pack) => {
                        pack.Id = ''
                        pack.CreationUserId = this.user.id
                        pack.ModificationUserId = this.user.id
                        pack.ModificationDate = ''
                        pack.CreationDate = ''
                        pack.IdOrder = ''
                        pack.Reference = undefined
                        pack.LastDeliveryCodeDate = null
                        pack.LastDeliveryCode = null

                        newPackages.push(structuredClone(pack))
                    })
                    const newForm = structuredClone(this.orderToDuplicate)

                    // this.orderToDuplicate = null
                    this.formData = {
                        CommentClient: newForm.comment_client,
                        CommentInternal: newForm.comment_internal,

                        CreationUserId: this.user.id,
                        DateBegin: newForm.begin_date,
                        DateEnd: newForm.end_date,
                        DateReception: null,
                        Duration: newForm.duration,
                        Id: '',
                        IdClient: newForm.client_id,
                        IdDriver: newForm.driver_id,
                        IdVehicle: newForm.vehicle_id,

                        IdExternal: newForm.id_external,
                        IdInvoice: newForm.invoice_id,
                        IdUserContact: newForm.user_contact_id,
                        Method: newForm.method,

                        ModificationUserId: this.user.id,
                        OrderPackages: newPackages,
                        OrderSteps: [],
                        OrderOptions: newForm.order_options,

                        // PacketNumber: newForm.packet_number,
                        PacketValue: newForm.packet_value,
                        Reference: '',
                        Status: 'new',
                        Type: newForm.type,
                        VoucherNumber: newForm.voucher_number,
                        Weight: newForm.weight,
                    }
                    this.orderToDuplicate = null

                    // this.vehicleOptions = []
                    // this.loadAvailableVehicles(
                    //     newForm.begin_date
                    // )
                    this.selectedTemp =
                        newForm.order_packages[0].TemperatureOption ?? 'none'
                    this.packageQuantity = newForm.order_packages.length
                    this.pickedContainerId = newForm.order_packages[0]
                        .IdContainer
                        ? newForm.order_packages[0].IdContainer
                        : ''
                    this.selectedMethod = this.methodOptions.find(
                        (option) => option.name === this.formData.Method
                    )

                    const clientInterval = setInterval(() => {
                        if (this.clients) {
                            this.clients.forEach((client) => {
                                clearInterval(clientInterval)

                                const option = {
                                    name: client.company_name,
                                    value: client.id,
                                }
                                this.clientOptions.push(option)
                            })
                            this.refreshMS += 1
                        }
                    }, 200)

                    const clientAddressesPayload = {
                        clientId: this.formData.IdClient,
                        query: [],
                        filter: [],
                        perPage: 100000000000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                    }
                    this.clientStore
                        .fetchAddresses(clientAddressesPayload)
                        .then(() => {
                            const clientAddresses = setInterval(() => {
                                if (this.clientAddresses) {
                                    this.clientAddresses.forEach((address) => {
                                        clearInterval(clientAddresses)

                                        const option = {
                                            name: address.name,
                                            value: address.id,
                                        }
                                        this.addressOptions.push(option)
                                    })
                                    this.refreshMS += 1
                                }
                            }, 1000)
                        })
                    this.refresh += 1
                    this.refreshMS += 1
                } else {
                    // this.packageHeight = 0
                    this.packageQuantity = 1
                    this.containerName = ''
                    this.selectedTemp = 'none'
                    this.pickedContainerId = ''

                    this.isEditPackage = false
                    this.clientStore.$patch({
                        containers: null,
                        currentContacts: null,
                        current: null,
                        currentClientContactIds: null,
                        currentContainer: null,
                    })
                    this.deliveryAddressId = null
                    this.currentOrderIntervall = null
                    this.contactOptions = []
                    this.containerOptions = []

                    this.noContactForClient = false
                    this.clientOptions = []
                    this.driverOptions = []
                    this.vehicleOptions = []
                    if (!this.$route.params.id) {
                        this.currentOrder = null
                        const clientsPayload = {
                            query: [],
                            filter: [],
                            perPage: 10000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        this.clientStore.search(clientsPayload).then(() => {
                            const clientInterval = setInterval(() => {
                                if (this.clients) {
                                    this.clients.forEach((client) => {
                                        clearInterval(clientInterval)

                                        const option = {
                                            name: client.company_name,
                                            value: client.id,
                                        }
                                        this.clientOptions.push(option)
                                    })
                                }
                            }, 200)
                        })
                    }

                    this.date = new Date()
                    this.formData = {
                        Name: '',
                        IdClient: '',
                        IdUserContact: '',
                        IdDriver: '',
                        IdInvoice: '',
                        Status: 'new',
                        Method: 'normal',
                        Type: 'occasionnal',
                        Latitude: '',
                        Longitude: '',
                        VoucherNumber: 0,
                        CommentInternal: '',
                        CommentClient: '',
                        OrderStepsHTML: null,
                        CreationUserId: this.user.id,
                        OrderSteps: [],
                        OrderPackages: [],
                        DateBegin: '',
                        DateEnd: '',
                        OrderOptions: null,
                        // InvoiceNumber: `${
                        //     this.prefix.invoice
                        // }${this.date.getFullYear()}${this.date.getMonth()}${this.date.getDate()}${this.date.getHours()}${this.date.getMinutes()}${this.date.getSeconds()}`,
                        OrderOption: [],
                        ModificationUserId: '',
                        ModificationDate: '',
                        PacketNumber: 0,
                    }
                    this.stepsToAdd = [
                        {
                            Type: 'pickup',
                            Status: 'new',
                            StepNumber: 1,
                            IdClientAddress: 0,
                            AddressName: '',
                            Address1: '',
                            Address2: '',
                            Address3: '',
                            Address4: '',
                            PostalCode: '',
                            City: '',
                            Country: 'FRA',
                            Latitude: '',
                            Longitude: '',
                            Moment: 'between',
                            FromDateTime: '08:00',
                            BeforeDateTime: '18:00',
                            TypeVehicle: '',
                            Hours: null,
                            IdVehicle: '',
                            IdDriver: '',
                            stepDate: this.minDate,
                        },
                        {
                            Type: 'delivery',
                            Status: 'new',
                            StepNumber: 2,
                            IdClientAddress: 0,
                            AddressName: '',
                            Address1: '',
                            Address2: '',
                            Address3: '',
                            Address4: '',
                            PostalCode: '',
                            City: '',
                            Country: 'FRA',
                            Latitude: '',
                            Longitude: '',
                            Moment: 'between',
                            FromDateTime: '08:00',
                            BeforeDateTime: '18:00',
                            TypeVehicle: '',
                            Hours: null,
                            IdVehicle: '',
                            IdDriver: '',
                            stepDate: this.minDate,
                        },
                    ]
                    this.addPackage()

                    this.loadAvailableDrivers(this.minDate)
                    this.loadAvailableVehicles(this.minDate)
                }
            }
        },
        selectedTemp() {
            this.formData.OrderPackages[0].TemperatureOption = this.selectedTemp
            this.refreshMS += 1
        },
        selectedMethod() {
            this.formData.Method = this.selectedMethod.value
        },
        //   pickUpAddressId() {
        //     if (this.pickUpAddressId !== null) {
        //         this.setPickUp(this.pickUpAddressId)
        //     }
        // },
        packageQuantity() {
            this.formData.OrderPackages[0].Quantity = this.packageQuantity
            this.refreshMS += 1
        },
        packageHeight() {
            this.formData.OrderPackages[0].Height = this.packageHeight
            this.refreshMS += 1
        },
        deliveryAddressId() {
            if (this.deliveryAddressId !== null) {
                this.setAddressInfo(this.deliveryAddressId)
                this.refreshMS += 1
            }
        },
        pickedContainerId() {
            if (
                this.pickedContainerId &&
                this.pickedContainerId !== '' &&
                +this.pickedContainerId !== 0
            ) {
                this.clientStore.$patch({ currentContainer: null })
                this.clientStore
                    .fetchOneContainer(this.pickedContainerId)
                    .then(() => {
                        const interval = setInterval(() => {
                            if (this.currentContainer !== null) {
                                clearInterval(interval)

                                this.containerName = this.currentContainer.Name

                                this.setPackageContainer(this.currentContainer)
                                this.refreshMS += 1
                            } else {
                                this.containerName = ''
                            }
                        }, 100)
                    })
            } else {
                this.containerName = ''
            }
        },

        currentOrder() {
            const stepsWithDate = []
            if (this.currentOrder && this.isB2C === false) {
                if (this.currentOrder.order_steps.length > 0) {
                    this.currentOrder.order_steps.forEach((step) => {
                        step.IdVehicle = +step.IdVehicle
                        step.IdDriver = +step.IdDriver

                        if (step.Moment === 'between') {
                            step.stepDate = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' ')
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                step.FromDateTime.indexOf(' ') + 1
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.length - 3
                            )
                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                step.BeforeDateTime.indexOf(' ') + 1
                            )
                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.length - 3
                            )
                        } else if (step.Moment === 'from') {
                            step.stepDate = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' ')
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                step.FromDateTime.indexOf(' ') + 1
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.length - 3
                            )
                            step.BeforeDateTime = ''
                        } else if (step.Moment === 'before') {
                            step.stepDate = step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.indexOf(' ')
                            )

                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                step.BeforeDateTime.indexOf(' ') + 1
                            )
                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.length - 3
                            )
                            step.FromDateTime = ''
                        }
                        stepsWithDate.push(structuredClone(step))
                    })
                }

                this.formData = {
                    CommentClient: this.currentOrder.comment_client,
                    CommentInternal: this.currentOrder.comment_internal,
                    CreationDate: this.currentOrder.creation_date,
                    CreationUserId: this.currentOrder.creation_user_id,
                    DateBegin: this.currentOrder.begin_date,
                    DateEnd: this.currentOrder.end_date,
                    DateReception: this.currentOrder.reception_date,
                    Duration: this.currentOrder.duration,
                    Id: this.currentOrder.id,
                    IdClient: this.currentOrder.client_id,
                    IdDriver: this.currentOrder.driver_id,
                    IdVehicle: this.currentOrder.vehicle_id,

                    IdExternal: this.currentOrder.id_external,
                    IdInvoice: this.currentOrder.invoice_id,
                    IdUserContact: this.currentOrder.user_contact_id,
                    Method: this.currentOrder.method,
                    ModificationDate: this.currentOrder.modification_date,
                    ModificationUserId: this.currentOrder.modification_user_id,
                    OrderPackages: this.currentOrder.order_packages,
                    OrderSteps: stepsWithDate,
                    OrderOptions: this.currentOrder.order_options,

                    // PacketNumber: this.currentOrder.packet_number,
                    PacketValue: this.currentOrder.packet_value,
                    Reference: this.currentOrder.reference,
                    Status: this.currentOrder.status,
                    Type: this.currentOrder.type,
                    VoucherNumber: this.currentOrder.voucher_number,
                    Weight: this.currentOrder.weight,
                }
                // this.vehicleOptions = []
                // this.loadAvailableVehicles(
                //     this.currentOrder.begin_date
                // )
                this.selectedTemp =
                    this.currentOrder.order_packages[0].TemperatureOption ??
                    'none'
                this.packageQuantity = this.currentOrder.order_packages.length
                this.pickedContainerId = this.currentOrder.order_packages[0]
                    .IdContainer
                    ? this.currentOrder.order_packages[0].IdContainer
                    : ''
                this.isEditPackage = true
                this.selectedMethod = this.methodOptions.find(
                    (option) => option.name === this.formData.Method
                )
                this.refreshMS += 1
            }
        },
        // this.clientStore.fetchOne(
        //     this.currentOrder.client_id
        // )
    },

    // TODO au changement de route faire en sorte  de refresh le contenu entre new et update
    created() {
        window.scrollTo(0, 0)
        const isB2CInterval = setInterval(() => {
            if (this.isB2C !== null) {
                clearInterval(isB2CInterval)

                if (this.isB2C === false) {
                    if (this.$route.name === 'new_order') {
                        this.newDeliveryName = `${this.$t(
                            'delivery_title'
                        )} ${new Date().toISOString().substr(0, 10)}`
                    }
                    this.clientStore.$patch({
                        containers: null,
                        currentContacs: null,
                    })
                    this.noContactForClient = false

                    const driversInterval = setInterval(() => {
                        if (this.drivers) {
                            clearInterval(driversInterval)
                            this.drivers.forEach((driver) => {
                                const name = `${driver.firstname} ${driver.lastname}`
                                const option = {
                                    name,
                                    value: +driver.id,
                                }
                                this.driverOptions.push(option)
                            })
                        }
                    }, 200)

                    const vehiclesInterval = setInterval(() => {
                        if (this.vehicles) {
                            clearInterval(vehiclesInterval)
                            this.vehicles.forEach((vehicle) => {
                                const name = `${vehicle.name} - ${this.$t(
                                    vehicle.type
                                )}`
                                const option = {
                                    name,
                                    value: +vehicle.id,
                                }
                                this.vehicleOptions.push(option)
                            })
                        }
                    }, 200)
                    // })

                    if (!this.$route.params.id) {
                        const clientsPayload = {
                            query: [],
                            filter: [],
                            perPage: 10000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        this.clientStore.search(clientsPayload)
                        const clientInterval = setInterval(() => {
                            if (this.clients) {
                                this.clients.forEach((client) => {
                                    clearInterval(clientInterval)

                                    const option = {
                                        name: client.company_name,
                                        value: client.id,
                                    }
                                    this.clientOptions.push(option)
                                })
                            }
                        }, 200)
                        this.stepsToAdd = [
                            {
                                Type: 'pickup',
                                Status: 'new',
                                StepNumber: 1,
                                IdClientAddress: 0,
                                AddressName: '',
                                Address1: '',
                                Address2: '',
                                Address3: '',
                                Address4: '',
                                PostalCode: '',
                                City: '',
                                Country: 'FRA',
                                Latitude: '',
                                Longitude: '',
                                Moment: 'between',
                                FromDateTime: '08:00',
                                BeforeDateTime: '18:00',
                                TypeVehicle: '',
                                Hours: null,
                                IdVehicle: '',
                                IdDriver: '',
                                stepDate: this.minDate,
                            },
                            {
                                Type: 'delivery',
                                Status: 'new',
                                StepNumber: 2,
                                IdClientAddress: 0,
                                AddressName: '',
                                Address1: '',
                                Address2: '',
                                Address3: '',
                                Address4: '',
                                PostalCode: '',
                                City: '',
                                Country: 'FRA',
                                Latitude: '',
                                Longitude: '',
                                Moment: 'between',
                                FromDateTime: '08:00',
                                BeforeDateTime: '18:00',
                                TypeVehicle: '',
                                Hours: null,
                                IdVehicle: '',
                                IdDriver: '',
                                stepDate: this.minDate,
                            },
                        ]
                        this.addPackage()

                        this.loadAvailableDrivers(this.minDate)
                        this.loadAvailableVehicles(this.minDate)
                    }

                    if (this.$route.params.id) {
                        this.contactOptions = []

                        const contactInterval = setInterval(() => {
                            if (this.contacts) {
                                this.contacts.forEach((contact) => {
                                    clearInterval(contactInterval)
                                    this.noContactForClient = false
                                    const option = {
                                        name: `${contact.FirstName}  ${contact.LastName}`,
                                        value: +contact.IdUser,
                                    }
                                    this.contactOptions.push(option)
                                })
                            }
                        }, 200)
                        this.containerOptions = []
                        const containerInt = setInterval(() => {
                            if (this.containers) {
                                clearInterval(containerInt)
                                this.containers.forEach((container) => {
                                    this.containerOptions.push({
                                        name:
                                            container.Name.includes('_') ||
                                            container.Name === 'pallet'
                                                ? this.$t(container.Name)
                                                : container.Name,
                                        value: container.Id,
                                        icon: container.Name.includes('pallet')
                                            ? 'pallet'
                                            : container.Name.includes('letter')
                                            ? 'envelope'
                                            : container.Name.includes('box')
                                            ? 'box'
                                            : 'star',
                                    })
                                })
                            }
                        }, 200)
                    }
                }
                if (this.formData.OrderSteps.length) {
                    this.setMinDate(this.formData.OrderSteps.length)
                }
                this.refreshMS += 1
            }
        }, 200)
    },
    data() {
        return {
            isDuplicate: false,
            days: [
                'noday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
            confirmDelete: null,
            showDeleteConfirmModal: false,
            elementToDelete: '',
            showForm: [],
            minDate: new Date().toISOString().substr(0, 10),
            maxDate: '',
            containerForAll: false,
            containerCreation: false,
            containerNameError: false,
            date: new Date(),
            refreshMS: 0,
            containerName: '',
            isEditPackage: false,
            temperatureOptions: [
                {
                    name: 'no_temp_specified',
                    value: 'none',
                    icon: 'temperature-half',
                    noTemp: true,
                },
                {
                    name: 'cool',
                    value: 'fresh',
                    icon: 'temperature-low',
                    color: 'color:dodgerblue;',
                },
                {
                    name: 'frozen',
                    value: 'frozen',
                    icon: 'temperature-arrow-down',
                    color: 'color:cyan;',
                },
                {
                    name: 'hot',
                    value: 'warm',
                    icon: 'temperature-high',
                    color: 'color:firebrick;',
                },
            ],
            selectedMethod: {
                name: 'normal',
                value: 'normal',
                icon: 'gauge',
                style: 'color:green;',
            },
            methodOptions: [
                {
                    name: 'normal',
                    value: 'normal',
                    icon: 'gauge',
                    style: 'color:green;',
                },
                {
                    name: 'fast',
                    value: 'fast',
                    icon: 'gauge-high',
                    style: 'color:orange;',
                },
                {
                    name: 'express',
                    value: 'express',
                    icon: 'rocket',
                    style: 'color: red;',
                },
            ],

            selectedTemp: 'none',
            containerOptions: [],
            noContactForClient: false,
            price: null,
            creating: false,
            show: false,
            newStep: null,
            noClientError: false,
            noDriverError: false,
            noContactError: false,
            refreshSteps: 0,
            refresh: 0,
            cards: [],
            badTimeRange: false,
            badTimeRangeItem: null,
            badTimeRangeSteps: false,
            delivBeforePickUp: false,
            stepToUpdate: null,
            packageTypeErrors: [],
            packageReferenceErrors: [],
            packageValueErrors: [],
            packageWeightErrors: [],
            packageHeightErrors: [],
            packageWidthErrors: [],
            packageLengthErrors: [],
            packageQuantityErrors: [],
            stepMomentOptions: [
                { name: this.$t('between'), value: 'between' },
                { name: this.$t('before'), value: 'before' },
                { name: this.$t('from'), value: 'from' },
            ],
            refreshToggle: 0,
            stepsToAdd: [],
            formData: {
                Name: '',
                IdClient: '',
                IdUserContact: '',
                IdDriver: '',
                IdVehicle: '',

                IdInvoice: '',
                // Reference: '',
                Status: 'new',
                Method: 'normal',
                Type: 'occasionnal',
                Latitude: '',
                Longitude: '',
                VoucherNumber: 0,
                CommentInternal: '',
                CommentClient: '',
                OrderStepsHTML: null,
                CreationUserId: '',
                OrderSteps: [],
                OrderPackages: [],
                DateBegin: '',
                DateEnd: '',
                OrderOptions: null,
                InvoiceNumber: '',
                OrderOption: [],
                ModificationUserId: '',
                ModificationDate: '',
                PacketNumber: 0,
            },

            typeOptions: [
                { name: this.$t('destination'), value: 'destination' },
                { name: this.$t('origin'), value: 'origin' },
            ],
            clientOptions: [],
            driverOptions: [],
            vehicleOptions: [],
            contactOptions: [],
            addressOptions: [],
            deliveryAddressId: null,
            currentOrderIntervall: null,
            packageHeight: 0,
            pickedContainerId: null,
            packageQuantity: 1,
            newDeliveryName: '',
        }
    },
    validations() {
        return {
            newDeliveryName: {
                required: requiredIf(function (abc) {
                    return this.$route.name === 'new_order'
                }),
            },
            formData: {
                IdClient: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false
                    }),
                },
                // IdDriver: {
                //     required: requiredIf(function (abc) {
                //         return this.isB2C === false
                //     }),
                // },
                // IdUserContact: { required },
                // Reference: { required },
            },
            newStep: {
                AddressName: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                },
                Address1: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                },
                PostalCode: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode
                    ),
                },
                Type: {
                    required: requiredIf(function (ab) {
                        return this.show === true
                    }),
                },
                City: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                },
                FromDateTime: {
                    required: requiredIf(function (abc) {
                        return (
                            (this.newStep.Moment === 'between' ||
                                this.newStep.Moment === 'from') &&
                            this.isB2C === false &&
                            this.show === true
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                BeforeDateTime: {
                    required: requiredIf(function (abc) {
                        return (
                            (this.newStep.Moment === 'between' ||
                                this.newStep.Moment === 'before') &&
                            this.isB2C === false &&
                            this.show === true
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                stepDate: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid
                    ),
                },
            },
            stepsToAdd: {
                $each: helpers.forEach({
                    AddressName: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                    },
                    Address1: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                    },
                    PostalCode: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                        isZipCode: helpers.withMessage(
                            this.$t('bad_zip_format'),
                            isZipCode
                        ),
                    },
                    // Type: {
                    //     required: requiredIf(function (ab) {
                    //         return this.show === true
                    //     }),
                    // },
                    City: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                    },
                    FromDateTime: {
                        required: requiredIf(function (abc, stepToAdd) {
                            const i = this.stepsToAdd.findIndex(
                                (step) =>
                                    step.StepNumber === stepToAdd.StepNumber
                            )
                            return (
                                (this.stepsToAdd[i].Moment === 'between' ||
                                    this.stepsToAdd[i].Moment === 'from') &&
                                this.isB2C === false
                            )
                        }),
                        timeIsValid: helpers.withMessage(
                            this.$t('bad_time_format'),
                            timeIsValid
                        ),
                    },
                    BeforeDateTime: {
                        required: requiredIf(function (abc, stepToAdd) {
                            const i = this.stepsToAdd.findIndex(
                                (step) =>
                                    step.StepNumber === stepToAdd.StepNumber
                            )
                            return (
                                (this.stepsToAdd[i].Moment === 'between' ||
                                    this.stepsToAdd[i].Moment === 'before') &&
                                this.isB2C === false
                            )
                        }),
                        timeIsValid: helpers.withMessage(
                            this.$t('bad_time_format'),
                            timeIsValid
                        ),
                    },
                    stepDate: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                        dateIsValid: helpers.withMessage(
                            this.$t('bad_date_format'),
                            dateIsValid
                        ),
                    },
                }),
            },
        }
    },

    computed: {
        ordersActionsAccess() {
            return actionsAccess.orders
        },
        ...mapStores(useClientStore, useUserStore),
        ...mapWritableState(useOrderStore, {
            currentOrder: 'current',
            orderToDuplicate: 'orderToDuplicate',
        }),
        ...mapState(useVehicleStore, {
            vehicles: 'all',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
            currentDriver: 'current',
        }),
        ...mapState(useClientStore, {
            currentClient: 'current',
            clients: 'all',
            businessHours: 'businessHours',
            contacts: 'currentContacts',
            clientAddresses: 'addresses',
            currentDeliveryAddress: 'currentAddress',
            containers: 'containers',
            currentContainer: 'currentContainer',
        }),
        ...mapState(useUserStore, {
            user: 'current',
            isB2C: 'isB2C',
            mapMarker: 'mapMarker',
        }),
        selectedContact() {
            let contact = null

            if (this.formData.IdUserContact && this.contacts) {
                contact = this.contacts.find(
                    (el) => +el.IdUser === +this.formData.IdUserContact
                )
            }
            return contact
        },
        canAssignDriver() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.assign.driver
            )
        },
        canAssignVehicle() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.assign.vehicle
            )
        },
        canUpdateStepStatus() {
            return (
                this.$route.name === 'update_order' &&
                this.currentOrder &&
                this.formData.OrderSteps.length ===
                    this.currentOrder.order_steps.length &&
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.updateStatus
                )
            )
        },
    },
    methods: {
        stepsDeleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.creating = confirmDelete
            this.showDeleteConfirmModal = false
        },
        showAddressForm(index) {
            this.stepsToAdd[index].Address1 = ''
            this.stepsToAdd[index].IdClientAddress = 0
            this.stepsToAdd[index].AddressName = ''
            this.stepsToAdd[index].Address2 = ''
            this.stepsToAdd[index].Address3 = ''
            this.stepsToAdd[index].Address4 = ''
            this.stepsToAdd[index].PostalCode = ''
            this.stepsToAdd[index].City = ''
            this.stepsToAdd[index].Country = 'FRA'
            this.stepsToAdd[index].Latitude = ''
            this.stepsToAdd[index].Longitude = ''

            const isShown = this.showForm.findIndex((el) => el === index)

            if (isShown !== -1) {
                this.showForm.splice(isShown, 1)
            } else {
                this.showForm.push(index)
            }
            if (this.showForm.includes(index)) {
                // this.stepsToAdd.forEach((stp, index) => {
                this.loadGoogleScript(`autocompleteAddStep_${index}`, index)
                // })
            }
        },
        removeTempSteps(index) {
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `delete_steps_pair_info`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.stepsToAdd.forEach((stp, i) => {
                            if (i > index) {
                                stp.StepNumber = +stp.StepNumber - 2
                                this.stepsToAdd[i] = stp
                            }
                        })
                        this.stepsToAdd.splice(index, 2)
                    }
                    this.creating = false
                }
            }, 150)
        },
        setExtraInfos(index, stepDate) {
            this.setMinMaxDates(index)
            this.loadAvailableDrivers(stepDate)
            this.loadAvailableVehicles(stepDate)
        },
        findStepDay(date) {
            return new Date(date).getDay()
        },
        openingHoursForDay(businessHours, dayNumber) {
            const hours = []
            businessHours.forEach((bHour) => {
                if (+bHour.Day === dayNumber && +bHour.Open === 1) {
                    hours.push({
                        start: bHour.HourBegin.substring(0, 5),
                        end: bHour.HourEnd.substring(0, 5),
                    })
                }
            })
            return hours
        },
        resetBadTimes() {
            this.badTimeRange = false
            this.badTimeRangeItem = null
            this.badTimeRangeSteps = false
            this.delivBeforePickUp = false
        },

        updateStepStatus(step) {
            const clone = structuredClone(step.step)
            // this.searchingCursor = true
            clearInterval(this.ordersInterval)
            clone.Status = step.newStatus
            step.step.Status = step.newStatus
            // this.formData.Status = step.newStatus

            clone.ModificationUserId = this.user.id
            const from = `${clone.stepDate} ${clone.FromDateTime}`

            const before = `${clone.stepDate} ${clone.BeforeDateTime}`
            if (clone.Moment === 'between') {
                clone.FromDateTime = from
                clone.BeforeDateTime = before
            } else if (clone.Moment === 'from') {
                clone.FromDateTime = from
                clone.BeforeDateTime = ''
            } else if (clone.Moment === 'before') {
                clone.FromDateTime = ''
                clone.BeforeDateTime = before
            }
            if (step.newStatus === 'executed') {
                clone.ExecutedDateTime = new Date()
            } else {
                clone.ExecutedDateTime = null
            }
            const payload = {
                id: clone.Id,
                form: clone,
            }
            this.updateStep(payload)
                .then((res) => {
                    this.refreshToggle += 1
                    if (
                        (step.newStatus === 'inProgress' ||
                            (step.newStatus === 'executed' && !step.isLast)) &&
                        (step.lastStatus !== 'executed' || step.isLast === true)
                    ) {
                        this.fetchOneOrderAndUpdateStatus({
                            id: clone.IdOrder,
                            newStatus: 'inprogress',
                            userId: this.user.id,
                        })
                    }

                    if (step.newStatus === 'executed' && step.isLast === true) {
                        this.fetchOneOrderAndUpdateStatus({
                            id: clone.IdOrder,
                            newStatus: 'finished',
                            userId: this.user.id,
                        })
                    }
                })
                .catch((err) => {
                    this.searchingCursor = false
                    this.$toast.error(this.$t('error_occured'))
                })
        },
        previousPickUpTimesBis(index = null) {
            let times = null
            const previousStep =
                index === 0
                    ? this.stepsToAdd[index]
                    : this.stepsToAdd[index - 1]
            times = `${this.$t('short_from')} ${previousStep.FromDateTime}`
            if (previousStep.Moment === 'between') {
                times = `${this.$t('short_from')} ${
                    previousStep.FromDateTime
                } ${this.$t('short_before')} ${previousStep.BeforeDateTime}`
            } else if (previousStep.Moment === 'before') {
                times = `${this.$t('before')} ${previousStep.BeforeDateTime}`
            }

            if (index && !this.isOdd(index)) {
                const nextStep = this.stepsToAdd[index + 1]
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
            }
            if (index && this.isOdd(index) === 1) {
                const nextStep = this.stepsToAdd[index]
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
            }
            return times
        },
        previousPickUpTimes(update = null) {
            let times = null
            const previousStep =
                this.formData.OrderSteps[this.formData.OrderSteps.length - 1]
            times = `${this.$t('short_from')} ${previousStep.FromDateTime}`
            if (previousStep.Moment === 'between') {
                times = `${this.$t('short_from')} ${
                    previousStep.FromDateTime
                } ${this.$t('short_before')} ${previousStep.BeforeDateTime}`
            } else if (previousStep.Moment === 'before') {
                times = `${this.$t('before')} ${previousStep.BeforeDateTime}`
            }

            if (update && !this.isOdd(update)) {
                const nextStep = this.formData.OrderSteps[update + 1]
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
            }
            if (update && this.isOdd(update) === 1) {
                const nextStep = this.formData.OrderSteps[update - 1]
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
            }

            return times
        },
        unsetContainerCreation() {
            this.containerCreation = false

            this.containerNameError = false
            this.packageTypeErrors = []
            this.packageValueErrors = []
            this.packageWeightErrors = []
            this.packageReferenceErrors = []
            this.packageWidthErrors = []
            this.packageLengthErrors = []
            this.packageHeightErrors = []
            this.containerNameError = false
            if (this.$route.params.id) {
                this.containerName =
                    this.formData.OrderPackages[0].Container.Name
                this.formData.OrderPackages[0].IdContainer =
                    this.formData.OrderPackages[0].Container.Id
                this.formData.OrderPackages[0].Length =
                    this.formData.OrderPackages[0].Container.Length
                this.formData.OrderPackages[0].Width =
                    this.formData.OrderPackages[0].Container.Width
                this.packageHeight =
                    this.formData.OrderPackages[0].Container.Height
                this.formData.OrderPackages[0].Height = this.packageHeight
            } else {
                this.containerName = ''
                this.formData.OrderPackages[0].IdContainer = ''
                this.formData.OrderPackages[0].Length = 0
                this.formData.OrderPackages[0].Width = 0
                this.packageHeight = 0
                this.formData.OrderPackages[0].Height = this.packageHeight
            }
        },
        setContainerCreation() {
            this.containerCreation = true
            this.containerName = ''
            if (!this.$route.params.id) {
                this.pickedContainerId = null
            }
            this.formData.OrderPackages[0].IdContainer = ''
            this.formData.OrderPackages[0].Length = 0
            this.formData.OrderPackages[0].Width = 0

            this.packageHeight = 0
            this.formData.OrderPackages[0].Height = this.packageHeight
            this.clientStore.$patch({ currrentContainer: null })
        },
        toCreateClient() {
            this.$router.push({ name: 'new_client' })
        },
        setMinMaxDates(stepIndex) {
            if (this.stepsToAdd[stepIndex - 1]) {
                this.minDate = this.stepsToAdd[stepIndex - 1].stepDate
            }
            if (this.stepsToAdd[stepIndex + 1]) {
                this.maxDate = this.stepsToAdd[stepIndex + 1].stepDate
            }
        },
        resetMinMaxDate(index) {
            this.minDate = new Date().toISOString().substr(0, 10)
            if (
                this.isOdd(index) !== 1 &&
                this.stepsToAdd[index + 1].stepDate !==
                    this.stepsToAdd[index].stepDate
            ) {
                this.maxDate = this.stepsToAdd[index + 1].stepDate
            } else {
                this.maxDate = ''
            }
        },
        setMinDate(stepIndex) {
            if (this.formData.OrderSteps[stepIndex - 1]) {
                this.minDate = this.formData.OrderSteps[stepIndex - 1].stepDate
            }
        },
        setMaxDate(stepIndex) {
            if (this.formData.OrderSteps[stepIndex + 1]) {
                this.maxDate = this.formData.OrderSteps[stepIndex + 1].stepDate
            }
        },
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            availableDriversForDate: 'availableDriversForDate',
        }),
        ...mapActions(useOrderStore, [
            'updateStep',
            'createDelivery',
            'updateOrderClient',
            'deleteStep',
            'fetchOneOrderAndUpdateStatus',
        ]),
        ...mapActions(useVehicleStore, {
            availableVehiclesForDate: 'availableVehiclesForDate',
            searchVehicles: 'search',
        }),

        loadAvailableVehicles(date) {
            this.vehicleOptions = []
            const formattedDate = date.replace('-', '')
            this.availableVehiclesForDate(formattedDate.replace('-', '')).then(
                (res) => {
                    if (res.status === 200) {
                        res.data.forEach((vehicle) => {
                            const name = `${vehicle.Name} - ${this.$t(
                                vehicle.Type
                            )}`
                            const option = {
                                name,
                                value: +vehicle.Id,
                            }
                            this.vehicleOptions.push(option)
                        })
                    }
                }
            )
        },
        loadAvailableDrivers(date) {
            this.driverOptions = []
            // TODO uncomment when available call works
            // const formattedDate = date.replace('-', '')
            // // this.availableDriversForDate(formattedDate.replace('-', '')).then(
            // //     (res) => {
            // //         console.log(res)
            // //         if (res.status === 200) {
            // //             res.data.Records.forEach((driver) => {
            // //                 const name = `${driver.Firstname} ${driver.Lastname}`
            // //                 const option = {
            // //                     name,
            // //                     value: +driver.Id,
            // //                 }
            // //                 this.driverOptions.push(option)
            // //             })
            // //         }
            // //     }
            // // )
            const driversPayload = {
                query: [],
                filter: [{ dbField: 'Active', value: 1 }],
                perPage: 100000000000,
                page: 1,
                sortField: 'LastName',
                sortDirection: 'ASC',
            }

            this.searchDrivers(driversPayload).then((res) => {
                if (res.status === 200) {
                    res.data.Records.forEach((driver) => {
                        const name = `${driver.FirstName} ${driver.LastName}`
                        const option = {
                            name,
                            value: +driver.Id,
                        }
                        this.driverOptions.push(option)
                    })
                }
            })
        },
        isOdd(num) {
            return num % 2
        },
        stepStatusIcon(status) {
            let icon = 'circle-question'
            if (status === 'inProgress') {
                icon = 'truck-fast'
            }
            if (status === 'affected') {
                icon = 'route'
            }
            if (status === 'executed') {
                icon = 'flag-checkered'
            }
            if (status === 'affectationError') {
                icon = 'xmark'
            }
            return icon
        },
        resetFormsValidations() {
            this.packageTypeErrors = []
            this.packageValueErrors = []
            this.packageWeightErrors = []
            this.packageReferenceErrors = []
            this.packageWidthErrors = []
            this.packageLengthErrors = []
            this.packageHeightErrors = []
            this.containerNameError = false
            // this.packageQuantityErrors = []
            this.v$.newStep.$reset()
            this.v$.formData.$reset()
            this.v$.stepsToAdd.$reset()
        },
        setDriverAndVehicleToOtherSteps() {
            this.formData.OrderSteps.forEach((step, index) => {
                if (
                    !this.isOdd(index) &&
                    index !== this.formData.OrderSteps.length - 1
                ) {
                    this.formData.OrderSteps[index + 1].IdDriver = step.IdDriver
                    this.formData.OrderSteps[index + 1].IdVehicle =
                        step.IdVehicle
                }
            })
        },
        addPackage() {
            this.resetFormsValidations()

            this.selectedTemp = 'none'

            this.formData.OrderPackages.push({
                PackageNumber: 1,
                Width: 0,
                Length: 0,
                Height: this.packageHeight ?? 0,
                Weight: 0,
                Value: 0,
                Quantity: this.packageQuantity ?? 1,
                TemperatureOption: this.selectedTemp,
                IdContainer: '',
                Id: '',
                CreationUserId: this.user.id,
                ModificatioUserId: this.user.id,
            })
        },
        setPackageContainer(container) {
            if (+container.Height !== 0) {
                this.packageHeight = +container.Height
            }
            if (
                this.isEditPackage === false &&
                this.currentOrder &&
                +this.currentOrder.order_packages[0].IdContainer !==
                    +container.Id
            ) {
                this.packageHeight = +container.Height
            }
            if (
                this.isEditPackage === true &&
                this.currentOrder &&
                +this.currentOrder.order_packages[0].IdContainer ===
                    +container.Id
            ) {
                this.packageHeight = +this.currentOrder.order_packages[0].Height
            }
            const id = container.Id
            const length = +container.Length
            const width = +container.Width
            this.formData.OrderPackages[0].IdContainer = id
            this.formData.OrderPackages[0].Width = width
            this.formData.OrderPackages[0].Length = length
            this.formData.OrderPackages[0].Height = this.packageHeight
            this.isEditPackage = false
        },

        deletePackage(packageIndex) {
            this.formData.OrderPackages.splice(packageIndex, 1)
            this.packageTypeErrors = []
            this.packageReferenceErrors = []
        },
        async isPackageValid() {
            this.formData.OrderPackages[0].IdContainer = +this.pickedContainerId
            if (this.formData.OrderPackages[0]) {
                if (this.formData.OrderPackages[0].IdContainer === '') {
                    this.packageTypeErrors.push('package error container')
                }
                if (this.formData.OrderPackages[0].Reference === '') {
                    this.packageReferenceErrors.push('package error reference')
                }
                if (this.formData.OrderPackages[0].Value === 0) {
                    this.packageValueErrors.push('package error value')
                }
                if (this.formData.OrderPackages[0].Weight === 0) {
                    this.packageWeightErrors.push('package error weight')
                }
                if (this.formData.OrderPackages[0].Width === 0) {
                    this.packageWidthErrors.push('package error width')
                }
                if (this.formData.OrderPackages[0].Length === 0) {
                    this.packageLengthErrors.push('package error length')
                }
                if (this.formData.OrderPackages[0].Quantity === 0) {
                    this.packageQuantityErrors.push('package error quantity')
                }
                if (this.formData.OrderPackages[0].Height === 0) {
                    this.packageHeightErrors.push('package error height')
                }
            }

            return (
                this.packageTypeErrors.length === 0 &&
                this.packageReferenceErrors.length === 0 &&
                this.packageWeightErrors.length === 0 &&
                this.packageValueErrors.length === 0 &&
                this.packageWidthErrors.length === 0 &&
                this.packageLengthErrors.length === 0 &&
                this.packageQuantityErrors.length === 0 &&
                this.packageHeightErrors.length === 0
            )
        },
        addStepsToAdd(remove = false) {
            if (remove === true) {
                this.showDeleteConfirmModal = true
                this.deleteModalHeader = 'delete_modal_generic_header'
                this.deleteModalText = 'delete_all_unsaved_steps'
                this.elementToDelete = `delete_all_unsaved_steps_info`

                const deleteInterval = setInterval(() => {
                    if (this.confirmDelete !== null) {
                        clearInterval(deleteInterval)

                        if (this.confirmDelete === true) {
                            this.stepsToAdd = []
                        }
                        this.creating = false
                    }
                }, 150)
            } else {
                // const isValid = this.v$.stepsToAdd.$validate()
                // if (isValid) {
                const newPickup = this.stepsToAdd.length
                    ? structuredClone(
                          this.stepsToAdd[this.stepsToAdd.length - 1]
                      )
                    : structuredClone(
                          this.formData.OrderSteps[
                              this.formData.OrderSteps.length - 1
                          ]
                      )
                const realPrevPickUp = this.stepsToAdd.length
                    ? structuredClone(
                          this.stepsToAdd[this.stepsToAdd.length - 2]
                      )
                    : structuredClone(
                          this.formData.OrderSteps[
                              this.formData.OrderSteps.length - 2
                          ]
                      )
                if (
                    this.showForm === false &&
                    newPickup.IdClientAddress === 0
                ) {
                    this.$toast.warning(this.$t('previous_step_incomplete'))
                } else if (
                    this.showForm === false &&
                    realPrevPickUp.IdClientAddress === newPickup.IdClientAddress
                ) {
                    this.$toast.warning(this.$t('same_address'))
                } else {
                    if (!this.addressOptions.length) {
                        const clientAddressesPayload = {
                            clientId: this.formData.IdClient,
                            query: [],
                            filter: [],
                            perPage: 100000000000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        this.clientStore
                            .fetchAddresses(clientAddressesPayload)
                            .then(() => {
                                const clientAddresses = setInterval(() => {
                                    if (this.clientAddresses) {
                                        this.clientAddresses.forEach(
                                            (address) => {
                                                clearInterval(clientAddresses)

                                                const option = {
                                                    name: address.name,
                                                    value: address.id,
                                                }
                                                this.addressOptions.push(option)
                                            }
                                        )
                                        this.refreshMS += 1
                                    }
                                }, 1000)
                            })
                    }
                    // const pickup = this.stepsToAdd.length
                    //     ? structuredClone(
                    //           this.stepsToAdd[this.stepsToAdd.length - 1]
                    //       )
                    //     : structuredClone(
                    //           this.formData.OrderSteps[
                    //               this.formData.OrderSteps.length - 1
                    //           ]
                    //       )
                    newPickup.Type = 'pickup'
                    newPickup.Id = ''
                    newPickup.IdVehicle = ''
                    newPickup.IdDriver = ''
                    newPickup.StepNumber = +newPickup.StepNumber + 1
                    newPickup.IdClientAddress = +newPickup.IdClientAddress
                    const newDelivery = {
                        Type: 'delivery',
                        Status: 'new',
                        StepNumber: +newPickup.StepNumber + 1,
                        IdClientAddress: 0,
                        AddressName: '',
                        Address1: '',
                        Address2: '',
                        Address3: '',
                        Address4: '',
                        PostalCode: '',
                        City: '',
                        Country: 'FRA',
                        Latitude: '',
                        Longitude: '',
                        Moment: 'between',
                        FromDateTime: '08:00',
                        BeforeDateTime: '18:00',
                        TypeVehicle: '',
                        Hours: null,
                        IdVehicle: '',
                        IdDriver: '',
                        stepDate: newPickup.stepDate,
                    }
                    this.loadAvailableDrivers(newPickup.stepDate)
                    this.loadAvailableVehicles(newPickup.stepDate)

                    this.stepsToAdd.push(newPickup)
                    this.stepsToAdd.push(newDelivery)
                    this.refreshMS += 1
                }
                // } else {
                //     this.$toast.error('form_errors')
                // }
            }
        },
        showStepModal() {
            if (this.formData.OrderSteps.length) {
                this.setMinDate(this.formData.OrderSteps.length)
            }
            // rechercher les adresses uniquement du client si B2C
            const clientAddressesPayload = {
                clientId: this.formData.IdClient,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            if (!this.addressOptions.length) {
                this.clientStore
                    .fetchAddresses(clientAddressesPayload)
                    .then(() => {
                        const clientAddresses = setInterval(() => {
                            if (this.clientAddresses) {
                                this.clientAddresses.forEach((address) => {
                                    clearInterval(clientAddresses)

                                    const option = {
                                        name: address.name,
                                        value: address.id,
                                    }
                                    this.addressOptions.push(option)
                                })
                            }
                        }, 1000)
                    })
            }
            this.loadGoogleScript('autocompleteAddStep')

            this.newStep = {
                Type:
                    this.formData.OrderSteps.length &&
                    this.isOdd(this.formData.OrderSteps.length)
                        ? 'delivery'
                        : 'pickup',
                Status: 'new',
                StepNumber: this.formData.OrderSteps.length + 1,
                IdClientAddress: 0,
                AddressName: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                PostalCode: '',
                City: '',
                Country: 'FRA',
                Latitude: '',
                Longitude: '',
                Moment: 'between',
                FromDateTime: '08:00',
                BeforeDateTime: '18:00',
                TypeVehicle: '',
                Hours: null,
                IdVehicle: '',
                IdDriver: '',
                stepDate: this.minDate,
            }
            this.show = true
        },
        updateStp(step) {
            this.maxDate = ''
            this.minDate = this.date.toISOString().substr(0, 10)

            this.setMaxDate(step)
            this.setMinDate(step)
            const clientAddressesPayload = {
                clientId:
                    this.isB2C === true
                        ? this.currentClient.id
                        : this.formData.IdClient,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            this.clientStore.fetchAddresses(clientAddressesPayload).then(() => {
                const clientAddresses = setInterval(() => {
                    if (this.clientAddresses) {
                        this.clientAddresses.forEach((address) => {
                            clearInterval(clientAddresses)

                            const option = {
                                name: address.name,
                                value: address.id,
                            }
                            this.addressOptions.push(option)
                        })
                    }
                }, 1000)
            })
            // const vm = this
            this.loadGoogleScript('autocompleteAddStep')
            this.newStep = structuredClone(this.formData.OrderSteps[step])

            this.stepToUpdate = step
            this.show = true
        },
        deleteStp(step, id) {
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `delete_steps_pair_info`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        const stepsToDelete = [
                            id,
                            this.formData.OrderSteps[step + 1].Id,
                        ]
                        this.formData.OrderSteps.splice(step, 2)
                        stepsToDelete.forEach((stpId) => {
                            this.deleteStep(stpId)
                        })
                    }
                    this.creating = false
                }
            }, 150)

            // const stepsToDelete = [id, this.formData.OrderSteps[step + 1].Id]
            // this.formData.OrderSteps.splice(step, 2)
            // stepsToDelete.forEach((stpId) => {
            //     this.deleteStep(stpId)
            // })
        },
        setAddressInfo(addressId, newStepIndex = null) {
            this.clientStore.fetchOneAddress({ id: addressId })
            const currentAddressInterval = setInterval(() => {
                if (this.currentDeliveryAddress) {
                    clearInterval(currentAddressInterval)
                    if (newStepIndex === null) {
                        this.newStep = {
                            Type: this.newStep.Type ?? '',
                            // Type: this.currentDeliveryAddress.address_type ==? this.currentDeliveryAddress.address_type : 'pickup',
                            Status: 'new',
                            StepNumber:
                                this.newStep.StepNumber ??
                                this.formData.OrderSteps.length + 1,
                            IdClientAddress: this.currentDeliveryAddress.id,
                            AddressName: this.currentDeliveryAddress.name,
                            Address1: this.currentDeliveryAddress.address,
                            Address2:
                                this.currentDeliveryAddress.address2 || '',
                            Address3:
                                this.currentDeliveryAddress.address3 || '',
                            Address4:
                                this.currentDeliveryAddress.address4 || '',
                            PostalCode: this.currentDeliveryAddress.postal_code,
                            City: this.currentDeliveryAddress.city,
                            Country: 'FRA',
                            Latitude: this.currentDeliveryAddress.lat,
                            Longitude: this.currentDeliveryAddress.lng,
                            Moment: this.newStep.Moment ?? 'between',
                            FromDateTime: this.newStep.FromDateTime ?? '08:00',
                            BeforeDateTime:
                                this.newStep.BeforeDatetime ?? '18:00',
                            TypeVehicle: this.newStep.TypeVehicle ?? '',
                            IdDriver: this.newStep.IdDriver ?? '',
                            IdVehicle: this.newStep.IdVehicle ?? '',
                            Hours: this.currentDeliveryAddress.hours ?? null,

                            stepDate:
                                this.newStep.stepDate ??
                                new Date().toISOString().substr(0, 10),
                            Id: this.newStep.Id ?? '',
                        }
                    } else {
                        this.stepsToAdd[newStepIndex].IdClientAddress =
                            this.currentDeliveryAddress.id
                        this.stepsToAdd[newStepIndex].AddressName =
                            this.currentDeliveryAddress.name
                        this.stepsToAdd[newStepIndex].Address1 =
                            this.currentDeliveryAddress.address
                        this.stepsToAdd[newStepIndex].Address2 =
                            this.currentDeliveryAddress.address2 || ''
                        this.stepsToAdd[newStepIndex].Address3 =
                            this.currentDeliveryAddress.address3 || ''
                        this.stepsToAdd[newStepIndex].Address4 =
                            this.currentDeliveryAddress.address4 || ''
                        this.stepsToAdd[newStepIndex].PostalCode =
                            this.currentDeliveryAddress.postal_code
                        this.stepsToAdd[newStepIndex].City =
                            this.currentDeliveryAddress.city
                        this.stepsToAdd[newStepIndex].Country =
                            this.currentDeliveryAddress.country
                        this.stepsToAdd[newStepIndex].Latitude =
                            this.currentDeliveryAddress.lat
                        this.stepsToAdd[newStepIndex].Longitude =
                            this.currentDeliveryAddress.lng
                        // Type: this.stepsToAdd[newStepIndex].Type ?? '',
                        // // Type: this.currentDeliveryAddress.address_type ==? this.currentDeliveryAddress.address_type : 'pickup',
                        // Status: 'new',
                        // StepNumber:
                        //     this.stepsToAdd[newStepIndex].StepNumber ??
                        //     this.formData.OrderSteps.length + 1,
                        // Moment:
                        //     this.stepsToAdd[newStepIndex].Moment ??
                        //     'between',
                        // FromDateTime:
                        //     this.stepsToAdd[newStepIndex].FromDateTime ??
                        //     '08:00',
                        // BeforeDateTime:
                        //     this.stepsToAdd[newStepIndex].BeforeDatetime ??
                        //     '18:00',
                        // TypeVehicle:
                        //     this.stepsToAdd[newStepIndex].TypeVehicle ?? '',
                        // IdDriver:
                        //     this.stepsToAdd[newStepIndex].IdDriver ?? '',
                        // IdVehicle:
                        //     this.stepsToAdd[newStepIndex].IdVehicle ?? '',
                        // Hours: this.currentDeliveryAddress.hours ?? null,

                        // stepDate:
                        //     this.stepsToAdd[newStepIndex].stepDate ??
                        //     new Date().toISOString().substr(0, 10),
                        // Id: this.stepsToAdd[newStepIndex].Id ?? '',
                    }
                }
            }, 1000)
        },
        timesAreCorrect(addNew = false, stepToUpdate = null) {
            this.badTimeRange = false
            this.badTimeRangeItem = null
            // const isValid = await this.v$.stepsToAdd.$validate()
            if (
                (this.stepsToAdd[stepToUpdate].FromDateTime >
                    this.stepsToAdd[stepToUpdate].BeforeDateTime ||
                    this.stepsToAdd[stepToUpdate].FromDateTime ===
                        this.stepsToAdd[stepToUpdate].BeforeDateTime) &&
                this.stepsToAdd[stepToUpdate].Moment === 'between'
            ) {
                this.badTimeRange = true
                this.badTimeRangeItem = this.stepsToAdd[stepToUpdate]
            }

            if (!this.isOdd(this.stepsToAdd[stepToUpdate].StepNumber)) {
                this.badTimeRangeSteps = false
                const previousStep = this.stepsToAdd[stepToUpdate - 1]

                if (
                    ((previousStep.Moment === 'from' ||
                        previousStep.Moment === 'between') &&
                        this.stepsToAdd[stepToUpdate].Moment === 'before' &&
                        previousStep.FromDateTime >=
                            this.stepsToAdd[stepToUpdate].BeforeDateTime) ||
                    (previousStep.Moment === 'from' &&
                        this.stepsToAdd[stepToUpdate].Moment === 'between' &&
                        previousStep.FromDateTime >=
                            this.stepsToAdd[stepToUpdate].BeforeDateTime) ||
                    (previousStep.Moment === 'between' &&
                        this.stepsToAdd[stepToUpdate].Moment === 'between' &&
                        ((previousStep.FromDateTime >=
                            this.stepsToAdd[stepToUpdate].BeforeDateTime &&
                            previousStep.FromDateTime >=
                                this.stepsToAdd[stepToUpdate].FromDateTime) ||
                            previousStep.FromDateTime >=
                                this.stepsToAdd[stepToUpdate].BeforeDateTime))
                ) {
                    this.badTimeRangeSteps = true
                }
            }

            if (
                this.isOdd(this.stepsToAdd[stepToUpdate].StepNumber) === 1 &&
                stepToUpdate !== null
            ) {
                this.delivBeforePickUp = false
                const nextStep = this.stepsToAdd[stepToUpdate + 1]
                if (
                    ((this.stepsToAdd[stepToUpdate].Moment === 'from' ||
                        this.stepsToAdd[stepToUpdate].Moment === 'between') &&
                        nextStep.Moment === 'before' &&
                        this.stepsToAdd[stepToUpdate].FromDateTime >=
                            nextStep.BeforeDateTime) ||
                    (this.stepsToAdd[stepToUpdate].Moment === 'from' &&
                        nextStep.Moment === 'between' &&
                        this.stepsToAdd[stepToUpdate].FromDateTime >=
                            nextStep.BeforeDateTime) ||
                    (this.stepsToAdd[stepToUpdate].Moment === 'between' &&
                        nextStep.Moment === 'between' &&
                        ((this.stepsToAdd[stepToUpdate].FromDateTime >=
                            nextStep.BeforeDateTime &&
                            this.stepsToAdd[stepToUpdate].FromDateTime >=
                                nextStep.FromDateTime) ||
                            this.stepsToAdd[stepToUpdate].FromDateTime >=
                                nextStep.BeforeDateTime))
                ) {
                    this.delivBeforePickUp = true
                }
            }
        },
        async submitModal(addNew = false, stepToUpdate = null) {
            this.badTimeRange = false
            const isValid = await this.v$.newStep.$validate()
            if (
                (this.newStep.FromDateTime > this.newStep.BeforeDateTime ||
                    this.newStep.FromDateTime ===
                        this.newStep.BeforeDateTime) &&
                this.newStep.Moment === 'between'
            ) {
                this.badTimeRange = true
            }

            if (!this.isOdd(this.newStep.StepNumber)) {
                this.badTimeRangeSteps = false
                const previousStep =
                    this.formData.OrderSteps[
                        this.formData.OrderSteps.length - 1
                    ]

                if (
                    ((previousStep.Moment === 'from' ||
                        previousStep.Moment === 'between') &&
                        this.newStep.Moment === 'before' &&
                        previousStep.FromDateTime >=
                            this.newStep.BeforeDateTime) ||
                    (previousStep.Moment === 'from' &&
                        this.newStep.Moment === 'between' &&
                        previousStep.FromDateTime >=
                            this.newStep.BeforeDateTime) ||
                    (previousStep.Moment === 'between' &&
                        this.newStep.Moment === 'between' &&
                        ((previousStep.FromDateTime >=
                            this.newStep.BeforeDateTime &&
                            previousStep.FromDateTime >=
                                this.newStep.FromDateTime) ||
                            previousStep.FromDateTime >=
                                this.newStep.BeforeDateTime))
                ) {
                    this.badTimeRangeSteps = true
                }
            }

            if (
                this.isOdd(this.newStep.StepNumber) === 1 &&
                stepToUpdate !== null
            ) {
                this.delivBeforePickUp = false
                const nextStep = this.formData.OrderSteps[stepToUpdate + 1]
                if (
                    ((this.newStep.Moment === 'from' ||
                        this.newStep.Moment === 'between') &&
                        nextStep.Moment === 'before' &&
                        this.newStep.FromDateTime >= nextStep.BeforeDateTime) ||
                    (this.newStep.Moment === 'from' &&
                        nextStep.Moment === 'between' &&
                        this.newStep.FromDateTime >= nextStep.BeforeDateTime) ||
                    (this.newStep.Moment === 'between' &&
                        nextStep.Moment === 'between' &&
                        ((this.newStep.FromDateTime >=
                            nextStep.BeforeDateTime &&
                            this.newStep.FromDateTime >=
                                nextStep.FromDateTime) ||
                            this.newStep.FromDateTime >=
                                nextStep.BeforeDateTime))
                ) {
                    this.delivBeforePickUp = true
                }
                // !! REMOVE BELOW ELSE WHEN DONE
            }
            if (
                isValid &&
                addNew &&
                this.badTimeRange === false &&
                this.badTimeRangeSteps === false &&
                this.delivBeforePickUp === false
            ) {
                this.newStep.CreationUserId =
                    this.formData.OrderSteps[stepToUpdate] &&
                    this.formData.OrderSteps[stepToUpdate].CreationUserId
                        ? this.formData.OrderSteps[stepToUpdate].CreationUserId
                        : this.user.id
                if (stepToUpdate !== null) {
                    this.formData.OrderSteps[stepToUpdate] = this.newStep
                } else {
                    this.formData.OrderSteps.push(this.newStep)
                }
                if (this.formData.OrderSteps.length === 1) {
                    this.formData.DateBegin = this.newStep.stepDate
                    this.formData.DateEnd = this.newStep.stepDate
                } else {
                    this.formData.DateEnd = this.newStep.stepDate
                }
                // this.closeModal()
                if (
                    this.$route.name === 'update_order' &&
                    stepToUpdate !== null &&
                    this.formData.OrderSteps[stepToUpdate]
                ) {
                    const formDataCopy = structuredClone(
                        this.formData.OrderSteps[stepToUpdate]
                    )
                    const from = `${formDataCopy.stepDate} ${formDataCopy.FromDateTime}`

                    const before = `${formDataCopy.stepDate} ${formDataCopy.BeforeDateTime}`
                    if (formDataCopy.Moment === 'between') {
                        formDataCopy.FromDateTime = from
                        formDataCopy.BeforeDateTime = before
                    } else if (formDataCopy.Moment === 'from') {
                        formDataCopy.FromDateTime = from
                        formDataCopy.BeforeDateTime = ''
                    } else if (formDataCopy.Moment === 'before') {
                        formDataCopy.FromDateTime = ''
                        formDataCopy.BeforeDateTime = before
                    }
                    this.updateStep({
                        id: formDataCopy.Id,
                        form: formDataCopy,
                    }).then(() => {
                        this.vehicleOptions = []
                        this.loadAvailableVehicles(this.newStep.stepDate)
                        this.driverOptions = []
                        this.loadAvailableDrivers(this.newStep.stepDate)
                        this.closeModal()

                        this.refreshSteps += 1
                    })
                } else {
                    this.vehicleOptions = []
                    this.loadAvailableVehicles(this.newStep.stepDate)
                    this.driverOptions = []
                    this.loadAvailableDrivers(this.newStep.stepDate)
                    this.closeModal()

                    this.refreshSteps += 1
                }
            }
        },
        closeModal() {
            this.stepToUpdate = null
            this.newStep = null
            this.show = false
            // this.addressOptions = []
            this.resetBadTimes()
            this.clientStore.$patch({ addresses: null, currentAddress: null })

            this.v$.newStep.$reset()
        },

        fetchContactsAddressesAndContainers(clientId) {
            this.clientStore.fetchOne(clientId)
            this.clientStore.$patch({ containers: null, currentContacts: null })

            this.noContactForClient = false

            this.contactOptions = []
            this.clientStore
                .fetchClientContacts({
                    clientId,
                    fromOrders: true,
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.Records.length) {
                            res.data.Records.forEach((contact) => {
                                this.noContactForClient = false
                                const option = {
                                    name: `${contact.FirstName}  ${contact.LastName}`,
                                    value: +contact.IdUser,
                                }
                                this.contactOptions.push(option)
                            })
                        } else {
                            this.noContactForClient = true
                        }
                    } else {
                        this.noContactForClient = true
                    }
                })

            this.containerOptions = []
            this.clientStore.fetchContainers(clientId).then(() => {
                const containerInt = setInterval(() => {
                    if (this.containers !== null) {
                        clearInterval(containerInt)
                        this.containers.forEach((container) => {
                            this.containerOptions.push({
                                name:
                                    container.Name.includes('_') ||
                                    container.Name === 'pallet'
                                        ? this.$t(container.Name)
                                        : container.Name,
                                value: container.Id,
                                icon: container.Name.includes('pallet')
                                    ? 'pallet'
                                    : container.Name.includes('letter')
                                    ? 'envelope'
                                    : container.Name.includes('box')
                                    ? 'box'
                                    : 'star',
                            })
                        })
                    }
                }, 200)
            })
            this.addressOptions = []
            const clientAddressesPayload = {
                clientId: this.formData.IdClient,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            this.clientStore.fetchAddresses(clientAddressesPayload).then(() => {
                const clientAddresses = setInterval(() => {
                    if (this.clientAddresses) {
                        this.clientAddresses.forEach((address) => {
                            clearInterval(clientAddresses)

                            const option = {
                                name: address.name,
                                value: address.id,
                            }
                            this.addressOptions.push(option)
                        })
                    }
                }, 1000)
            })
        },

        back() {
            this.packageTypeErrors = []
            this.packageReferenceErrors = []
            // const env = window.location.hostname
            const back = 'orders'
            // env !== 'becycle.biiim-solutions.com' &&
            // env !== 'localhost' &&
            // env !== 'dev.biiim-solutions.com'
            //     ? 'orders'
            //     : 'becycle_orders'
            // this.$router.push({ name: 'orders' })
            // const backPath = this.$router.options.history.state.back

            this.$router.push({ name: 'orders' })

            this.$emit('back', back)
        },
        fillInAddress(autocomplete, index = null, modal = false) {
            let addressToFillIn = this.newStep
            if (index !== null) {
                addressToFillIn = this.stepsToAdd[index]
            }
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            addressToFillIn.Address2 = ''
            addressToFillIn.PostalCode = ''
            addressToFillIn.City = ''

            const userInput = addressToFillIn.Address1
            addressToFillIn.Latitude = place.geometry.location.lat()
            addressToFillIn.Longitude = place.geometry.location.lng()
            addressToFillIn.Address1 = ''
            // console.log(userInput)
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    addressToFillIn.PostalCode = val
                } else if (addressType === 'locality') {
                    addressToFillIn.City = val
                } else if (addressType === 'street_number') {
                    addressToFillIn.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    addressToFillIn.Address1 =
                        addressToFillIn.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${addressToFillIn.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    addressToFillIn.Address2 = `${val}`
                }

                // TODO voir ce que ça casse dans la génération des adresses rm le 4 nov
                // console.log(place.types[0] )

                //                 console.log(val)
                //                 console.log(addressType)
                //                 console.log('------------------------')
                // if (
                //     (place.types[0] !== 'street_number' ||
                //         place.types[0] !== 'route') &&
                //     addressType === 'locality' &&
                //     addressToFillIn.Address1 === userInput
                // ) {
                //     addressToFillIn.Address1 = ` ${val}`
                // }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    addressToFillIn.Address2 === '' &&
                    place.types[0] !== 'premise'
                ) {
                    if (addressToFillIn.Address1 === '') {
                        addressToFillIn.Address1 = place.name
                    } else {
                        addressToFillIn.Address2 = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.Name === ''
                ) {
                    if (!modal) {
                        addressToFillIn.Name = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.AddressName === ''
                ) {
                    if (modal) {
                        addressToFillIn.AddressName = place.name
                    }
                }
            })
        },
        async createNewContainer(pack) {
            const container = {
                CreationUserId: this.user.id,
                IdClient: this.containerForAll
                    ? 0
                    : this.currentClient.id ?? this.currentClient.Id,
                Description: this.containerDescription,
                Name: this.containerName,
                Width: pack.Width,
                Height: this.packageHeight,
                Length: pack.Length,
                Active: 1,
            }
            const res = await this.clientStore.createContainer(container)
            if (res) {
                this.pickedContainerId = +res.data.Id
                this.formData.OrderPackages[0].IdContainer = +res.data.Id
            }

            this.containerNameError = false
        },
        async createOrder(duplicate = false) {
            this.creating = true
            this.orderToDuplicate = null

            this.containerNameError = this.containerName === ''
            if (this.containerNameError === false) {
                if (
                    this.containerCreation === true ||
                    this.containerOptions.length === 0
                ) {
                    const containerResp = await this.createNewContainer(
                        this.formData.OrderPackages[0]
                    )
                }
            } else {
                this.creating = false
                this.$toast.error(this.$t('bad_packages'))

                return false
            }
            this.resetFormsValidations()

            if (this.isPackageValid()) {
                this.formData.CreationUserId = this.user.id
                // this.formData.PacketNumber = this.formData.OrderPackages.length
                // todo voir ici car step date va cahnger a chaque création de new step faire un tableau ? mais pb si update du step avec un tableau à remettre a jour si le step cahnge de place
                this.formData.DateBegin = this.stepsToAdd[0].stepDate
                this.formData.DateEnd =
                    this.stepsToAdd[this.stepsToAdd.length - 1].stepDate

                const clone = structuredClone(this.stepsToAdd)
                clone.forEach((step) => {
                    step.CreationUserId = this.user.id
                    step.ModificationUserId = this.user.id

                    step.Price = this.price

                    const from = `${step.stepDate} ${step.FromDateTime}`

                    const before = `${step.stepDate} ${step.BeforeDateTime}`
                    if (step.Moment === 'between') {
                        step.FromDateTime = from
                        step.BeforeDateTime = before
                    } else if (step.Moment === 'from') {
                        step.FromDateTime = from
                        step.BeforeDateTime = ''
                    } else if (step.Moment === 'before') {
                        step.FromDateTime = ''
                        step.BeforeDateTime = before
                    }
                    delete step.stepDate
                })

                this.formData.OrderSteps = clone
                if (this.isB2C === true) {
                    this.formData.IdClient = this.currentClient.id
                }
                this.setDriverAndVehicleToOtherSteps()
                //  this.v$.formData.$touch()
                // const isFormCorrect = this.v$.formData.$invalid
                const isValid = await this.v$.formData.$validate()
                const hasName = await this.v$.newDeliveryName.$validate()

                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (isValid && hasName) {
                    const deliveryForm = {
                        // Reference: `${
                        //     this.prefix.delivery
                        // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                        CreationUserId: this.user.id,
                        IdClient: this.formData.IdClient,
                        Name: this.newDeliveryName,

                        CreationDate: '',
                        OrdersClient: [
                            {
                                Orders: [
                                    {
                                        IdClient: this.formData.IdClient,
                                        IdUserContact:
                                            this.formData.IdUserContact,
                                        IdDriver: this.formData.IdDriver ?? '',
                                        IdInvoice:
                                            this.formData.IdInvoice ?? '',
                                        Reference: this.formData.Reference,
                                        Status: this.formData.Status,
                                        Method: this.formData.Method,
                                        Type: this.formData.Type,
                                        VoucherNumber:
                                            this.formData.VoucherNumber ?? 0,
                                        CommentInternal:
                                            this.formData.CommentInternal ?? '',
                                        CommentClient:
                                            this.formData.CommentClient ?? '',
                                        OrderStepsHTML: null,
                                        CreationUserId: this.user.id,
                                        OrderSteps: this.formData.OrderSteps,
                                        OrderPackages: [
                                            this.formData.OrderPackages[0],
                                        ],
                                        DateBegin: this.formData.DateBegin,
                                        DateEnd: this.formData.DateEnd,
                                        OrderOptions: null,
                                        OrderOption: [],
                                    },
                                ],
                            },
                        ],
                    }
                    try {
                        const response = await this.createDelivery(deliveryForm)

                        if (response) {
                            this.isDuplicate = false
                            if (duplicate === true) {
                                this.isDuplicate = true
                                this.refreshMS += 1

                                this.$toast.success(this.$t('order_create_ok'))

                                this.creating = false
                            } else {
                                this.$router.push({ name: 'orders' })
                                this.$toast.success(this.$t('order_create_ok'))
                                const env = window.location.hostname
                                const back = 'orders'
                                // env !== 'becycle.biiim-solutions.com' &&
                                // env !== 'localhost' &&
                                // env !== 'dev.biiim-solutions.com'
                                //     ? 'orders'
                                //     : 'becycle_orders'
                                this.$emit('created', back)
                            }
                        }
                    } catch (err) {
                        this.creating = false
                        this.$toast.error(`${this.$t('order_create_ko')}`)
                    }
                } else {
                    this.creating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async updateOrder(duplicate = false) {
            this.creating = true
            this.containerNameError = this.containerName === ''
            if (this.containerNameError === false) {
                if (
                    this.containerCreation === true ||
                    this.containerOptions.length === 0
                ) {
                    const containerResp = await this.createNewContainer(
                        this.formData.OrderPackages[0]
                    )
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('bad_packages'))
                return false
            }
            this.resetFormsValidations()

            if (this.isPackageValid()) {
                this.formData.ModificationUserId = this.user.id

                this.formData.PacketNumber = this.formData.OrderPackages.length
                const clone = structuredClone(this.stepsToAdd)
                if (clone.length) {
                    clone.forEach((toAdd) => {
                        toAdd.CreationUserId = this.user.id
                        toAdd.ModificationUserId = this.user.id

                        this.formData.OrderSteps.push(toAdd)
                    })
                }
                this.formData.OrderSteps.forEach((step) => {
                    const from = `${step.stepDate} ${step.FromDateTime}`

                    const before = `${step.stepDate} ${step.BeforeDateTime}`

                    if (step.Moment === 'between') {
                        step.FromDateTime = from
                        step.BeforeDateTime = before
                    } else if (step.Moment === 'from') {
                        step.FromDateTime = from
                        step.BeforeDateTime = ''
                    } else if (step.Moment === 'before') {
                        step.FromDateTime = ''
                        step.BeforeDateTime = before
                    }
                    step.ModificationUserId = this.user.id
                    delete step.stepdate
                })

                this.setDriverAndVehicleToOtherSteps()

                const isValid = await this.v$.formData.$validate()

                if (isValid === true) {
                    const steps = []
                    this.formData.OrderSteps.forEach((step) => {
                        step.IdOrder = this.currentOrder.id
                        steps.push(step)
                    })
                    const packages = []
                    this.formData.OrderPackages.forEach((pack) => {
                        const packageForm = {
                            IdOrder: this.currentOrder.id,
                            // IdVehicleType:
                            //     this.formData.OrderPackages[0].IdVehicleType,
                            Reference: pack.Reference,
                            PackageNumber: pack.PackageNumber,
                            Width: this.formData.OrderPackages[0].Width,
                            Length: this.formData.OrderPackages[0].Length,
                            Height: this.formData.OrderPackages[0].Height,
                            Weight: this.formData.OrderPackages[0].Weight,
                            Value: this.formData.OrderPackages[0].Value,
                            Price: this.formData.OrderPackages[0].Price,
                            Id: pack.Id,
                            IdContainer: this.pickedContainerId,
                            LabelFile: this.formData.OrderPackages[0].LabelFile,
                            LastDeliveryCode:
                                this.formData.OrderPackages[0].LastDeliveryCode,
                            LastDeliveryCodeDate:
                                this.formData.OrderPackages[0]
                                    .LastDeliveryCodeDate,
                            TemperatureOption:
                                this.formData.OrderPackages[0]
                                    .TemperatureOption,
                            ModificationUserId: this.user.id,
                            CreationUserId:
                                this.formData.OrderPackages[0].CreationUserId,
                        }
                        packages.push(packageForm)
                    })

                    if (this.packageQuantity !== packages.length) {
                        if (this.packageQuantity > packages.length) {
                            const diff = this.packageQuantity - packages.length
                            for (let index = 0; index < diff; index += 1) {
                                const refPackage = packages[packages.length - 1]
                                const newPackage = {
                                    IdOrder: refPackage.IdOrder,
                                    // IdVehicleType: refPackage.IdVehicleType,
                                    Width: refPackage.Width,
                                    Length: refPackage.Length,
                                    Height: refPackage.Height,
                                    Weight: refPackage.Weight,
                                    Value: refPackage.Value,
                                    Price: refPackage.Price,
                                    IdContainer: refPackage.IdContainer,
                                    LabelFile: refPackage.LabelFile,
                                    LastDeliveryCode:
                                        refPackage.LastDeliveryCode,
                                    LastDeliveryCodeDate:
                                        refPackage.LastDeliveryCodeDate,
                                    TemperatureOption:
                                        refPackage.TemperatureOption,
                                    ModificationUserId:
                                        refPackage.ModificationUserId,
                                    CreationUserId: refPackage.CreationUserId,
                                }
                                packages.push(newPackage)
                            }
                        } else {
                            const diff = packages.length - this.packageQuantity
                            for (let index = 0; index < diff; index += 1) {
                                packages.pop()
                            }
                        }
                    }

                    const form = {
                        IdDelivery: this.currentOrder.idDelivery,
                        ModificationUserId: this.user.id,
                        Orders: [
                            {
                                Id: this.formData.Id,
                                OrderOptions: [],
                                IdDelivery: this.currentOrder.idDelivery,
                                IdOrderClient: this.currentOrder.idOrderClient,
                                IdClient: this.formData.IdClient,
                                IdUserContact: this.formData.IdUserContact,
                                IdDriver: this.formData.IdDriver,
                                IdInvoice: this.formData.IdInvoice,
                                DateBegin: this.formData.DateBegin,
                                DateEnd: this.formData.DateEnd,
                                DateReception: this.formData.DateReception,
                                Reference: this.currentOrder.Reference,
                                Status: this.formData.Status,
                                Method: this.formData.Method,
                                Type: this.formData.Type,
                                VoucherNumber: this.formData.VoucherNumber,
                                CommentInternal: this.formData.CommentInternal,
                                CommentClient: this.formData.CommentClient,
                                Duration: this.formData.Duration,
                                PacketNumber: null,
                                Weight: this.formData.Weight,
                                PacketValue: null,
                                ModificationUserId: this.user.id,

                                OrderPackages: packages,
                                OrderSteps: steps,
                            },
                        ],
                    }
                    const params = {
                        id: this.currentOrder.idOrderClient,
                        form,
                        duplicate,
                    }
                    try {
                        const response = await this.updateOrderClient(params)
                        if (response.status === 200) {
                            if (duplicate === true) {
                                this.$toast.success(this.$t('order_update_ok'))
                                this.creating = false
                                this.currentOrder = null

                                this.$router.push({ name: 'new_order' })
                            } else {
                                this.currentOrder = null

                                this.$router.push({ name: 'orders' })

                                this.$toast.success(this.$t('order_update_ok'))
                                const env = window.location.hostname
                                const back = 'orders'
                                // env !== 'becycle.biiim-solutions.com' &&
                                // env !== 'localhost' &&
                                // env !== 'dev.biiim-solutions.com'
                                //     ? 'orders'
                                //     : 'becycle_orders'
                                this.$emit('updated', back)
                                this.creating = false
                            }
                        } else {
                            this.$toast.error(this.$t('order_update_ko'))
                            this.$toast.error(response.data.message)
                            this.creating = false
                        }
                    } catch (err) {
                        this.$toast.error(`${this.$t('order_update_ko')}`)
                        this.$toast.error(err)
                        this.creating = false
                    }
                } else {
                    this.creating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
    },
    beforeUnmount() {
        clearInterval(this.currentOrderInterval)
        this.currentOrder = null
        this.clientStore.$patch({
            addresses: null,
            currentAddress: null,
            containers: null,
            currentContacts: null,
            currentContainer: null,
            current: null,
        })
    },
}
</script>
<style lang="scss" scoped>
.stop-stretching {
    width: 1%;
    white-space: nowrap;
}
#moment-select ::v-deep(.multiselect) {
    height: auto;
    width: 11rem;
}
.selectMS ::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: small !important;
    }
}
</style>
