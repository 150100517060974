<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <div
                class="mt-20 flex mb-3 items-center text-3xl ml-2"
                v-if="userStore.isB2C === true"
            >
                {{ $t('my_bills') }}
            </div>

            <fetch-data-error v-if="fetchError" :dataType="'billings'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="billingsList"
                :items="billings"
                :columns="columns"
                :storeToSort="'billings'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="updateFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useUserStore } from '@/stores/userStore'
import { useBillingStore } from '@/stores/billingStore'
import { useClientStore } from '@/stores/clientStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'BillingsList',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            billingsInterval: null,
            filter: [],

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        billings() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        }

        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null && this.currentClient) {
                clearInterval(isB2CInterval)
                if (this.userStore.isB2C === true) {
                    this.filter.push({
                        dbField: 'IdClient',
                        value: this.currentClient.id,
                    })
                }
                const realSearch = {
                    query: this.search,
                    filter: this.filter,
                    sortDirection: this.sortDirection,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    page: this.page,
                }
                this.searchBillings(realSearch)
                this.billingsInterval = setInterval(() => {
                    this.searchBillings(realSearch)
                }, 20000)
            }
        }, 200)
    },

    beforeUnmount() {
        clearInterval(this.billingsInterval)
    },
    computed: {
        billingsActionsAccess() {
            return actionsAccess.billings
        },
        ...mapState(useBillingStore, {
            billings: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore),
        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        columns() {
            return [
                {
                    name: 'bill_number',
                    filtrable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.billingsActionsAccess.update
                    ),
                    dbField: 'Number',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'client',
                    filtrable: true,
                    dbField: 'ClientName',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'reference',
                    filtrable: true,
                    // isLink: true,
                    dbField: 'Number',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'type',
                    filtrable: true,
                    dbField: 'Type',
                    type: 'select',
                    sortable: false,
                    options: [
                        {
                            name: 'end_month',
                            value: 'monthinvoice',
                            dbField: 'Type',
                        },
                        {
                            name: 'occasionnal',
                            value: 'occasionnal',
                            dbField: 'Type',
                        },
                        {
                            name: 'voucher_book',
                            value: 'voucherbook',
                            dbField: 'Type',
                        },
                    ],
                },
                {
                    name: 'PDF',
                    // filtrable: true,
                    // isLink: true,
                    // dbField: 'Number',
                    // type: 'search',
                    // sortable: true,
                },

                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.delete)
            ) {
                return [
                    {
                        name: 'delete',
                        action: 'delete',
                        icon: 'trash-can',
                    },
                ]
            }
            return []
        },
        multiActions() {
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.delete)
            ) {
                return [
                    {
                        name: 'delete_selection',
                        action: 'deleteAll',
                        icon: 'trash-can',
                    },
                ]
            }
            return []
        },
    },
    methods: {
        ...mapActions(useBillingStore, ['searchBillings', 'delete']),
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.billingsActionsAccess.create
            )
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.delete(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('area_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('area_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.delete(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            if (this.userStore.isB2C === true) {
                this.filter.push({
                    dbField: 'IdClient',
                    value: this.currentClient.id,
                })
            }
            this.setRealSearch(this.search)

            this.searchBillings(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.billingsInterval = setInterval(() => {
                this.searchBillings(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1

            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
