<template>
    <div class="mt-1 items-center">
        <label
            v-if="withLabel"
            class="font-semibold basis-1/4 col-span-1 text-gray-600 pb-1 mr-2 block"
            >{{ $t('sort_by') }}</label
        >
        <Multiselect
            class="multiselect-no-pr darky sort-select"
            :class="small ? 'h-2 pd-1' : 'h10'"
            v-model="field"
            :options="options"
            label="name"
            track-by="name"
            :searchable="false"
            :placeholder="$t(fieldType)"
            :can-deselect="canDeselect"
            :object="true"
            @clear="$emit('clear', field.dbField)"
            @select="$emit('selected', field)"
        >
            <template #singlelabel="{ value }">
                <div class="multiselect-single-label" :style="value.style">
                    <div class="select-label-icon-wrap">
                        <fa-icon
                            :title="value.name"
                            :icon="value.icon"
                            class="fa-xl"
                        ></fa-icon>
                    </div>
                </div>
            </template>
            <template #option="{ option }">
                <div class="select-option-icon-wrap" :style="option.style">
                    <fa-icon
                        :title="option.name"
                        :icon="option.icon"
                        class="fa-xl"
                    ></fa-icon>
                </div> </template
        ></Multiselect>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    name: 'IconSelect',
    components: { Multiselect },
    data() {
        return {
            field: this.selected,
        }
    },
    computed: {
        options() {
            const formattedOptions = []
            this.sortableFields.options.forEach((option) => {
                formattedOptions.push({
                    name: this.$t(option.name),
                    value: option.value,
                    dbField: option.dbField,
                    icon: option.icon,
                    style: option.style,
                })
            })
            return formattedOptions
        },
    },
    props: {
        withLabel: {
            type: Boolean,
            default: true,
        },
        fieldData: {
            type: Object,
            default: () => {},
        },
        selected: {
            type: Object,
            default: null,
        },
        sortableFields: {
            type: Object,
            default: () => {},
        },
        searchedItems: {
            type: Array,
            default: () => [],
        },
        storeToSort: {
            type: String,
            default: '',
        },
        canDeselect: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
        preselectFirst: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        fieldType: {
            type: String,
            default: 'no_filter',
        },
    },
}
</script>
<style lang="scss" scoped>
#sort-select ::v-deep(.multiselect) {
    font-size: x-small !important;
    height: auto;

    width: 6rem;
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
    }
    .multiselect-tag {
        font-size: x-small !important;
    }
}
</style>
