import Wrapper from '../views/users/Wrapper.vue'
import CreateOrUpdate from '../views/users/CreateOrUpdate.vue'
import RolesList from '../views/users/RolesList.vue'

const users = [
    {
        path: '/users',
        name: 'users',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'USERS_FULL', 'USERS_LIST'],
        },
        children: [
            {
                path: 'new',
                name: 'new_user',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'USERS_FULL',
                        'USERS_UPDATE',
                    ],
                },
            },
            {
                path: 'update-user/:id',
                name: 'update_user',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'USERS_FULL',
                        'USERS_CREATE',
                    ],
                },
            },
            {
                path: 'roles',
                name: 'roles_list',
                component: RolesList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'USERS_FULL',
                        'ROLES_LIST',
                        'ROLES_FULL'
                    ],
                },
            },
        ],
    },
]

export default users
