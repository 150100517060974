<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />

        <div
            id="create-address"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div class="p-5">
                <form
                    class="mb-2"
                    @keydown.enter.prevent
                    @submit.prevent="
                        $route.params.id ? updateAddress() : createAddress()
                    "
                >
                    <div>
                        <div class="mb-2">
                            <span
                                class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                            >
                                {{
                                    $t(
                                        $route.params.id
                                            ? 'update_address'
                                            : 'new_address'
                                    )
                                }}
                            </span>
                        </div>
                        <div class="grid grid-cols-6 gap-4 mt-2">
                            <div
                                class="rounded-md border-2 p-4"
                                :class="
                                    currentAddress
                                        ? 'col-span-2 '
                                        : 'col-span-3'
                                "
                            >
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('address') }}
                                        <hr />
                                    </span>
                                </div>
                                <span v-if="isB2C === false">
                                    <div
                                        v-if="
                                            !$route.params.client &&
                                            !$route.params.id
                                        "
                                        class="mb-3 items-center px-3"
                                    >
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('client') + ' *' }}</label
                                        >
                                        <Multiselect
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            v-model="formData.IdClient"
                                            :options="clientOptions"
                                            label="name"
                                            track-by="name"
                                            :searchable="true"
                                            :placeholder="'select a client'"
                                            :allow-empty="false"
                                            :can-clear="false"
                                        ></Multiselect>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="noClientError"
                                        >
                                            <div class="error-msg">
                                                {{ $t('select_client_info') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="mb-3 flex">
                                        <span
                                            v-if="currentClient"
                                            class="text-xl"
                                        >
                                            {{ $t('client') + ': ' }}
                                            {{
                                                currentClient.company_name
                                            }}</span
                                        >
                                    </div>
                                </span>

                                <div class="mb-3 items-center px-3">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('address_type') + ' *' }}</label
                                    >
                                    <Multiselect
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        v-model="formData.AddressType"
                                        :options="typeOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :can-deselect="false"
                                        :can-clear="false"
                                        label="name"
                                        track-by="name"
                                    ></Multiselect>
                                </div>

                                <div class="px-3">
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{
                                                $t('address_name') + ' *'
                                            }}</label
                                        >
                                        <input
                                            v-model="formData.Name"
                                            @blur="v$.formData.Name.$touch"
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.Name
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>

                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('address') + ' 1 *' }}
                                        </label>
                                        <!-- type="text" -->
                                        <input
                                            v-model="formData.Address1"
                                            id="autocomplete"
                                            @blur="v$.formData.Address1.$touch"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.Address1
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>

                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('address') }} 2</label
                                        >
                                        <input
                                            v-model="formData.Address2"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{
                                                $t('postal_code') + ' *'
                                            }}</label
                                        >
                                        <input
                                            id="postal_code"
                                            autocomplete="postal-code"
                                            v-model="formData.PostalCode"
                                            @blur="
                                                v$.formData.PostalCode.$touch
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.PostalCode
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('city') + ' *' }}</label
                                        >
                                        <input
                                            v-model="formData.City"
                                            id="locality"
                                            @blur="v$.formData.City.$touch"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.City
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-4 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                >Longitude</label
                                            >
                                            <input
                                                type="text"
                                                v-model="formData.Longitude"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                            <!-- <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .Longitude.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div> -->
                                        </div>

                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                >Latitude</label
                                            >
                                            <input
                                                type="text"
                                                v-model="formData.Latitude"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                            <!-- <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .Latitude.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('additional_info') }}
                                        </label>
                                        <input
                                            type="text"
                                            v-model="
                                                formData.AdditionalInformation
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('driver_info') }}
                                        </label>
                                        <input
                                            type="text"
                                            v-model="formData.DriverInformation"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="rounded-md border-2 p-4"
                                :class="
                                    currentAddress
                                        ? 'col-span-2 '
                                        : 'col-span-3'
                                "
                            >
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('contact_and_wait') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="grid grid-cols-4 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('contact_name') }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="formData.ContactName"
                                                autocomplete="name"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    $t('contact_name_imported')
                                                }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    formData.ContactNameImported
                                                "
                                                autocomplete="name"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-4 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('phone') }}</label
                                            >
                                            <input
                                                type="tel"
                                                v-model="formData.ContactPhone"
                                                autocomplete="tel"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .ContactPhone.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{
                                                    $t('phone_imported')
                                                }}</label
                                            >
                                            <input
                                                type="tel"
                                                v-model="
                                                    formData.ContactPhoneImported
                                                "
                                                autocomplete="tel"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .ContactPhoneImported
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                            >{{ $t('email_address') }}</label
                                        >
                                        <input
                                            type="email"
                                            autocomplete="email"
                                            v-model="formData.ContactEmail"
                                            @blur="
                                                v$.formData.ContactEmail.$touch
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.ContactEmail
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>

                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('waiting_time') }}</label
                                        >
                                        <input
                                            type="text"
                                            v-model="formData.WaitingTime"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div class="text-xs italic mt-1 mb-2">
                                        <div class="">
                                            {{ $t('waiting_time_info') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="currentAddress"
                                class="col-span-2 rounded-md border-2 p-4"
                            >
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('delivery_hours') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div
                                        v-for="(day, i) in days"
                                        :key="i"
                                        class="border-b-2 mb-4"
                                    >
                                        <div class="flex justify-center">
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 mr-2 mb-2"
                                                >{{ $t(day) }}
                                            </label>
                                            <icon-button
                                                :icon="'plus'"
                                                :noStyle="true"
                                                :iconStyle="'fa-md cursor-pointer'"
                                                :title="$t('new_item')"
                                                @click="addHour(i)"
                                            >
                                            </icon-button>
                                        </div>
                                        <div
                                            v-for="(
                                                openingHour, index
                                            ) in openingHours"
                                            :key="index"
                                        >
                                            <!-- {{ typeof openingHour.day  }}
                                            {{ typeof (i + 1) }}
                                            <hr /> -->
                                            <span
                                                :key="refreshHours"
                                                v-if="openingHour.day === i + 1"
                                            >
                                                <div class="flex items-center">
                                                    <div
                                                        class="grid grid-cols-7 gap-1 mb-4"
                                                    >
                                                        <div
                                                            class="col-span-1 mt-2"
                                                            :class="
                                                                openingHour.open
                                                                    ? ''
                                                                    : 'ml-3'
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    openingHour.open
                                                                        ? 'open'
                                                                        : 'closed'
                                                                )
                                                            }}
                                                        </div>
                                                        <div
                                                            class="col-span-1 mt-2 relative inline-block w-10 ml-2 mb-2 align-middle select-none transition duration-200 ease-in"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    openingHour.open
                                                                "
                                                                :name="
                                                                    'toggle' +
                                                                    index
                                                                "
                                                                :id="
                                                                    'toggle' +
                                                                    index
                                                                "
                                                                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-zinc-400 border-4 appearance-none cursor-pointer"
                                                            />
                                                            <label
                                                                :for="
                                                                    'toggle' +
                                                                    index
                                                                "
                                                                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                                            ></label>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <input
                                                                type="time"
                                                                v-if="
                                                                    openingHour.open ===
                                                                    true
                                                                "
                                                                v-model="
                                                                    openingHour.hourBegin
                                                                "
                                                                :placeholder="
                                                                    $t(
                                                                        'opening_time'
                                                                    ) +
                                                                    ' - 12:30'
                                                                "
                                                                class="border text-sm rounded px-3 py-2 w-full"
                                                            />
                                                            <span
                                                                v-if="
                                                                    openingHour.open ===
                                                                    true
                                                                "
                                                            >
                                                                <div
                                                                    v-for="error in v$
                                                                        .openingHours
                                                                        .$each
                                                                        .$response
                                                                        .$errors[
                                                                        index
                                                                    ].hourBegin"
                                                                    :key="error"
                                                                >
                                                                    <div
                                                                        class="error-msg text-xs italic"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div
                                                            class="col-span-2 mr-2"
                                                        >
                                                            <input
                                                                type="time"
                                                                v-if="
                                                                    openingHour.open ===
                                                                    true
                                                                "
                                                                v-model="
                                                                    openingHour.hourEnd
                                                                "
                                                                :placeholder="
                                                                    $t(
                                                                        'closing_time'
                                                                    ) +
                                                                    ' - 17:30'
                                                                "
                                                                class="border text-sm rounded px-3 py-2 w-full"
                                                            />
                                                            <span
                                                                v-if="
                                                                    openingHour.open ===
                                                                    true
                                                                "
                                                            >
                                                                <div
                                                                    v-for="error in v$
                                                                        .openingHours
                                                                        .$each
                                                                        .$response
                                                                        .$errors[
                                                                        index
                                                                    ].hourEnd"
                                                                    :key="error"
                                                                >
                                                                    <div
                                                                        class="error-msg text-xs italic"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div
                                                            class="col-span-1 mt-2 ml-2 flex items-center"
                                                        >
                                                            <icon-button
                                                                :icon="'floppy-disk'"
                                                                :noStyle="true"
                                                                :iconStyle="'fa-md cursor-pointer '"
                                                                :title="
                                                                    $t('save')
                                                                "
                                                                @click="
                                                                    saveOpeningHour(
                                                                        openingHour
                                                                    )
                                                                "
                                                            />
                                                            <icon-button
                                                                :icon="'trash-can'"
                                                                class="ml-3"
                                                                :noStyle="true"
                                                                :iconStyle="'fa-md cursor-pointer '"
                                                                :title="
                                                                    $t('delete')
                                                                "
                                                                @click="
                                                                    removeHour(
                                                                        openingHour
                                                                    )
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    type="submit"
                                    :disabled="creating"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon icon="spinner" spin></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="map" style="width: 100%; height: 500px"></div>
            </div>
        </div>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import { mapState, mapStores } from 'pinia'
import keys from '@/resources/keys'
import IconButton from '@/components/atoms/IconButton.vue'
import BackTo from '@/components/elements/BackTo.vue'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'
import googleScriptLoader from '@/mixins/googleScript'
import gmapStyles from '@/mixins/gmapStyles'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/
)
const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
)
// TODO ajouter validation pour les opening hours
const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
let marker = null
export default {
    name: 'CreateOrUpdateAddress',
    components: {
        IconButton,
        Multiselect,
        BackTo,
    },
    mixins: [googleScriptLoader, gmapStyles],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]

        return { key, v$: useVuelidate() }
    },
    created() {
        this.loadGoogleScript('autocomplete')
        this.map = null
    },
    mounted() {
        window.scrollTo(0, 0)
        if (this.isB2C === false) {
            if (!this.$route.params.client && !this.$route.params.id) {
                const clientInterval = setInterval(() => {
                    if (this.clients) {
                        this.clients.forEach((client) => {
                            clearInterval(clientInterval)

                            const option = {
                                name: client.company_name,
                                value: client.id,
                            }
                            this.clientOptions.push(option)
                        })
                    }
                }, 1000)
            } else {
                const clientInterval = setInterval(() => {
                    if (this.currentClient) {
                        clearInterval(clientInterval)
                    }
                }, 1000)
            }
        }
        this.currentAddressInterval = setInterval(() => {
            if (this.currentAddress) {
                clearInterval(this.currentAddressInterval)
                this.formData = {
                    Id: this.currentAddress.id,
                    Name: this.currentAddress.name,
                    Latitude: !Number.isNaN(this.currentAddress.lat)
                        ? parseFloat(this.currentAddress.lat)
                        : null,
                    Longitude: !Number.isNaN(this.currentAddress.lng)
                        ? parseFloat(this.currentAddress.lng)
                        : null,
                    Address1: this.currentAddress.address,
                    Address2: this.currentAddress.address2,
                    AdditionalInformation: this.currentAddress.additional_info,
                    PostalCode: this.currentAddress.postal_code,
                    City: this.currentAddress.city,
                    DriverInformation: this.currentAddress.driver_info,
                    ContactName: this.currentAddress.contact_name,
                    ContactEmail: this.currentAddress.contact_email,
                    ContactPhone: this.currentAddress.contact_phone,
                    ContactNameImported:
                        this.currentAddress.contact_name_imported,
                    ContactPhoneImported:
                        this.currentAddress.contact_phone_imported,
                    WaitingTime: this.currentAddress.waiting_time,
                    IdClient: this.currentAddress.client_id,
                    ModificationUserId: this.user.id,
                    CreationUserId: this.currentAddress.creation_user_id,
                    AddressType: this.currentAddress.address_type,
                }
                this.setOpeningHours()
            }
        }, 1000)
        const googleInterval = setInterval(() => {
            if (google) {
                if (!this.$route.params.id) {
                    clearInterval(googleInterval)

                    this.map = new google.maps.Map(
                        document.getElementById('map'),
                        {
                            center: {
                                lat: 45.75,
                                lng: 4.85,
                            },
                            zoom: 12,
                        }
                    )
                } else if (this.currentAddress) {
                    clearInterval(googleInterval)

                    this.map = new google.maps.Map(
                        document.getElementById('map'),
                        {
                            center: {
                                lat: !Number.isNaN(this.currentAddress.lat)
                                    ? parseFloat(this.currentAddress.lat)
                                    : 45.75,
                                lng: !Number.isNaN(this.currentAddress.lng)
                                    ? parseFloat(this.currentAddress.lng)
                                    : 4.85,
                            },
                            zoom: 15,
                        }
                    )
                    if (
                        !Number.isNaN(this.currentAddress.lat) &&
                        !Number.isNaN(this.currentAddress.lng)
                    ) {
                        marker = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,
                            position: {
                                lat: parseFloat(this.currentAddress.lat),

                                lng: parseFloat(this.currentAddress.lng),
                            },
                            draggable: true,
                        })
                        marker.setMap(this.map)
                        const eventScreen =
                            'ontouchstart' in document.documentElement
                                ? 'touchend'
                                : 'dragend'

                        marker.addListener(eventScreen, (evt) => {
                            this.markerDragged(evt)
                        })
                    }
                }
                this.setGoogleMapStyle(false)
            }
        }, 20)
    },
    data() {
        return {
            creating: false,

            days: [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
            noClientError: false,
            currentAddressInterval: null,
            formData: {
                Name: '',
                Latitude: '',
                Longitude: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                AdditionalInformation: '',
                PostalCode: '',
                City: '',
                Country: 'FRA',
                DriverInformation: '',
                ContactName: '',
                ContactEmail: '',
                ContactPhone: '',
                ContactNameImported: '',
                ContactPhoneImported: '',
                WaitingTime: 5,
                IdClient: this.$route.params.client
                    ? this.$route.params.client
                    : '',
                CreationUserId: '',
                ModificationUserId: '',
                AddressType: 'destination',
                IdExternal: 0,
            },
            openingHours: [],

            clientOptions: [],
            refreshHours: 0,
        }
    },
    validations() {
        return {
            formData: {
                IdClient: { required },
                Name: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode
                    ),
                },

                City: { required },
                ContactEmail: { email },
                ContactPhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
                ContactPhoneImported: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber
                    ),
                },
            },
            openingHours: {
                $each: helpers.forEach({
                    hourBegin: {
                        required,
                        timeIsValid: helpers.withMessage(
                            this.$t('bad_time_format'),
                            timeIsValid
                        ),
                    },
                    hourEnd: {
                        required,
                        timeIsValid: helpers.withMessage(
                            this.$t('bad_time_format'),
                            timeIsValid
                        ),
                    },
                }),
            },
        }
    },
    watch: {
        businessHours() {
            this.refreshHours += 1
        },
    },
    computed: {
        typeOptions() {
            const options = [
                { name: this.$t('destination'), value: 'destination' },
                { name: this.$t('origin'), value: 'origin' },
            ]
            if (this.isB2C === false) {
                options.push({ name: this.$t('step'), value: 'step' })
            }
            return options
        },
        ...mapState(useClientStore, {
            currentAddress: 'currentAddress',
            currentClient: 'current',
            clients: 'all',
            businessHours: 'businessHours',
        }),
        ...mapState(useUserStore, {
            isB2C: 'isB2C',
            user: 'current',
            mapMarker: 'mapMarker',
        }),
        ...mapStores(useClientStore),
    },
    methods: {
        setOpeningHours() {
            if (this.businessHours !== null) {
                const hours = JSON.parse(JSON.stringify(this.businessHours))
                hours.forEach((hour) => {
                    this.openingHours.push(hour)
                })
            }
        },
        back() {
            this.$router.push(this.$router.options.history.state.back)
            this.$emit('back', 'delivery_addresses')
        },
        markerDragged(position) {
            this.formData.Latitude = position.latLng.lat()
            this.formData.Longitude = position.latLng.lng()
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.formData.Address2 = ''
            this.formData.Address1 = ''

            this.formData.PostalCode = ''
            this.formData.City = ''

            const userInput = this.formData.Address1
            this.formData.Latitude = place.geometry.location.lat()
            this.formData.Longitude = place.geometry.location.lng()
            if (marker !== null) {
                marker.setMap(null)
            }
            this.map.setCenter({
                lat: this.formData.Latitude,
                lng: this.formData.Longitude,
            })
            this.map.setZoom(15)

            marker = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                position: {
                    lat: this.formData.Latitude,

                    lng: this.formData.Longitude,
                },
                draggable: true,
            })
            marker.setMap(this.map)
            const eventScreen =
                'ontouchstart' in document.documentElement
                    ? 'touchend'
                    : 'dragend'

            marker.addListener(eventScreen, (evt) => {
                this.markerDragged(evt)
            })

            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.formData.PostalCode = val
                } else if (addressType === 'locality') {
                    this.formData.City = val
                } else if (addressType === 'street_number') {
                    this.formData.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.formData.Address1 =
                        this.formData.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.formData.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.formData.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.formData.Address1 === userInput
                ) {
                    this.formData.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Address2 === ''
                ) {
                    this.formData.Address2 = place.name
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Name === ''
                ) {
                    this.formData.Name = place.name
                }
            })
        },
        async createAddress() {
            this.v$.formData.$reset()

            this.creating = true

            // this.formData.IdClient = this.$route.params.client ? this.$route.params.client : ''
            this.formData.CreationUserId = this.user.id
            if (this.isB2C === true) {
                this.formData.IdClient = this.user.idClient
            }
            if (this.$route.params.client) {
                this.formData.IdClient = this.$route.params.client
            }
            const isValid = await this.v$.formData.$validate()
            if (isValid) {
                try {
                    const response =
                        await this.clientStore.createDeliveryAddress(
                            this.formData
                        )
                    if (response.status === 200) {
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$emit('created', 'delivery_addresses')
                        this.$toast.success(this.$t('address_create_ok'))
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('address_create_ko'))
                        this.$toast.error(response.data.message)
                    }
                } catch (err) {
                    this.creating = false

                    this.$toast.error(`${this.$t('address_create_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateAddress() {
            this.v$.formData.$reset()

            this.creating = true
            if (this.isB2C === true) {
                this.formData.IdClient = this.user.idClient
            }
            const isFormCorrect = await this.v$.$validate()

            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    const response =
                        await this.clientStore.updateDeliveryAddress(params)
                    if (response.status === 200) {
                        this.$emit('updated', 'delivery_addresses')
                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$toast.success(this.$t('address_update_ok'))
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('address_update_ko'))
                        this.$toast.error(response.data.message)
                    }
                } catch (err) {
                    this.creating = false

                    this.$toast.error(`${this.$t('address_update_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        addHour(day) {
            this.openingHours.push({
                id_client_address: this.currentAddress.id,
                day: day + 1,
                open: false,
                hourBegin: null,
                hourEnd: null,
            })
        },
        async saveOpeningHour(openingHour) {
            let res = null
            if (!openingHour.id) {
                const formatted = {
                    // CreationDate:new Date(),
                    CreationUserId: this.user.id,
                    Day: openingHour.day,
                    HourBegin: openingHour.hourBegin,
                    HourEnd: openingHour.hourEnd,
                    Id: openingHour.id,
                    Open: openingHour.open,

                    IdClientAddress: openingHour.id_client_address,
                    ModificationUserId: this.user.id,
                }
                try {
                    res = await this.clientStore.addOpeningHours(formatted)
                    this.clientStore.fetchBusinessHours(this.currentAddress.id)
                    this.$toast.success(this.$t('business_hour_updated'))
                } catch (err) {
                    this.$toast.error(this.$t('business_hour_update_error'))
                }
            } else {
                const formatted = {
                    Day: openingHour.day,
                    HourBegin: openingHour.hourBegin,
                    HourEnd: openingHour.hourEnd,
                    Id: openingHour.id,
                    Open: openingHour.open,
                    IdClientAddress: openingHour.id_client_address,
                    ModificationDate: '',
                    ModificationUserId: this.user.id,
                }
                try {
                    res = await this.clientStore.updateOpeningHours({
                        id: openingHour.id,
                        hour: formatted,
                    })
                    this.clientStore.fetchBusinessHours(this.currentAddress.id)
                    this.$toast.success(this.$t('business_hour_updated'))
                } catch (err) {
                    this.$toast.error(this.$t('business_hour_update_error'))
                }
            }
        },
        async removeHour(openingHour) {
            // this.$toast.info('WIP no data from db yet ')

            const hourIndex = this.openingHours.indexOf(openingHour)
            this.openingHours.splice(hourIndex, 1)
            if (openingHour.id) {
                const res = await this.clientStore.deleteOpeningHours(
                    openingHour.id
                )
                if (res.status === 200) {
                    this.clientStore.fetchBusinessHours(this.currentAddress.id)
                    this.$toast.success(this.$t('business_hour_updated'))
                } else {
                    this.$toast.success(this.$t('business_hour_error'))
                }
            }
        },
    },
    beforeUnmount() {
        this.clientStore.$patch({
            currentAddress: null,
            businessHours: null,
            current: null,
        })
    },
}
</script>
