import Wrapper from '../views/vehicles/Wrapper.vue'
import CreateOrUpdate from '../views/vehicles/CreateOrUpdate.vue'

const vehicles = [
    {
        path: '/vehicles',
        name: 'vehicles',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'VEHICLES_FULL', 'VEHICLES_LIST'],
        },
        children: [
            {
                path: 'new',
                name: 'new_vehicle',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'VEHICLES_FULL',
                        'VEHICLES_CREATE',
                    ],
                },
            },
            {
                path: 'update/:id',
                name: 'update_vehicle',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'VEHICLES_FULL',
                        'VEHICLES_UPDATE',
                    ],
                },
            },
        ],
    },
]

export default vehicles
