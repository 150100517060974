import { createApp, markRaw } from 'vue'
import { useCookies, globalCookiesConfig } from 'vue3-cookies'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './index.css'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import VueToast from 'vue-toast-notification'
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css'
// import 'vue-toast-notification/dist/theme-sugar.css'

import i18n from './resources/i18n'
// import store from './store'
import router from './router/router'
import App from './App.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'
import FullLayout from './layouts/FullLayout.vue'

import './assets/scss/_variables.scss'
import './assets/scss/_base.scss'
import './assets/scss/_dark-mode.scss'
import './assets/scss/_mode-toggle.scss'

const hostTheme =
    window.location.hostname === 'localhost' ||
    window.location.host.includes('dev') ||
    window.location.host.includes('preprod')
        ? 'biiim'
        : window.location.host.includes('lium')
        ? 'lium'
        : window.location.host.substring(0, window.location.host.indexOf('.'))

import(`./assets/scss/_${hostTheme}-theme.scss`)

library.add(fas, far, fab)

dom.watch()
const { host } = window.location
const parts = host.split('.')

// ? Do not remove special baseUrl for dev env
if (parts[0] === 'dev') {
    axios.defaults.baseURL = `https://dev.biiim-solutions.com`
} else if (hostTheme === 'beaujolyon') {
    axios.defaults.baseURL = `https://beaujolyon.biiim-solutions.fr`
}
// else if (window.location.hostname !== 'localhost') {
//     axios.defaults.baseURL = `https://dev.biiim-solutions.com`
// }
// TODO a tester

const { cookies } = useCookies()
cookies.config = {
    expireTimes: '4h',
    path: '/',
    domain: '',
    secure: true,
    sameSite: 'None',
}

const pinia = createPinia()
pinia.use(({ store }) => {
    store.axios = markRaw(axios)
    store.router = markRaw(router)
    store.i18n = markRaw(i18n)
    store.cookies = markRaw(cookies)
    store.VueToast = markRaw(VueToast)
})

// store.axios = axios
// store.router = router
// store.i18n = i18n
// store.cookies = cookies
// store.toast = VueToast

// .use(store)

const app = createApp(App)
app.config.globalProperties.$cookies = cookies
app.use(router)
    .use(VueToast, {
        position: 'bottom-right',
        duration: 7000,
    })
    .use(pinia)
    .use(i18n)
    .use(VueAxios, axios)
    .component('fa-icon', FontAwesomeIcon)
    .component('fa-layer', FontAwesomeLayers)
    .component('default-layout', DefaultLayout)
    .component('full-layout', FullLayout)
    .mount('#app')

export default { app }
