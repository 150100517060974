<template>
    <div class="bg-white darky" :class="$route.meta.map ? '' : 'mr-20'">
        <!-- <nav-tabs :navTabs="tabs" @navigate="changeContent($event)" /> -->
        <component
            :is="componentToDisplay"
            :key="$route.fullPath"
            @create="toCreate()"
            @created="changeContent($event)"
            @updated="changeContent($event)"
            @back="changeContent($event)"
            @goToSimulation="toSimulation($event)"
            @simulationView="simulationView($event)"
            @tourView="tourView($event)"
            @goToTour="toTour($event)"
            @goToUpdateStep="toUpdateStep($event)"
            @goToCorrection="toCorrection($event)"
            @goToUpdateOrphanOrder="toUpdateOrder($event)"
        />
        <!-- @deleted="deleted()" -->
    </div>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'
import NavTabs from '@/components/elements/NavTabs.vue'
import Simulations from '@/views/tours/SimulationsList.vue'
import Tours from '@/views/tours/List.vue'
import TourDetail from '@/views/tours/TourDetail.vue'
import SimulationGeneration from '@/views/tours/SimulationGeneration.vue'
import SimulationDetail from '@/views/tours/SimulationDetail.vue'
import MapView from '@/views/tours/MapView.vue'
import { useUserStore } from '@/stores/userStore'
import { useOrderStore } from '@/stores/orderStore'
import { useSimulationStore } from '@/stores/simulationStore'
import { useTourStore } from '@/stores/tourStore'
import { useDriverStore } from '@/stores/driverStore'
import { useVehicleStore } from '@/stores/vehicleStore'

export default {
    name: 'ToursWrapper',
    components: {
        NavTabs,
        Tours,
        Simulations,
        SimulationGeneration,
        SimulationDetail,
        MapView,
        TourDetail,
    },
    data() {
        return {
            componentToDisplay: this.humanize(
                this.sideBarNav || this.$route.name
            ),
            tabs: [
                ['list-ul', 'tours'],
                // ['cart-plus', 'new_order'],
                ['list-ul', 'simulations'],
            ],
        }
    },
    created() {
        this.$cookies.remove('filtered')
    },
    mounted() {
        this.$emit('removeSidebarNav')

        if (this.$route.name === 'simulation_view') {
            this.simulationView(this.$route.params.id)
        } else if (this.$route.name === 'tour_view') {
            this.tourView(this.$route.params.id)
        } else if (this.$route.name === 'daily_view') {
            this.toDaily()
        } else {
            this.changeContent(this.$route.name)
        }
    },
    updated() {
        if (this.sideBarNav) {
            if (this.sidebarNavigateFromRoute === false) {
                this.$cookies.remove('filtered')
            }

            if (this.sideBarNav === 'daily_view') {
                this.toDaily()
            } else {
                this.changeContent(this.sideBarNav)
            }
            this.$emit('removeSidebarNav')
        }
    },

    props: {
        sideBarNav: {
            type: String,
            default: null,
        },
        sidebarNavigateFromRoute: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useTourStore),
    },
    methods: {
        ...mapActions(useOrderStore, ['fetchStep', 'fetchOne']),
        ...mapActions(useSimulationStore, ['fetchOneSimulation']),
        ...mapActions(useTourStore, ['fetchOneTour', 'fetchDaily']),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
        }),
        ...mapActions(useVehicleStore, {
            searchVehicles: 'search',
        }),
        toCorrection(date) {
            // ? pour comportement cohé"rent avec celui mis dans la map view on ne limite plus
            // l'affichage des stetps à corriger  à ceux correspondantt à la date de la simulation
            this.$router.replace({
                path: '/orders/order-step-correction',
                // query: { date },
            })
        },
        toUpdateOrder(id) {
            const vehiclePayload = {
                query: [],
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'Type',
                sortDirection: 'ASC',
            }
            const driversPayload = {
                query: [],
                filter: [{ dbField: 'Active', value: 1 }],
                perPage: 100000000000,
                page: 1,
                sortField: 'LastName',
                sortDirection: 'ASC',
            }

            this.searchDrivers(driversPayload)
            this.searchVehicles(vehiclePayload)
            this.fetchOne(id)

            this.$router.push({ name: 'update_order', params: { id } })
        },
        toUpdateStep(step) {
            if (step.incomplete) {
                step.id = step.Id
                step.order_ref = step.Reference
            }
            this.fetchStep({
                id: step.id,
                order_ref: step.order_ref,
            })

            this.$router.push({
                name: 'update_step',
                params: { id: step.id, order_ref: step.order_ref },
            })
        },

        toSimulation(id) {
            this.$router
                .push({ name: 'simulation_detail', params: { id } })
                .then(() => {
                    this.componentToDisplay = this.humanize('simulation_detail')
                })
        },
        simulationView(id) {
            this.fetchOneSimulation({ id }).then(() => {
                this.$router
                    .push({ name: 'simulation_view', params: { id } })
                    .then(() => {
                        this.componentToDisplay = this.humanize('map_view')
                    })
            })
        },
        tourView(id) {
            this.fetchOneTour({ id }).then(() => {
                this.$router
                    .push({ name: 'tour_view', params: { id } })
                    .then(() => {
                        this.componentToDisplay = this.humanize('map_view')
                    })
            })
        },
        toDaily() {
            this.tourStore.$patch({ dailyUnaffected: [] })
            // this.fetchDaily().then(() => {
                this.componentToDisplay = this.humanize('map_view')
            // })
        },
        toTour(id) {
            this.$router
                .push({ name: 'tour_detail', params: { id } })
                .then(() => {
                    this.componentToDisplay = this.humanize('tour_detail')
                })
        },
        // deleted() {
        //     this.$toast.success(this.$t('order_delete_ok'))
        // },

        changeContent(tab) {
            this.$emit('removeSidebarNav')

            this.componentToDisplay = this.humanize(tab)
        },
        toCreate() {
            this.componentToDisplay = this.humanize('simulation_generation')
            this.$router.push({ name: 'simulation_generation' })
        },
        toUpdate(id) {
            this.fetchOne(id)
            this.$router.push({ name: 'update_order', params: { id } })
            this.componentToDisplay = this.humanize('new_order')
        },

        humanize(str) {
            let i
            const frags = str.split('_')
            for (i = 0; i < frags.length; i += 1) {
                frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
            }
            return frags.join('')
        },
    },
}
</script>
