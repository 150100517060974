<template>
    <div class="items-center justify-between ">
        <icon-button
            class="cursor-pointer ml-2"
            :icon="icon"
            :noStyle="true"
            :iconStyle="iconStyle"
            @click="toggleSort"
        />
    </div>
</template>
<script>
import IconButton from './IconButton.vue'

export default {
    name: 'SortIcon',
    components: { IconButton },
    data() {
        return {
            direction: this.sortDirection,
            // icon: 'arrow-down-wide-short',
        }
    },
    computed: {
        icon() {
            return this.direction === 'DESC'
                ? 'arrow-down-wide-short'
                : 'arrow-down-short-wide'
        },
    },
    methods: {
        toggleSort() {
            if (this.direction === 'ASC') {
                this.direction = 'DESC'
                // this.icon = 'arrow-down-wide-short'
            } else {
                this.direction = 'ASC'
                // this.icon = 'arrow-down-short-wide'
            }
            this.$emit('sortChanged', {
                direction: this.direction,
                field: this.sortField,
            })
        },
    },
    props: {
        storeToSort: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        sortDirection: {
            type: String,
            default: 'DESC',
        },
        sortField: {
            type: String,
            default: '',
        },
        noStyle: {
            type: Boolean,
            default: false,
        },
        iconStyle: {
            type: String,
            default: 'fa-xl',
        },
    },
}
</script>
