<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <div class="flex items-center">
            <back-to @back="back()" />

            <button
                type="button"
                @click="refreshMap()"
                class="themed-button mt-12 text-base ml-96 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
            >
                <fa-icon icon="rotate" class="mr-2 mt-1"></fa-icon>

                {{ $t('refresh') }}
            </button>
        </div>

        <iframe
            :key="refresh"
            id="sim-detail"
            style="position: absolute; height: 90%; width: 90%; border: none"
            class="shadow rounded-md xs:p-0 mx-auto"
        ></iframe>
    </main>
</template>
<script>
import BackTo from '@/components/elements/BackTo.vue'

export default {
    name: 'SimulationDetail',
    components: { BackTo },
    data() {
        return {
            refresh: 0,
        }
    },
    setup() {
        const env =
            window.location.hostname === 'localhost' ||
            window.location.host.includes('dev')
                ? 'dev'
                : window.location.host.includes('lium')
                ? 'lium'
                : window.location.host.substring(
                      0,
                      window.location.host.indexOf('.')
                  )
        return { env }
    },
    async mounted() {
        this.getURL()
    },
    methods: {
        back() {
            this.$router.push(this.$router.options.history.state.back)
            this.$emit('back', 'simulations')
        },
        async getURL() {
            const dot = this.env === 'beaujolyon' ? 'fr' : 'com'
            this.axios
                .get(
                    `https://${this.env}.biiim-solutions.${dot}/api/v1/tour/form/tourManagement?entity=tour&form=tourManagement&id=${this.$route.params.id}&phase=simulation&target=iframe`,
                    {
                        responseType: 'blob',
                        headers: {
                            Authorization: `Bearer ${this.$cookies.get(
                                'user_session'
                            )}`,
                        },
                    }
                )
                .then((res) => {
                    const urlObject = URL.createObjectURL(res.data)
                    document
                        .querySelector('#sim-detail')
                        .setAttribute('src', urlObject)
                })
        },
        refreshMap() {
            this.getURL()
            this.refresh += 1
        },
    },
}
</script>
