import Wrapper from '../views/billings/Wrapper.vue'

const billings = [
    {
        path: '/billings',
        name: 'billings',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'BILLINGS_FULL', 'BILLINGS_LIST'],
        },
        // children: [
        //     {
        //         path: 'occasional',
        //         name: 'occasional',
        //        meta: {
        //             authorisations: ['FULL_ACCESS', 'BILLINGS_FULL',  ],

        //         },
        //     },
        //     {
        //         path: 'monthly',
        //         name: 'monthly',
        //        meta: {
        //             authorisations: ['FULL_ACCESS'],

        //         },
        //     },
        // ],
    },
]

export default billings
