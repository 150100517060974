<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md h-full">
        <div class="flex items-center text-zinc-500 justify-center pb-6 mt-16">
            <fa-icon icon="spinner" spin class="fa-2x mr-2" />
            {{ $t('loading') }}
        </div>
    </main>
</template>
<script>
export default {
    name: 'SpinLoader',
}
</script>
