<template>
    <main
        class="px-4 pt-2 rounded-md h-full"
        style="width: 96vw"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <div
                class="mt-20 flex mb-3 items-center text-3xl ml-2"
                v-if="userStore.isB2C === true"
            >
                {{ $t('my_orders') }}
            </div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'orders'"
                :multiTranslation="userStore.isB2C === true ? 2 : 1"
            />

            <filtrable-table
                v-else
                id="ordersList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="orders"
                :columns="columns"
                :storeToSort="'orders'"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                @changeSearch="changeSearch()"
                @checkboxFilterUpdate="checkboxFilter($event)"
                @dateFilterUpdate="setDateFilter($event)"
                :hoverableItems="userStore.isB2C === false"
                :itemActions="itemActions"
                :resetCheckedItems="resetCheckedItems"
                :canAddNew="canAddNew()"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @printAllLabels="printLabels($event)"
                @orderTracking="trackParcels($event)"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="updateFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @updatedAddress="$emit('updated', $event)"
                @update="goToUpdateOne($event)"
                @updateStep="goToUpdateStep($event)"
                @updateStepDriver="updateStepsDriver($event)"
                @updateStepStatus="updateStepStatus($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
                @packageTracking="$emit('packageTracking', $event)"
            >
                <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'
import { useDriverStore } from '@/stores/driverStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'BecycleOrders',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            driverOptions: [],
            dateFilter: [],
            resetCheckedItems: false,
            pickupTodayOnly: true,
            deliveryTodayOnly: true,
            searchByName: true,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            ordersInterval: null,
            sortableFields: [
                {
                    name: this.$t('client'),
                    value:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('reference'), value: 'Reference' },
            ],
        }
    },
    watch: {
        orders(oldVal, newVal) {
            if (
                this.page === 1 &&
                oldVal !== newVal &&
                (localStorage.getItem('popoverOpen') === null ||
                    localStorage.getItem('popoverOpen') === false)
            ) {
                this.refresh += 1
            }
        },
        drivers() {
            if (this.drivers !== null) {
                // clearInterval(driversInterval)
                this.drivers.forEach((driver) => {
                    const name = `${driver.firstname} ${driver.lastname}`
                    const option = {
                        name,
                        value: +driver.id,
                    }
                    this.driverOptions.push(option)
                })
            }
        },
    },
    created() {
        window.scrollTo(0, 0)

        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)
                const filteredList = this.$cookies.get('filtered')

                if (filteredList) {
                    // this.search.push(this.$cookies.get('query'))
                    this.search = filteredList.query
                    // this.searchQuery = filteredList.query[0]
                    //     ? filteredList.query[0].term
                    //     : ''
                    this.sortDirection = filteredList.sortDirection
                    this.filter = filteredList.filter
                    this.sortField = filteredList.sortField
                    this.perPage = filteredList.perPage
                    this.page = filteredList.page
                    this.pickupTodayOnly = filteredList.pickupTodayOnly
                    this.deliveryTodayOnly = filteredList.deliveryTodayOnly

                    this.dateFilter = filteredList.dateFilter
                    this.columns.forEach((col, index) => {
                        if (
                            col.type === 'select' ||
                            col.type === 'icon-select'
                        ) {
                            if (!col.multi) {
                                this.setRealSelected(col.dbField, index)
                            } else {
                                this.setRealSelected(col.dbField, index, true)
                            }
                        }
                    })
                    this.setRealSearch(this.search)
                }

                if (this.userStore.isB2C === false) {
                    if (!filteredList) {
                        this.filter.push({
                            name: 'delivery_status.in_progress',
                            value: 'inprogress',
                            dbField: 'Status',
                            icon: 'route',
                        })
                        this.filter.push({
                            name: 'delivery_new',
                            value: 'new',
                            dbField: 'Status',
                            icon: 'hourglass-half',
                        })
                        this.setRealSelected('Status', 2, true)
                    }

                    const payload = {
                        query: [],
                        filter: [],
                        perPage: 10000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                    }
                    this.searchContainers(payload)

                    const drPayload = {
                        query: [],
                        filter: [],
                        perPage: 10000,
                        page: 1,
                        sortField: 'LastName',
                        sortDirection: 'ASC',
                    }
                    this.searchDrivers(drPayload)

                    this.searchOrders({
                        query: this.search,
                        filter: this.filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                        deliveryTodayOnly: this.deliveryTodayOnly,
                        pickupTodayOnly: this.pickupTodayOnly,
                        dateFilter: this.dateFilter,
                    }).then(() => {
                        this.ordersInterval = setInterval(() => {
                            this.searchOrders({
                                query: this.search,
                                filter: this.filter,
                                sortDirection: this.sortDirection,
                                sortField: this.sortField,
                                perPage: this.perPage,
                                page: this.page,
                                deliveryTodayOnly: this.deliveryTodayOnly,
                                pickupTodayOnly: this.pickupTodayOnly,
                                dateFilter: this.dateFilter,
                            })
                        }, 20000)
                    })
                } else {
                    const payload = {
                        query: [],
                        filter: [],
                        perPage: 10000,
                        page: 1,
                        clientId: this.userStore.current.idClient,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                    }
                    this.searchContainersByClient(payload)
                    this.searchDeliveriesByClient({
                        query: this.search,
                        clientId: this.userStore.current.idClient,
                        filter: this.filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                    }).then(() => {
                        // this.ordersInterval = setInterval(() => {
                        //     if (this.orders !== null) {
                        //         clearInterval(this.ordersInterval)
                        this.ordersInterval = setInterval(() => {
                            this.searchDeliveriesByClient({
                                query: this.search,
                                clientId: this.userStore.current.idClient,
                                filter: this.filter,
                                sortDirection: this.sortDirection,
                                sortField: this.sortField,
                                perPage: this.perPage,
                                page: this.page,
                            })
                        }, 20000)
                    })
                    //     }
                    // }, 50)
                }
            }
        }, 20)
        if (this.$cookies.get('currentDeliveryId'))
            this.$cookies.remove('currentDeliveryId')
    },

    beforeUnmount() {
        clearInterval(this.ordersInterval)
    },

    computed: {
        ordersActionsAccess() {
            return actionsAccess.orders
        },
        // driverOptions() {
        //     const drOptions = []
        //     // const driversInterval = setInterval(() => {
        //     if (this.drivers !== null) {
        //         // clearInterval(driversInterval)
        //         this.drivers.forEach((driver) => {
        //             const name = `${driver.firstname} ${driver.lastname}`
        //             const option = {
        //                 name,
        //                 value: +driver.id,
        //             }
        //             drOptions.push(option)
        //         })
        //     }
        //     // }, 200)
        //     return drOptions
        // },
        columns() {
            return [
                {
                    name: 'client',
                    showRef: this.searchByName === false,
                    filtrable: true,
                    dbField:
                        this.searchByName === true
                            ? 'Client.Name'
                            : 'OrderClient.Reference',
                    isLink: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update
                    ),
                    searchIcon: this.searchByName === true ? 'user' : 'hashtag',
                    type: 'search',
                    sortable: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    subInfo: true,
                },
                {
                    name: 'name',
                    filtrable: true,
                    dbField: 'Name',
                    isLink: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update
                    ),

                    type: 'search',
                    sortable: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C === false
                        : this.$cookies.get('is_B2C') === 'false',
                },
                // {
                //     name: 'reference',
                //     filtrable: true,
                //     dbField: 'Reference',
                //     type: 'search',
                //     sortable: true,
                //     hide: this.userStore.isB2C
                //         ? this.userStore.isB2C === false
                //         : this.$cookies.get('is_B2C') === 'false',
                // },
                {
                    name: 'status',
                    filtrable: true,
                    type: 'icon-select',
                    dbField: 'Status',

                    withIconNoText: true,
                    sortable: false,
                    multi: true,

                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                    // selected: ,
                    options: [
                        {
                            name: 'delivery_status.in_progress',
                            value: 'inprogress',
                            dbField: 'Status',
                            icon: 'route',
                        },
                        {
                            name: 'delivery_status.finished',
                            value: 'finished',
                            icon: 'flag-checkered',
                            dbField: 'Status',
                        },
                        {
                            name: 'delivery_new',
                            value: 'new',
                            dbField: 'Status',
                            icon: 'hourglass-half',
                        },
                    ],
                },

                {
                    name: 'delivery_mode',
                    filtrable: true,
                    type: 'icon-select',
                    withIconNoText: true,
                    dbField: 'Method',

                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',

                    options: [
                        {
                            name: 'delivery_type.normal',
                            value: 'normal',
                            dbField: 'Method',
                            icon: 'gauge',
                            style: 'color:green;',
                        },
                        {
                            name: 'delivery_type.fast',
                            value: 'fast',
                            dbField: 'Method',
                            icon: 'gauge-high',
                            style: 'color:orange;',
                        },
                        {
                            name: 'delivery_type.express',
                            value: 'express',
                            dbField: 'Method',
                            icon: 'rocket',
                            style: 'color: red;',
                        },
                    ],
                },

                {
                    name: 'drivers',
                    filtrable: false,
                    sortable: false,
                    drivers: true,
                    options: this.driverOptions,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.update
                    ),
                    // type: 'search',
                    // dbField: 'OrderSteps.Driver.LastName',
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'pick_up',
                    filtrable: false,
                    notCentered: true,
                    sortable: false,
                    dbField: 'DateBegin',
                    datePicker: true,
                    withButton: true,
                    pickedDate: new Date(),
                    buttonValue: this.pickupTodayOnly,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.updateStatus
                    ),
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'delivery',
                    filtrable: false,
                    notCentered: true,
                    withButton: true,
                    canUpdate: checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.updateStatus
                    ),
                    buttonValue: this.deliveryTodayOnly,
                    dbField: 'DateEnd',
                    datePicker: true,
                    pickedDate: new Date(),
                    sortable: false,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'additional_info',
                    filtrable: false,
                    sortable: false,
                    packages: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C
                        : this.$cookies.get('is_B2C') === 'true',
                },
                {
                    name: 'packages',
                    filtrable: false,
                    sortable: false,
                    packages: true,
                    hide: this.userStore.isB2C
                        ? this.userStore.isB2C === false
                        : this.$cookies.get('is_B2C') === 'false',
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                    hide: false,
                },
            ]
        },

        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.ordersActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            if (this.userStore.isB2C === false) {
                // multiActions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                // })
            }
            return actions
        },
        itemActions() {
            const actions = []

            if (this.userStore.isB2C === true) {
                // actions.push({
                //     sub: true,
                //     action: 'printAllLabels',
                // })

                // actions.push({
                //     action: 'orderTracking',
                //     sub: true,
                // })
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.update)
                ) {
                    actions.push({
                        name: 'edit',
                        action: 'update',
                        icon: 'pen',
                        hide: false,
                        notAffectedOnly: true,
                    })
                }
                // actions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                //     hide: this.userStore.isB2C,
                // })
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.delete)
                ) {
                    actions.push({
                        name: 'delete',
                        action: 'delete',
                        icon: 'trash-can',
                        notAffectedOnly: true,
                        hide: false,
                    })
                }
            } else {
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.update)
                ) {
                    actions.push({
                        name: 'edit',
                        action: 'update',
                        icon: 'pen',
                        hide: false,
                    })
                }
                // actions.push({
                //     name: 'generate_invoice',
                //     action: 'invoice',
                //     icon: 'file-invoice-dollar',
                //     hide: false,
                // })
                if (
                    checkAccess(this.userStore, this.ordersActionsAccess.delete)
                ) {
                    actions.push({
                        name: 'delete',
                        action: 'delete',
                        icon: 'trash-can',
                        hide: false,
                    })
                }
            }

            return actions
        },
        ...mapState(useOrderStore, {
            orders: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore),

        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
        }),
    },
    methods: {
        canAddNew() {
            return checkAccess(this.userStore, this.ordersActionsAccess.create)
        },
        ...mapActions(useClientStore, [
            'searchContainersByClient',
            'searchContainers',
        ]),
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
        }),
        ...mapActions(useOrderStore, [
            'searchOrders',
            'searchDeliveriesByClient',
            'deleteOrder',
            'deleteDelivery',
            'deleteManyDeliveries',
            'deleteManyOrders',
            'deleteOrderClient',
            'updateStep',
            'fetchOneOrderAndUpdateStatus',
        ]),
        changeSearch() {
            this.searchByName = !this.searchByName
            const searchFilters = JSON.parse(JSON.stringify(this.search))
            searchFilters.forEach((filter, index) => {
                if (filter.dbField === 'Client.Name') {
                    this.search[index].dbField = 'OrderClient.Reference'
                } else if (filter.dbField === 'OrderClient.Reference') {
                    this.search[index].dbField = 'Client.Name'
                }
            })
            this.setResults()
        },
        goToUpdateOne(id) {
            clearInterval(this.ordersInterval)

            this.$emit('goToUpdateOrder', id)
        },
        goToUpdateStep(event) {
            clearInterval(this.ordersInterval)

            this.$emit('goToUpdateStep', event)
        },
        printLabels(order) {
            this.axios
                .get(`/api/v1/orderPackage/${order.id}/getlabels`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session'
                        )}`,
                    },
                })
                .then((res) => {
                    // const url = URL.createObjectURL(res.data)
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.setAttribute('print', `order-${order.reference}`)
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // URL.revokeObjectURL(url)
                    const iframe = document.createElement('iframe')
                    // Hide the IFrame.
                    iframe.style.visibility = 'hidden'
                    // Define the source.
                    iframe.src = window.URL.createObjectURL(res.data)
                    // Add the IFrame to the web page.
                    document.body.appendChild(iframe)
                    iframe.contentWindow.focus()
                    iframe.contentWindow.print()
                })
        },
        trackParcels(order) {
            const env =
                window.location.hostname === 'localhost' ||
                window.location.host.includes('dev')
                    ? 'dev'
                    : window.location.host.includes('lium')
                    ? 'lium'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            const dot = env === 'beaujolyon' ? 'fr' : 'com'
            window
                .open(
                    `https://${env}.biiim-solutions.${dot}/api/v1/tracking/form/live?reference=${order.reference}`,
                    '_blank'
                )
                .focus()
        },
        updateStepsDriver(infos) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)
            infos.steps.forEach((step, index) => {
                step.IdDriver = infos.newDriver
                const payload = {
                    id: step.Id,
                    form: step,
                }
                this.updateStep(payload)
                    .then((res) => {
                        if (index === infos.steps.length - 1) {
                            this.setResults()
                        }
                    })
                    .catch((err) => {
                        this.searchingCursor = false
                        this.$toast.error(this.$t(' error_occured'))
                    })
            })
        },
        updateStepStatus(step) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)
            step.step.Status = step.newStatus
            step.step.ModificationUserId = this.userStore.current.id
            if (step.newStatus === 'executed') {
                step.step.ExecutedDateTime = new Date()
            } else {
                step.step.ExecutedDateTime = null
            }
            const payload = {
                id: step.step.Id,
                form: step.step,
            }
            this.updateStep(payload)
                .then((res) => {
                    if (
                        (step.newStatus === 'inProgress' ||
                            (step.newStatus === 'executed' && !step.isLast)) &&
                        (step.lastStatus !== 'executed' || step.isLast === true)
                    ) {
                        this.fetchOneOrderAndUpdateStatus({
                            id: step.step.IdOrder,
                            newStatus: 'inprogress',
                            userId: this.userStore.current.id,
                        })
                    }

                    if (step.newStatus === 'executed' && step.isLast) {
                        this.fetchOneOrderAndUpdateStatus({
                            id: step.step.IdOrder,
                            newStatus: 'finished',
                            userId: this.userStore.current.id,
                        })
                    }
                    this.setResults()
                })
                .catch((err) => {
                    this.searchingCursor = false
                    this.$toast.error(this.$t(' error_occured'))
                })
        },
        async deleteOne(id) {
            this.searchingCursor = true

            let response = null
            let toastSuccessMessage = 'order_delete_ok'
            let toastErrorMessage = 'order_delete_ko'

            if (this.userStore.isB2C === true) {
                toastSuccessMessage = 'delivery_delete_ok'
                toastErrorMessage = 'delivery_delete_ko'
                response = await this.deleteDelivery(id)
            } else {
                const orderClientToDelete = this.orders.find(
                    (order) => order.id === id
                )
                id = orderClientToDelete.idOrderClient
                response = await this.deleteOrderClient(id)
            }

            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t(toastSuccessMessage))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t(toastErrorMessage))
            }
        },
        async deleteAll(elToDelete) {
            this.searchingCursor = true

            const elements = []
            let response = null
            elToDelete.forEach((id) => {
                elements.push({ Id: id })
            })
            if (this.userStore.isB2C === true) {
                response = await this.deleteManyDeliveries(elements)
            } else {
                this.searchingCursor = false

                response = await this.deleteManyOrders(elements)
            }
            if (response.status === 200) {
                this.resetCheckedItems = true
                this.$toast.success(this.$t('delete_many_ok'))
                this.searchingCursor = false
            } else {
                this.$toast.error(this.$t('delete_many_ko'))
                this.searchingCursor = false
            }
            this.resetCheckedItems = false
        },
        checkboxFilter(dbField) {
            this.searchingCursor = true
            clearInterval(this.ordersInterval)
            const filtToRemoveIndex = this.dateFilter.findIndex(
                (filt) => filt.dbField === dbField
            )
            if (dbField === 'DateBegin') {
                this.pickupTodayOnly = !this.pickupTodayOnly
                if (this.pickupTodayOnly === true) {
                    if (filtToRemoveIndex !== -1) {
                        this.dateFilter.splice(filtToRemoveIndex, 1)
                    }
                }
            }
            if (dbField === 'DateEnd') {
                this.deliveryTodayOnly = !this.deliveryTodayOnly
                if (this.deliveryTodayOnly === true) {
                    if (filtToRemoveIndex !== -1) {
                        this.dateFilter.splice(filtToRemoveIndex, 1)
                    }
                }
            }
            this.setResults()
        },
        setDateFilter(dFilter) {
            this.searchingCursor = true

            if (dFilter.dbField === 'DateBegin') {
                this.pickupTodayOnly = false
            }
            if (dFilter.dbField === 'DateEnd') {
                this.deliveryTodayOnly = false
            }
            const filterExistsIndex = this.dateFilter.findIndex(
                (df) => df.dbField === dFilter.dbField
            )
            if (filterExistsIndex !== -1) {
                this.dateFilter[filterExistsIndex] = dFilter
            } else {
                this.dateFilter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
            }

            this.setResults()
        },
        setPage(isNext) {
            clearInterval(this.ordersInterval)
            this.$cookies.remove('filtered')

            this.searchingCursor = true
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.ordersInterval)
            this.$cookies.remove('filtered')

            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            // const query = search.query

            this.$cookies.set('filtered', search)
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            // console.log(this.$cookies.get('query'))

            // this.$router.replace({
            //     path: this.$router.currentRoute.value.fullPath,
            //     query: { search: this.$cookies.get('query').term },
            // })
            search.clientId =
                this.userStore.isB2C === true
                    ? this.userStore.current.idClient
                    : ''
            if (this.userStore.isB2C === true) {
                this.searchDeliveriesByClient(search)
                this.searchingCursor = false
                this.ordersInterval = setInterval(() => {
                    this.searchDeliveriesByClient(search)
                }, 20000)
            } else {
                this.searchOrders(search)
                this.searchingCursor = false
                this.ordersInterval = setInterval(() => {
                    this.searchOrders(search)
                }, 20000)
            }

            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.ordersInterval)

            this.$cookies.remove('filtered')
            this.searchingCursor = true
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.ordersInterval)

            const search = {
                query: this.search,
                clientId:
                    this.userStore.isB2C === true
                        ? this.userStore.current.idClient
                        : '',
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            if (this.userStore.isB2C === false) {
                search.deliveryTodayOnly = this.deliveryTodayOnly
                search.pickupTodayOnly = this.pickupTodayOnly
                search.dateFilter = this.dateFilter
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
