import { defineStore } from 'pinia'
import { useClientStore } from './clientStore'

const useOrderStore = defineStore('order', {
    state: () => ({
        all: null,
        count: null,
        countSteps: null,
        stepsTotalPages: null,
        currentStep: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        steps: null,
        importedOrders: null,
        importedOrdersCount: null,
        importedOrdersTotalPages: null,
        orderFileTypes: null,
        orderFiletypesCount: null,
        trackedPackages: [],
        orderToDuplicate: null,
    }),
    actions: {
        async searchOrders(payload) {
            // console.log(payload)
            this.current = null
            this.loading = true

            let query = []

            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                const statusFilter = []
                payload.filter.forEach((type) => {
                    if (type.dbField !== 'Status') {
                        if (
                            type.dbField === 'Id' ||
                            type.dbField === 'IdClient'
                        ) {
                            query.push(
                                `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                            )
                        } else if (
                            type.dbField !== 'DateBegin' ||
                            type.dbField !== 'DateEnd'
                        ) {
                            query.push(
                                `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                            )
                        }
                    } else {
                        statusFilter.push(`"${type.value}"`)
                    }
                })
                if (statusFilter.length > 0) {
                    query.push(
                        `{"Status":[{"operator":"IN","value":[${statusFilter}]}]}`
                    )
                }
            }

            // if (payload.deliveryTodayOnly || payload.pickupTodayOnly) {
            const now = new Date().toISOString().slice(0, 10)
            if (
                payload.deliveryTodayOnly === true &&
                payload.pickupTodayOnly === true
            ) {
                query.push(
                    `{"DateBegin":[{"operator":"LIKE","value":"${now}"}],"DateEnd":[{"operator":"LIKE","value":"${now}"}]}`
                )
            }
            if (
                payload.deliveryTodayOnly === false &&
                payload.pickupTodayOnly === true
            ) {
                if (payload.dateFilter) {
                    const filterExistsIndex = payload.dateFilter.findIndex(
                        (df) => df.dbField === 'DateEnd'
                    )
                    if (filterExistsIndex !== -1) {
                        const end = payload.dateFilter[filterExistsIndex]
                        if (end.value !== '') {
                            query.push(
                                `{"DateBegin":[{"operator":"LIKE","value":"${now}"}],"DateEnd":[{"operator":"LIKE","value":"${end.value}"}]}`
                            )
                        } else {
                            query.push(
                                `{"DateBegin":[{"operator":"LIKE","value":"${now}"}]}`
                            )
                        }
                    } else {
                        query.push(
                            `{"DateBegin":[{"operator":"LIKE","value":"${now}"}]}`
                        )
                    }
                }
            }
            if (
                payload.deliveryTodayOnly === true &&
                payload.pickupTodayOnly === false
            ) {
                if (payload.dateFilter) {
                    const filterExistsIndex = payload.dateFilter.findIndex(
                        (df) => df.dbField === 'DateBegin'
                    )
                    if (filterExistsIndex !== -1) {
                        const begin = payload.dateFilter[filterExistsIndex]
                        if (begin.value !== '') {
                            query.push(
                                `{"DateEnd":[{"operator":"LIKE","value":"${now}"}],"DateBegin":[{"operator":"LIKE","value":"${begin.value}"}]}`
                            )
                        } else {
                            query.push(
                                `{"DateEnd":[{"operator":"LIKE","value":"${now}"}]}`
                            )
                        }
                    } else {
                        query.push(
                            `{"DateEnd":[{"operator":"LIKE","value":"${now}"}]}`
                        )
                    }
                }
            }

            if (
                payload.deliveryTodayOnly === false &&
                payload.pickupTodayOnly === false
            ) {
                if (payload.dateFilter) {
                    const filterBeginExistsIndex = payload.dateFilter.findIndex(
                        (df) => df.dbField === 'DateBegin'
                    )
                    const filterEndExistsIndex = payload.dateFilter.findIndex(
                        (df) => df.dbField === 'DateEnd'
                    )
                    if (
                        filterBeginExistsIndex !== -1 &&
                        filterEndExistsIndex !== -1
                    ) {
                        const begin = payload.dateFilter[filterBeginExistsIndex]
                        const end = payload.dateFilter[filterEndExistsIndex]
                        if (begin.value !== '' && end.value !== '') {
                            query.push(
                                `{"DateEnd":[{"operator":"LIKE","value":"${end.value}"}],"DateBegin":[{"operator":"LIKE","value":"${begin.value}"}]}`
                            )
                        } else if (begin.value === '' && end.value !== '') {
                            query.push(
                                `{"DateEnd":[{"operator":"LIKE","value":"${end.value}"}]}`
                            )
                        } else if (begin.value !== '' && end.value === '') {
                            query.push(
                                `{"DateBegin":[{"operator":"LIKE","value":"${begin.value}"}]}`
                            )
                        }
                    } else if (
                        filterBeginExistsIndex !== -1 &&
                        filterEndExistsIndex === -1
                    ) {
                        const begin = payload.dateFilter[filterBeginExistsIndex]
                        query.push(
                            `{"DateBegin":[{"operator":"LIKE","value":"${begin.value}"}]}`
                        )
                    } else if (
                        filterBeginExistsIndex === -1 &&
                        filterEndExistsIndex !== -1
                    ) {
                        const end = payload.dateFilter[filterEndExistsIndex]
                        query.push(
                            `{"DateEnd":[{"operator":"LIKE","value":"${end.value}"}]}`
                        )
                    }
                }
            }
            // }
            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/order/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/order/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200 && res.data.Records) {
                            const orders = []
                            res.data.Records.forEach((order) => {
                                let formattedOrder = null
                                let currentDriver = null

                                let iconInfos = {}
                                if (
                                    order.Status === 'finished' ||
                                    +order.ExecutedSteps ===
                                        order.OrderSteps.length ||
                                    order.OrderSteps[
                                        order.OrderSteps.length - 1
                                    ].Status === 'executed'
                                ) {
                                    iconInfos = {
                                        icon: 'flag-checkered',
                                        title: 'last_step_finished',
                                    }
                                    currentDriver =
                                        order.OrderSteps[
                                            order.OrderSteps.length - 1
                                        ].Driver ?? null
                                } else if (order.Status === 'new') {
                                    iconInfos = {
                                        title: 'delivery_new',
                                        icon: 'hourglass-half',
                                    }
                                    currentDriver =
                                        order.OrderSteps[
                                            order.OrderSteps.length - 1
                                        ].Driver ?? null
                                } else if (
                                    order.Status === 'inprogress' &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle !== '' &&
                                    order.OrderSteps[+order.ExecutedSteps]
                                        .IdVehicle !== '0'
                                ) {
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'truck'
                                    ) {
                                        iconInfos = {
                                            title: 'truck',
                                            icon: 'truck',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'bike'
                                    ) {
                                        iconInfos = {
                                            title: 'bike',
                                            icon: 'person-biking',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'car'
                                    ) {
                                        iconInfos = {
                                            title: 'car',
                                            icon: 'car',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'van'
                                    ) {
                                        iconInfos = {
                                            icon: 'van',
                                            title: 'van',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'boat'
                                    ) {
                                        iconInfos = {
                                            title: 'boat',
                                            icon: 'ship',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'deliveryTricycle'
                                    ) {
                                        iconInfos = {
                                            icon: 'deliveryTricycle',
                                            title: 'delivery_tricycle',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === 'cargoBike'
                                    ) {
                                        iconInfos = {
                                            icon: 'cargoBike',
                                            title: 'cargo_bike',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === '' ||
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .Vehicle.Type === null
                                    ) {
                                        iconInfos = {
                                            icon: null,
                                        }
                                    }
                                    currentDriver =
                                        order.OrderSteps[order.ExecutedSteps]
                                            .Driver ?? null
                                } else if (order.Status === 'inprogress') {
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'truck'
                                    ) {
                                        iconInfos = {
                                            title: 'truck',
                                            icon: 'truck',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'bike'
                                    ) {
                                        iconInfos = {
                                            title: 'bike',
                                            icon: 'person-biking',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'car'
                                    ) {
                                        iconInfos = {
                                            title: 'car',
                                            icon: 'car',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'van'
                                    ) {
                                        iconInfos = {
                                            icon: 'van',
                                            title: 'van',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'boat'
                                    ) {
                                        iconInfos = {
                                            title: 'boat',
                                            icon: 'ship',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'deliveryTricycle'
                                    ) {
                                        iconInfos = {
                                            icon: 'deliveryTricycle',
                                            title: 'delivery_tricycle',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === 'cargoBike'
                                    ) {
                                        iconInfos = {
                                            icon: 'cargoBike',
                                            title: 'cargo_bike',
                                        }
                                    }
                                    if (
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === '' ||
                                        order.OrderSteps[+order.ExecutedSteps]
                                            .TypeVehicle === null
                                    ) {
                                        iconInfos = {
                                            icon: null,
                                        }
                                    }
                                    currentDriver =
                                        order.OrderSteps[order.ExecutedSteps]
                                            .Driver ?? null
                                } else {
                                    iconInfos = {
                                        icon: null,
                                    }
                                    currentDriver =
                                        order.OrderSteps[order.ExecutedSteps]
                                            .Driver ?? null
                                }
                                const currentSteps = this.setCurrentSteps(
                                    order.OrderSteps,
                                    iconInfos.icon
                                )
                                formattedOrder = {
                                    id: +order.Id,
                                    reference: order.OrderClient.Reference,
                                    weight: order.Weight,
                                    duration: order.OrderClient.Duration,
                                    distance: order.OrderClient.Distance,
                                    externalId: order.OrderClient.IdExternal,
                                    subInfoForListDisplay:
                                        order.OrderClient.IdExternal,
                                    subInfoText: 'external_ref',
                                    client: order.Client.Name,
                                    current_driver: currentDriver,
                                    number_of_parcels: +order.PacketNumber,
                                    delivery_mode: order.Method,
                                    contact: order.Contact,
                                    idOrderClient: order.IdOrderClient,
                                    executedSteps: order.ExecutedSteps,
                                    break: order.Break === 1,
                                    steps: order.OrderSteps,
                                    currentStepsToDisplay: currentSteps,
                                    packages: order.OrderPackages,
                                    idClient: order.IdClient,

                                    deliveryId: order.IdDelivery,
                                    // order_client: order.Client,
                                    worth: +order.PacketValue,
                                    status: order.Status,
                                    icons: {
                                        status: {
                                            icon: iconInfos,
                                            // order.Status === 'inprogress'
                                            //     ? 'truck-fast'
                                            //     : 'flag-checkered',
                                        },
                                        delivery_mode: {
                                            icon:
                                                order.Method === 'fast'
                                                    ? 'gauge-high'
                                                    : order.Method === 'express'
                                                    ? 'rocket'
                                                    : 'gauge',
                                            style:
                                                order.Method === 'fast'
                                                    ? 'color: orange; '
                                                    : order.Method === 'express'
                                                    ? 'color: red;'
                                                    : 'color: green;',
                                        },
                                    },
                                    creation_date: order.CreationDate,
                                    modification_date: order.ModificationDate,
                                }
                                orders.push(formattedOrder)
                            })
                            this.all = orders
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchSteps(payload) {
            this.loading = true

            // const clients = JSON.parse(JSON.stringify(rootState.clients.all))

            const query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }
            if (payload.toCorrect) {
                query.push(
                    `{"Latitude":[{"operator": "IS NULL", "value": ""}]}`
                )
                query.push(
                    `{"Longitude":[{"operator": "IS NULL", "value": ""}]}`
                )
            }
            if (payload.date) {
                query.push(
                    `{"FromDateTime":[{"operator": "LIKE", "value": "${payload.date}"}]}`
                )
                // query.push(`"Longitude":[{"operator": "IS NULL", "value": ""}]`)
            }

            const apiUrl = `/api/v1/orderStep/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const steps = []
                            res.data.Records.forEach(async (step) => {
                                const formattedStep = {
                                    id: step.Id,
                                    name: step.AddressName,
                                    address: `${step.Address1} ${step.Address2}`,
                                    postal_code: step.PostalCode,
                                    city: step.City,
                                    creation_date: step.CreationDate,
                                }

                                steps.push(formattedStep)
                            })

                            this.steps = steps
                            this.countSteps = +res.data.TotalResults
                            this.stepsTotalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.steps = null
                            this.countSteps = 0
                            this.stepsTotalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = err
                        this.loading = false

                        reject(err)
                    })
            })
        },
        setCurrentSteps(steps, icon = null) {
            let formattedSteps = steps
            if (formattedSteps.length > 2) {
                const currentSteps = []
                steps.forEach((step, index) => {
                    if (
                        steps[steps.length - 2].Status === 'executed' ||
                        steps[steps.length - 1].Status === 'executed'
                    ) {
                        currentSteps.push(steps[steps.length - 2])
                        currentSteps.push(steps[steps.length - 1])
                    } else if (
                        currentSteps.length === 0 &&
                        step.Type === 'pickup' &&
                        (index + 1) % 2 &&
                        steps[index + 1].Status !== 'executed' &&
                        steps[index + 1].ExecutedDateTime === null
                    ) {
                        if (
                            icon === null ||
                            (icon !== 'hourglass-half' &&
                                icon !== 'flag-checkered')
                        ) {
                            let vehicleIcon = ''
                            if (
                                step.IdVehicle &&
                                step.IdVehicle !== '' &&
                                step.IdVehicle !== '0'
                            ) {
                                if (step.Vehicle.Type === 'truck') {
                                    vehicleIcon = {
                                        title: 'truck',
                                        icon: 'truck',
                                    }
                                }
                                if (step.Vehicle.Type === 'bike') {
                                    vehicleIcon = {
                                        title: 'bike',
                                        icon: 'person-biking',
                                    }
                                }
                                if (step.Vehicle.Type === 'car') {
                                    vehicleIcon = {
                                        title: 'car',
                                        icon: 'car',
                                    }
                                }
                                if (step.Vehicle.Type === 'van') {
                                    vehicleIcon = {
                                        icon: 'van',
                                        title: 'van',
                                    }
                                }
                                if (step.Vehicle.Type === 'boat') {
                                    vehicleIcon = {
                                        title: 'boat',
                                        icon: 'ship',
                                    }
                                }
                                if (step.Vehicle.Type === 'deliveryTricycle') {
                                    vehicleIcon = {
                                        icon: 'deliveryTricycle',
                                        title: 'delivery_tricycle',
                                    }
                                }
                                if (step.Vehicle.Type === 'cargoBike') {
                                    vehicleIcon = {
                                        icon: 'cargoBike',
                                        title: 'cargo_bike',
                                    }
                                }
                                if (
                                    step.Vehicle.Type === '' ||
                                    step.Vehicle.Type === null
                                ) {
                                    vehicleIcon = {
                                        icon: null,
                                    }
                                }
                            }

                            step.icon = vehicleIcon
                        }
                        currentSteps.push(step)
                        currentSteps.push(steps[index + 1])
                    }
                })
                formattedSteps = currentSteps
            }
            return formattedSteps
        },
        searchByClient(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/client/${payload.clientId}/orders?format=pagination&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/client/${payload.clientId}/orders?format=pagination&query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const orders = []
                            res.data.Records.forEach(async (order) => {
                                const formattedOrder = {
                                    id: +order.Id,
                                    reference: order.Reference,
                                    weight: order.Weight,
                                    number_of_parcels: +order.PacketNumber,
                                    delivery_mode: order.Method,
                                    steps: order.OrderSteps,
                                    packages: order.OrderPackages,
                                    worth: +order.PacketValue,
                                    status: order.Status,
                                    creation_date: order.CreationDate,
                                    duration: order.OrderClient.Duration,
                                    distance: order.OrderClient.Distance,
                                }

                                orders.push(formattedOrder)
                            })
                            this.all = orders
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchDeliveriesByClient(payload) {
            this.loading = true
            this.current = null
            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }
            query.push(
                `{"IdClient":[{"operator":"LIKE","value":"${payload.clientId}"}]}`
            )

            let apiUrl = ''
            // if (Object.keys(query).length === 0) {
            //     query = ''
            //     apiUrl = `/api/v1/delivery/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            // } else {
            //     apiUrl = `/api/v1/delivery/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            // }
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/client/${payload.clientId}/deliveries?format=pagination&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/client/${payload.clientId}/deliveries?format=pagination&query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const orders = []
                            res.data.Records.forEach(async (order) => {
                                const formattedOrder = {
                                    id: +order.Id,
                                    reference: order.Reference,
                                    orderClient: order.OrdersClient,
                                    name: order.Name
                                        ? order.Name
                                        : this.i18n.global.t(
                                              'no_delivery_name'
                                          ),
                                    subInfoForListDisplay: `${this.i18n.global.t(
                                        'distance_nokm'
                                    )} ${order.Distance} km 
                                    ${this.i18n.global.t('duration')} ${
                                        order.Duration
                                    } `,

                                    weight: order.Weight,
                                    duration: order.Duration,
                                    distance: order.Distance,
                                    // steps: order.OrderSteps,
                                    // packages: order.OrderPackages,
                                    // worth: +order.PacketValue,
                                    // status: order.Status,
                                    creation_date: order.CreationDate,
                                }

                                orders.push(formattedOrder)
                            })
                            this.all = orders
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchImportedOrders(payload) {
            this.loading = true

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }
            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/orderFile/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/orderFile/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages
                            )
                                ? 0
                                : +res.data.TotalPages
                            const orders = []
                            if (count > 0) {
                                res.data.Records.forEach(async (order) => {
                                    const type = this.orderFileTypes.find(
                                        (orderFileType) =>
                                            +orderFileType.Id ===
                                            +order.IdOrderFileType
                                    )
                                    const formattedOrder = {
                                        id: order.Id,
                                        title: `${this.i18n.global.t(
                                            'order_file_title'
                                        )} ${order.Client.Name}`,
                                        file: order.Name,
                                        path: order.Path,
                                        file_type: type.Name.toUpperCase(),
                                        client_id: order.IdClient,
                                        status: order.Status,
                                        lines_to_import: order.LinesToImport,
                                        imported_lines: order.ImportedLines,

                                        creation_date: order.CreationDate,
                                    }

                                    orders.push(formattedOrder)
                                })
                            }
                            this.importedOrders = orders
                            this.importedOrdersCount = +res.data.TotalResults
                            this.importedOrdersTotalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.importedOrders = null
                            this.importedOrdersCount = 0
                            this.importedOrdersTotalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchOrderFileTypes(payload) {
            const query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0 && q.dbField !== 'client') {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            const apiUrl = `/api/v1/orderFileType/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults

                            this.orderFileTypes = res.data.Records
                            this.orderFiletypesCount = count
                        } else {
                            this.orderFileTypes = null
                            this.orderFiletypesCount = 0
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneDelivery(payload) {
            this.cookies.set('currentDeliveryId', payload)

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/delivery/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        const order = res.data

                        const current = {
                            id: +order.Id,
                            client_id: +order.IdClient,
                            orderClient: order.OrdersClient,
                            reference: order.Reference,
                            name: order.Name,
                            client: order.Client,
                            contact: order.Contact,
                            creation_date: order.CreationDate,
                            modification_date: order.ModificationDate,
                            creation_user_id: +order.CreationUserId,
                            modification_user_id: +order.ModificationUserId,
                        }

                        this.current = current
                        const clientStore = useClientStore()

                        if (this.cookies.get('is_B2C') === 'false') {
                            clientStore
                                .fetchClientContacts({
                                    clientId: order.IdClient,
                                    fromOrders: true,
                                })
                                .then(resolve(res))
                        } else {
                            clientStore
                                .fetchContainers(order.IdClient)
                                .then(() => {
                                    resolve(res)
                                })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        fetchOne(payload) {
            this.current = null

            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/order/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        const order = res.data
                        const current = {
                            begin_date: order.DateBegin,
                            end_date: order.DateEnd,
                            reception_date: order.DateReception,
                            duration: order.Duration,
                            comment_client: order.CommentClient,
                            id: +order.Id,
                            client_id: +order.IdClient,
                            driver_id: order.IdDriver,
                            id_external: order.OrderClient.IdExternal,
                            vehicle_id: order.IdVehicle,
                            invoice_id: order.IdInvoice,
                            comment_internal: order.CommentInternal,
                            order_packages: order.OrderPackages,
                            order_steps: order.OrderSteps,
                            reference: order.OrderClient.Reference,
                            method: order.Method,
                            type: order.Type,
                            status: order.Status,
                            user_contact_id: order.IdUserContact,
                            creation_date: order.CreationDate,
                            modification_date: order.ModificationDate,
                            creation_user_id: +order.CreationUserId,
                            modification_user_id: +order.ModificationUserId,
                            order_options: order.OrderOptions,
                            packet_number: order.PacketNumber,
                            packet_value: order.PacketValue,
                            weight: order.Weight,
                            contact: order.Contact,
                            idDelivery: order.IdDelivery,
                            idOrderClient: order.IdOrderClient,

                            voucher_number: order.VoucherNumber,
                            client: order.Client,
                        }
                        this.current = current
                        const clientStore = useClientStore()

                        clientStore.$patch({
                            current: order.Client,
                            currentContacts: null,
                        })

                        clientStore.fetchContainers(order.IdClient).then(() => {
                            resolve(res)
                        })
                        clientStore.fetchClientContacts({
                            clientId: order.IdClient,
                            fromOrders: true,
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        fetchOneOrderAndUpdateStatus(payload) {
            return new Promise((resolve, reject) => {
                this.fetchOne(payload.id)
                    .then((res) => {
                        const order = res.data
                        if (order.Status !== payload.newStatus) {
                            order.Status = payload.newStatus
                            order.ModificationUserId = payload.userId

                            const updatePayload = { id: order.Id, form: order }
                            this.updateOrder(updatePayload).then(() => {
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        trackOnePackage(pacakgeRef, steps) {
            this.trackedPackages = []
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/orderPackage/route?reference=${pacakgeRef}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const packageInfos = {
                                steps,

                                coordinates: res.data.GeoJSON.coordinates,
                            }
                            this.trackedPackages.push(packageInfos)
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(error)
                    })
            })
        },
        async createDelivery(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/delivery', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(error)
                    })
            })
        },
        async createOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/order', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(error)
                    })
            })
        },
        async createOrderFileType(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderFileType', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(error)
                    })
            })
        },
        async createOrderClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderclient', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(error)
                    })
            })
        },
        async uploadOrderFile(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/orderfile', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()

                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }

                        reject(error)
                    })
            })
        },
        updateOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/order/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        updateDeliveryName(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/delivery/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        updateOrderClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderclient/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (payload.duplicate === true) {
                            const order = res.data.Orders[0].Id
                            this.fetchOne(order).then((res) => {
                                this.orderToDuplicate = structuredClone(
                                    this.current
                                )
                                const clientStore = useClientStore()
                                const clientsPayload = {
                                    query: [],
                                    filter: [],
                                    perPage: 10000,
                                    page: 1,
                                    sortField: 'Name',
                                    sortDirection: 'ASC',
                                }
                                clientStore.search(clientsPayload)
                                this.current = null
                                resolve(res)
                            })
                        } else {
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        savePackageToOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/orderPackage`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        updatePackageToOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderPackage/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        fetchStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/orderStep/${payload.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        const step = res.data
                        const current = {
                            id: step.Id,
                            name: step.AddressName,
                            address: step.Address1,
                            address2: step.Address2,
                            address3: step.Address3,
                            address4: step.Address4,
                            postal_code: step.PostalCode,
                            city: step.City,
                            driver: step.IdDriver,
                            order_id: +step.IdOrder,
                            vehicle_id: +step.IdVehicle,
                            moment: step.Moment,
                            fromDateTime: step.FromDateTime,
                            beforeDateTime: step.BeforeDateTime,
                            step_number: +step.StepNumber,
                            lat: step.Latitude,
                            lng: step.Longitude,
                            creation_date: step.CreationDate,
                            creation_user_id: step.CreationUserId,
                            modification_user_id: step.ModificationuserId,
                            vehicle_type: step.TypeVehicle,
                            address_type: step.Type,
                            client_address_id: step.IdClientAddress,
                            reference_address: step.ClientAddress ?? null,
                            order: step.Order,
                        }

                        if (step.Latitude === null || step.Longitude === null) {
                            this.axios
                                .get(
                                    `https://maps.googleapis.com/maps/api/geocode/json?address=${step.City},${step.PostalCode}&region=fr&key=${payload.key}`
                                )
                                .then((googleRes) => {
                                    if (googleRes.data.results.length > 0) {
                                        current.coordinates_by_city = {
                                            pos: {
                                                lat: JSON.parse(
                                                    JSON.stringify(
                                                        googleRes.data
                                                            .results[0].geometry
                                                            .location.lat
                                                    )
                                                ),

                                                lng: JSON.parse(
                                                    JSON.stringify(
                                                        googleRes.data
                                                            .results[0].geometry
                                                            .location.lng
                                                    )
                                                ),
                                            },
                                            label: '?',
                                            draggable: true,
                                            title: 'order_step',
                                        }
                                        this.currentStep = current
                                        resolve(res)
                                    } else {
                                        let zip = step.PostalCode.slice(0, -3)
                                        zip *= 1000
                                        this.axios
                                            .get(
                                                `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&region=fr&key=${payload.key}`
                                            )
                                            .then((googleRes2) => {
                                                current.coordinates_by_city = {
                                                    pos: {
                                                        lat: JSON.parse(
                                                            JSON.stringify(
                                                                googleRes2.data
                                                                    .results[0]
                                                                    .geometry
                                                                    .location
                                                                    .lat
                                                            )
                                                        ),

                                                        lng: JSON.parse(
                                                            JSON.stringify(
                                                                googleRes2.data
                                                                    .results[0]
                                                                    .geometry
                                                                    .location
                                                                    .lng
                                                            )
                                                        ),
                                                    },
                                                    label: '?',
                                                    draggable: true,
                                                    title: 'order_step',
                                                }
                                                this.currentStep = current

                                                resolve(res)
                                            })
                                    }
                                })
                        } else {
                            this.currentStep = current

                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/order/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteManyOrders(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/order/deleteRecords`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteManyDeliveries(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/delivery/deleteRecords`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteOrderClient(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderclient/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteDelivery(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/delivery/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteImportedOrder(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderFile/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        updateStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/orderStep/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteStep(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderStep/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentStep = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deletePackage(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/orderPackage/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
    },
})
export { useOrderStore }
