<template>
    <!-- Page Content -->
    <div class="pb-4" :class="smallMT === true ? 'mt-2' : 'mt-14'">
        <div class="-mx-4">
            <div
                :class="
                    isB2C === true || $route.name !== 'orders' ? 'px-4' : 'px-'
                "
            >
                <table class="leading-normal" :class="tableWidth">
                    <thead class="text-xs" :key="refresh">
                        <tr
                            class="darky z-100 text-xs file:flex flex-col bg-zinc-100 flex-no wrap sm:table-row xs:table-row"
                        >
                            <th
                                v-if="
                                    multiActions.length > 0 ||
                                    canSelectItems === true
                                "
                                class="rounded-tl-lg pl-4 stop-stretching border-b-2 bg-zinc-100 border-zinc-200"
                            >
                                <!-- :class="
                                    isB2C === true ||
                                    $route.name !== 'orders'
                                        ? 'px-3'
                                        : ''
                                " -->
                                <div class="flex items-center">
                                    <input
                                        class="themed-checkbox h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox"
                                        value=""
                                        v-model="all"
                                        @change="checkAll()"
                                        :id="`check-all`"
                                    />
                                    <dropdown-menu
                                        ref="itemsActionDD"
                                        v-if="canSelectItems === false"
                                        class="inline-flex"
                                        :position="''"
                                        :ellipsisStyle="true"
                                        :items="multiActions"
                                        @picked="multiAction($event)"
                                    >
                                        <template #buttonContent>
                                            <fa-icon
                                                :title="$t('action')"
                                                icon="ellipsis-vertical"
                                                class="fa-lg"
                                                :disabled="!all"
                                            />
                                        </template>
                                    </dropdown-menu>
                                </div>
                            </th>
                            <th
                                v-for="(column, index) in columns"
                                :key="index"
                                :id="
                                    column.hide && column.hide === true
                                        ? `${column.name}-${index}-hidden`
                                        : `${column.name}-${index}`
                                "
                                class="py-1 border-b-2 border-zinc-200 bg-zinc-100 font-semibold text-zinc-500 tracking-wider darky"
                                :class="
                                    column.hide && column.hide === true
                                        ? 'hidden'
                                        : (multiActions.length > 0 ||
                                              canSelectItems === true ||
                                              isNotFirstColumn(index)) &&
                                          (isB2C === true ||
                                              $route.name !== 'orders')
                                        ? 'px-3 sm:px-0'
                                        : isB2C === true ||
                                          $route.name !== 'orders'
                                        ? 'px-3 sm:px-0 rounded-tl-lg'
                                        : multiActions.length > 0 ||
                                          canSelectItems === true ||
                                          isNotFirstColumn(index)
                                        ? ''
                                        : 'rounded-tl-lg'
                                "
                            >
                                <span
                                    v-if="!column.hide || column.hide === false"
                                >
                                    <span v-if="column.filtrable">
                                        <span
                                            class="flex items-center justify-center"
                                        >
                                            <div class="relative">
                                                <div
                                                    class="absolute inset-y-0 right-0 flex items-center px-2"
                                                >
                                                    <sort-icon
                                                        v-if="column.sortable"
                                                        :storeToSort="
                                                            storeToSort
                                                        "
                                                        :noStyle="true"
                                                        :iconStyle="'text-zinc-500 pt-2'"
                                                        :sortDirection="
                                                            sortDirection
                                                        "
                                                        :sortField="
                                                            column.dbField
                                                        "
                                                        :items="items"
                                                        :title="$t('order')"
                                                        @sortChanged="
                                                            $emit(
                                                                'sortDirectionChanged',
                                                                $event
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <search-input
                                                    v-if="
                                                        column.type === 'search'
                                                    "
                                                    :key="refresh"
                                                    :searchBy="
                                                        column.showRef &&
                                                        column.showRef === true
                                                            ? 'operator_ref'
                                                            : column.name.includes(
                                                                  'name'
                                                              )
                                                            ? 'search_name'
                                                            : column.name
                                                    "
                                                    :dbField="column.dbField"
                                                    :searchQuery="
                                                        column.term
                                                            ? column.term
                                                            : searchQuery
                                                    "
                                                    :small="false"
                                                    :searchIcon="
                                                        column.searchIcon
                                                            ? column.searchIcon
                                                            : 'magnifying-glass'
                                                    "
                                                    @changeSearch="
                                                        $emit('changeSearch')
                                                    "
                                                    @searched="
                                                        $emit(
                                                            'searched',
                                                            $event
                                                        )
                                                    "
                                                >
                                                </search-input>
                                            </div>
                                        </span>
                                        <select-one
                                            v-if="column.type === 'select'"
                                            id="sort-select"
                                            :key="refresh"
                                            :sortField="column.options[0]"
                                            :sortableFields="column"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected ?? null"
                                            :withLabel="false"
                                            :searchedItems="items"
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                        ></select-one>
                                        <!-- :key="refresh" -->
                                        <icon-select
                                            v-if="
                                                column.type === 'icon-select' &&
                                                !column.multi
                                            "
                                            id="sort-select"
                                            :sortField="column.options[0]"
                                            :sortableFields="column"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected ?? []"
                                            :withLabel="false"
                                            :fieldType="column.name"
                                            :searchedItems="items"
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                        ></icon-select>
                                        <icon-select-multiple
                                            v-else-if="
                                                column.type === 'icon-select' &&
                                                column.multi &&
                                                column.multi === true
                                            "
                                            id="sort-select"
                                            :elemType="column.name"
                                            :sortableFields="column.options"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected"
                                            :withLabel="false"
                                            :fieldType="column.name"
                                            :multi="true"
                                            :searchedItems="items"
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                        ></icon-select-multiple>
                                        <!-- :sortField="sortField" -->
                                    </span>

                                    <span v-else>
                                        <!-- :class="
                                            column.sortable
                                                ? 'flex items-center md:whitespace-nowrap'
                                                : ''
                                        " -->
                                        <div
                                            class="flex items-center"
                                            :class="
                                                column.notCentered &&
                                                column.notCentered === true
                                                    ? 'pl-2'
                                                    : ' justify-center'
                                            "
                                        >
                                            <span
                                                class="text-xs items-center flex"
                                                :class="
                                                    column.withButton
                                                        ? 'border-2 rounded px-1 py-1'
                                                        : ''
                                                "
                                            >
                                                <span class="items-center flex">
                                                    {{ $t(column.name) }}
                                                    <span
                                                        v-if="
                                                            column.datePicker &&
                                                            column.datePicker ===
                                                                true
                                                        "
                                                        class="ml-1"
                                                    >
                                                        <span
                                                            class="items-center flex"
                                                        >
                                                            <input
                                                                :id="`${column.name}-time`"
                                                                type="date"
                                                                v-model="
                                                                    pickedDate[
                                                                        column
                                                                            .dbField
                                                                    ]
                                                                "
                                                                @change="
                                                                    $emit(
                                                                        'dateFilterUpdate',
                                                                        {
                                                                            dbField:
                                                                                column.dbField,
                                                                            value: pickedDate[
                                                                                column
                                                                                    .dbField
                                                                            ],
                                                                        }
                                                                    )
                                                                "
                                                                class="border rounded px-3 py-2 w-full"
                                                            />
                                                            <!-- <label
                                                                :for="`${column.name}-checkbox`"
                                                                class="ml-2 text-xs cursor-pointer font-medium"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'show_today_only'
                                                                    )
                                                                }}
                                                            </label> -->
                                                        </span>
                                                    </span>
                                                    <span
                                                        v-if="
                                                            column.withButton &&
                                                            column.withButton ===
                                                                true
                                                        "
                                                        class="ml-2"
                                                    >
                                                        <span
                                                            class="items-center flex mr-1"
                                                        >
                                                            <input
                                                                :id="`${column.name}-checkbox`"
                                                                type="checkbox"
                                                                v-model="
                                                                    column.buttonValue
                                                                "
                                                                @click.stop="
                                                                    $emit(
                                                                        'checkboxFilterUpdate',
                                                                        column.dbField
                                                                    ) &
                                                                        resetDatePicker(
                                                                            {
                                                                                value: column.buttonValue,
                                                                                name: column.dbField,
                                                                            }
                                                                        )
                                                                "
                                                                class="themed-checkbox cursor-pointer w-3 h-3 bg-gray-100 rounded border-gray-300"
                                                            />
                                                            <label
                                                                :for="`${column.name}-checkbox`"
                                                                class="text-xs cursor-pointer whitespace-nowrap ml-1 font-medium"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'show_today_only'
                                                                    )
                                                                }}
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <sort-icon
                                                v-if="column.sortable"
                                                :storeToSort="storeToSort"
                                                :sortDirection="sortDirection"
                                                :items="items"
                                                :noStyle="true"
                                                :sortField="column.dbField"
                                                :iconStyle="'text-zinc-500 pt-1'"
                                                :title="$t('order')"
                                                @sortChanged="
                                                    $emit(
                                                        'sortDirectionChanged',
                                                        $event
                                                    )
                                                "
                                            />
                                        </div>
                                    </span>
                                </span>
                            </th>

                            <th
                                class="z-10 w-0 py-1 border-b-2 stop-stretching border-zinc-200 bg-zinc-100 font-semibold text-zinc-500 rounded-tr-lg"
                            >
                                <icon-button
                                    v-if="canAddNew"
                                    icon="plus"
                                    :noStyle="true"
                                    :iconStyle="'fa-xl cursor-pointer'"
                                    :title="$t('new_item')"
                                    @click="$emit('create')"
                                ></icon-button>
                            </th>
                        </tr>
                    </thead>

                    <tbody
                        v-if="count > 0"
                        :key="refresh"
                        class="flex-1 sm:flex-none"
                    >
                        <tr
                            v-for="(item, index) in items"
                            :key="index"
                            class="darky flex flex-col flex-no wrap sm:table-row xs:table-row"
                            :class="
                                !isOdd(index) ? 'light-stripe' : 'dark-stripe'
                            "
                        >
                            <td
                                :class="
                                    multiActions.length === 0 &&
                                    canSelectItems === false
                                        ? 'hidden'
                                        : ''
                                "
                                class="px-4 text-xs"
                            >
                                <input
                                    class="themed-checkbox h-4 w-4 border border-gray-300 rounded-sm focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox"
                                    :value="item.id"
                                    :id="`check-${item.id}`"
                                    v-model="checkedItems"
                                    @click="check(item.id)"
                                />
                                <!-- @change="check(item.id)" -->
                            </td>
                            <td
                                v-for="(column, i) in columns"
                                :key="i"
                                class="border-b border-zinc-200"
                                :class="
                                    column.hide && column.hide === true
                                        ? 'hidden'
                                        : column.json
                                        ? 'stop-stretching-json text-xs'
                                        : column.withIconNoText &&
                                          column.withIconNoText === true &&
                                          isB2C === false
                                        ? 'stop-stretching w-2 text-xs'
                                        : column.withIconNoText &&
                                          column.withIconNoText === true &&
                                          (isB2C === true ||
                                              $route.name !== 'orders')
                                        ? 'stop-stretching py-4 w-4 text-xs'
                                        : column.withIconNoText &&
                                          column.withIconNoText === true &&
                                          (isB2C === true ||
                                              $route.name !== 'orders')
                                        ? 'py-4 w-4 text-xs'
                                        : isB2C === true && column.packages
                                        ? 'w-fit pt-4 text-sm'
                                        : 'text-xs'
                                "
                            >
                                <span
                                    v-if="!column.hide || column.hide === false"
                                >
                                    <!-- WIP display packages for extranet -->
                                    <span
                                        v-if="
                                            column.packages &&
                                            column.name == 'packages'
                                        "
                                        class="grid grid-cols-2 gap-x-14"
                                    >
                                        <span
                                            v-for="(
                                                order, indKey
                                            ) in item.orderClient"
                                            :key="indKey"
                                            class="mb-2 items-center justify-center col-span-1"
                                        >
                                            <span
                                                v-for="(
                                                    pack, index
                                                ) in setDeliveryPackages(
                                                    order.Orders
                                                )"
                                                :key="index"
                                                class=""
                                            >
                                                <span
                                                    :style="
                                                        methodStyle(pack.method)
                                                    "
                                                >
                                                    <fa-icon
                                                        :icon="
                                                            pack.method ===
                                                            'express'
                                                                ? 'rocket'
                                                                : pack.method ===
                                                                  'normal'
                                                                ? 'gauge'
                                                                : 'gauge-high'
                                                        "
                                                        :title="
                                                            $t(
                                                                pack.method ===
                                                                    'express'
                                                                    ? 'express'
                                                                    : pack.method ===
                                                                      'normal'
                                                                    ? 'normal'
                                                                    : 'fast'
                                                            )
                                                        "
                                                        class="fa-md mr-3"
                                                    ></fa-icon>
                                                </span>
                                                <span
                                                    class="font-semibold text-base"
                                                    :class="
                                                        orderStatus(order).color
                                                    "
                                                >
                                                    <fa-icon
                                                        :icon="
                                                            orderStatus(order)
                                                                .icon
                                                        "
                                                        class="fa-md mr-2"
                                                    ></fa-icon>
                                                    {{
                                                        `${
                                                            orderStatus(order)
                                                                .status
                                                        }`
                                                    }}

                                                    <button
                                                        v-if="
                                                            pack.trackings
                                                                .length > 0 ||
                                                            pack.pictures
                                                                .length > 0 ||
                                                            pack.signatures
                                                                .length > 0
                                                        "
                                                        @click="
                                                            showPopover(
                                                                order.Id
                                                            )
                                                        "
                                                        class="inline-block cursor-help px-2 text-zinc font-medium text-xs leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                                    >
                                                        <div
                                                            class="py-0.5 relative w-fit"
                                                        >
                                                            <fa-icon
                                                                :title="
                                                                    $t(
                                                                        'trackings_and_proofs'
                                                                    )
                                                                "
                                                                icon="eye"
                                                                class="fa-lg"
                                                            />
                                                        </div>
                                                    </button>

                                                    <div
                                                        class="text-xs mb-1 font-normal text-zinc-500"
                                                        v-else
                                                    >
                                                        {{
                                                            $t(
                                                                'no_tracking_data_available'
                                                            )
                                                        }}
                                                    </div>
                                                </span>
                                                <div
                                                    class="text-xs custom-link"
                                                >
                                                    {{
                                                        `${$t('distance_nokm')}
                                                        ${pack.distance} km -
                                                        ${$t('duration')}
                                                        ${pack.duration}`
                                                    }}
                                                </div>

                                                <popover
                                                    v-if="showPop === order.Id"
                                                    :item="
                                                        setPopoverData(
                                                            order.Orders[0]
                                                        )
                                                    "
                                                    :headerText="
                                                        order.Reference
                                                    "
                                                    :id="`popoverB2C-${order.Id}`"
                                                    @packageTracking="
                                                        $emit(
                                                            'packageTracking',
                                                            $event
                                                        )
                                                    "
                                                    @closePop="
                                                        showPopover(
                                                            item.id,
                                                            true
                                                        )
                                                    "
                                                ></popover>
                                                <div class="italic">
                                                    {{
                                                        `${formattedPickUpStepDate(
                                                            order.PickupFromDateTime
                                                        )} `
                                                    }}
                                                </div>

                                                <span class="whitespace-nowrap">
                                                    <fa-layer
                                                        :title="
                                                            $t(
                                                                'no_temp_specified'
                                                            )
                                                        "
                                                        v-if="
                                                            pack.temperature
                                                                .noTemp
                                                        "
                                                        class="fa-lg mr-1"
                                                    >
                                                        <fa-icon
                                                            icon="ban"
                                                            style="color: grey"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            :icon="
                                                                pack.temperature
                                                                    .icon
                                                            "
                                                            transform="shrink-6"
                                                        ></fa-icon>
                                                    </fa-layer>
                                                    <span
                                                        v-else
                                                        class="mr-1"
                                                        :style="`${pack.temperature.color}`"
                                                    >
                                                        <fa-icon
                                                            :title="
                                                                $t(
                                                                    pack
                                                                        .temperature
                                                                        .title
                                                                )
                                                            "
                                                            :icon="
                                                                pack.temperature
                                                                    .icon
                                                            "
                                                            class="fa-md"
                                                        ></fa-icon>
                                                    </span>
                                                    <span
                                                        v-if="
                                                            pack.name.includes(
                                                                '_'
                                                            ) === true ||
                                                            pack.name ===
                                                                'pallet'
                                                        "
                                                        class="whitespace-nowrap"
                                                    >
                                                        {{
                                                            `${
                                                                order.NbPackages
                                                            } ${$tc(
                                                                pack.name,
                                                                +order.NbPackages ===
                                                                    1
                                                                    ? 1
                                                                    : 2
                                                            )}`
                                                        }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="whitespace-nowrap"
                                                    >
                                                        {{
                                                            `${order.NbPackages} ${pack.name}`
                                                        }}
                                                    </span>
                                                </span>
                                                <span
                                                    class="custom-link items-center flex justify-center mt-1 cursor-copy"
                                                    :title="copyTitle"
                                                    @click="
                                                        copyToClipboard(
                                                            order.Reference
                                                        )
                                                    "
                                                >
                                                    <fa-icon
                                                        icon="copy"
                                                        class="fa-md mr-2"
                                                    ></fa-icon>
                                                    {{
                                                        `${$t('reference')}  ${
                                                            order.Reference
                                                        }`
                                                    }}
                                                </span>
                                                <br />
                                            </span>
                                        </span>
                                    </span>
                                    <span
                                        v-if="column.name === 'pick_up'"
                                        class="flex"
                                    >
                                        <span
                                            v-if="
                                                item.currentStepsToDisplay[0]
                                                    .Type === 'pickup'
                                            "
                                            class="text-xs flex items-center"
                                            :class="
                                                (item.currentStepsToDisplay[0]
                                                    .ExecutedDateTime !== '' &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .ExecutedDateTime !==
                                                        null) ||
                                                item.currentStepsToDisplay[0]
                                                    .Status === 'executed'
                                                    ? 'executed-step-color'
                                                    : ''
                                            "
                                        >
                                            <span v-if="column.canUpdate">
                                                <fa-icon
                                                    v-if="
                                                        (item
                                                            .currentStepsToDisplay[0]
                                                            .ExecutedDateTime !==
                                                            '' &&
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .ExecutedDateTime !==
                                                                null) ||
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Status ===
                                                            'executed'
                                                    "
                                                    :title="
                                                        $t('force_inprogress')
                                                    "
                                                    :icon="'toggle-on'"
                                                    class="fa-md m-1 cursor-pointer success-msg"
                                                    @click="
                                                        $emit(
                                                            'updateStepStatus',
                                                            {
                                                                step: item
                                                                    .currentStepsToDisplay[0],
                                                                newStatus:
                                                                    'inProgress',
                                                                lastStatus:
                                                                    item.steps[
                                                                        item
                                                                            .steps
                                                                            .length -
                                                                            1
                                                                    ].Status,
                                                            }
                                                        )
                                                    "
                                                ></fa-icon>
                                                <fa-icon
                                                    v-else
                                                    :title="$t('force_finish')"
                                                    :icon="'toggle-off'"
                                                    class="fa-md m-1 cursor-pointer"
                                                    @click="
                                                        $emit(
                                                            'updateStepStatus',
                                                            {
                                                                step: item
                                                                    .currentStepsToDisplay[0],
                                                                newStatus:
                                                                    'executed',
                                                                lastStatus:
                                                                    item.steps[
                                                                        item
                                                                            .steps
                                                                            .length -
                                                                            1
                                                                    ].Status,
                                                            }
                                                        )
                                                    "
                                                ></fa-icon>
                                            </span>
                                            <span
                                                class="font-extrabold"
                                                :style="`color:${latOmetre(
                                                    item
                                                        .currentStepsToDisplay[0]
                                                )}`"
                                            >
                                                <!-- <fa-icon
                                                    v-if="
                                                        (item.steps[0]
                                                            .ExecutedDateTime !==
                                                            '' &&
                                                            item.steps[0]
                                                                .ExecutedDateTime !==
                                                                null) ||
                                                        item.steps[0].Status ===
                                                            'executed'
                                                    "
                                                    :icon="'check'"
                                                    class="success-msg"
                                                ></fa-icon> -->

                                                {{
                                                    setMoment(
                                                        item
                                                            .currentStepsToDisplay[0]
                                                    )
                                                }}</span
                                            >
                                            <span class="font-extrabold mx-1">
                                                |
                                            </span>

                                            {{
                                                ` ${item.currentStepsToDisplay[0].Address1} ${item.currentStepsToDisplay[0].PostalCode} ${item.currentStepsToDisplay[0].City} `
                                            }}
                                        </span>
                                        <span
                                            v-else
                                            class="error-msg font-semibold"
                                        >
                                            {{ $t('undefined_address') }}
                                        </span>
                                    </span>
                                    <span
                                        v-if="column.name === 'delivery'"
                                        class="flex"
                                    >
                                        <span v-if="column.canUpdate">
                                            <fa-icon
                                                v-if="
                                                    (item
                                                        .currentStepsToDisplay[1]
                                                        .ExecutedDateTime !==
                                                        '' &&
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .ExecutedDateTime !==
                                                            null) ||
                                                    item
                                                        .currentStepsToDisplay[1]
                                                        .Status === 'executed'
                                                "
                                                :title="$t('force_inprogress')"
                                                :icon="'toggle-on'"
                                                class="fa-md m-1 cursor-pointer success-msg"
                                                @click="
                                                    $emit('updateStepStatus', {
                                                        step: item
                                                            .currentStepsToDisplay[1],
                                                        newStatus: 'inProgress',
                                                        isLast:
                                                            item
                                                                .currentStepsToDisplay[1]
                                                                .Id ===
                                                            item.steps[
                                                                item.steps
                                                                    .length - 1
                                                            ].Id,
                                                        lastStatus:
                                                            item.steps[
                                                                item.steps
                                                                    .length - 1
                                                            ].Status,
                                                    })
                                                "
                                            ></fa-icon>
                                            <fa-icon
                                                v-else
                                                :title="$t('force_finish')"
                                                :icon="'toggle-off'"
                                                class="fa-md m-1 cursor-pointer"
                                                @click="
                                                    $emit('updateStepStatus', {
                                                        step: item
                                                            .currentStepsToDisplay[1],
                                                        newStatus: 'executed',
                                                        isLast:
                                                            item
                                                                .currentStepsToDisplay[1]
                                                                .Id ===
                                                            item.steps[
                                                                item.steps
                                                                    .length - 1
                                                            ].Id,
                                                        lastStatus:
                                                            item.steps[
                                                                item.steps
                                                                    .length - 1
                                                            ].Status,
                                                    })
                                                "
                                            ></fa-icon>
                                        </span>
                                        <span
                                            v-if="
                                                item.currentStepsToDisplay
                                                    .length -
                                                    1 !==
                                                    0 ||
                                                item.currentStepsToDisplay[1]
                                                    .Type === 'delivery'
                                            "
                                            class="text-xs flex items-center"
                                            :class="
                                                (item.currentStepsToDisplay[1]
                                                    .ExecutedDateTime !== '' &&
                                                    item
                                                        .currentStepsToDisplay[1]
                                                        .ExecutedDateTime !==
                                                        null) ||
                                                item.currentStepsToDisplay[1]
                                                    .Status === 'executed'
                                                    ? 'executed-step-color'
                                                    : ''
                                            "
                                        >
                                            <span
                                                class="font-extrabold"
                                                :style="`color:${latOmetre(
                                                    item
                                                        .currentStepsToDisplay[1]
                                                )}`"
                                            >
                                                <!-- <fa-icon
                                                    v-if="
                                                        (item.steps[
                                                            item.steps.length -
                                                                1
                                                        ].ExecutedDateTime !==
                                                            '' &&
                                                            item.steps[
                                                                item.steps
                                                                    .length - 1
                                                            ]
                                                                .ExecutedDateTime !==
                                                                null) ||
                                                        item.steps[
                                                            item.steps.length -
                                                                1
                                                        ].Status === 'executed'
                                                    "
                                                    :icon="'check'"
                                                    class="success-msg"
                                                ></fa-icon> -->
                                                {{
                                                    setMoment(
                                                        item
                                                            .currentStepsToDisplay[1]
                                                    )
                                                }}
                                            </span>
                                            <span class="mx-1 font-extrabold">
                                                |
                                            </span>
                                            {{
                                                ` ${item.currentStepsToDisplay[1].Address1} ${item.currentStepsToDisplay[1].PostalCode} ${item.currentStepsToDisplay[1].City}`
                                            }}
                                            <!-- </span> -->
                                        </span>
                                        <span
                                            v-else
                                            class="error-msg font-semibold"
                                        >
                                            {{ $t('undefined_address') }}
                                        </span>
                                    </span>
                                    <span
                                        v-if="
                                            column.packages &&
                                            column.name == 'additional_info'
                                        "
                                        class="whitespace-nowrap"
                                    >
                                        <span class="text-xs mr-1">
                                            <fa-layer
                                                :title="$t('no_temp_specified')"
                                                v-if="
                                                    item.packages[0]
                                                        .TemperatureOption ===
                                                        'none' ||
                                                    item.packages[0]
                                                        .TemperatureOption ===
                                                        null
                                                "
                                                class="fa-xl mr-1"
                                            >
                                                <fa-icon
                                                    icon="ban"
                                                    style="color: grey"
                                                ></fa-icon>
                                                <fa-icon
                                                    icon="temperature-half"
                                                    transform="shrink-6"
                                                ></fa-icon>
                                            </fa-layer>

                                            <span
                                                v-else
                                                class="mr-1"
                                                :style="
                                                    item.packages[0]
                                                        .TemperatureOption ===
                                                    'fresh'
                                                        ? 'color:dodgerblue;'
                                                        : item.packages[0]
                                                              .TemperatureOption ===
                                                          'frozen'
                                                        ? 'color:cyan;'
                                                        : 'color:firebrick;'
                                                "
                                            >
                                                <fa-icon
                                                    :title="
                                                        $t(
                                                            item.packages[0]
                                                                .TemperatureOption ===
                                                                'fresh'
                                                                ? 'cool'
                                                                : item
                                                                      .packages[0]
                                                                      .TemperatureOption ===
                                                                  'frozen'
                                                                ? 'frozen'
                                                                : 'hot'
                                                        )
                                                    "
                                                    :icon="
                                                        item.packages[0]
                                                            .TemperatureOption ===
                                                        'fresh'
                                                            ? 'temperature-low'
                                                            : item.packages[0]
                                                                  .TemperatureOption ===
                                                              'frozen'
                                                            ? 'temperature-arrow-down'
                                                            : 'temperature-high'
                                                    "
                                                    class="fa-lg"
                                                ></fa-icon>
                                            </span>
                                            <span clas="italic ">
                                                {{
                                                    `${
                                                        item.packages.length
                                                    } ${$t('packages')} `
                                                }}
                                            </span>
                                            <span class="font-extrabold">
                                                |
                                            </span>
                                        </span>

                                        <span
                                            class="text-xs"
                                            :title="$t('finished_steps')"
                                        >
                                            <span
                                                v-if="
                                                    hasStepsErrors(
                                                        item.steps
                                                    ) === true
                                                "
                                                class="mr-1"
                                            >
                                                <fa-icon
                                                    :title="$t('steps_errors')"
                                                    icon="warning"
                                                    class="ko-link fa-lg"
                                                />
                                                <span
                                                    class="mr-1 font-extrabold"
                                                >
                                                    |
                                                </span>
                                            </span>
                                            {{
                                                ` ${item.executedSteps} / ${item.steps.length}`
                                            }}
                                            <fa-icon
                                                icon="flag-checkered"
                                                class="ml-1 fa-lg"
                                            ></fa-icon>
                                            <span
                                                v-if="item.break === true"
                                                class="mr-1"
                                            >
                                                <span
                                                    class="ml-1 font-extrabold"
                                                >
                                                    |
                                                </span>
                                                <fa-icon
                                                    :title="$t('break')"
                                                    icon="bolt"
                                                    class="ko-link fa-lg"
                                                />
                                            </span>
                                        </span>
                                        <!-- <span class="whitespace-nowrap">
                                            <fa-layer
                                                :title="$t('no_temp_specified')"
                                                v-if="pack.temperature.noTemp"
                                                class="fa-lg mr-1"
                                            >
                                                <fa-icon
                                                    icon="ban"
                                                    style="color: grey"
                                                ></fa-icon>
                                                <fa-icon
                                                    :icon="
                                                        pack.temperature.icon
                                                    "
                                                    transform="shrink-6"
                                                ></fa-icon>
                                            </fa-layer>
                                            <span
                                                v-else
                                                class="mr-1"
                                                :style="`${pack.temperature.color}`"
                                            >
                                                <fa-icon
                                                    :title="
                                                        $t(
                                                            pack.temperature
                                                                .title
                                                        )
                                                    "
                                                    :icon="
                                                        pack.temperature.icon
                                                    "
                                                    class="fa-md"
                                                ></fa-icon>
                                            </span>
                                            <span
                                                v-if="
                                                    pack.name.includes('_') ===
                                                        true ||
                                                    pack.name === 'pallet'
                                                "
                                                class="whitespace-nowrap text-xs"
                                            >
                                                {{
                                                    `${order.NbPackages} ${$tc(
                                                        pack.name,
                                                        +order.NbPackages === 1
                                                            ? 1
                                                            : 2
                                                    )}`
                                                }}
                                            </span>
                                            <span
                                                v-else
                                                class="whitespace-nowrap text-xs"
                                            >
                                                {{
                                                    `${order.NbPackages} ${pack.name}`
                                                }}
                                            </span> -->
                                        <!-- </span> -->
                                        <br />
                                    </span>
                                    <span v-if="column.steps">
                                        <span
                                            v-for="(step, index) in item[
                                                column.name
                                            ]"
                                            :key="index"
                                            class="my-2 stop-stretching"
                                        >
                                            <button
                                                class="mb-2 hover:bg-transparent"
                                                :title="
                                                    $t('step_needs_correction')
                                                "
                                                @click="
                                                    $emit('updateStep', {
                                                        id: step.Id,
                                                        order_ref:
                                                            item.reference,
                                                    })
                                                "
                                            >
                                                <span
                                                    class="items-center"
                                                    :class="
                                                        step.Latitude ===
                                                            null ||
                                                        step.Longitude === null
                                                            ? 'ko-link'
                                                            : 'ok-link'
                                                    "
                                                >
                                                    <fa-icon
                                                        v-if="
                                                            step.Latitude ===
                                                                null ||
                                                            step.Longitude ===
                                                                null
                                                        "
                                                        icon="warning"
                                                        class="mr-1"
                                                    />
                                                    {{
                                                        step.StepNumber +
                                                        ' - ' +
                                                        step.AddressName +
                                                        ' - ' +
                                                        step.Type
                                                    }}
                                                </span>
                                            </button>
                                            <br />
                                        </span>
                                    </span>
                                    <span
                                        v-else-if="
                                            column.drivers &&
                                            column.name === 'drivers'
                                        "
                                        class="stop-streching darky"
                                    >
                                        <span
                                            v-if="column.canUpdate === true"
                                            class="darky text-xs selectMS"
                                        >
                                            <Multiselect
                                                class="rounded mt-1 w-full darky"
                                                v-model="
                                                    selectedDriver(
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Driver
                                                    ).value
                                                "
                                                :options="column.options"
                                                :loading="
                                                    column.options.length === 0
                                                "
                                                label="name"
                                                trackBy="name"
                                                :searchable="true"
                                                :placeholder="
                                                    column.options.length
                                                        ? $t('select_one')
                                                        : $t(
                                                              'no_available_for_date'
                                                          )
                                                "
                                                :allow-empty="false"
                                                :can-clear="false"
                                                @change="
                                                    $emit('updateStepDriver', {
                                                        steps: item.currentStepsToDisplay,
                                                        newDriver: $event,
                                                    })
                                                "
                                            ></Multiselect>
                                        </span>
                                        <span
                                            v-else-if="
                                                column.canUpdate === false &&
                                                item.current_driver
                                            "
                                            class="flex justify-center whitespace-nowrap items-center"
                                        >
                                            {{
                                                ' ' +
                                                item.current_driver.FirstName +
                                                ' ' +
                                                item.current_driver.LastName
                                            }}
                                            <br />
                                        </span>
                                    </span>

                                    <span v-else-if="column.isLink">
                                        <fa-icon
                                            v-if="storeToSort === 'drivers'"
                                            :title="$t('planning')"
                                            icon="calendar"
                                            class="hover:font-semibold fa-lg cursor-pointer"
                                            @click="$emit('planning', item.id)"
                                        ></fa-icon>
                                        <button
                                            class="hover:font-semibold ml-2"
                                            @click="$emit('update', item.id)"
                                        >
                                            <span
                                                class="items-center custom-link"
                                            >
                                                {{
                                                    column.name.includes('type')
                                                        ? $t(item[column.name])
                                                        : item[column.name]
                                                }}
                                                <!-- <span v-if="column.showRef" class="truncate">
                                                {{` - ${item.reference}`}}

                                            </span> -->
                                            </span>

                                            <!-- class="hidden" -->
                                        </button>
                                    </span>
                                    <button
                                        v-else-if="column.isFileDownload"
                                        @click="$emit('download', item.file)"
                                        :title="$t('download')"
                                        class="hover:font-semibold"
                                    >
                                        <span
                                            class="items-center flex custom-link"
                                        >
                                            <fa-icon
                                                icon="download"
                                                class="fa-sm mr-2"
                                            ></fa-icon>
                                            {{
                                                column.name.includes('type')
                                                    ? $t(item[column.name])
                                                    : item[column.name]
                                            }}
                                        </span>
                                    </button>
                                    <span
                                        v-else-if="
                                            column.withIconNoText &&
                                            column.type === 'icon-select'
                                        "
                                        class="stop-stretching"
                                        :style="item.icons[column.name].style"
                                    >
                                        <span v-if="column.name === 'status'">
                                            <span
                                                v-if="
                                                    !item
                                                        .currentStepsToDisplay[0]
                                                        .icon
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        item.icons[column.name]
                                                            .icon.icon !== '' &&
                                                        item.icons[column.name]
                                                            .icon.icon !== null
                                                    "
                                                    :title="
                                                        item.icons[column.name]
                                                            .icon.icon ===
                                                            'flag-checkered' ||
                                                        item.icons[column.name]
                                                            .icon.icon ===
                                                            'hourglass-half'
                                                            ? $t(
                                                                  item.icons[
                                                                      column
                                                                          .name
                                                                  ].icon.title
                                                              )
                                                            : $t(
                                                                  item[
                                                                      column
                                                                          .name
                                                                  ]
                                                              ) +
                                                              ' - ' +
                                                              $t(
                                                                  item.icons[
                                                                      column
                                                                          .name
                                                                  ].icon.title
                                                              )
                                                    "
                                                >
                                                    <span
                                                        v-if="
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'car' ||
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'ship' ||
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'truck' ||
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'person-biking' ||
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'flag-checkered' ||
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'hourglass-half'
                                                        "
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                    <img
                                                        v-else
                                                        :alt="
                                                            $t(
                                                                item.icons[
                                                                    column.name
                                                                ].icon.title
                                                            )
                                                        "
                                                        svg-inline
                                                        :src="`biiimIcons/${
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon
                                                        }.svg`"
                                                        class="mx-auto darky icon-img"
                                                        :class="
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                            'van'
                                                                ? 'w-4'
                                                                : 'w-7 '
                                                        "
                                                    />
                                                </span>
                                                <span
                                                    v-else
                                                    :title="
                                                        $t(item[column.name])
                                                    "
                                                >
                                                    <fa-icon
                                                        icon="route"
                                                        class="fa-lg"
                                                    />
                                                </span>
                                            </span>

                                            <!-- <span
                                                v-else
                                                :title="$t(item[column.name])"
                                            >
                                                <fa-icon
                                                    :icon="
                                                        item.currentStepsToDisplay[0].icon.icon
                                                    "
                                                    class="fa-lg"
                                                />
                                            </span> -->
                                            <span v-else>
                                                <span
                                                    v-if="
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .icon.icon !== '' &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .icon.icon !== null
                                                    "
                                                    :title="
                                                        $t(item[column.name]) +
                                                        ' - ' +
                                                        $t(
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.title
                                                        )
                                                    "
                                                >
                                                    <span
                                                        v-if="
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon ===
                                                                'car' ||
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon ===
                                                                'ship' ||
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon ===
                                                                'truck' ||
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon ===
                                                                'person-biking'
                                                        "
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon.icon
                                                            "
                                                        ></fa-icon>
                                                    </span>
                                                    <img
                                                        v-else
                                                        :alt="
                                                            $t(
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon.title
                                                            )
                                                        "
                                                        svg-inline
                                                        :src="`biiimIcons/${item.currentStepsToDisplay[0].icon.icon}.svg`"
                                                        class="mx-auto darky icon-img"
                                                        :class="
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon ===
                                                            'van'
                                                                ? 'w-4'
                                                                : 'w-7 '
                                                        "
                                                    />
                                                </span>
                                                <span
                                                    v-else
                                                    :title="
                                                        $t(item[column.name])
                                                    "
                                                >
                                                    <fa-icon
                                                        icon="route"
                                                        class="fa-lg"
                                                    />
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            v-else
                                            :title="$t(item[column.name])"
                                        >
                                            <fa-icon
                                                :icon="
                                                    item.icons[column.name].icon
                                                "
                                                class="fa-lg"
                                            />
                                        </span>
                                    </span>

                                    <span
                                        v-else-if="column.enum"
                                        class="my-1"
                                        :class="
                                            column.enumColNumber
                                                ? `grid gap-1 ${column.enumColNumber}`
                                                : column.enumNoGrid
                                                ? ''
                                                : ' grid gap-1 grid-cols-3'
                                        "
                                    >
                                        <div
                                            v-for="(enumItem, index) in item[
                                                column.name
                                            ]"
                                            :key="index"
                                            class="text-xs col-span-1 flex items-center overflow-auto"
                                        >
                                            <span
                                                class="border-2 rounded p-2 w-full h-full"
                                            >
                                                <div
                                                    v-if="
                                                        column.canRemoveEnum ===
                                                        true
                                                    "
                                                    class="flex justify-end"
                                                >
                                                    <fa-icon
                                                        icon="trash-can"
                                                        :title="$t('remove')"
                                                        class="ml-2 cursor-pointer"
                                                        @click="
                                                            $emit(
                                                                'removeEnum',
                                                                enumItem
                                                            )
                                                        "
                                                    ></fa-icon>
                                                </div>
                                                <div
                                                    class="font-semibold mb-1"
                                                    :title="$t('name')"
                                                >
                                                    {{ `${enumItem.Name}` }}
                                                </div>
                                                <!-- <div
                                                    class="cursor-copy theme-color mb-1"
                                                    :title="`${$t(
                                                        'code'
                                                    )} - ${copyTitle}`"
                                                    @click="
                                                        copyToClipboard(
                                                            enumItem.Code
                                                        )
                                                    "
                                                >
                                                    {{ `${enumItem.Code}` }}
                                                </div> -->
                                                <div
                                                    class="italic"
                                                    :title="$t('description')"
                                                >
                                                    {{
                                                        `${enumItem.Description}`
                                                    }}
                                                </div>
                                            </span>
                                        </div>
                                    </span>
                                    <span
                                        v-else-if="column.withIconNoText"
                                        class="stop-stretching"
                                    >
                                        <fa-icon
                                            v-if="item[column.name] === 1"
                                            icon="check"
                                            :title="$t('active')"
                                            class="success-msg fa-xl"
                                        />
                                        <fa-icon
                                            v-else
                                            icon="xmark"
                                            :title="$t('deactivated')"
                                            class="error-msg fa-xl"
                                        />
                                    </span>
                                    <!-- <span
                                        v-else-if="column.moment"
                                        class="stop-stretching"
                                    >
                                        <span
                                            v-for="(step, index) in item.steps"
                                            :key="index"
                                        >
                                            {{
                                                ` ${$t(step.Moment)} ${
                                                    step.Moment === 'between'
                                                        ? `${
                                                              step.FromDateTime
                                                          } ${$t('and')} ${
                                                              step.BeforeDateTime
                                                          }`
                                                        : step.Moment ===
                                                          'before'
                                                        ? step.BeforeDateTime
                                                        : step.FromDateTime
                                                }`
                                            }}</span
                                        >
                                    </span> -->
                                    <span
                                        v-else-if="
                                            column.name === 'report' &&
                                            item['status'] === 'errors'
                                        "
                                    >
                                        <button
                                            @click="
                                                $emit(
                                                    'goToCorrection',
                                                    item.date_begin
                                                )
                                            "
                                        >
                                            <span class="ko-link">
                                                <fa-icon
                                                    icon="warning"
                                                    class="mr-1"
                                                />
                                                {{ item[column.name] }}
                                            </span>
                                        </button>
                                    </span>
                                    <span
                                        v-else-if="
                                            item[column.name] !== null &&
                                            item[column.name] !== ''
                                        "
                                    >
                                        {{
                                            column.name.includes('type') ||
                                            (column.translate &&
                                                column.translate === true)
                                                ? $t(item[column.name])
                                                : column.name.includes('date')
                                                ? formattedDate(
                                                      item[column.name],
                                                      $route.name ===
                                                          'new_vehicle' ||
                                                          $route.name ===
                                                              'update_vehicle'
                                                  )
                                                : item[column.name]
                                        }}
                                    </span>
                                    <span
                                        v-if="
                                            column.subInfo &&
                                            column.subInfo === true &&
                                            item.subInfoForListDisplay
                                        "
                                        class="text-xs"
                                    >
                                        <div
                                            class="text-xs cursor-copy flex items-center justify-center"
                                            :title="
                                                item.subInfoText
                                                    ? `${$t(
                                                          item.subInfoText
                                                      )} - ${copyTitle}`
                                                    : ''
                                            "
                                            @click.stop="
                                                item.subInfoText
                                                    ? copyToClipboard(
                                                          item.subInfoForListDisplay
                                                      )
                                                    : ''
                                            "
                                        >
                                            {{ item.subInfoForListDisplay }}

                                            <fa-icon
                                                icon="copy"
                                                class="fa-xs ml-2"
                                            ></fa-icon>
                                        </div>
                                    </span>
                                </span>
                            </td>
                            <td
                                class="px-5 border-b border-zinc-200 text-xs"
                                :class="
                                    isB2C === true || $route.name !== 'orders'
                                        ? 'py-4'
                                        : ''
                                "
                            >
                                <span class="flex">
                                    <div
                                        v-if="hoverableItems === true"
                                        class="flex space-x-2 justify-center items-center"
                                    >
                                        <button
                                            @click="showPopover(item.id)"
                                            class="inline-block cursor-help px-2 text-zinc font-medium text-xs leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                        >
                                            <div
                                                class="inline-flex relative w-fit"
                                            >
                                                <fa-icon
                                                    icon="eye"
                                                    class="fa-lg"
                                                />
                                            </div>
                                        </button>
                                    </div>
                                    <dropdown-menu
                                        v-if="itemActions.length > 0"
                                        ref="listActionDD"
                                        class="inline-flex"
                                        :ddId="'dd-' + item.id"
                                        :ellipsisStyle="true"
                                        :items="
                                            itemActionsUpdated(
                                                item.active &&
                                                    item.active === 1,
                                                item
                                            )
                                        "
                                        @picked="actionOnItem($event, item.id)"
                                    >
                                        <template #buttonContent>
                                            <fa-icon
                                                :title="$t('action')"
                                                icon="ellipsis-vertical"
                                                class="fa-lg"
                                            />
                                        </template>
                                    </dropdown-menu>
                                </span>
                            </td>
                            <!-- hoverableItems === true && -->

                            <popover
                                v-if="showPop === item.id"
                                :item="item"
                                :headerText="item.reference"
                                :id="`popover-${item.id}`"
                                @closePop="showPopover(item.id, true)"
                                @packageTracking="
                                    $emit('packageTracking', $event)
                                "
                                @updateStep="
                                    $emit('updateStep', {
                                        id: $event,
                                        order_ref: item.reference,
                                    })
                                "
                            ></popover>
                            <!-- class="hidden" -->
                        </tr>
                    </tbody>
                </table>
                <div
                    v-if="count === 0"
                    class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
                >
                    {{ $t('no_records_found') }}
                </div>
                <div
                    v-if="loading && count === null"
                    class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
                >
                    <fa-icon class="fa-2xl" icon="spinner" spin></fa-icon>
                </div>
                <list-pagination
                    :class="
                        isB2C === true || $route.name !== 'orders'
                            ? 'w-full px-4'
                            : ' max-w-screen-2xl'
                    "
                    v-if="count > 0"
                    :perPage="perPage"
                    :page="page"
                    :totalPages="totalPages"
                    :count="count"
                    @back="back()"
                    @next="next()"
                    :openDirection="'top'"
                    @perPageChanged="$emit('perPageChanged', $event)"
                ></list-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'
import Multiselect from '@vueform/multiselect'
import { useUserStore } from '@/stores/userStore'
import SearchInput from '@/components/elements/SearchInput.vue'
import SelectOne from '@/components/elements/SelectOne.vue'
import IconSelect from '@/components/elements/IconSelect.vue'
import IconSelectMultiple from '@/components/elements/IconSelectMultiple.vue'

import SortIcon from '@/components/atoms/SortIcon.vue'
import ListPagination from '@/components/elements/ListPagination.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import DropdownMenu from '@/components/atoms/DropdownMenu.vue'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import Popover from '@/components/elements/Popover.vue'
import converter from '@/mixins/converter'

export default {
    name: 'FiltrableTable',
    components: {
        SearchInput,
        SelectOne,
        SortIcon,
        ListPagination,
        IconButton,
        DropdownMenu,
        Popover,
        IconSelect,
        IconSelectMultiple,
        Multiselect,
    },

    mixins: [copyToClipboard, converter],

    created() {
        window.scrollTo(0, 0)
        localStorage.removeItem('popoverOpen', false)
    },
    mounted() {
        const filtered = this.$cookies.get('filtered')
        if (filtered) {
            if (filtered.dateFilter) {
                if (filtered.dateFilter.length === 0) {
                    if (
                        filtered.pickupTodayOnly === false &&
                        filtered.deliveryTodayOnly === false
                    ) {
                        this.resetDatePicker({
                            value: true,
                            name: 'DateBegin',
                        })
                        this.resetDatePicker({
                            value: true,
                            name: 'DateEnd',
                        })
                    } else if (filtered.deliveryTodayOnly === false) {
                        this.resetDatePicker({
                            value: true,
                            name: 'DateEnd',
                        })
                    } else {
                        this.resetDatePicker({
                            value: true,
                            name: 'DateBegin',
                        })
                    }
                } else if (filtered.dateFilter.length === 1) {
                    this.pickedDate[filtered.dateFilter[0].dbField] =
                        filtered.dateFilter[0].value

                    if (filtered.dateFilter[0].dbField === 'DateBegin') {
                        if (filtered.deliveryTodayOnly === false) {
                            this.resetDatePicker({
                                value: true,
                                name: 'DateEnd',
                            })
                        } else {
                            this.pickedDate.DateEnd = new Date()
                                .toISOString()
                                .substr(0, 10)
                        }
                    } else if (filtered.dateFilter[0].dbField === 'DateEnd') {
                        if (filtered.pickupTodayOnly === false) {
                            this.resetDatePicker({
                                value: true,
                                name: 'DateBegin',
                            })
                        } else {
                            this.pickedDate.DateBegin = new Date()
                                .toISOString()
                                .substr(0, 10)
                        }
                    }
                } else {
                    filtered.dateFilter.forEach((dateFilter) => {
                        this.pickedDate[dateFilter.dbField] = dateFilter.value
                    })
                }
            }
        }
    },
    data() {
        return {
            pickedDate: {
                DateBegin: new Date().toISOString().substr(0, 10),
                DateEnd: new Date().toISOString().substr(0, 10),
                ModificationDate: null,
            },
            showPop: null,
            debounce: null,
            itemsPerPage: [5, 10, 25, 50, 100],
            dataToSearch: this.items,
            open: false,
            checkedItems: [],
            all: false,
        }
    },
    props: {
        tableWidth: {
            type: String,
            default: 'table-auto  w-full',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        smallMT: {
            type: Boolean,
            default: false,
        },
        hoverableItems: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => {},
        },
        columns: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: 0,
        },
        storeToSort: {
            type: String,
            default: '',
        },
        sortableFields: {
            type: Array,
            default: () => [],
        },
        perPage: {
            type: Number,
            default: 10,
        },
        page: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
        sortDirection: {
            type: String,
            default: 'DESC',
        },
        searchQuery: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        refresh: {
            type: Number,
            default: 0,
        },
        itemActions: {
            type: Array,
            default: () => [],
        },
        multiActions: {
            type: Array,
            default: () => [],
        },
        canAddNew: {
            type: Boolean,
            default: true,
        },
        canSelectItems: {
            type: Boolean,
            default: false,
        },
        resetCheckedItems: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        allSelected() {
            this.all = this.allSelected
        },
        items() {
            let checkedIt = 0
            if (this.items) {
                this.items.forEach((item) => {
                    if (this.checkedItems.includes(item.id)) {
                        checkedIt += 1
                    }
                })

                this.all = checkedIt === this.perPage
            }
        },
        page() {
            window.scrollTo(0, 0)
        },
        perPage() {
            window.scrollTo(0, 0)
        },
        resetCheckedItems() {
            if (this.resetCheckedItems === true) {
                this.checkedItems = []
            }
        },
    },
    computed: {
        ...mapState(useUserStore, { user: 'current', isB2C: 'isB2C' }),

        allSelected() {
            let result = false
            if (this.count > 0 && this.items) {
                let checkedIt = 0
                this.items.forEach((item) => {
                    if (this.checkedItems.includes(item.id)) {
                        checkedIt += 1
                    }
                })

                if (checkedIt === this.perPage) {
                    result = true
                }
                // if (this.count === this.checkedItems.length) result = true
                // if (this.perPage === this.checkedItems.length) result = true
                if (this.items.length === this.checkedItems.length)
                    result = true
            }

            return result
        },
    },
    methods: {
        methodStyle(method) {
            return method === 'express'
                ? 'color: red;'
                : method === 'normal'
                ? 'color: green;'
                : 'color: orange;'
        },
        selectedDriver(currentDriver) {
            return { value: currentDriver ? currentDriver.Id : '' }
        },
        setPopoverData(order) {
            const popoverData = structuredClone(order)
            popoverData.packages = popoverData.OrderPackages
            return popoverData
        },
        resetDatePicker(column) {
            if (column.value === true) {
                this.pickedDate[column.name] = ''
            } else if (column.value === false) {
                this.pickedDate[column.name] = new Date()
                    .toISOString()
                    .substr(0, 10)
            }
        },
        latOmetre(step) {
            const locale = 'en-En'
            let color = ''
            if (
                (step.ExecutedDateTime === '' ||
                    step.ExecutedDateTime === null) &&
                step.Status !== 'executed'
            ) {
                const currentTime = new Date().toLocaleTimeString(
                    locale,

                    {
                        hourCycle: 'h23',
                        hour: '2-digit',
                        minute: '2-digit',
                    }
                )
                const currentDate = new Date().toLocaleDateString(
                    locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }
                )
                const currentDateGetTime = new Date(currentDate).getTime()

                // si from
                let formattedTime = null
                let minusOne = null
                if (step.Moment === 'from') {
                    const fDate = new Date(
                        step.FromDateTime
                    ).toLocaleDateString(
                        locale,

                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }
                    )
                    const fDateGetTime = new Date(fDate).getTime()
                    if (
                        fDate < currentDate ||
                        fDateGetTime < currentDateGetTime
                    ) {
                        return 'red'
                    }
                    if (
                        fDate > currentDate ||
                        fDateGetTime > currentDateGetTime
                    ) {
                        return color
                    }

                    formattedTime = new Date(
                        step.FromDateTime
                    ).toLocaleTimeString(
                        locale,

                        {
                            hourCycle: 'h23',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                    )

                    if (
                        currentDate ===
                            new Date(step.FromDateTime).toLocaleDateString(
                                locale,

                                {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }
                            ) &&
                        (formattedTime === currentTime ||
                            formattedTime < currentTime)
                    ) {
                        color = 'green'
                    }
                }

                if (step.Moment === 'between' || step.Moment === 'before') {
                    const fDate = new Date(
                        step.BeforeDateTime
                    ).toLocaleDateString(
                        locale,

                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }
                    )
                    const fDateGetTime = new Date(fDate).getTime()

                    if (
                        fDate < currentDate ||
                        fDateGetTime < currentDateGetTime
                    ) {
                        return 'red'
                    }
                    if (
                        fDate > currentDate ||
                        fDateGetTime > currentDateGetTime
                    ) {
                        return ''
                    }

                    if (
                        (step.Moment === 'between' &&
                            new Date(step.FromDateTime).toLocaleTimeString(
                                locale,

                                {
                                    hourCycle: 'h23',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }
                            ) === currentTime) ||
                        new Date(step.FromDateTime).toLocaleTimeString(
                            locale,

                            {
                                hourCycle: 'h23',
                                hour: '2-digit',
                                minute: '2-digit',
                            }
                        ) < currentTime
                    ) {
                        color = 'green'
                    }

                    formattedTime = new Date(
                        step.BeforeDateTime
                    ).toLocaleTimeString(
                        locale,

                        {
                            hourCycle: 'h23',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                    )
                    minusOne = new Date(step.BeforeDateTime).setHours(
                        new Date(step.BeforeDateTime).getHours() - 1
                    )
                    minusOne = new Date(minusOne).toLocaleTimeString(
                        locale,

                        {
                            hourCycle: 'h23',
                            hour: '2-digit',
                            minute: '2-digit',
                        }
                    )
                    if (
                        currentDate === fDate &&
                        (minusOne === currentTime || minusOne < currentTime)
                    ) {
                        color = 'orange'
                    }
                    if (
                        currentDate === fDate &&
                        (formattedTime === currentTime ||
                            formattedTime < currentTime)
                    ) {
                        color = 'red'
                    }
                }
            }

            return color
        },
        setMoment(step) {
            if (step.Moment === 'from') {
                return `${new Date(step.FromDateTime).toLocaleDateString(
                    this.$i18n.locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }
                )} ${this.$t('from')} ${new Date(
                    step.FromDateTime
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    }
                )}`
            }
            if (step.Moment === 'between') {
                return `${new Date(step.FromDateTime).toLocaleDateString(
                    this.$i18n.locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }
                )} ${this.$t('short_from')} ${new Date(
                    step.FromDateTime
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    }
                )} ${this.$t('short_before')} ${new Date(
                    step.BeforeDateTime
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    }
                )}`
            }
            if (step.Moment === 'before') {
                return `${new Date(step.BeforeDateTime).toLocaleDateString(
                    this.$i18n.locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }
                )} ${this.$t('before')} ${new Date(
                    step.BeforeDateTime
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    }
                )}`
            }
        },
        hasStepsErrors(steps) {
            let hasErrors = false
            steps.forEach((step) => {
                if (step.Latitude === null || step.Longitude === null) {
                    hasErrors = true
                }
            })

            return hasErrors
        },
        orderStatus(order) {
            let status = {
                status: this.$t('in_progress'),
                color: 'theme-color',
                icon: 'person-biking',
            }
            if (
                order.Orders[0].Status === 'finished' ||
                +order.Orders[0].ExecutedSteps ===
                    order.Orders[0].OrderSteps.length ||
                order.Orders[0].OrderSteps[
                    order.Orders[0].OrderSteps.length - 1
                ].Status === 'executed'
            ) {
                status = {
                    status: this.$t('last_step_finished'),
                    color: 'success-msg',
                    icon: 'flag-checkered',
                }
            }
            if (order.Orders[0].Status === 'new') {
                status = {
                    status: this.$t('delivery_new'),
                    color: 'theme-color',
                    icon: 'hourglass-half',
                }
            }
            return status
        },
        setDeliveryPackages(order) {
            const deliveryPackages = []
            const trackings = []
            const pictures = []
            const signatures = []

            const clone = [...order]
            const temperatureInfos =
                clone[0].OrderPackages[0].TemperatureOption === 'warm'
                    ? {
                          icon: 'temperature-high',
                          color: 'color:firebrick;',
                          title: 'hot',
                      }
                    : clone[0].OrderPackages[0].TemperatureOption === 'fresh'
                    ? {
                          icon: 'temperature-low',
                          color: 'color:dodgerblue;',

                          title: 'cool',
                      }
                    : clone[0].OrderPackages[0].TemperatureOption === 'frozen'
                    ? {
                          icon: 'temperature-arrow-down',
                          color: 'color:cyan;',

                          title: 'frozen',
                      }
                    : {
                          icon: 'temperature-half',
                          noTemp: true,
                      }
            clone[0].OrderPackages.forEach((pack) => {
                if (pack.Trackings && pack.Trackings.length > 0) {
                    trackings.push(pack.Trackings)
                }
                if (pack.Signatures && pack.Signatures.length > 0) {
                    signatures.push(pack.Signatures)
                }
                if (pack.Pictures && pack.Pictures.length > 0) {
                    pictures.push(pack.Pictures)
                }
            })
            if (this.isB2C === true) {
                trackings.forEach((tracking, index) => {
                    trackings[index] = tracking.filter(
                        (track) => track.TypeTracking === 'client'
                    )
                    if (trackings[index].length === 0) {
                        trackings.splice(index, 1)
                    }
                })
            }
            deliveryPackages.push({
                name: clone[0].OrderPackages[0].Container
                    ? clone[0].OrderPackages[0].Container.Name
                    : 'no_package_selected',
                temperature: temperatureInfos,
                trackings,
                signatures,
                pictures,
                distance: this.formatDistance(clone[0].OrderClient.Distance),
                duration: this.formatDuration(clone[0].OrderClient.Duration),
                method: clone[0].Method,
            })
            return deliveryPackages
        },

        isOdd(num) {
            return num % 2
        },
        showPopover(elId, close = false) {
            if (close === false) {
                this.showPop = elId
                localStorage.setItem('popoverOpen', true)
            } else {
                this.showPop = null
                localStorage.removeItem('popoverOpen')
            }
        },
        formattedDate(date, withTime = false) {
            let formatted = new Date(date).toLocaleDateString(this.$i18n.locale)

            if (withTime) {
                formatted = new Date(date).toLocaleTimeString(
                    this.$i18n.locale,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }
                )
            }

            return `${formatted}`
        },
        formattedPickUpStepDate(date) {
            const formatted = date.substring(0, date.indexOf(' '))
            return new Date(formatted).toLocaleDateString(this.$i18n.locale)
        },
        isNotFirstColumn(index) {
            if (index === 0) {
                return false
            }
            return document.getElementById(
                `${this.columns[index - 1].name}-${index - 1}`
            )
        },
        itemActionsUpdated(itemIsActive, item) {
            const items = []
            this.itemActions.forEach((action) => {
                if (action.sub) {
                    if (
                        action.action === 'printAllLabels' &&
                        item.packages.length > 0
                    ) {
                        // item.packages.forEach((pack, index) => {
                        items.push({
                            name: `${this.$t('print_all_labels')}`,
                            sub: true,
                            packageId: item.Id,
                            reference: item.Reference,
                            action: 'printAllLabels',
                            icon: 'download',
                            hide: false,
                        })
                        // })
                    }
                    if (
                        action.action === 'orderTracking' &&
                        item.steps &&
                        item.steps.length > 0
                    ) {
                        items.push({
                            name: `${this.$t('tracking_all')}`,
                            action: 'orderTracking',
                            reference: item.packages[0].Reference,
                            sub: true,
                            icon: 'route',
                            hide: false,
                        })
                    }
                    // } else if (action.notAffectedOnly && item.steps) {
                    //     let isAffected = false
                    //     item.steps.forEach((step) => {
                    //         if (step.Status === 'affected') {
                    //             isAffected = true
                    //         }
                    //         if (isAffected === false && !items.includes(action)) {
                    //             items.push(action)
                    //         }
                    //     })
                } else if (action.action === 'deactivate') {
                    if (action.containers && action.containers === true) {
                        if (
                            (item.client === +this.user.idClient ||
                                this.isB2C === false) &&
                            !itemIsActive
                        ) {
                            items.push({
                                name: 'activate',
                                action: 'activate',
                                icon: 'check',
                            })
                        } else if (
                            item.client !== +this.user.idClient &&
                            this.isB2C === true
                        ) {
                            items.push({
                                name: 'container_not_editable',
                            })
                        } else {
                            items.push(action)
                        }
                    } else if (!itemIsActive) {
                        items.push({
                            name: 'activate',
                            action: 'activate',
                            icon: 'check',
                        })
                    } else {
                        items.push(action)
                    }
                } else if (
                    action.action === 'update' &&
                    action.containers &&
                    action.containers === true
                ) {
                    if (
                        item.client === +this.user.idClient ||
                        this.isB2C === false
                    ) {
                        items.push(action)
                    }
                } else if (
                    !action.hide ||
                    (action.hide && action.hide === false)
                ) {
                    items.push(action)
                }
            })
            return items
        },
        check(itemId) {
            if (this.checkedItems.includes(itemId)) {
                const index = this.checkedItems.indexOf(itemId)
                this.checkedItems.splice(index, 1)
            } else {
                this.checkedItems.push(itemId)
            }
            if (this.canSelectItems === true) {
                this.$emit('selectedChanged', this.checkedItems)
            }
        },
        checkAll() {
            if (this.all) {
                this.items.forEach((item) => {
                    if (!this.checkedItems.includes(item.id)) {
                        this.check(item.id)
                    }
                })
            } else {
                this.items.forEach((item, index) => {
                    this.checkedItems.forEach((chk, i) => {
                        if (item.id === chk) {
                            this.checkedItems.splice(i, 1)
                        }
                    })
                })
            }
            if (this.canSelectItems === true) {
                this.$emit('selectedChanged', this.checkedItems)
            }
        },
        multiAction(action) {
            this.$emit(action.action, this.checkedItems)
            if (this.$route.name !== 'orders') {
                this.checkedItems = []
            }
        },
        back() {
            this.$emit('pageChanged', false)
        },
        next() {
            this.$emit('pageChanged', true)
        },
        isHtml(item) {
            if (item && item instanceof String) {
                return item.includes('<div>')
            }
            return false
        },
        actionOnItem(itemAction, itemId) {
            if (!itemAction.no_action || itemAction.no_action === false) {
                if (!itemAction.sub) {
                    this.$emit(itemAction.action, itemId)
                } else {
                    this.$emit(itemAction.action, itemAction)
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
thead {
    position: sticky;
    z-index: 10;
    top: 3.6px; /* Don't forget this, required for the stickiness */
}
.stop-stretching {
    width: 1%;
    white-space: nowrap;
}
.stop-stretching-json {
    width: 1%;
    white-space: break-spaces;
    // word-break: break-all;
}

.selectMS::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: x-small !important;
        white-space: nowrap;
        overflow: hidden;
    }
    .multiselect-single-label-text {
        white-space: nowrap;
        text-overflow: unset;
        font-size: x-small !important;
        white-space: nowrap;
        padding-right: calc(2.3rem + var(--ms-px, 0.875rem));
    }
    font-size: x-small !important;

    min-height: unset !important;
    width: 6rem;
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
    }
    .multiselect-tag {
        font-size: x-small !important;
    }
}
</style>
