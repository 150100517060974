<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md h-full">
        <div
            class="flex darky items-center text-zinc-500 justify-center pb-6 mt-16"
        >
            {{ $t('error_while_loading') }}
            {{
                multiTranslation === 0
                    ? $t(dataType)
                    : $tc(dataType, multiTranslation)
            }}
        </div>
    </main>
</template>
<script>
export default {
    name: 'FetchDataError',
    props: {
        dataType: {
            type: String,
            default: 'clients',
        },
        multiTranslation: {
            type: Number,
            default: 0,
        },
    },
}
</script>
