import Wrapper from '../views/areas/Wrapper.vue'
import CreateOrUpdate from '../views/areas/CreateOrUpdate.vue'

const areas = [
    {
        path: '/areas',
        name: 'areas',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'AREAS_FULL', 'AREAS_LIST'],
        },
        children: [
            {
                path: 'new-area',
                name: 'new_area',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'AREAS_FULL',
                        'AREAS_CREATE',
                    ],
                },
            },
            {
                path: 'update-area/:id',
                name: 'update_area',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'AREAS_FULL',
                        'AREAS_UPDATE',
                    ],
                },
            },
        ],
    },
]

export default areas
