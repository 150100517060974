<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md h-full">
        <div class="flex mt-14 justify-center bg-white darky mb-6 text-6xl">
            {{ $t('welcome') }} !
        </div>
        <div id="logo" class="flex justify-center">
            <img alt="Company logo" :src="`/logo/${logo}-nobg.png`" />
        </div>
    </main>
</template>
<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'Home',
    computed: {
        ...mapState(useUserStore, {
            logo: 'logo',
        }),
    },
}
</script>
