<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />
        <div
            id="create-cLient"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div>
                <div class="p-5">
                    <form
                        @keydown.enter.prevent
                        @submit.prevent="
                            $route.params.id ? updateClient() : createClient()
                        "
                    >
                        <div>
                            <div class="rounded-md p-3">
                                <div class="mb-2">
                                    <span
                                        class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                                    >
                                        {{
                                            $t(
                                                $route.params.id
                                                    ? 'update_client'
                                                    : 'new_client'
                                            )
                                        }}
                                    </span>
                                </div>
                                <div class="grid grid-cols-6 gap-4">
                                    <div
                                        class="col-span-6 rounded-md border-2 p-4"
                                    >
                                        <div class="px-3">
                                            <div class="mb-2">
                                                <span
                                                    class="text-3xl flex items-start mb-5"
                                                >
                                                    <span class="text-lg">
                                                        {{
                                                            $t(
                                                                'client_information'
                                                            )
                                                        }}
                                                        <hr />
                                                    </span>
                                                </span>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('name') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="formData.Name"
                                                    @blur="
                                                        v$.formData.Name.$touch
                                                    "
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData.Name
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('address') }} 1 *
                                                </label>
                                                <input
                                                    v-model="formData.Address1"
                                                    id="autocomplete"
                                                    @blur="
                                                        v$.formData.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .Address1.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address')
                                                    }}
                                                    2</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="formData.Address2"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    id="postal_code"
                                                    autocomplete="postal-code"
                                                    v-model="
                                                        formData.PostalCode
                                                    "
                                                    @blur="
                                                        v$.formData.PostalCode.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('city') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="formData.City"
                                                    id="locality"
                                                    @blur="
                                                        v$.formData.City.$touch
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData.City
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >Type</label
                                                >
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="formData.Type"
                                                    :options="typeOptions"
                                                    :searchable="false"
                                                    :allow-empty="false"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                    label="name"
                                                    track-by="name"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >RCS</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="formData.RCS"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >TVA</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="formData.VATIntra"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >Siret</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="formData.Siret"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('billing') }}
                                                </label>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="
                                                        formData.ClientType
                                                    "
                                                    :options="clientTypeOptions"
                                                    :searchable="false"
                                                    :allow-empty="false"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                    label="name"
                                                    track-by="name"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('email_address') +
                                                        (createUser === true
                                                            ? ' *'
                                                            : '')
                                                    }}
                                                </label>
                                                <input
                                                    type="email"
                                                    autocomplete="email"
                                                    @focus="
                                                        userAlreadyExists = false
                                                    "
                                                    @blur="
                                                        v$.formData.Email.$touch()
                                                    "
                                                    v-model="formData.Email"
                                                    :class="
                                                        userAlreadyExists
                                                            ? 'border-red-500 text-red-500'
                                                            : ''
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .Email.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    class="flex mt-1 italic text-xs items-center justify-center"
                                >
                                    {{ $t('required_fields') }}
                                </span>
                            </div>
                            <div class="p-1">
                                <span v-if="!$route.params.id">
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <input
                                            id="create-user-checkbox"
                                            type="checkbox"
                                            v-model="createUser"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="create-user-checkbox"
                                            class="ml-2 font-medium text-sm"
                                            >{{
                                                $t('create_user_account')
                                            }}</label
                                        >
                                    </div>
                                    <div class="text-xs mb-4 mt-2 italic">
                                        {{ $t('create_user_rp_info') }}
                                    </div>
                                </span>
                                <div class="flex justify-center">
                                    <button
                                        type="button"
                                        @click="back()"
                                        class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                    >
                                        {{ $t('cancel') }}
                                    </button>
                                    <button
                                        type="submit"
                                        :disabled="creating"
                                        class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span v-if="creating">
                                            <fa-icon
                                                icon="spinner"
                                                spin
                                            ></fa-icon
                                        ></span>
                                        <span v-else>
                                            {{ $t('save') }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import Multiselect from '@vueform/multiselect'
import { required, email, requiredIf, helpers } from '@vuelidate/validators'
import { mapState, mapStores } from 'pinia'
import BackTo from '@/components/elements/BackTo.vue'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'
import googleScriptLoader from '@/mixins/googleScript'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/
)

export default {
    name: 'CreateOrUpdate',
    components: { Multiselect, BackTo },
    mixins: [googleScriptLoader],

    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        window.scrollTo(0, 0)

        this.currentClientInterval = setInterval(() => {
            if (this.currentClient) {
                clearInterval(this.currentClientInterval)

                this.formData = {
                    Name: this.currentClient.company_name,
                    Email: this.currentClient.email,
                    ClientType: this.currentClient.client_type,
                    VATIntra: this.currentClient.vat_intra,
                    RCS: this.currentClient.rcs,
                    Address1: this.currentClient.address,
                    Address2: this.currentClient.address2,
                    Address3: this.currentClient.address3,
                    Country: this.currentClient.country,
                    PostalCode: this.currentClient.postal_code,
                    City: this.currentClient.city,
                    // FirstName: this.currentClient.firstname,
                    // LastName: this.currentClient.lastname,

                    // Phone: this.currentClient,
                    Type: this.currentClient.type,
                    Siret: this.currentClient.registration_number,
                    PaiementType: this.currentClient.payment_type,
                    IdExternal: this.currentClient.id_external,
                    IdUserFacturation: this.currentClient.id_user_facturation,
                    IdAddressFacturation:
                        this.currentClient.id_address_facturation,
                    VoucherPrice: this.currentClient.voucher_price,
                    VoucherLimit: this.currentClient.voucher_limit,
                    Informations: this.currentClient.informations,
                    CreationUserId: this.currentClient.creation_user_id,
                    CreationDate: this.currentClient.creation_date,
                }
            }
        }, 1000)
        this.loadGoogleScript('autocomplete')
    },
    data() {
        return {
            creating: false,
            userAlreadyExists: false,
            currentClientInterval: null,
            contact: null,
            createUser: false,
            formData: {
                Name: '',
                Email: '',
                ClientType: 'occasionnal',
                VATIntra: '',
                RCS: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                Country: 'FRA',
                PostalCode: '',
                City: '',
                Type: 'business',
                Siret: '',
                PaiementType: 'check',
                IdExternal: 0,
                IdUserFacturation: 0,
                IdAddressFacturation: 0,
                VoucherPrice: 0,
                VoucherLimit: 0,
                Informations: '',
                CreationUserId: '',
                CreationDate: '',
                ModificationUserId: '',
                ModificationDate: '',
            },
        }
    },
    validations() {
        return {
            formData: {
                Name: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode
                    ),
                },
                City: { required },
                Email: {
                    required: requiredIf(this.createUser === true),
                    email,
                },
            },
        }
    },
    computed: {
        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapStores(useUserStore, useClientStore),
        clientTypeOptions() {
            return [
                { name: this.$t('voucher_book'), value: 'voucherbook' },
                { name: this.$t('occasionnal'), value: 'occasionnal' },
                { name: this.$t('month_invoice'), value: 'monthinvoice' },
            ]
        },

        typeOptions() {
            return [
                { name: this.$t('B2C'), value: 'customer' },
                { name: this.$t('B2B'), value: 'business' },
            ]
        },
    },

    methods: {
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.clientStore.$patch({ current: null })
            this.$emit('back', 'clients')
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.formData.Address2 = ''
            this.formData.Address1 = ''

            this.formData.PostalCode = ''
            this.formData.City = ''

            const userInput = this.formData.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name

                if (addressType === 'postal_code') {
                    this.formData.PostalCode = val
                } else if (addressType === 'locality') {
                    this.formData.City = val
                } else if (addressType === 'street_number') {
                    this.formData.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.formData.Address1 =
                        this.formData.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.formData.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.formData.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.formData.Address1 === userInput
                ) {
                    this.formData.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Address2 === ''
                ) {
                    this.formData.Address2 = place.name
                }
                // TODO à voir si on ajoute pour préremplir le nom du client s'il est vide
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Name === ''
                ) {
                    this.formData.Name = place.name
                }
            })
        },
        async createClient() {
            this.creating = true
            this.userAlreadyExists = false

            this.formData.CreationUserId = this.userStore.current.id
            this.formData.ModificationUserId = this.userStore.current.id
            const isFormCorrect = await this.v$.$validate()

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                try {
                    if (this.createUser === true) {
                        const userForm = {
                            Login: this.formData.Email,
                            Password: '',
                            Email: this.formData.Email,
                            FirstName: this.formData.Name,
                            LastName: this.formData.Name,
                            Type: 'client',
                            Phone: '',
                            MobilePhone: '',
                            CreationUserId: this.formData.CreationUserId,
                            ModificationUserId:
                                this.formData.ModificationUserId,
                            Client: this.formData,
                        }
                        const response = await this.userStore.create(userForm)

                        if (response.status === 200) {
                            this.userStore.forgotPassword({
                                Email: this.formData.Email,
                            })

                            this.creating = false
                            const backPath =
                                this.$router.options.history.state.back
                            this.$router.push(backPath)
                            this.$toast.success(this.$t('client_create_ok'))
                            this.$emit('created', 'clients')
                        }
                        //  else {

                        //     this.$toast.error(
                        //         `${this.$t('user_already_exists')}`
                        //     )
                        // }
                    } else {
                        const response = await this.clientStore.createClient(
                            this.formData
                        )
                        if (response) {
                            this.clientStore.$patch({ current: null })
                            const backPath =
                                this.$router.options.history.state.back
                            this.$router.push(backPath)

                            this.$emit('created', 'clients')
                            this.$toast.success(this.$t('client_create_ok'))
                        }
                    }
                } catch (err) {
                    this.creating = false
                    if (err.data.status === 422) {
                        this.userAlreadyExists = true
                        this.$toast.error(`${this.$t('user_already_exists')}`)
                    } else {
                        this.$toast.error(`${this.$t('client_create_ko')}`)
                        // this.$toast.error(err.data.message)
                    }
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        async updateClient() {
            this.creating = true
            this.formData.ModificationUserId = this.userStore.current.id
            const isFormCorrect = await this.v$.$validate()

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    const response = this.clientStore.update(params)
                    if (response) {
                        // document.head.removeChild(this.googleScript)
                        this.clientStore.$patch({ current: null })

                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$toast.success(this.$t('client_update_ok'))

                        this.$emit('updated', 'clients')
                    }
                } catch (err) {
                    this.creating = false

                    console.log(err.data.message)
                    this.$toast.error(`${this.$t('client_update_ko')}`)
                    this.$toast.error(err.data.message)
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    beforeUnmount() {
        this.clientStore.$patch({ current: null })
    },
}
</script>
