import keys from '../resources/keys'

function loaded() {
    console.log('google script loaded')
}
window.loaded = loaded
export default {
    methods: {
        loadGoogleScript(elementId = null, index = null) {
            // const head = document.getElementsByTagName('head')[0]
            // const rmScript = document.getElementById('googleApiScript')
            // if (rmScript !== null) {
            //     head.removeChild(rmScript)
            // }
            const googleScriptExists =
                document.getElementById('googleApiScript')

            if (!googleScriptExists) {
                const key =
                    window.location.hostname === 'localhost'
                        ? 'localhost'
                        : window.location.host.substring(
                              0,
                              window.location.host.indexOf('.')
                          )

                const script = document.createElement('script')
                script.id = 'googleApiScript'

                script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${keys[key]}&v=weekly&callback=loaded`
                document.getElementsByTagName('head')[0].appendChild(script)
            }
            if (elementId !== null) {
                const vm = this

                const checkInterval = setInterval(() => {
                    if (google && document.getElementById(elementId)) {
                        clearInterval(checkInterval)
                        const autocomplete =
                            new google.maps.places.Autocomplete(
                                document.getElementById(elementId)
                            )
                        autocomplete.setComponentRestrictions({
                            country: ['fr'],
                        })
                        autocomplete.addListener('place_changed', () => {
                            vm.fillInAddress(autocomplete, index)
                        })
                    }
                }, 20)
            }
        },
    },
}
