import Wrapper from '../views/suppliers/Wrapper.vue'
import CreateOrUpdate from '../views/suppliers/CreateOrUpdate.vue'

const suppliers = [
    {
        path: '/suppliers',
        name: 'suppliers',
        // redirect: '/login',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'SUPPLIERS_FULL', 'SUPPLIERS_LIST'],
        },
        children: [
            {
                path: 'new',
                name: 'new_supplier',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SUPPLIERS_FULL',
                        'SUPPLIERS_CREATE',
                    ],
                },
            },
            {
                path: 'update/:id',
                name: 'update_supplier',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SUPPLIERS_FULL',
                        'SUPPLIERS_UPDATE',
                    ],
                },
            },
            {
                path: 'billing/:supplier',
                name: 'supplier_billing',
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SUPPLIERS_FULL',
                        'SUPPLIERS_BILLINGS',
                    ],
                },
            },
        ],
    },
]

export default suppliers
