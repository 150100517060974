<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'drivers'" />
            <filtrable-table
                v-else
                id="driversList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="drivers"
                :columns="columns"
                :storeToSort="'drivers'"
                :loading="loading"
                :page="page"
                :count="count"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :itemActions="itemActions"
                :canAddNew="canAddNew()"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="updateFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @deactivate="deactivateOne($event)"
                @activate="activateOne($event)"
                @planning="toPlanning($event)"
            >
                <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'

import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useUserStore } from '@/stores/userStore'
import { useDriverStore } from '@/stores/driverStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Drivers',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            driversInterval: null,

            sortableFields: [
                { name: this.$t('lastname'), value: 'LastName' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('firstname'), value: 'FirstName' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('address'), value: 'Address1' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    watch: {
        drivers() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    created() {
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        } else {
            this.filter.push({ dbField: 'Active', value: 1, name: 'active' })
            this.setRealSelected('Active', 0)
        }
        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }
        this.searchDrivers(realSearch)
        this.driversInterval = setInterval(() => {
            this.searchDrivers(realSearch)
        }, 20000)
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    beforeUnmount() {
        clearInterval(this.driversInterval)
    },

    computed: {
        ...mapState(useDriverStore, {
            drivers: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapWritableState(useDriverStore, {
            currentDriver: 'current',
        }),
        ...mapStores(useUserStore),
        driversActionsAccess() {
            return actionsAccess.drivers
        },
        columns() {
            return [
                {
                    name: 'active',
                    filtrable: true,
                    dbField: 'Active',
                    type: 'select',
                    sortable: false,
                    withIconNoText: true,
                    selected: {
                        name: this.$t('active'),
                        value: 1,
                        dbField: 'Active',
                    },
                    options: [
                        {
                            name: 'active',
                            value: 1,
                            dbField: 'Active',
                        },
                        {
                            name: 'deactivated',
                            value: 0,
                            dbField: 'Active',
                        },
                    ],
                },
                {
                    name: 'firstname',
                    filtrable: true,
                    dbField: 'FirstName',
                    type: 'search',
                    sortable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.driversActionsAccess.update
                    ),
                },
                {
                    name: 'lastname',
                    filtrable: true,
                    dbField: 'LastName',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'address',
                    filtrable: true,
                    dbField: 'Address1',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'postal_code',
                    filtrable: true,
                    dbField: 'PostalCode',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'city',
                    filtrable: true,
                    dbField: 'City',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'vehicle',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.driversActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.driversActionsAccess.activate)
            ) {
                actions.push({
                    name: 'deactivate',
                    action: 'deactivate',
                    icon: 'times',
                })
            }
            if (
                checkAccess(this.userStore, actionsAccess.driverPlanning.read)
            ) {
                actions.push({
                    name: 'planning',
                    action: 'planning',
                    icon: 'calendar',
                })
            }

            return actions
        },
        multiActions() {
            if (
                checkAccess(this.userStore, this.driversActionsAccess.activate)
            ) {
                return [
                    {
                        name: 'deactivate_selection',
                        action: 'deactivateAll',
                        icon: 'times',
                    },
                ]
            }
            return []
        },
    },

    methods: {
        canAddNew() {
            return checkAccess(this.userStore, this.driversActionsAccess.create)
        },
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            deactivate: 'deactivate',
            update: 'update',
            fetchOne: 'fetchOne',
        }),
        goToUpdateOne(id) {
            this.$emit('goToUpdatedriver', id)
        },
        toPlanning(id) {
            this.$emit('goToDriverPlanning', id)
        },
        async deactivateOne(id) {
            this.searchingCursor = true

            const response = await this.deactivate(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$emit('deleted')
                this.$toast.success(this.$t('driver_deactivate_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('driver_deactivate_ko'))
            }
        },
        deactivateAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.deactivate(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        activateOne(id) {
            this.searchingCursor = true

            this.currentDriver = null
            this.fetchOne(id).then(() => {
                const driverInterval = setInterval(() => {
                    if (this.currentDriver !== null) {
                        clearInterval(driverInterval)

                        const form = {
                            id,
                            form: {
                                Id: this.currentDriver.id,
                                Address1: this.currentDriver.address,
                                Address2: this.currentDriver.address2,
                                PostalCode: this.currentDriver.postal_code,
                                City: this.currentDriver.city,
                                FirstName: this.currentDriver.firstname,
                                LastName: this.currentDriver.lastname,
                                PhonePro: this.currentDriver.phone_pro,
                                PhonePerso: this.currentDriver.phone_perso,
                                CreationUserId:
                                    this.currentDriver.creation_user_id,
                                ModificationUserId: this.userStore.current.id,
                                Active: 1,
                                ContactToWarnFirstName:
                                    this.currentDriver.contact_first,
                                ContactToWarnLastName:
                                    this.currentDriver.contact_last,
                                ContactToWarnPhone1:
                                    this.currentDriver.contact_phone1,
                                ContactToWarnPhone2:
                                    this.currentDriver.contact_phone2,
                                SubContractor:
                                    this.currentDriver.sub_contractor,
                                IdVehicle: this.currentDriver.vehicle,
                                IdUser: this.currentDriver.user,
                                IdSupplier: this.currentDriver.supplier,
                                IdSector: this.currentDriver.area,
                                GPSPointLatitudeStart:
                                    this.currentDriver.lat_start,
                                GPSPointLatitudeEnd: this.currentDriver.lat_end,
                                GPSPointLongitudeStart:
                                    this.currentDriver.long_start,
                                GPSPointLongitudeEnd:
                                    this.currentDriver.long_end,
                                CreationDate: this.currentDriver.creation_date,
                            },
                        }
                        this.update(form)
                            .then((response) => {
                                if (response.status === 200) {
                                    this.searchingCursor = false

                                    this.$toast.success(
                                        this.$t('driver_activate_ok')
                                    )
                                } else {
                                    this.searchingCursor = false

                                    this.$toast.error(
                                        this.$t('driver_activate_ko')
                                    )
                                }
                            })
                            .catch((err) => {
                                this.searchingCursor = false

                                this.$toast.error(this.$t('driver_activate_ko'))
                            })
                    }
                }, 20)
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.driversInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.driversInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.searchDrivers(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.driversInterval = setInterval(() => {
                this.searchDrivers(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.driversInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
