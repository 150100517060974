// import { markRaw } from 'vue'

import { defineStore } from 'pinia'
import { useUserStore } from './userStore'

const useAllUsersStore = defineStore('allUsers', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        currentUserInEdition: null,
    }),
    actions: {
        search(payload) {
            const userStore = useUserStore()

            this.loading = true

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            let filteredUserType = false
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        if (filteredUserType === false) {
                            filteredUserType = type.dbField === 'Type'
                        }
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }
            const uInterval = setInterval(() => {
                if (userStore.current) {
                    clearInterval(uInterval)
                    if (
                        !payload.fromBo &&
                        filteredUserType === false &&
                        userStore.current.type !== 'admin'
                    ) {
                        query.push(
                            `{"Type":[{"operator":"IN","value":["client", "driver", "operator"]}]}`
                        )
                    }
                    if (
                        !payload.fromBo &&
                        filteredUserType === false &&
                        userStore.current.type === 'admin'
                    ) {
                        query.push(
                            `{"Type":[{"operator":"IN","value":["client", "driver", "admin", "operator"]}]}`
                        )
                    }

                    let apiUrl = ''
                    if (Object.keys(query).length === 0) {
                        query = ''
                        apiUrl = `/api/v1/user/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
                    } else {
                        apiUrl = `/api/v1/user/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
                    }
                    return new Promise((resolve, reject) => {
                        this.axios
                            .get(apiUrl, {
                                headers: {
                                    Authorization: `Bearer ${this.cookies.get(
                                        'user_session'
                                    )}`,
                                },
                            })
                            .then((res) => {
                                if (res.status === 200) {
                                    const count = Number.isNaN(
                                        +res.data.TotalResults
                                    )
                                        ? 0
                                        : +res.data.TotalResults
                                    const totalPages = Number.isNaN(
                                        +res.data.TotalPages
                                    )
                                        ? 0
                                        : +res.data.TotalPages
                                    const users = []
                                    if (count > 0) {
                                        res.data.Records.forEach((user) => {
                                            const formattedUser = {
                                                id: +user.Id,
                                                firstname: user.FirstName,
                                                lastname: user.LastName,
                                                mobile: user.MobilePhone,
                                                phone: user.Phone,
                                                type: user.Type,
                                                login_only: user.Login,
                                                email: user.Email,
                                                creation_user_id:
                                                    +user.CreationUserId,
                                                creation_date:
                                                    user.CreationDate,
                                                modification_date:
                                                    user.ModificationDate,
                                                roles: user.Roles,
                                            }
                                            users.push(formattedUser)
                                        })
                                    }

                                    this.all = users
                                    this.count = count
                                    this.totalPages = totalPages
                                    this.loading = false
                                } else {
                                    this.all = null
                                    this.count = 0
                                    this.totalPages = 0
                                    this.loading = false
                                }

                                resolve(res)
                            })
                            .catch((err) => {
                                this.fetchError = false
                                this.loading = false

                                reject(err)
                            })
                    })
                }
            }, 200)
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/user', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentUserInEdition = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/user/${payload.id}`, payload.form, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentUserInEdition = null

                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/user/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentUserInEdition = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/user/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentUserInEdition = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        addUserRole(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/userrole', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteUserRole(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/userrole/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
    },
})
export { useAllUsersStore }
