<!-- NEXT TO DO  -->
<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" />
            <filtrable-table
                v-else
                id="clientsList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="clients"
                :columns="columns"
                :storeToSort="'clients'"
                :page="page"
                :count="count"
                :loading="loading"
                :canAddNew="canAddNew"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :itemActions="itemActions"
                :multiActions="[]"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="updateFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
                @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)"
                @addContact="$emit('addContact', $event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapState, mapStores } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useClientStore } from '@/stores/clientStore'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'
import { useUserStore } from '@/stores/userStore'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Clients',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            clientsInterval: null,
            filter: [],

            sortableFields: [
                { name: this.$t('company_name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('address'), value: 'Address1' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    watch: {
        clients() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        }
        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }
        this.clientStore.search(realSearch)
        this.clientsInterval = setInterval(() => {
            this.clientStore.search(realSearch)
        }, 20000)
    },

    beforeUnmount() {
        clearInterval(this.clientsInterval)
    },
    computed: {
        clientsActionsAccess() {
            return actionsAccess.clients
        },
        canAddNew() {
            return checkAccess(this.userStore, this.clientsActionsAccess.create)
        },
        ...mapState(useClientStore, {
            clients: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useClientStore, useUserStore),
        columns() {
            return [
                {
                    name: 'company_name',
                    filtrable: true,
                    isLink: checkAccess(
                        this.userStore,
                        this.clientsActionsAccess.update
                    ),
                    dbField: 'Name',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'address',
                    filtrable: true,
                    dbField: 'Address1',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'postal_code',
                    filtrable: true,
                    dbField: 'PostalCode',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'city',
                    filtrable: true,
                    dbField: 'City',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'registration_number',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'client_type',
                    filtrable: true,
                    type: 'select',
                    dbField: 'ClientType',

                    sortable: false,
                    options: [
                        {
                            name: 'voucher_book',
                            value: 'voucherbook',
                            dbField: 'ClientType',
                        },
                        {
                            name: 'occasionnal',
                            value: 'occasionnal',
                            dbField: 'ClientType',
                        },
                        {
                            name: 'month_invoice',
                            value: 'monthinvoice',
                            dbField: 'ClientType',
                        },
                    ],
                },
                {
                    name: 'payment_type',
                    filtrable: true,
                    type: 'select',
                    dbField: 'PaiementType',

                    sortable: false,
                    options: [
                        {
                            name: 'notselected',
                            value: 'notselected',
                            dbField: 'PaiementType',
                        },
                        {
                            name: 'check',
                            value: 'check',

                            dbField: 'PaiementType',
                        },
                        {
                            name: 'transfer',
                            value: 'transfer',

                            dbField: 'PaiementType',
                        },
                    ],
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []

            if (checkAccess(this.userStore, this.clientsActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.addresses.list
                )
            ) {
                actions.push({
                    name: 'delivery_addresses',
                    action: 'viewDeliveryAddresses',
                    icon: 'address-book',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.contacts.list
                )
            ) {
                actions.push({
                    name: 'contacts',
                    action: 'viewContacts',
                    icon: 'users',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.clientsActionsAccess.contacts.create
                )
            ) {
                actions.push({
                    name: 'add_contact',
                    action: 'addContact',
                    icon: 'user-plus',
                })
            }
            // if (
            //     checkAccess(
            //         this.userStore,
            //         this.clientsActionsAccess.delete
            //     )
            // ) {
            //     actions.push( {
            //     name: 'delete',
            //     action: 'delete',
            //     icon: 'trash-can',
            // },)
            // })}

            return actions
        },
        // multiActions() {[
        // if (
        //     checkAccess(
        //         this.userStore,
        //         this.clientsActionsAccess.delete
        //     )
        // ) {
        //         name: 'delete_selection',
        //         action: 'deleteAll',
        //         icon: 'trash-can',
        //     },
        // },
    },
    methods: {
        goToUpdateOne(id) {
            this.$emit('goToUpdateClient', id)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.clientStore.deleteOne(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('client_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('client_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.clientStore.deleteOne(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.clientsInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.clientsInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            this.setRealSearch(this.search)

            this.clientStore.search(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.clientsInterval = setInterval(() => {
                this.clientStore.search(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            this.perPage = perPage
            this.page = 1
            clearInterval(this.clientsInterval)
            this.$cookies.remove('filtered')
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
