const actionsAccess = {
    areas: {
        update: ['AREAS_UPDATE', 'AREAS_FULL', 'FULL_ACCESS'],
        activate: [
            'AREAS_ACTIVATE',
            'AREAS_FULL',
            'FULL_ACCESS',
            'AREAS_UPDATE',
        ],
        create: ['AREAS_CREATE', 'AREAS_FULL', 'FULL_ACCESS'],
    },
    billings: {
        update: ['BILLINGS_UPDATE', 'BILLINGS_FULL', 'FULL_ACCESS'],
        delete: ['BILLINGS_DELETE', 'BILLINGS_FULL', 'FULL_ACCESS'],
        create: ['BILLINGS_CREATE', 'BILLINGS_FULL', 'FULL_ACCESS'],
    },
    clients: {
        update: ['CLIENTS_UPDATE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        delete: ['CLIENTS_DELETE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        create: ['CLIENTS_CREATE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        contacts: {
            list: ['CONTACTS_LIST', 'CONTACTS_FULL', 'FULL_ACCESS'],
            create: ['CONTACTS_CREATE', 'CONTACTS_FULL', 'FULL_ACCESS'],
            update: ['CONTACTS_UPDATE', 'CONTACTS_FULL', 'FULL_ACCESS'],
            delete: ['CONTACTS_DELETE', 'CONTACTS_FULL', 'FULL_ACCESS'],
        },
        addresses: {
            list: [
                'DELIVERY-ADDRESSES_LIST',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            create: [
                'DELIVERY-ADDRESSES_CREATE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'DELIVERY-ADDRESSES_UPDATE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'DELIVERY-ADDRESSES_DELETE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
        },
    },
    roles: {
        update: ['ROLES_UPDATE', 'ROLES_FULL', 'FULL_ACCESS'],
        delete: ['ROLES_DELETE', 'ROLES_FULL', 'FULL_ACCESS'],
        create: ['ROLES_CREATE', 'ROLES_FULL', 'FULL_ACCESS'],
        assignAuthToRole: ['ROLES_ADDAUTH', 'ROLES_FULL', 'FULL_ACCESS'],
    },
    users: {
        update: ['USERS_UPDATE', 'USERS_FULL', 'FULL_ACCESS'],
        delete: ['USERS_DELETE', 'USERS_FULL', 'FULL_ACCESS'],
        create: ['USERS_CREATE', 'USERS_FULL', 'FULL_ACCESS'],
        assignRole: ['ROLES_ASSIGN', 'USERS_FULL', 'FULL_ACCESS'],
        resetPass: ['USERS_SENDRPLINK', 'USERS_FULL', 'FULL_ACCESS'],
    },
    drivers: {
        list: ['DRIVERS_LIST', 'DRIVERS_FULL', 'FULL_ACCESS'],
        create: ['DRIVERS_CREATE', 'DRIVERS_FULL', 'FULL_ACCESS'],
        update: ['DRIVERS_UPDATE', 'DRIVERS_FULL', 'FULL_ACCESS'],
        // delete: ['DRIVERS_DELETE', 'DRIVERS_FULL', 'FULL_ACCESS'],

        activate: [
            'DRIVERS_ACTIVATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'DRIVERS_UPDATE',
        ],
    },
    driverPlanning: {
        read: [
            'PLANNING-BASE_READ',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'WEEKLY_FULL',
        ],
        update: [
            'PLANNING-BASE_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'WEEKLY_FULL',
        ],
    },
    weeklyPlanning: {
        read: ['WEEKLY_READ', 'WEEKLY_FULL', 'DRIVERS_FULL', 'FULL_ACCESS'],
        create: [
            'WEEKLY_CREATEACTIVITY',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
        updateActivity: [
            'WEEKLY_UPDATEACTIVITY',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
        update: [
            'WEEKLY_UPDATE',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
    },
    suppliers: {
        list: ['SUPPLIERS_LIST', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        create: ['SUPPLIERS_CREATE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        update: ['SUPPLIERS_UPDATE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        delete: ['SUPPLIERS_DELETE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
    },
    vehicles: {
        list: ['VEHICLES_LIST', 'VEHICLES_FULL', 'FULL_ACCESS'],
        create: ['VEHICLES_CREATE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        update: ['VEHICLES_UPDATE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        // delete: ['VEHICLES_DELETE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        activate: [
            'VEHICLES_ACTIVATE',
            'VEHICLES_FULL',
            'FULL_ACCESS',
            'VEHICLES_UPDATE',
        ],
        maintenances: ['VEHICLES_FULL', 'FULL_ACCESS', 'VEHICLES_MAINTENANCE'],
    },
    orders: {
        list: ['ORDERS_LIST', 'ORDERS_FULL', 'FULL_ACCESS'],
        create: ['ORDERS_CREATE', 'ORDERS_FULL', 'FULL_ACCESS'],
        update: ['ORDERS_UPDATE', 'ORDERS_FULL', 'FULL_ACCESS'],
        updateStatus: ['ORDERS_STEPSSTATUS', 'ORDERS_FULL', 'FULL_ACCESS'],
        assign: {
            driver: ['ORDERS_ASSIGNDRIVER', 'ORDERS_FULL', 'FULL_ACCESS'],
            vehicle: ['ORDERS_ASSIGNVEHICLE', 'ORDERS_FULL', 'FULL_ACCESS'],
        },

        delete: ['ORDERS_DELETE', 'ORDERS_FULL', 'FULL_ACCESS'],
        signatures: ['ORDERS_SIGNATURES', 'ORDERS_FULL', 'FULL_ACCESS'],
        trackings: ['ORDERS_TRACKINGS', 'ORDERS_FULL', 'FULL_ACCESS'],
        imports: {
            list: ['ORDERS-IMPORT_LIST', 'ORDERS-IMPORT_FULL', 'FULL_ACCESS'],
            create: [
                'ORDERS-IMPORT_CREATE',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'ORDERS-IMPORT_DELETE',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            download: [
                'ORDERS-IMPORT_DOWNLOAD',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
        },
        containers: {
            list: ['PACKAGES_LIST', 'PACKAGES_FULL', 'FULL_ACCESS'],
            create: ['PACKAGES_CREATE', 'PACKAGES_FULL', 'FULL_ACCESS'],
            update: ['PACKAGES_UPDATE', 'PACKAGES_FULL', 'FULL_ACCESS'],
            activate: [
                'PACKAGES_ACTIVATE',
                'PACKAGES_FULL',
                'FULL_ACCESS',
                'PACKAGES_UPDATE',
            ],
        },
    },
    settings: {
        orders: ['SETTINGS_ORDERSCODES', 'FULL_ACCESS', 'SETTINGS_FULL'],

        notifications: [
            'SETTINGS_NOTIFICATIONS',
            'FULL_ACCESS',
            'SETTINGS_FULL',
        ],

        billings: ['SETTINGS_BILLINGS', 'FULL_ACCESS', 'SETTINGS_FULL'],
    },
    tours: {
        map: ['TOURS_MAPVIEW', 'FULL_ACCESS', 'TOURS_FULL'],
        create: ['TOURS_CREATE', 'FULL_ACCESS', 'TOURS_FULL'],
        delete: ['TOURS_DELETE', 'FULL_ACCESS', 'TOURS_FULL'],
    },
    simulations: {
        map: [
            'SIMULATIONS_MAPVIEW',
            'ORDERS_FULL',
            'FULL_ACCESS',
            'SIMULATIONS_FULL',
        ],
        update: [
            'ORDERS_FULL',
            'ORDERS_UPDATE',
            'FULL_ACCESS',
            'SIMULATIONS_FULL',
        ],
        create: ['SIMULATIONS_GENERATE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        delete: ['SIMULATIONS_DELETE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
    },
    daily: {
        map: ['DAILY_MAPVIEW', 'ORDERS_FULL', 'FULL_ACCESS', 'DAILY_FULL'],
        update: ['DAILY_FULL', 'ORDERS_FULL', 'ORDERS_UPDATE', 'FULL_ACCESS'],
    },
}

export default actionsAccess
