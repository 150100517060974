<template>
    <div
        id="create-B2C-order"
        class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        :class="preparingToPrint === true ? 'cursor-progress' : ''"
    >
        <div class="px-5">
            <div>
                <div class="mb-2">
                    <span
                        class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                    >
                        <span class="mr-2 mt-5">
                            {{
                                $t(
                                    $route.params.id
                                        ? 'update_order_B2C'
                                        : 'new_order_B2C'
                                )
                            }}
                        </span>
                        <span
                            v-if="
                                $route.params.id &&
                                currentDelivery &&
                                editDeliveryName === false &&
                                currentDelivery.name
                            "
                            class="items-center inline-flex custom-link mt-5 mb-1"
                        >
                            - {{ currentDelivery.name }}
                            <icon-button
                                :title="$t('edit')"
                                class="text-zinc-600"
                                :icon="'fa-pen'"
                                @click="makeNameEditable()"
                            ></icon-button>
                        </span>
                        <span
                            v-else-if="
                                $route.params.id &&
                                currentDelivery &&
                                (editDeliveryName === true ||
                                    !currentDelivery.name)
                            "
                            class="items-center inline-flex custom-link mt-5 mb-1"
                        >
                            -
                            <input
                                type="name"
                                :placeholder="$t('delivery_name')"
                                v-model="newDeliveryName"
                                class="border-2 rounded px-3 w-full"
                            />
                            <icon-button
                                :icon="'floppy-disk'"
                                :title="$t('save')"
                                class="text-zinc-600"
                                @click="saveDeliveryName()"
                            ></icon-button>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newDeliveryName.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </span>
                        <!-- saveDeliveryName() -->
                    </span>
                </div>
                <div class="grid grid-cols-3 gap-2" :key="refresh">
                    <div
                        v-for="(card, index) in cards"
                        :key="index"
                        class="col-span-1"
                    >
                        <span class="flex-row flex-wrap p-3">
                            <div class="w-full">
                                <!-- Profile Card -->
                                <div
                                    class="rounded card-dark shadow-lg flex flex-row flex-wrap p-3 antialiased"
                                >
                                    <div
                                        class="grid grid-cols-6 w-full p-2 pb-4 items-center"
                                    >
                                        <div class="col-span-2">
                                            <img
                                                alt="package"
                                                class="w-auto sm"
                                                :src="`/img/picto_livrez_64.png`"
                                            />
                                        </div>
                                        <div
                                            class="col-span-3 justify-start flex"
                                        >
                                            <span
                                                class="justify-start whitespace-nowrap"
                                            >
                                                <span
                                                    :style="
                                                        methodStyle(card.method)
                                                    "
                                                >
                                                    <fa-icon
                                                        :icon="
                                                            card.method ===
                                                            'express'
                                                                ? 'rocket'
                                                                : card.method ===
                                                                  'normal'
                                                                ? 'gauge'
                                                                : 'gauge-high'
                                                        "
                                                        :title="
                                                            $t(
                                                                card.method ===
                                                                    'express'
                                                                    ? 'express'
                                                                    : card.method ===
                                                                      'normal'
                                                                    ? 'normal'
                                                                    : 'fast'
                                                            )
                                                        "
                                                        class="fa-md mr-3"
                                                    ></fa-icon>
                                                </span>
                                                <span
                                                    class="font-semibold text-base"
                                                    :class="card.status.color"
                                                >
                                                    <fa-icon
                                                        :icon="card.status.icon"
                                                        class="fa-md mr-2"
                                                    ></fa-icon>
                                                    {{
                                                        `${card.status.status}`
                                                    }}

                                                    <span
                                                        v-if="
                                                            card.trackings
                                                                .length > 0 ||
                                                            card.pictures
                                                                .length > 0 ||
                                                            card.signatures
                                                                .length > 0
                                                        "
                                                    >
                                                        <button
                                                            @click="
                                                                showPopover(
                                                                    card.id
                                                                )
                                                            "
                                                            class="inline-block cursor-help px-2 text-zinc font-medium text-base leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                                        >
                                                            <div
                                                                class="py-0.5 relative w-fit"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            'trackings_and_proofs'
                                                                        )
                                                                    "
                                                                    icon="eye"
                                                                    class="fa-lg"
                                                                />
                                                            </div>
                                                        </button>
                                                        <!-- <button
                                                            @click="
                                                                $emit(
                                                                    'packageTracking',
                                                                    {
                                                                        packageRef:
                                                                            card
                                                                                .package
                                                                                .reference,
                                                                        orderClientId:
                                                                            card.orderCLientId,
                                                                        deliveryId:
                                                                            currentDelivery.id,
                                                                    }
                                                                )
                                                            "
                                                            class="inline-block cursor-pointer px-2 text-zinc font-medium text-base leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                                        >
                                                            <span
                                                                class="py-0.5 relative w-fit text-base"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            'map'
                                                                        )
                                                                    " 
                                                                    icon="map"
                                                                    class="fa-lg"
                                                                />
                                                            </span>
                                                        </button>-->
                                                    </span>

                                                    <div
                                                        class="text-xs mb-1 font-normal"
                                                        v-else
                                                    >
                                                        {{
                                                            $t(
                                                                'no_tracking_data_available'
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="text-xs mb-1">
                                                        {{
                                                            `${$t(
                                                                'distance_nokm'
                                                            )}
                                                        ${card.distance} km -
                                                        ${$t('duration')}
                                                        ${card.duration}`
                                                        }}
                                                    </div>
                                                </span>
                                                <popover
                                                    v-if="showPop === card.id"
                                                    :item="card.full"
                                                    :headerText="card.reference"
                                                    :id="`popover-${card.id}`"
                                                    @packageTracking="
                                                        $emit(
                                                            'packageTracking',
                                                            $event
                                                        )
                                                    "
                                                    @closePop="
                                                        showPopover(
                                                            card.id,
                                                            true
                                                        )
                                                    "
                                                ></popover>
                                                <div
                                                    class="text-sm mb-1 font-semibold items-center flex"
                                                >
                                                    <fa-layer
                                                        :title="
                                                            $t(
                                                                'no_temp_specified'
                                                            )
                                                        "
                                                        v-if="
                                                            card.package
                                                                .temperature
                                                                .noTemp
                                                        "
                                                        class="fa-2xl mr-2"
                                                    >
                                                        <fa-icon
                                                            icon="ban"
                                                            style="color: grey"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            :icon="
                                                                card.package
                                                                    .temperature
                                                                    .icon
                                                            "
                                                            transform="shrink-6"
                                                        ></fa-icon>
                                                    </fa-layer>
                                                    <span
                                                        v-else
                                                        class="mr-2"
                                                        :style="`${card.package.temperature.color}`"
                                                    >
                                                        <fa-icon
                                                            :title="
                                                                $t(
                                                                    card.package
                                                                        .temperature
                                                                        .title
                                                                )
                                                            "
                                                            :icon="
                                                                card.package
                                                                    .temperature
                                                                    .icon
                                                            "
                                                            class="fa-xl"
                                                        ></fa-icon>
                                                    </span>
                                                    <span
                                                        v-if="
                                                            card.package.container.includes(
                                                                '_'
                                                            ) ||
                                                            card.package
                                                                .container ===
                                                                'pallet'
                                                        "
                                                        class="whitespace-nowrap"
                                                    >
                                                        {{
                                                            `${
                                                                card.package
                                                                    .quantity
                                                            } ${$tc(
                                                                card.package
                                                                    .container,
                                                                +card.package
                                                                    .quantity ===
                                                                    1
                                                                    ? 1
                                                                    : 2
                                                            )}`
                                                        }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="whitespace-nowrap"
                                                    >
                                                        {{
                                                            `${card.package.quantity} ${card.package.container}`
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="text-xs">
                                                    {{
                                                        `${card.package.size}  - 
                                                        
                                                        ${card.package.weight} kg`
                                                    }}
                                                </div>
                                                <div class="text-sm">
                                                    {{
                                                        `${$t('value')}: ${
                                                            card.package.worth
                                                        }`
                                                    }}
                                                </div>
                                            </span>
                                        </div>
                                        <div
                                            class="col-span-1 justify-end flex"
                                        >
                                            <dropdown-menu
                                                ref="packageActionDD"
                                                class="inline-flex"
                                                :position="''"
                                                :items="packageActions"
                                                @picked="
                                                    packageAction($event, card)
                                                "
                                            >
                                                <template #buttonContent>
                                                    <fa-icon
                                                        :title="$t('action')"
                                                        icon="ellipsis-vertical"
                                                        class="fa-lg"
                                                    />
                                                </template>
                                            </dropdown-menu>
                                        </div>
                                    </div>
                                    <div
                                        class="w-full bg-contain bg-center bg-no-repeat h-60"
                                        style="
                                            background-image: url('/img/ecran3_map_2.png');
                                        "
                                    >
                                        <div
                                            class="grid grid-cols-6 gap-2 w-full"
                                        >
                                            <div
                                                class="col-span-2 px-2 pt-4 darky justify-start"
                                            >
                                                <div class="bg-white">
                                                    <span
                                                        class="text-sm mt-4 italic"
                                                    >
                                                        <fa-icon
                                                            icon="house"
                                                            class="fa-sm"
                                                        ></fa-icon>
                                                        {{ card.pick_up.date }}
                                                    </span>
                                                    <div class="text-xs">
                                                        {{
                                                            card.pick_up.moment
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.pick_up.address
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.pick_up.zipCity
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </div>
                                        <div
                                            class="grid grid-cols-6 pl-10 gap-2 w-full"
                                        > -->
                                            <div
                                                class="col-start-5 pt-20 ml-3 col-span-2 darky px-1"
                                            >
                                                <div class="bg-white">
                                                    <span
                                                        class="text-sm italic"
                                                    >
                                                        <fa-icon
                                                            icon="flag-checkered"
                                                            class="fa-sm"
                                                        ></fa-icon>
                                                        {{ card.delivery.date }}
                                                    </span>
                                                    <div class="text-xs">
                                                        {{
                                                            card.delivery.moment
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.delivery
                                                                .address
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        {{
                                                            card.delivery
                                                                .zipCity
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- End Profile Card -->
                            </div>
                        </span>
                    </div>
                    <div class="col-span-1">
                        <span class="flex flex-row flex-wrap p-3">
                            <div
                                class="w-full justify-center flex"
                                :class="
                                    cards.length === 0 ? 'pb-3' : 'pt-32 pb-32'
                                "
                            >
                                <div
                                    class="flex flex-row flex-wrap p-3 antialiased"
                                >
                                    <button
                                        type="button"
                                        :disabled="adding"
                                        @click="showAddModal()"
                                        class="themed-button text-base ml-2 focus:outline-none flex items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span>
                                            <fa-icon
                                                icon="plus"
                                                class="mr-2"
                                            ></fa-icon>

                                            {{ $t('add_package') }}
                                        </span>
                                    </button>
                                </div>
                            </div></span
                        >
                    </div>
                </div>
            </div>
            <modal
                v-if="show"
                :headerText="isEditPackage ? 'update_package' : 'add_package'"
                :buttonText="isEditPackage ? 'update' : 'save'"
                :secondSubmit="true"
                :secondSubmitText="
                    isEditPackage ? 'update_and_copy' : 'save_and_copy'
                "
                :spin="creating"
                :creating="
                    creating ||
                    stepDatePickup > stepDateDeliv ||
                    stepDatePickup < date.toISOString().substr(0, 10)
                "
                id="addModal"
                :mxWidth="'max-w-8xl'"
                :mxHeight="'max-h-screen'"
                :buttonAlign="'justify-center'"
                @closeModal="closeModal()"
                @sendFromModal="submitModal($event)"
            >
                <template #body>
                    <span v-if="isCopying === true" class="py-40">
                        <fa-icon
                            icon="spinner"
                            class="fa-2xl py-40"
                            spin
                        ></fa-icon>
                    </span>

                    <div v-else>
                        <div
                            v-if="$route.name === 'new_order'"
                            class="text-sm italic custom-link items-center pl-6 pt-2 my-2 flex justify-center"
                        >
                            <fa-icon icon="fa-lightbulb" class="mr-2"></fa-icon>
                            {{ $t('delivery_name_info') }}
                        </div>
                        <div
                            v-if="$route.name === 'new_order'"
                            class="items-center pl-6 mb-2 flex justify-center"
                        >
                            <label
                                class="font-semibold custom-link text-2xl text-gray-600 pb-1 mr-2 block"
                                >{{ $t('delivery_name') + ' *' }}</label
                            >
                            <input
                                type="name"
                                v-model="newDeliveryName"
                                class="border rounded py-2 px-2"
                            />
                            <div
                                class="text-xs italic mt-1 ml-2 mb-2"
                                v-for="error of v$.newDeliveryName.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <hr v-if="$route.name === 'new_order'" />
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <div class="col-span-2 px-2">
                                <div class="text-lg py-4">
                                    {{ $t('pick_up_label') }}
                                </div>
                                <hr />
                                <div class="mt-5">
                                    <div class="mt-2 rounded-md p-4 border-2">
                                        <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{
                                                    $t(
                                                        'can_select_address_v1'
                                                    ) + ' *'
                                                }}
                                                <hr />
                                            </span>
                                        </div>
                                        <div
                                            v-if="
                                                pickUpAddressOptions.length > 10
                                            "
                                            class="mb-3 mt-3 items-center flex flex-row"
                                        >
                                            <Multiselect
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="pickUpAddressId"
                                                :options="pickUpAddressOptions"
                                                label="name"
                                                track-by="name"
                                                :searchable="true"
                                                :placeholder="
                                                    $t('can_select_address_v1')
                                                "
                                                :allow-empty="false"
                                                :can-clear="false"
                                                @select="
                                                    setPickUp(pickUpAddressId)
                                                "
                                            ></Multiselect>
                                        </div>
                                        <div
                                            v-else
                                            class="grid-cols-2 grid gap-2"
                                        >
                                            <div
                                                class="flex col-span-1 justify-center items-center"
                                                v-for="(
                                                    pickupOption, index
                                                ) in pickUpAddressOptions"
                                                :key="index"
                                            >
                                                <button
                                                    type="button"
                                                    class="text-sm ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    :class="
                                                        pickUpAddressId ===
                                                        pickupOption.value
                                                            ? 'secondary-themed-button'
                                                            : 'themed-button'
                                                    "
                                                    @click="
                                                        pickUpAddressId =
                                                            pickupOption.value
                                                    "
                                                >
                                                    <fa-icon
                                                        icon="house"
                                                        class="mr-2"
                                                    ></fa-icon
                                                    >{{ pickupOption.name }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pickUpStep" class="mt-4">
                                    <div class="mt-2 rounded-md p-4 border-2">
                                        <div class="font-semibold mb-2">
                                            {{ pickUpStep.AddressName }}
                                        </div>

                                        <div class="mb-2">
                                            {{ pickUpStep.Address1 }}
                                        </div>
                                        <div
                                            v-if="
                                                pickUpStep.Address2 !==
                                                    'null' &&
                                                pickUpStep.Address2 !== null &&
                                                pickUpStep.Address2 !== ''
                                            "
                                        >
                                            {{ pickUpStep.Address2 }}
                                        </div>
                                        <div class="mb-2">
                                            {{
                                                `${pickUpStep.PostalCode} ${pickUpStep.City}`
                                            }}
                                        </div>

                                        <div
                                            class="mb-3 mt-3 items-center flex flex-row"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                >{{ $t('on') + ' *' }}</label
                                            >
                                            <input
                                                type="date"
                                                :max="
                                                    stepDateDeliv !== ''
                                                        ? stepDateDeliv
                                                        : ''
                                                "
                                                :min="
                                                    date
                                                        .toISOString()
                                                        .substr(0, 10)
                                                "
                                                v-model="stepDatePickup"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.pickUpStep
                                                .stepDate.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                stepDatePickup <
                                                date.toISOString().substr(0, 10)
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $t('past_date') }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                stepDateDeliv !== '' &&
                                                stepDatePickup > stepDateDeliv
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $tc('date_error_after', 2) }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                pickUpStep.Hours &&
                                                openingHoursForDay(
                                                    pickUpStep.Hours,
                                                    findStepDay(stepDatePickup)
                                                ).length > 0
                                            "
                                            class="mb-3"
                                        >
                                            <div
                                                class="mb-1 justify-center flex"
                                            >
                                                {{
                                                    `${$t(
                                                        'opening_hours'
                                                    )} ${$t('for')} ${$t(
                                                        days[
                                                            findStepDay(
                                                                stepDatePickup
                                                            )
                                                        ]
                                                    )} ${stepDatePickup} `
                                                }}
                                            </div>
                                            <div
                                                v-for="(
                                                    hour, index
                                                ) in openingHoursForDay(
                                                    pickUpStep.Hours,
                                                    findStepDay(stepDatePickup)
                                                )"
                                                :key="index"
                                                class="justify-center flex"
                                            >
                                                {{
                                                    `${hour.start} - ${hour.end}`
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="grid grid-cols-3 gap-1 pl-4 mb-3"
                                        >
                                            <div
                                                class="col-span-1 flex items-center"
                                            >
                                                <span class="mr-2 text-lg">
                                                    *
                                                </span>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="pickupMoment"
                                                    :options="stepMomentOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                v-if="
                                                    pickupMoment ===
                                                        'between' ||
                                                    pickupMoment === 'from'
                                                "
                                                :class="
                                                    pickupMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="fromTimePickup"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .pickUpStep.fromTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    pickupMoment ===
                                                        'between' ||
                                                    pickupMoment === 'before'
                                                "
                                                :class="
                                                    pickupMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="beforeTimePickup"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .pickUpStep.beforeTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="badPickupTimeRange"
                                        >
                                            <div class="error-msg">
                                                {{ $t('bad_time_range') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2 px-2">
                                <div class="text-lg py-4">
                                    {{ $t('package_info') }}
                                </div>
                                <hr />
                                <div class="mt-2 rounded-md p-4 border-2 mt-5">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('delivery_mode') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <Multiselect
                                        class="darky"
                                        v-model="selectedMethod"
                                        :options="methodOptions"
                                        :key="refreshMS"
                                        label="name"
                                        track-by="name"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                    >
                                        <template #singlelabel="{ value }">
                                            <div
                                                class="multiselect-single-label"
                                            >
                                                <div
                                                    class="select-label-icon-wrap"
                                                    :style="value.style"
                                                >
                                                    <fa-icon
                                                        :title="value.name"
                                                        :icon="value.icon"
                                                        class="fa-sm"
                                                    ></fa-icon>
                                                    <span class="ml-1">
                                                        {{ $t(value.name) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template #option="{ option }">
                                            <div
                                                class="select-option-icon-wrap"
                                                :style="option.style"
                                            >
                                                <fa-icon
                                                    :title="option.name"
                                                    :icon="option.icon"
                                                    class="fa-sm"
                                                ></fa-icon
                                                ><span class="ml-1">
                                                    {{ $t(option.name) }}
                                                </span>
                                            </div>
                                        </template></Multiselect
                                    >
                                </div>

                                <div class="mt-5 rounded-md p-4 border-2">
                                    <div>
                                        <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{
                                                    containerCreation === true
                                                        ? $t(
                                                              'create_my_container'
                                                          )
                                                        : $t(
                                                              'select_package_format'
                                                          ) + ' *'
                                                }}
                                                <hr />
                                            </span>
                                        </div>
                                        <span
                                            v-if="
                                                containerOptions.length > 10 &&
                                                containerCreation === false
                                            "
                                        >
                                            <div class="mb-3 items-center">
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="pickedContainerId"
                                                    :options="containerOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :searchable="true"
                                                    :placeholder="
                                                        $t(
                                                            'select_package_format'
                                                        )
                                                    "
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                ></Multiselect>
                                                <!-- @select="
                                                    setAddressInfo(
                                                        deliveryAddressId
                                                    )
                                                " -->
                                                <div>
                                                    <button
                                                        @click="
                                                            setContainerCreation
                                                        "
                                                        class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            >{{
                                                                $t(
                                                                    'create_container'
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </span>
                                        <div
                                            v-else-if="
                                                containerCreation === true
                                            "
                                        >
                                            <!-- <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{ $t('create_my_container') }}
                                                <hr />
                                            </span>
                                        </div> -->
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('package_name') +
                                                        ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="containerName"
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-4"
                                                v-if="
                                                    containerNameError === true
                                                "
                                            >
                                                <div class="error-msg">
                                                    {{ $t('value_required') }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('description')
                                                    }}</label
                                                >
                                                <textarea
                                                    v-model="
                                                        containerDescription
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>

                                            <div
                                                class="grid grid-cols-3 gap-2 mt-4"
                                            >
                                                <div class="col-span-1">
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('width') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            formData
                                                                .OrderPackages[
                                                                packageInCreationIndex
                                                            ].Width
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                    <div
                                                        class="text-xs italic mt-1 mb-4"
                                                        v-if="
                                                            packageWidthErrors.length >
                                                            0
                                                        "
                                                    >
                                                        <div class="error-msg">
                                                            {{
                                                                $t(
                                                                    'value_required'
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-span-1">
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('length') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            formData
                                                                .OrderPackages[
                                                                packageInCreationIndex
                                                            ].Length
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                    <div
                                                        class="text-xs italic mt-1 mb-4"
                                                        v-if="
                                                            packageLengthErrors.length >
                                                            0
                                                        "
                                                    >
                                                        <div class="error-msg">
                                                            {{
                                                                $t(
                                                                    'value_required'
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-span-1">
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('height') + ' *'
                                                        }}</label
                                                    >

                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="packageHeight"
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                </div>
                                            </div>

                                            <button
                                                @click.stop="
                                                    unsetContainerCreation
                                                "
                                                class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                            >
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t(
                                                            'back_to_predefined_containers'
                                                        )
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                        <div v-else>
                                            <div class="grid-cols-2 grid gap-2">
                                                <div
                                                    class="flex col-span-1 justify-start items-center"
                                                    v-for="(
                                                        container, index
                                                    ) in containerOptions"
                                                    :key="index"
                                                >
                                                    <button
                                                        type="button"
                                                        class="text-sm ml-2 items-center focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                        :class="
                                                            pickedContainerId ===
                                                            container.value
                                                                ? 'secondary-themed-button'
                                                                : 'themed-button'
                                                        "
                                                        @click="
                                                            pickedContainerId =
                                                                container.value
                                                        "
                                                    >
                                                        <fa-icon
                                                            :icon="
                                                                container.icon
                                                            "
                                                            class="mr-2"
                                                        ></fa-icon
                                                        >{{
                                                            container.name.includes(
                                                                '_'
                                                            ) ||
                                                            container.name ===
                                                                'pallet'
                                                                ? $t(
                                                                      container.name
                                                                  )
                                                                : container.name
                                                        }}
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                @click.stop="
                                                    setContainerCreation
                                                "
                                                class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                            >
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t('create_container')
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mt-2 p-2">
                                        <div
                                            v-if="
                                                formData.OrderPackages.length >
                                                    0 &&
                                                formData.OrderPackages[
                                                    packageInCreationIndex
                                                ].IdContainer !== '' &&
                                                formData.OrderPackages[
                                                    packageInCreationIndex
                                                ].Reference !== undefined
                                            "
                                            class="mb-3 font-semibold"
                                        >
                                            {{
                                                `${$t('reference')} ${
                                                    formData.OrderPackages[
                                                        packageInCreationIndex
                                                    ].Reference
                                                }`
                                            }}
                                        </div>

                                        <span
                                            v-if="
                                                formData.OrderPackages[
                                                    packageInCreationIndex
                                                ].IdContainer !== '' ||
                                                containerName !== ''
                                            "
                                        >
                                            <div class="font-semibold mb-2">
                                                {{
                                                    containerName.includes(
                                                        '_'
                                                    ) ||
                                                    containerName === 'pallet'
                                                        ? $t(containerName)
                                                        : containerName
                                                }}
                                            </div>

                                            <div class="mb-2">
                                                {{
                                                    `${formData.OrderPackages[packageInCreationIndex].Length}cm x ${formData.OrderPackages[packageInCreationIndex].Width}cm x ${packageHeight}cm`
                                                }}
                                            </div>
                                        </span>
                                        <span v-else>
                                            <div class="italic mb-2">
                                                {{ $t('no_package_selected') }}
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-if="
                                                    packageTypeErrors.length > 0
                                                "
                                            >
                                                <div class="error-msg">
                                                    {{
                                                        $t(
                                                            'no_package_type_error'
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </span>
                                        <span>
                                            <div
                                                class="grid gap-2 mt-4"
                                                :class="
                                                    currentContainer &&
                                                    +currentContainer.Height ===
                                                        0
                                                        ? 'grid-cols-2'
                                                        : 'grid-cols-3'
                                                "
                                            >
                                                <div
                                                    v-if="
                                                        currentContainer &&
                                                        +currentContainer.Height ===
                                                            0
                                                    "
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('height') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="packageHeight"
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                        :class="
                                                            packageHeightErrors.length >
                                                            0
                                                                ? 'border-red-600 text-red-600'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold whitespace-nowrap basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('unit_weight') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            formData
                                                                .OrderPackages[
                                                                packageInCreationIndex
                                                            ].Weight
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                        :class="
                                                            packageWeightErrors.length >
                                                            0
                                                                ? 'border-red-600 text-red-600'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('quantity') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        v-model="
                                                            packageQuantity
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-4"
                                                    v-if="
                                                        packageQuantityErrors.length >
                                                        0
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t('value_required')
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-span-1 items-center"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                        >{{
                                                            $t('worth') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            formData
                                                                .OrderPackages[
                                                                packageInCreationIndex
                                                            ].Value
                                                        "
                                                        :class="
                                                            packageValueErrors.length >
                                                            0
                                                                ? 'border-red-600 text-red-600'
                                                                : ''
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-28"
                                                    />
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('temperature') }}
                                            <hr />
                                        </span>
                                    </div>

                                    <div
                                        class="flex mb-2 items-center"
                                        v-for="(
                                            tempOption, index
                                        ) in temperatureOptions"
                                        :key="index"
                                    >
                                        <button
                                            type="button"
                                            class="text-xs ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            :class="
                                                selectedTemp ===
                                                tempOption.value
                                                    ? 'secondary-themed-button'
                                                    : 'themed-button'
                                            "
                                            @click="
                                                selectedTemp = tempOption.value
                                            "
                                        >
                                            <fa-layer
                                                v-if="tempOption.noTemp"
                                                class="fa-xl mr-2"
                                            >
                                                <fa-icon
                                                    icon="ban"
                                                    style="color: grey"
                                                ></fa-icon>
                                                <fa-icon
                                                    :icon="tempOption.icon"
                                                    transform="shrink-6"
                                                ></fa-icon>
                                            </fa-layer>
                                            <span
                                                v-else
                                                class="mr-2"
                                                :style="`${tempOption.color}`"
                                            >
                                                <fa-icon
                                                    :icon="tempOption.icon"
                                                    class=""
                                                ></fa-icon> </span
                                            >{{ $t(tempOption.name) }}
                                        </button>
                                    </div>
                                    <!-- <div
                                        class="flex items-center mt-3"
                                        v-for="(
                                            tempOption, index
                                        ) in temperatureOptions"
                                        :key="index"
                                    >
                                        <input
                                            :id="`temp-checkbox${index}`"
                                            type="radio"
                                            :value="tempOption.value"
                                            v-model="selectedTemp"
                                            class="themed-radio w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            :for="`temp-checkbox${index}`"
                                            class="ml-2 font-medium"
                                            >{{ $t(tempOption.name) }}</label
                                        >
                                    </div> -->
                                </div>
                            </div>

                            <div class="col-span-2 px-2">
                                <div class="text-lg py-4">
                                    {{ $t('delivery_label') }}
                                </div>
                                <hr />
                                <div class="rounded-md p-4 border-2 mt-5">
                                    <div class="px-3">
                                        <span
                                            v-if="
                                                addressOptions.length > 16 &&
                                                addressCreation === false
                                            "
                                        >
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t(
                                                            'can_select_address_v1'
                                                        ) + ' *'
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div class="mb-3 items-center">
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="deliveryAddressId"
                                                    :options="addressOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :searchable="true"
                                                    :placeholder="
                                                        $t(
                                                            'can_select_address_v1'
                                                        )
                                                    "
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                    @select="
                                                        setAddressInfo(
                                                            deliveryAddressId
                                                        )
                                                    "
                                                ></Multiselect>
                                                <div>
                                                    <button
                                                        @click="
                                                            createNewAddress()
                                                        "
                                                        class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            >{{
                                                                $t(
                                                                    'create_address'
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                </div>
                                            </div>
                                        </span>
                                        <div
                                            v-else-if="addressCreation === true"
                                        >
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t('create_my_address')
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address_name') +
                                                        ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="
                                                        deliveryStep.AddressName
                                                    "
                                                    @blur="
                                                        v$.deliveryStep.AddressName.$touch()
                                                    "
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .AddressName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address') + ' 1 *'
                                                    }}
                                                </label>
                                                <input
                                                    v-model="
                                                        deliveryStep.Address1
                                                    "
                                                    id="autocompleteAddStep"
                                                    @blur="
                                                        v$.deliveryStep.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .Address1.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('address')
                                                    }}
                                                    2</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="
                                                        deliveryStep.Address2
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    id="postal_code"
                                                    autocomplete="postal-code"
                                                    v-model="
                                                        deliveryStep.PostalCode
                                                    "
                                                    @blur="
                                                        v$.deliveryStep.PostalCode.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('city') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    type="text"
                                                    v-model="deliveryStep.City"
                                                    id="locality"
                                                    @blur="
                                                        v$.deliveryStep.City.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .City.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <button
                                                @click="addressCreation = false"
                                                class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                            >
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t(
                                                            'back_to_predefined_addresses'
                                                        )
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                        <div v-else>
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t(
                                                            'can_select_address_v1'
                                                        ) + ' *'
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                class="grid-cols-2 grid gap-2 mb-2"
                                            >
                                                <div
                                                    class="flex col-span-1 items-center"
                                                    v-for="(
                                                        deliveryOption, index
                                                    ) in addressOptions"
                                                    :key="index"
                                                >
                                                    <button
                                                        type="button"
                                                        class="text-sm ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                        :class="
                                                            deliveryAddressId ===
                                                            deliveryOption.value
                                                                ? 'secondary-themed-button'
                                                                : 'themed-button'
                                                        "
                                                        @click="
                                                            deliveryAddressId =
                                                                deliveryOption.value
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="flag-checkered"
                                                            class="mr-2"
                                                        ></fa-icon
                                                        >{{
                                                            deliveryOption.name
                                                        }}
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                @click="addressCreation = true"
                                                class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                            >
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    >{{
                                                        $t('create_address')
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div class="mt-2 rounded-md p-4 border-2">
                                        <span v-if="deliveryStep.AddressName">
                                            <div class="font-semibold mb-2">
                                                {{ deliveryStep.AddressName }}
                                            </div>

                                            <div class="mb-2">
                                                {{ deliveryStep.Address1 }}
                                            </div>
                                            <div
                                                v-if="
                                                    deliveryStep.Address2 !==
                                                        'null' &&
                                                    deliveryStep.Address2 !==
                                                        null &&
                                                    deliveryStep.Address2 !== ''
                                                "
                                            >
                                                {{ deliveryStep.Address2 }}
                                            </div>
                                            <div class="mb-2">
                                                {{
                                                    `${deliveryStep.PostalCode} ${deliveryStep.City}`
                                                }}
                                            </div>
                                        </span>
                                        <span v-else>
                                            <div class="italic mb-2">
                                                {{ $t('no_address_selected') }}
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.deliveryStep
                                                    .AddressName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </span>
                                        <div
                                            class="mb-3 items-center flex flex-row"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                >{{ $t('on') + ' *' }}</label
                                            >
                                            <input
                                                type="date"
                                                :min="stepDatePickup"
                                                v-model="stepDateDeliv"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.deliveryStep
                                                .stepDate.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                stepDateDeliv !== '' &&
                                                stepDateDeliv <
                                                    date
                                                        .toISOString()
                                                        .substr(0, 10)
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{ $t('past_date') }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                stepDateDeliv !== '' &&
                                                stepDateDeliv < stepDatePickup
                                            "
                                            class="text-xs italic mt-1 mb-3"
                                        >
                                            <div class="error-msg">
                                                {{
                                                    $tc('date_error_before', 2)
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                deliveryStep.Hours &&
                                                openingHoursForDay(
                                                    deliveryStep.Hours,
                                                    findStepDay(stepDateDeliv)
                                                ).length > 0
                                            "
                                            class="mb-3"
                                        >
                                            <div
                                                class="mb-1 justify-center flex"
                                            >
                                                {{
                                                    `${$t(
                                                        'opening_hours'
                                                    )} ${$t('for')} ${$t(
                                                        days[
                                                            findStepDay(
                                                                stepDateDeliv
                                                            )
                                                        ]
                                                    )} ${stepDateDeliv} `
                                                }}
                                            </div>
                                            <div
                                                v-for="(
                                                    hour, index
                                                ) in openingHoursForDay(
                                                    deliveryStep.Hours,
                                                    findStepDay(stepDateDeliv)
                                                )"
                                                :key="index"
                                                class="justify-center flex"
                                            >
                                                {{
                                                    `${hour.start} - ${hour.end}`
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="grid grid-cols-3 gap-1 pl-4 mb-3"
                                        >
                                            <div
                                                class="col-span-1 flex items-center"
                                            >
                                                <span class="mr-2 text-lg">
                                                    *
                                                </span>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="deliveryMoment"
                                                    :options="stepMomentOptions"
                                                    label="name"
                                                    track-by="name"
                                                    :allow-empty="false"
                                                    :can-clear="false"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                v-if="
                                                    deliveryMoment ===
                                                        'between' ||
                                                    deliveryMoment === 'from'
                                                "
                                                :class="
                                                    deliveryMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="fromTimeDeliv"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .deliveryStep.fromTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    deliveryMoment ===
                                                        'between' ||
                                                    deliveryMoment === 'before'
                                                "
                                                :class="
                                                    deliveryMoment === 'between'
                                                        ? 'col-span-1'
                                                        : 'col-span-2'
                                                "
                                            >
                                                <input
                                                    type="time"
                                                    v-model="beforeTimeDeliv"
                                                    @input="resetBadTimes()"
                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .deliveryStep.beforeTime
                                                        .$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="badTimeRange"
                                        >
                                            <div class="error-msg">
                                                {{ $t('bad_time_range') }}
                                            </div>
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-if="badTimeRangeSteps"
                                        >
                                            <div class="error-msg">
                                                {{
                                                    `${$t(
                                                        'bad_time_range_steps'
                                                    )} : ${previousPickUpTimes(
                                                        1
                                                    )}`
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import { mapState, mapStores } from 'pinia'
import keys from '@/resources/keys'
import Modal from '@/components/atoms/Modal.vue'
import prefixes from '@/resources/prefixes'
import DropdownMenu from '@/components/atoms/DropdownMenu.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import googleScriptLoader from '@/mixins/googleScript'
import Popover from '@/components/elements/Popover.vue'
import converter from '@/mixins/converter'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/
)

const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const dateIsValid = helpers.regex(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
)

export default {
    name: 'B2CCreateOrUpdate',
    components: { Multiselect, Modal, DropdownMenu, IconButton, Popover },
    mixins: [googleScriptLoader, converter],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]
        const prefix =
            prefixes[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            ]
        const date = new Date()
        return { key, prefix, date, v$: useVuelidate() }
    },
    watch: {
        $route(to, from) {
            if (to.name === 'new_order') {
                this.cards = []
            }
        },
        selectedMethod() {
            this.formData.Method = this.selectedMethod.value
        },
        addressCreation() {
            if (this.addressCreation === true) {
                this.loadGoogleScript('autocompleteAddStep')
            }
        },
        pickUpAddressId() {
            if (this.pickUpAddressId !== null) {
                this.setPickUp(this.pickUpAddressId)
            }
        },
        packageQuantity() {
            if (this.packageInCreationIndex !== null) {
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Quantity = this.packageQuantity
            }
        },
        packageHeight() {
            if (this.packageInCreationIndex !== null) {
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Height = this.packageHeight
            }
        },
        deliveryAddressId() {
            if (this.deliveryAddressId !== null) {
                this.setAddressInfo(this.deliveryAddressId)
            }
        },
        pickedContainerId() {
            if (this.pickedContainerId) {
                this.clientStore.$patch({ currrentContainer: null })
                this.clientStore
                    .fetchOneContainer(this.pickedContainerId)
                    .then(() => {
                        const interval = setInterval(() => {
                            if (this.currentContainer !== null) {
                                clearInterval(interval)

                                this.containerName = this.currentContainer.Name

                                this.setPackageContainer(this.currentContainer)
                            }
                        }, 100)
                    })
            }
        },
        selectedTemp() {
            this.formData.OrderPackages[
                this.packageInCreationIndex
            ].TemperatureOption = this.selectedTemp
        },
        currentDelivery(newVal, oldVal) {
            if (newVal === null) {
                const date = new Date()

                this.formData = {
                    Name: '',
                    IdClient: this.isB2C === false ? '' : this.user.idClient,
                    IdUserContact: this.user.id,
                    IdDriver: '',
                    IdInvoice: '',
                    Reference: '',
                    Status: 'new',
                    Method: 'normal',
                    Type: 'occasionnal',
                    Latitude: '',
                    Longitude: '',
                    VoucherNumber: 0,
                    CommentInternal: '',
                    CommentClient: '',
                    OrderStepsHTML: null,
                    CreationUserId: 1,
                    OrderSteps: [],
                    OrderPackages: [],
                    DateBegin: '',
                    DateEnd: '',
                    OrderOptions: null,
                    // InvoiceNumber: `${
                    //     this.prefix.invoice
                    // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                    OrderOption: [],
                    ModificationUserId: '',
                    ModificationDate: '',
                    PacketNumber: 0,
                }
            }
            if (this.show === false) {
                this.setCards()
            }
        },
    },
    created() {
        window.scrollTo(0, 0)
        this.clientStore.$patch({ containers: null })
        this.containerOptions = []
        const currentClientInterval = setInterval(() => {
            if (this.currentClient) {
                clearInterval(currentClientInterval)
                this.formData.IdClient = this.user.idClient
            }
        }, 500)
    },
    props: {
        reference: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            days: [
                'noday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
            showPop: null,
            badTimeRangeSteps: false,
            containerNameError: false,
            preparingToPrint: false,
            newDeliveryName: '',
            editDeliveryName: false,
            orderIndexInUpdate: null,
            containerName: '',
            packageHeight: 0,
            beforeTimeDeliv: '18:00',
            fromTimeDeliv: '08:00',
            beforeTimePickup: '18:00',
            fromTimePickup: '08:00',
            stepDateDeliv: '',
            stepDatePickup: new Date().toISOString().substr(0, 10),
            pickupMoment: 'between',
            deliveryMoment: 'between',
            containerDescription: '',
            containerCreation: false,
            addressCreation: false,
            isCopying: false,
            duplication: false,
            containerOptions: [],
            packageQuantity: 1,
            adding: false,
            isEditPackage: false,
            packageInCreationIndex: null,
            container: 'Palette',
            noContactForClient: false,
            price: null,
            // vehicleType: this.prefix.defaultDeliveryMode,
            creating: false,
            show: false,
            deliveryStep: null,
            pickUpStep: null,
            noClientError: false,
            noDriverError: false,
            noContactError: false,
            refresh: 0,
            refreshDName: 0,
            cards: [],
            badTimeRange: false,
            badPickupTimeRange: false,
            deliveryStepToUpdate: null,
            pickUpStepToUpdate: null,
            stepMomentOptions: [
                { name: this.$t('between'), value: 'between' },
                { name: this.$t('before'), value: 'before' },
                { name: this.$t('from'), value: 'from' },
            ],
            formData: {},

            typeOptions: [
                { name: this.$t('destination'), value: 'destination' },
                { name: this.$t('origin'), value: 'origin' },
            ],
            clientOptions: [],

            addressOptions: [],
            pickUpAddressOptions: [],
            temperatureOptions: [
                {
                    name: 'no_temp_specified',
                    value: 'none',
                    icon: 'temperature-half',
                    noTemp: true,
                },
                {
                    name: 'cool',
                    value: 'fresh',
                    icon: 'temperature-low',
                    color: 'color:dodgerblue;',
                },
                {
                    name: 'frozen',
                    value: 'frozen',
                    icon: 'temperature-arrow-down',
                    color: 'color:cyan;',
                },
                {
                    name: 'hot',
                    value: 'warm',
                    icon: 'temperature-high',
                    color: 'color:firebrick;',
                },
            ],
            selectedTemp: 'none',
            deliveryAddressId: null,
            pickUpAddressId: null,
            pickedContainerId: null,

            currentDeliveryInterval: null,
            packageTypeErrors: [],
            packageReferenceErrors: [],
            packageValueErrors: [],
            packageWeightErrors: [],
            packageHeightErrors: [],
            packageWidthErrors: [],
            packageLengthErrors: [],
            packageQuantityErrors: [],
            selectedMethod: {
                name: 'normal',
                value: 'normal',
                icon: 'gauge',
                style: 'color:green;',
            },
            methodOptions: [
                {
                    name: 'normal',
                    value: 'normal',
                    icon: 'gauge',
                    style: 'color:green;',
                },
                {
                    name: 'fast',
                    value: 'fast',
                    icon: 'gauge-high',
                    style: 'color:orange;',
                },
                {
                    name: 'express',
                    value: 'express',
                    icon: 'rocket',
                    style: 'color: red;',
                },
            ],
            packageActions: [
                {
                    name: 'print_label',
                    action: 'printLabel',
                    icon: 'download',
                },
                // {
                //     name: 'tracking',
                //     action: 'tracking',
                //     icon: 'route',
                // },
                {
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                },
                {
                    name: 'duplicate',
                    action: 'duplicate',
                    icon: 'copy',
                },
                {
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                },
            ],
        }
    },
    validations() {
        return {
            newDeliveryName: { required },
            formData: {
                IdClient: {
                    required,
                },
                IdDriver: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false
                    }),
                },
                // Reference: { required },
            },
            deliveryStep: {
                AddressName: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode
                    ),
                },

                City: { required },
                fromTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.deliveryStep.Moment === 'between' ||
                            this.deliveryStep.Moment === 'from'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                beforeTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.deliveryStep.Moment === 'between' ||
                            this.deliveryStep.Moment === 'before'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                stepDate: {
                    required,
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid
                    ),
                },
            },
            pickUpStep: {
                fromTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.pickUpStep.Moment === 'between' ||
                            this.pickUpStep.Moment === 'from'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                beforeTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.pickUpStep.Moment === 'between' ||
                            this.pickUpStep.Moment === 'before'
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid
                    ),
                },
                stepDate: {
                    required,
                    dateIsValid: helpers.withMessage(
                        this.$t('bad_date_format'),
                        dateIsValid
                    ),
                },
            },
        }
    },

    computed: {
        ...mapStores(useClientStore, useOrderStore),
        ...mapState(useOrderStore, {
            currentDelivery: 'current',
        }),
        ...mapState(useClientStore, {
            currentClient: 'current',
            clientAddresses: 'addresses',
            currentDeliveryAddress: 'currentAddress',
            currentClientPickup: 'currentClientPickup',
            currentClientPickupAddresses: 'currentClientPickupAddresses',
            containers: 'containers',
            currentContainer: 'currentContainer',
        }),

        ...mapState(useUserStore, {
            user: 'current',
            isB2C: 'isB2C',
            mapMarker: 'mapMarker',
        }),
    },
    methods: {
        createNewAddress() {
            this.addressCreation = true

            // Type: 'delivery',
            // Status: 'new',
            this.deliveryStep.StepNumber = 2
            this.deliveryStep.IdClientAddress = 0
            this.deliveryStep.AddressName = ''
            this.deliveryStep.Id = ''
            this.deliveryStep.Address1 = ''
            this.deliveryStep.Address2 = ''
            this.deliveryStep.Address3 = ''
            this.deliveryStep.Address4 = ''
            this.deliveryStep.PostalCode = ''
            this.deliveryStep.City = ''
            this.deliveryStep.Country = 'FRA'
            this.deliveryStep.Latitude = ''
            this.deliveryStep.Longitude = ''
            this.deliveryStep.CreationUserId = +this.user.id
            this.deliveryStep.ModificationUserId = +this.user.id
            this.deliveryStep.TypeVehicle = ''
            this.deliveryStep.IdDriver = ''
            this.deliveryStep.IdClientAddress = ''
            this.deliveryAddressId = null
        },
        findStepDay(date) {
            return new Date(date).getDay()
        },
        openingHoursForDay(businessHours, dayNumber) {
            const hours = []
            businessHours.forEach((bHour) => {
                if (+bHour.Day === dayNumber && +bHour.Open === 1) {
                    hours.push({
                        start: bHour.HourBegin.substring(0, 5),
                        end: bHour.HourEnd.substring(0, 5),
                    })
                }
            })
            return hours
        },
        resetBadTimes() {
            this.badTimeRange = false
            this.badPickupTimeRange = false
            this.badTimeRangeSteps = false
        },
        isOdd(num) {
            return num % 2
        },
        previousPickUpTimes(update = null) {
            let times = null

            times = `${this.$t('from')} ${this.fromTimePickup}`
            if (this.pickupMoment === 'between') {
                times = `${this.$t('short_from')} ${
                    this.fromTimePickup
                } ${this.$t('short_before')} ${this.beforeTimePickup}`
            } else if (this.pickupMoment === 'before') {
                times = `${this.$t('before')} ${this.beforeTimePickup}`
            }

            return times
        },
        methodStyle(method) {
            return method === 'express'
                ? 'color: red;'
                : method === 'normal'
                ? 'color: green;'
                : 'color: orange;'
        },
        showPopover(elId, close = false) {
            this.$cookies.set('currentDeliveryId', this.$route.params.id)

            if (close === false) {
                this.showPop = elId
                if (this.$route.params.id)
                    localStorage.setItem('popoverOpen', true)
            } else {
                this.showPop = null
                localStorage.removeItem('popoverOpen')
            }
        },
        makeNameEditable() {
            this.editDeliveryName = true
            this.newDeliveryName = this.currentDelivery.name
        },
        orderStatus(order) {
            let status = {
                status: this.$t('in_progress'),
                real: 'inprogress',
                color: 'theme-color',
                icon: 'person-biking',
            }
            if (
                order.Orders[0].Status === 'finished' ||
                +order.Orders[0].ExecutedSteps ===
                    order.Orders[0].OrderSteps.length ||
                order.Orders[0].OrderSteps[
                    order.Orders[0].OrderSteps.length - 1
                ].Status === 'executed'
            ) {
                status = {
                    status: this.$t('delivery_finished'),
                    real: 'executed',
                    color: 'success-msg',
                    icon: 'flag-checkered',
                }
            }
            if (order.Orders[0].Status === 'new') {
                status = {
                    status: this.$t('delivery_new'),
                    real: 'new',
                    color: 'theme-color',
                    icon: 'hourglass-half',
                }
            }
            return status
        },
        async saveDeliveryName() {
            const isValid = await this.v$.newDeliveryName.$validate()
            if (isValid) {
                this.orderStore
                    .updateDeliveryName({
                        id: this.currentDelivery.id,
                        form: {
                            Name: this.newDeliveryName,
                            ModificationUserId: this.user.id,
                        },
                    })
                    .then(() => {
                        this.orderStore
                            .fetchOneDelivery(this.$route.params.id)
                            .then(() => {
                                this.newDeliveryName = ''
                                this.editDeliveryName = false
                                this.refreshDName += 1
                            })
                    })
            }
        },
        async areStepsValid(copyToNext = false) {
            this.isCopying = copyToNext
            this.badPickupTimeRange = false
            this.pickUpStep.Moment = this.pickupMoment
            this.pickUpStep.beforeTime = this.beforeTimePickup
            this.pickUpStep.fromTime = this.fromTimePickup
            this.pickUpStep.stepDate = this.stepDatePickup

            if (
                (this.pickUpStep.fromTime > this.pickUpStep.beforeTime ||
                    this.pickUpStep.fromTime === this.pickUpStep.beforeTime) &&
                this.pickUpStep.Moment === 'between'
            ) {
                this.badPickupTimeRange = true
            }

            this.deliveryStep.Moment = this.deliveryMoment
            this.deliveryStep.beforeTime = this.beforeTimeDeliv
            this.deliveryStep.fromTime = this.fromTimeDeliv
            this.deliveryStep.stepDate = this.stepDateDeliv

            if (
                (this.deliveryStep.fromTime > this.deliveryStep.beforeTime ||
                    this.deliveryStep.fromTime ===
                        this.deliveryStep.beforeTime) &&
                this.deliveryStep.Moment === 'between'
            ) {
                this.badTimeRange = true
            }

            const isValidPickup = await this.v$.pickUpStep.$validate()
            const isValidDelivery = await this.v$.deliveryStep.$validate()

            return (
                isValidDelivery &&
                this.badTimeRange === false &&
                isValidPickup &&
                this.badPickupTimeRange === false
            )
        },
        setContainerCreation() {
            this.containerCreation = true
            this.containerName = ''

            this.formData.OrderPackages[
                this.packageInCreationIndex
            ].IdContainer = ''
            this.formData.OrderPackages[this.packageInCreationIndex].Length = 0
            this.formData.OrderPackages[this.packageInCreationIndex].Width = 0
            this.packageHeight = 0
            this.formData.OrderPackages[this.packageInCreationIndex].Height =
                this.packageHeight
            if (!this.$route.params.id) {
                this.pickedContainerId = null
            }
            this.clientStore.$patch({ currrentContainer: null })
        },
        unsetContainerCreation() {
            this.containerCreation = false
            this.packageTypeErrors = []
            this.packageValueErrors = []
            this.packageWeightErrors = []
            this.packageReferenceErrors = []
            this.packageWidthErrors = []
            this.packageLengthErrors = []
            this.packageHeightErrors = []
            this.containerNameError = false

            if (this.$route.params.id) {
                this.containerName =
                    this.formData.OrderPackages[
                        this.packageInCreationIndex
                    ].Container.Name
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].IdContainer =
                    this.formData.OrderPackages[
                        this.packageInCreationIndex
                    ].Container.Id
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Length =
                    this.formData.OrderPackages[
                        this.packageInCreationIndex
                    ].Container.Length
                this.formData.OrderPackages[this.packageInCreationIndex].Width =
                    this.formData.OrderPackages[
                        this.packageInCreationIndex
                    ].Container.Width
                this.packageHeight =
                    this.formData.OrderPackages[
                        this.packageInCreationIndex
                    ].Container.Height
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Height = this.packageHeight
            } else {
                this.containerName = ''
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].IdContainer = ''
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Length = 0
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Width = 0
                this.packageHeight = 0
                this.formData.OrderPackages[
                    this.packageInCreationIndex
                ].Height = this.packageHeight
            }
        },
        async createContainer(pack) {
            const container = {
                CreationUserId: this.user.id,
                IdClient: this.user.idClient,
                Description: this.containerDescription,
                Name: this.containerName,
                Width: pack.Width,
                Height: this.packageHeight,
                Length: pack.Length,
                Active: 1,
            }
            const res = await this.clientStore.createContainer(container)
            if (res) {
                this.pickedContainerId = +res.data.Id
            }

            this.containerCreation = false
        },
        packageAction(event, card) {
            this.editDeliveryName = false

            this.orderIndexInUpdate = card.order_index

            if (event.action === 'printLabel') {
                this.printLabel(card.package)
            } else if (event.action === 'tracking') {
                // this.trackParcel(card.package)
                if (card.trackings.length > 0) {
                    this.$emit('packageTracking', {
                        packageRef: card.package.reference,
                        orderClientId: card.orderCLientId,
                        deliveryId: this.currentDelivery.id,
                    })
                } else {
                    this.$toast.info(this.$t('no_tracking_data_available'))
                }
            } else if (event.action === 'duplicate') {
                this.isEditPackage = false
                this.setFormData(card.order_index)

                this.duplicate(
                    card.pick_up.index,
                    card.delivery.index,
                    card.package.index,
                    card.order_index
                )
            } else if (event.action === 'delete') {
                this.deletePackage(
                    this.currentDelivery.orderClient[card.order_index].Id
                )
            } else if (event.action === 'update') {
                this.isEditPackage = true
                this.setFormData(card.order_index)
                this.updatePackage(card)
            }
        },
        setFormData(orderIndex) {
            this.formData = {
                CommentClient:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CommentClient,
                CommentInternal:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CommentInternal,
                CreationDate:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CreationDate,
                CreationUserId:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .CreationUserId,
                DateBegin:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .DateBegin,
                DateEnd:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .DateEnd,
                DateReception:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .DateReception,
                Duration:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .Duration,
                Id: this.currentDelivery.orderClient[orderIndex].Orders[0].Id,
                IdClient:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdClient,
                IdDriver:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdDriver,
                IdInvoice:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdExternal,
                IdUserContact:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .IdUserContact,
                Method: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Method,
                ModificationDate:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .ModificationDate,
                ModificationUserId:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .ModificationUserId,
                OrderPackages:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .OrderPackages,
                OrderSteps:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .OrderSteps,
                OrderOptions:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .OrderOptions,
                PacketNumber:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .PacketNumber,
                PacketValue:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .PacketValue,
                Reference:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .Reference,
                Status: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Status,
                Type: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Type,
                VoucherNumber:
                    this.currentDelivery.orderClient[orderIndex].Orders[0]
                        .VoucherNumber,
                Weight: this.currentDelivery.orderClient[orderIndex].Orders[0]
                    .Weight,
            }
        },
        duplicate(pickUpIndex, deliveryIndex, packageIndex, orderIndex) {
            const date = new Date()

            this.formData = {
                IdClient: this.user.idClient,
                IdUserContact: this.user.id,
                IdDriver: null,
                IdInvoice: null,
                Reference: `${
                    this.prefix.order
                }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                Status: 'new',
                Method: 'normal',
                Type: 'occasionnal',
                VoucherNumber: 0,
                CommentInternal: '',
                CommentClient: '',
                OrderStepsHTML: null,
                CreationUserId: this.user.id,
                OrderSteps: [],
                OrderPackages: [],
                DateBegin: '',
                DateEnd: '',
                OrderOptions: null,
                OrderOption: [],
                ModificationUserId: '',
                ModificationDate: '',
                PacketNumber:
                    this.currentDelivery.orderClient[orderIndex].NbPackages,
            }
            this.addPackage(true, packageIndex, orderIndex)
            const currentDeliveryInterv = setInterval(() => {
                if (
                    this.currentDelivery &&
                    this.currentDelivery.orderClient &&
                    this.currentDelivery.orderClient[orderIndex]
                ) {
                    clearInterval(currentDeliveryInterv)
                    this.duplication = true
                    this.pickUpAddressId =
                        +this.currentDelivery.orderClient[orderIndex].Orders[0]
                            .OrderSteps[pickUpIndex].IdClientAddress
                    this.deliveryAddressId =
                        +this.currentDelivery.orderClient[orderIndex].Orders[0]
                            .OrderSteps[deliveryIndex].IdClientAddress
                    this.containerOptions = []
                    this.clientStore
                        .fetchContainers(this.user.idClient)
                        .then(() => {
                            this.containers.forEach((container) => {
                                this.containerOptions.push({
                                    name:
                                        container.Name.includes('_') ||
                                        container.Name === 'pallet'
                                            ? this.$t(container.Name)
                                            : container.Name,
                                    value: container.Id,
                                    icon: container.Name.includes('pallet')
                                        ? 'pallet'
                                        : container.Name.includes('letter')
                                        ? 'envelope'
                                        : container.Name.includes('box')
                                        ? 'box'
                                        : 'star',
                                })
                                if (
                                    container.Id ===
                                    this.currentDelivery.orderClient[orderIndex]
                                        .OrderPackages[packageIndex].IdContainer
                                ) {
                                    this.pickedContainerId = container.Id
                                }
                            })
                        })

                    const pickupPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        filter: [],
                        perPage: 100000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: true,
                    }
                    this.pickUpAddressOptions = []
                    this.addressOptions = []
                    this.clientStore.fetchPickUpAddresses(pickupPayload)

                    const clientAddressesPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        filter: [],
                        perPage: 100000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: false,
                    }
                    this.clientStore.fetchAddresses(clientAddressesPayload)

                    const currentClientPickupAddressesInterval = setInterval(
                        () => {
                            if (
                                this.currentClientPickupAddresses &&
                                this.clientAddresses
                            ) {
                                clearInterval(
                                    currentClientPickupAddressesInterval
                                )
                                this.currentClientPickupAddresses.forEach(
                                    (address) => {
                                        this.pickUpAddressOptions.push({
                                            name: address.name,
                                            value: address.id,
                                            address: `${address.address} ${address.postal_code} ${address.city}`,
                                        })
                                    }
                                )

                                this.clientAddresses.forEach((address) => {
                                    const option = {
                                        name: address.name,
                                        value: address.id,
                                    }
                                    this.addressOptions.push(option)
                                })
                                this.pickUpStep = structuredClone(
                                    this.currentDelivery.orderClient[orderIndex]
                                        .Orders[0].OrderSteps[pickUpIndex]
                                )
                                this.pickUpStep.StepNumber = 1
                                this.pickUpStep.Id = ''

                                this.formatDateTimes(this.pickUpStep)

                                const stepsWoTechSteps = []
                                this.currentDelivery.orderClient[
                                    orderIndex
                                ].Orders[0].OrderSteps.forEach(
                                    (step, index) => {
                                        if (step.Step !== 'technical') {
                                            stepsWoTechSteps.push(step)
                                        }
                                    }
                                )
                                this.deliveryStep = structuredClone(
                                    stepsWoTechSteps[deliveryIndex]
                                )
                                this.deliveryStep.StepNumber = 2
                                this.deliveryStep.Id = ''
                                this.formatDateTimes(this.deliveryStep)
                                this.deliveryAddressId =
                                    +this.deliveryStep.IdClientAddress

                                this.packageInCreationIndex =
                                    this.formData.OrderPackages.length - 1
                                this.packageQuantity =
                                    this.currentDelivery.orderClient[
                                        orderIndex
                                    ].NbPackages
                                this.adding = false
                                this.show = true
                                this.creating = false
                                this.isCopying = false
                                this.selectedTemp =
                                    this.currentDelivery.orderClient[
                                        orderIndex
                                    ].OrderPackages[
                                        packageIndex
                                    ].TemperatureOption
                                this.pickUpStepToUpdate = null
                                this.deliveryStepToUpdate = null
                                this.pickupMoment = this.pickUpStep.Moment
                                this.beforeTimePickup =
                                    this.pickUpStep.beforeTime
                                this.fromTimePickup = this.pickUpStep.fromTime
                                this.stepDatePickup = this.pickUpStep.stepDate

                                this.deliveryMoment = this.deliveryStep.Moment
                                this.beforeTimeDeliv =
                                    this.deliveryStep.beforeTime
                                this.fromTimeDeliv = this.deliveryStep.fromTime
                                this.stepDateDeliv = this.deliveryStep.stepDate
                                this.selectedMethod = this.methodOptions.find(
                                    (option) =>
                                        option.name ===
                                        this.currentDelivery.orderClient[
                                            orderIndex
                                        ].Orders[0].Method
                                )
                            }
                        },
                        100
                    )
                }
            }, 100)
        },
        formatcurrentDeliveryDateTimes(orderClient) {
            if (orderClient.DeliveryMoment === 'between') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryFromDateTime.substring(
                        0,
                        orderClient.DeliveryFromDateTime.indexOf(' ')
                    )
                const stepFromDateTime =
                    orderClient.DeliveryFromDateTime.substring(
                        orderClient.DeliveryFromDateTime.indexOf(' ') + 1
                    )
                orderClient.DeliveryFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3
                )

                const stepBeforeDateTime =
                    orderClient.DeliveryBeforeDateTime.substring(
                        orderClient.DeliveryBeforeDateTime.indexOf(' ') + 1
                    )
                orderClient.DeliveryBeforeDateTime =
                    stepBeforeDateTime.substring(
                        0,
                        stepBeforeDateTime.length - 3
                    )
            } else if (orderClient.DeliveryMoment === 'from') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryFromDateTime.substring(
                        0,
                        orderClient.DeliveryFromDateTime.indexOf(' ')
                    )
                const stepFromDateTime =
                    orderClient.DeliveryFromDateTime.substring(
                        orderClient.DeliveryFromDateTime.indexOf(' ') + 1
                    )
                orderClient.DeliveryFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3
                )
                orderClient.DeliveryBeforeDateTime = ''
            } else if (orderClient.DeliveryMoment === 'before') {
                orderClient.DeliveryStepDate =
                    orderClient.DeliveryBeforeDateTime.substring(
                        0,
                        orderClient.DeliveryBeforeDateTime.indexOf(' ')
                    )

                const stepBeforeDateTime =
                    orderClient.DeliveryBeforeDateTime.substring(
                        orderClient.DeliveryBeforeDateTime.indexOf(' ') + 1
                    )
                orderClient.DeliveryBeforeDateTime =
                    stepBeforeDateTime.substring(
                        0,
                        stepBeforeDateTime.length - 3
                    )
                orderClient.DeliveryFromDateTime = ''
            }
            if (orderClient.PickupMoment === 'between') {
                orderClient.PickupStepDate =
                    orderClient.PickupFromDateTime.substring(
                        0,
                        orderClient.PickupFromDateTime.indexOf(' ')
                    )
                const stepFromDateTime =
                    orderClient.PickupFromDateTime.substring(
                        orderClient.PickupFromDateTime.indexOf(' ') + 1
                    )
                orderClient.PickupFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3
                )

                const stepBeforeDateTime =
                    orderClient.PickupBeforeDateTime.substring(
                        orderClient.PickupBeforeDateTime.indexOf(' ') + 1
                    )
                orderClient.PickupBeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3
                )
            } else if (orderClient.PickupMoment === 'from') {
                orderClient.PickupStepDate =
                    orderClient.PickupFromDateTime.substring(
                        0,
                        orderClient.PickupFromDateTime.indexOf(' ')
                    )
                const stepFromDateTime =
                    orderClient.PickupFromDateTime.substring(
                        orderClient.PickupFromDateTime.indexOf(' ') + 1
                    )
                orderClient.PickupFromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3
                )
                orderClient.PickupBeforeDateTime = ''
            } else if (orderClient.PickupMoment === 'before') {
                orderClient.PickupStepDate =
                    orderClient.PickupBeforeDateTime.substring(
                        0,
                        orderClient.PickupBeforeDateTime.indexOf(' ')
                    )

                const stepBeforeDateTime =
                    orderClient.PickupBeforeDateTime.substring(
                        orderClient.PickupBeforeDateTime.indexOf(' ') + 1
                    )
                orderClient.PickupBeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3
                )
                orderClient.PickupFromDateTime = ''
            }
        },
        setCards() {
            const interval = setInterval(() => {
                if (this.currentDelivery && this.containers) {
                    clearInterval(interval)
                    this.cards = []

                    const orderClientClone = structuredClone(
                        this.currentDelivery.orderClient
                    )
                    orderClientClone.forEach(
                        (orderClient, orderClientIndex) => {
                            const full = orderClient.Orders[0]
                            full.packages = full.OrderPackages

                            this.formatcurrentDeliveryDateTimes(orderClient)
                            const trackings = []
                            const signatures = []
                            const pictures = []
                            full.OrderPackages.forEach((pack) => {
                                if (
                                    pack.Trackings &&
                                    pack.Trackings.length > 0
                                ) {
                                    trackings.push(pack.Trackings)
                                }
                                if (
                                    pack.Signatures &&
                                    pack.Signatures.length > 0
                                ) {
                                    signatures.push(pack.Signatures)
                                }
                                if (pack.Pictures && pack.Pictures.length > 0) {
                                    pictures.push(pack.Pictures)
                                }
                            })
                            trackings.forEach((tracking, index) => {
                                trackings[index] = tracking.filter(
                                    (track) => track.TypeTracking === 'client'
                                )
                                if (trackings[index].length === 0) {
                                    trackings.splice(index, 1)
                                }
                            })
                            const stepsWoTechSteps = []
                            orderClient.Orders[0].OrderSteps.forEach(
                                (step, index) => {
                                    if (step.Step !== 'technical') {
                                        stepsWoTechSteps.push(step)
                                    }
                                }
                            )
                            const container = orderClient.OrderPackages[0]
                                .IdContainer
                                ? this.containers.find(
                                      (cntr) =>
                                          cntr.Id ===
                                          orderClient.OrderPackages[0]
                                              .IdContainer
                                  ).Name
                                : 'no_package_selected'
                            this.cards.push({
                                distance: this.formatDistance(
                                    orderClient.Distance
                                ),
                                duration: this.formatDuration(
                                    orderClient.Duration
                                ),
                                method: orderClient.Orders[0].Method,
                                trackings,
                                pictures,
                                signatures,
                                orderCLientId: orderClient.Id,
                                id: orderClient.Orders[0].Id,
                                reference: orderClient.Reference,
                                full,
                                status: this.orderStatus(orderClient),
                                order_index: orderClientIndex,
                                pick_up: {
                                    date: new Date(
                                        orderClient.PickupStepDate
                                    ).toLocaleDateString(this.$i18n.locale),
                                    moment: ` ${this.$t(
                                        orderClient.PickupMoment
                                    )} ${
                                        orderClient.PickupMoment === 'between'
                                            ? `${
                                                  orderClient.PickupFromDateTime
                                              } ${this.$t('and')} ${
                                                  orderClient.PickupBeforeDateTime
                                              }`
                                            : orderClient.PickupMoment ===
                                              'before'
                                            ? orderClient.PickupBeforeDateTime
                                            : orderClient.PickupFromDateTime
                                    }`,
                                    address: `${orderClient.PickupAddress1} ${
                                        orderClient.PickupAddress2 !== 'null' &&
                                        orderClient.PickupAddress2 !== ''
                                            ? orderClient.PickupAddress2
                                            : ''
                                    }`,
                                    zipCity: `${orderClient.PickupPostalCode} ${orderClient.PickupCity}`,
                                    index: 0,
                                },
                                delivery: {
                                    date: new Date(
                                        orderClient.DeliveryStepDate
                                    ).toLocaleDateString(this.$i18n.locale),
                                    index: stepsWoTechSteps.length - 1,
                                    moment: ` ${this.$t(
                                        orderClient.DeliveryMoment
                                    )} ${
                                        orderClient.DeliveryMoment === 'between'
                                            ? `${
                                                  orderClient.DeliveryFromDateTime
                                              } ${this.$t('and')} ${
                                                  orderClient.DeliveryBeforeDateTime
                                              }`
                                            : orderClient.DeliveryMoment ===
                                              'before'
                                            ? orderClient.DeliveryBeforeDateTime
                                            : orderClient.DeliveryFromDateTime
                                    }`,
                                    name: orderClient.DeliveryAddressName,
                                    address: `${orderClient.DeliveryAddress1} ${
                                        orderClient.DeliveryAddress2 !==
                                            'null' &&
                                        orderClient.DeliveryAddress2 !== ''
                                            ? orderClient.DeliveryAddress2
                                            : ''
                                    }`,
                                    zipCity: `${orderClient.DeliveryPostalCode} ${orderClient.DeliveryCity}`,
                                },
                                package: {
                                    index: 0,
                                    signatures:
                                        orderClient.OrderPackages[0].Signatures,
                                    trackings:
                                        orderClient.OrderPackages[0].Trackings,

                                    packageId:
                                        orderClient.OrderPackages[0].Id ?? null,
                                    reference:
                                        orderClient.OrderPackages[0]
                                            .Reference ?? null,
                                    size:
                                        `${
                                            orderClient.OrderPackages[0].Length
                                        } cm x ${
                                            orderClient.OrderPackages[0].Width
                                        } cm x ${
                                            orderClient.OrderPackages[0].Height
                                        } cm ${this.$t('lxwxh')} ` ?? null,
                                    weight: orderClient.Weight ?? null,
                                    worth:
                                        `${orderClient.OrderPackages[0].Value} €` ??
                                        null,
                                    quantity: orderClient.NbPackages,
                                    container,

                                    temperature:
                                        orderClient.OrderPackages[0]
                                            .TemperatureOption === 'warm'
                                            ? {
                                                  icon: 'temperature-high',
                                                  color: 'color:firebrick;',
                                                  title: 'hot',
                                              }
                                            : orderClient.OrderPackages[0]
                                                  .TemperatureOption === 'fresh'
                                            ? {
                                                  icon: 'temperature-low',
                                                  color: 'color:dodgerblue;',

                                                  title: 'cool',
                                              }
                                            : orderClient.OrderPackages[0]
                                                  .TemperatureOption ===
                                              'frozen'
                                            ? {
                                                  icon: 'temperature-arrow-down',
                                                  color: 'color:cyan;',

                                                  title: 'frozen',
                                              }
                                            : {
                                                  icon: 'temperature-half',
                                                  noTemp: true,
                                              },
                                },
                            })
                        }
                    )
                }
            }, 200)
        },

        printLabel(label) {
            this.preparingToPrint = true
            this.axios
                .get(`/api/v1/orderPackage/${label.packageId}/getlabel`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session'
                        )}`,
                    },
                })
                .then((res) => {
                    // const url = URL.createObjectURL(res.data)
                    // const link = document.createElement('a')
                    // link.href = url
                    // link.setAttribute('download', `label- ${label.reference}`)
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // URL.revokeObjectURL(url)

                    // window.open(window.URL.createObjectURL(res.data)).print()

                    const iframe = document.createElement('iframe')
                    // Hide the IFrame.
                    iframe.style.visibility = 'hidden'
                    // Define the source.
                    iframe.setAttribute(
                        'src',
                        window.URL.createObjectURL(res.data)
                    )
                    // Add the IFrame to the web page.
                    document.body.appendChild(iframe)
                    // iframe.focus()
                    // iframe.contentWindow.print()
                    iframe.onload = () => {
                        iframe.focus()
                        iframe.contentWindow.print()
                        this.preparingToPrint = false
                    }
                })
        },
        trackParcel(parcel) {
            const env =
                window.location.hostname === 'localhost' ||
                window.location.host.includes('dev')
                    ? 'dev'
                    : window.location.host.includes('lium')
                    ? 'lium'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.')
                      )
            const dot = env === 'beaujolyon' ? 'fr' : 'com'
            window
                .open(
                    `https://${env}.biiim-solutions.${dot}/api/v1/tracking/form/live?reference=${parcel.reference}`,
                    '_blank'
                )
                .focus()
        },
        isPackageValid() {
            this.formData.OrderPackages[
                this.packageInCreationIndex
            ].IdContainer = +this.pickedContainerId
            if (this.formData.OrderPackages[this.packageInCreationIndex]) {
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .IdContainer === ''
                ) {
                    this.packageTypeErrors.push('package error container')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Reference === ''
                ) {
                    this.packageReferenceErrors.push('package error reference')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Value === 0
                ) {
                    this.packageValueErrors.push('package error value')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Weight === 0
                ) {
                    this.packageWeightErrors.push('package error weight')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Width === 0
                ) {
                    this.packageWidthErrors.push('package error width')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Length === 0
                ) {
                    this.packageLengthErrors.push('package error length')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Quantity === 0
                ) {
                    this.packageQuantityErrors.push('package error quantity')
                }
                if (
                    this.formData.OrderPackages[this.packageInCreationIndex]
                        .Height === 0
                ) {
                    this.packageHeightErrors.push('package error height')
                }
            }

            return (
                this.packageTypeErrors.length === 0 &&
                this.packageReferenceErrors.length === 0 &&
                this.packageWeightErrors.length === 0 &&
                this.packageValueErrors.length === 0 &&
                this.packageWidthErrors.length === 0 &&
                this.packageLengthErrors.length === 0 &&
                this.packageQuantityErrors.length === 0 &&
                this.packageHeightErrors.length === 0
            )
        },

        showAddModal() {
            this.editDeliveryName = false
            this.containerOptions = []
            this.newDeliveryName = `${this.$t('delivery_title')} ${new Date()
                .toISOString()
                .substr(0, 10)}`
            this.copyToNext = false
            this.isEditPackage = false
            const date = new Date()
            this.formData = {
                IdClient: this.user.idClient,
                IdUserContact: this.user.id,
                IdDriver: '',
                IdInvoice: '',
                // Reference: `${
                //     this.prefix.order
                // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                Status: 'new',
                Method: 'normal',
                Type: 'occasionnal',
                VoucherNumber: 0,
                CommentInternal: '',
                CommentClient: '',
                OrderStepsHTML: null,
                CreationUserId: this.user.id,
                OrderSteps: [],
                OrderPackages: [],
                DateBegin: '',
                DateEnd: '',
                OrderOptions: null,
                OrderOption: [],
                ModificationUserId: '',
                ModificationDate: '',
                PacketNumber: 1,
            }

            this.addPackage()

            const pickupPayload = {
                clientId: this.currentClient
                    ? this.currentClient.id
                    : this.user.idClient,
                origin: true,
                query: [],
                filter: [],
                perPage: 100000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            this.clientStore.fetchPickUpAddresses(pickupPayload)

            const clientAddressesPayload = {
                clientId: this.currentClient
                    ? this.currentClient.id
                    : this.user.idClient,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
                origin: false,
            }
            this.clientStore.fetchAddresses(clientAddressesPayload)
            this.clientStore.fetchContainers(this.user.idClient)
            const currentClientPickupAddressesInterval = setInterval(() => {
                if (
                    this.currentClientPickupAddresses &&
                    this.clientAddresses &&
                    this.containers
                ) {
                    clearInterval(currentClientPickupAddressesInterval)
                    this.currentClientPickupAddresses.forEach((address) => {
                        this.pickUpAddressOptions.push({
                            name: address.name,
                            value: address.id,
                            address: `${address.address} ${address.postal_code} ${address.city}`,
                        })
                    })
                    this.pickUpAddressId = this.pickUpAddressOptions[0].value

                    this.clientAddresses.forEach((address) => {
                        const option = {
                            name: address.name,
                            value: address.id,
                        }
                        this.addressOptions.push(option)
                    })
                    this.setPickUp(this.currentClientPickupAddresses[0].id)

                    this.deliveryStep = {
                        Type: 'delivery',
                        Status: 'new',
                        StepNumber: 2,
                        IdClientAddress: 0,
                        AddressName: '',
                        Id: '',
                        Address1: '',
                        Address2: '',
                        Address3: '',
                        Address4: '',
                        PostalCode: '',
                        City: '',
                        Country: 'FRA',
                        Latitude: '',
                        Longitude: '',
                        Moment: 'between',
                        FromDateTime: '08:00',
                        BeforeDateTime: '18:00',
                        beforeTime: '18:00',
                        CreationUserId: +this.user.id,
                        ModificationUserId: +this.user.id,
                        fromTime: '08:00',
                        TypeVehicle: '',
                        IdDriver: '',
                        stepDate: new Date().toISOString().substr(0, 10),
                    }

                    this.containers.forEach((container) => {
                        this.containerOptions.push({
                            name:
                                container.Name.includes('_') ||
                                container.Name === 'pallet'
                                    ? this.$t(container.Name)
                                    : container.Name,
                            value: container.Id,
                            icon: container.Name.includes('pallet')
                                ? 'pallet'
                                : container.Name.includes('letter')
                                ? 'envelope'
                                : container.Name.includes('box')
                                ? 'box'
                                : 'star',
                        })
                    })

                    this.show = true
                    this.adding = false
                    this.orderIndexInUpdate = this.current
                }
            }, 100)
        },
        updatePackage(card) {
            this.containerOptions = []
            this.clientStore.fetchContainers(this.user.idClient).then(() => {
                this.containers.forEach((container) => {
                    this.containerOptions.push({
                        name:
                            container.Name.includes('_') ||
                            container.Name === 'pallet'
                                ? this.$t(container.Name)
                                : container.Name,
                        value: container.Id,
                        icon: container.Name.includes('pallet')
                            ? 'pallet'
                            : container.Name.includes('letter')
                            ? 'envelope'
                            : container.Name.includes('box')
                            ? 'box'
                            : 'star',
                    })
                })
            })
            const orderInterval = setInterval(() => {
                if (this.currentDelivery && this.currentClient) {
                    clearInterval(orderInterval)

                    this.packageInCreationIndex = card.package.index
                    const pickupPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        filter: [],
                        perPage: 100000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: true,
                    }

                    this.clientStore.fetchPickUpAddresses(pickupPayload)
                    const clientAddressesPayload = {
                        clientId: this.user.idClient,
                        query: [],
                        perPage: 100000000000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                        origin: false,
                    }
                    this.clientStore.fetchAddresses(clientAddressesPayload)

                    const addressesInterval = setInterval(() => {
                        if (
                            this.currentClientPickupAddresses &&
                            this.clientAddresses
                        ) {
                            clearInterval(addressesInterval)
                            this.currentClientPickupAddresses.forEach(
                                (address) => {
                                    this.pickUpAddressOptions.push({
                                        name: address.name,
                                        value: address.id,
                                        address: `${address.address} ${address.postal_code} ${address.city}`,
                                    })
                                }
                            )

                            this.clientAddresses.forEach((address) => {
                                const option = {
                                    name: address.name,
                                    value: address.id,
                                }
                                this.addressOptions.push(option)
                            })

                            const stepsWoTechSteps = []
                            this.formData.OrderSteps.forEach((step, index) => {
                                if (step.Step !== 'technical') {
                                    stepsWoTechSteps.push(step)
                                }
                            })
                            this.deliveryStep = structuredClone(
                                stepsWoTechSteps[card.delivery.index]
                            )
                            // this.deliveryStep.Id =
                            //     this.formData.OrderSteps[card.delivery.index].Id
                            // this.deliveryStep.StepNumber =
                            //         this.formData.OrderSteps.length
                            this.formatDateTimes(this.deliveryStep)
                            this.pickUpStep = structuredClone(
                                stepsWoTechSteps[card.pick_up.index]
                            )
                            // this.pickUpStep.Id =
                            //     this.formData.OrderSteps[card.pick_up.index].Id
                            this.formatDateTimes(this.pickUpStep)

                            const container = this.formData.OrderPackages[
                                card.package.index
                            ].IdContainer
                                ? this.containers.find(
                                      (el) =>
                                          el.Id ===
                                          this.formData.OrderPackages[
                                              card.package.index
                                          ].IdContainer
                                  ).Name
                                : 'no_package_selected'
                            this.containerName = container
                            this.pickedContainerId =
                                this.formData.OrderPackages[
                                    card.package.index
                                ].IdContainer
                            this.packageQuantity =
                                this.currentDelivery.orderClient[
                                    card.order_index
                                ].NbPackages

                            this.packageHeight =
                                this.formData.OrderPackages[
                                    card.package.index
                                ].Height
                            this.selectedTemp =
                                this.currentDelivery.orderClient[
                                    card.order_index
                                ].OrderPackages[
                                    card.package.index
                                ].TemperatureOption
                            this.pickupMoment = this.pickUpStep.Moment
                            this.beforeTimePickup = this.pickUpStep.beforeTime
                            this.fromTimePickup = this.pickUpStep.fromTime
                            this.stepDatePickup = this.pickUpStep.stepDate

                            this.deliveryMoment = this.deliveryStep.Moment
                            this.beforeTimeDeliv = this.deliveryStep.beforeTime
                            this.fromTimeDeliv = this.deliveryStep.fromTime
                            this.stepDateDeliv = this.deliveryStep.stepDate

                            this.deliveryAddressId =
                                +this.deliveryStep.IdClientAddress
                            this.pickUpAddressId =
                                +this.pickUpStep.IdClientAddress
                            this.deliveryStepToUpdate =
                                this.formData.OrderSteps.length - 1
                            this.pickUpStepToUpdate = card.pick_up.index
                            this.packageInCreationIndex = card.package.index
                            this.orderIndexInUpdate = card.order_index

                            this.selectedMethod = this.methodOptions.find(
                                (option) => option.name === this.formData.Method
                            )

                            this.adding = false
                            this.show = true
                        }
                    }, 300)
                }
            }, 100)
        },
        formatDateTimes(step) {
            // console.log(step)
            // if (step.Moment === 'between') {
            //     step.fromTime = step.FromDateTime

            //     step.beforeTime = step.BeforeDateTime
            // } else if (step.Moment === 'from') {
            //     step.fromTime = step.FromDateTime

            //     step.beforeTime = ''
            // } else if (step.Moment === 'before') {
            //     step.beforeTime = step.BeforeDateTime

            //     step.fromTime = ''
            // }
            if (step.Moment === 'between') {
                step.stepDate = step.FromDateTime.substring(
                    0,
                    step.FromDateTime.indexOf(' ')
                )
                step.fromTime = step.FromDateTime.substring(
                    step.FromDateTime.indexOf(' ') + 1
                )
                step.fromTime = step.fromTime.substring(
                    0,
                    step.fromTime.length - 3
                )
                step.beforeTime = step.BeforeDateTime.substring(
                    step.BeforeDateTime.indexOf(' ') + 1
                )
                step.beforeTime = step.beforeTime.substring(
                    0,
                    step.beforeTime.length - 3
                )
            } else if (step.Moment === 'from') {
                step.stepDate = step.FromDateTime.substring(
                    0,
                    step.FromDateTime.indexOf(' ')
                )
                step.fromTime = step.FromDateTime.substring(
                    step.FromDateTime.indexOf(' ') + 1
                )
                step.fromTime = step.fromTime.substring(
                    0,
                    step.fromTime.length - 3
                )
                step.beforeTime = ''
            } else if (step.Moment === 'before') {
                step.stepDate = step.BeforeDateTime.substring(
                    0,
                    step.BeforeDateTime.indexOf(' ')
                )

                step.beforeTime = step.BeforeDateTime.substring(
                    step.BeforeDateTime.indexOf(' ') + 1
                )
                step.beforeTime = step.beforeTime.substring(
                    0,
                    step.beforeTime.length - 3
                )
                step.fromTime = ''
            }
        },
        setAddressInfo(addressId) {
            this.clientStore.fetchOneAddress({ id: addressId })

            const currentAddressInterval = setInterval(() => {
                if (this.currentDeliveryAddress) {
                    clearInterval(currentAddressInterval)

                    this.deliveryStep = {
                        Type: 'delivery',
                        Status: 'new',
                        StepNumber:
                            this.isEditPackage === true
                                ? this.formData.OrderSteps.length
                                : 2, // +this.pickUpStep.StepNumber + 1, // ne va pas marcher
                        IdClientAddress: this.currentDeliveryAddress.id,
                        AddressName: this.currentDeliveryAddress.name,
                        Address1: this.currentDeliveryAddress.address,
                        Address2: this.currentDeliveryAddress.address2 || '',
                        Address3: this.currentDeliveryAddress.address3 || '',
                        Address4: this.currentDeliveryAddress.address4 || '',
                        PostalCode: this.currentDeliveryAddress.postal_code,
                        City: this.currentDeliveryAddress.city,
                        Country: 'FRA',
                        Latitude: this.currentDeliveryAddress.lat,
                        Longitude: this.currentDeliveryAddress.lng,
                        IdDriver: this.currentDeliveryAddress.IdDriver,
                        CreationUserId: +this.user.id,
                        ModificationUserId: +this.user.id,
                        TypeVehicle:
                            this.currentDeliveryAddress.TypeVehicle ?? '',
                        Hours: this.currentDeliveryAddress.hours ?? null,
                        Moment: this.deliveryMoment,
                        FromDateTime: '',
                        BeforeDateTime: '',
                        beforeTime: this.beforeTimeDeliv,
                        fromTime: this.fromTimeDeliv,
                        stepDate: this.stepDateDeliv,
                    }
                }
            }, 1000)
        },
        setPackageContainer(container) {
            if (+container.Height !== 0 && this.isEditPackage === false) {
                this.packageHeight = +container.Height
            }
            if (
                this.isEditPackage === true &&
                this.formData.OrderPackages[this.packageInCreationIndex]
                    .IdContainer !== container.Id
            ) {
                this.packageHeight = +container.Height
            }

            if (
                (this.duplication === true || this.copyToNext === true) &&
                +this.formData.OrderPackages[this.packageInCreationIndex]
                    .IdContainer !== +container.Id
            ) {
                this.packageHeight = +container.Height
            }

            this.formData.OrderPackages[
                this.packageInCreationIndex
            ].IdContainer = container.Id
            this.formData.OrderPackages[this.packageInCreationIndex].Width =
                +container.Width
            this.formData.OrderPackages[this.packageInCreationIndex].Length =
                +container.Length
            this.formData.OrderPackages[this.packageInCreationIndex].Height =
                this.packageHeight
        },
        setPickUp(addressId) {
            const payload = {
                id: addressId,
                origin: true,
            }
            this.clientStore.fetchOneAddress(payload)

            const currentPickupInterval = setInterval(() => {
                if (this.currentClientPickup) {
                    clearInterval(currentPickupInterval)
                    this.pickUpStep = {
                        Type: 'pickup',
                        Status: 'new',
                        StepNumber: 1,
                        IdClientAddress: this.currentClientPickup.id,
                        AddressName: this.currentClientPickup.name,
                        Address1: this.currentClientPickup.address,
                        Address2: this.currentClientPickup.address2 || '',
                        Address3: this.currentClientPickup.address3 || '',
                        Address4: this.currentClientPickup.address4 || '',
                        PostalCode: this.currentClientPickup.postal_code,
                        Hours: this.currentClientPickup.hours,

                        City: this.currentClientPickup.city,
                        Country: 'FRA',
                        Latitude: this.currentClientPickup.lat,
                        Longitude: this.currentClientPickup.lng,
                        CreationUserId: +this.user.id,
                        ModificationUserId: +this.user.id,
                        IdDriver: null,
                        TypeVehicle: '',
                        FromDateTime: '',
                        Moment: this.pickupMoment,
                        BeforeDateTime: '',
                        beforeTime: this.beforeTimePickup,
                        fromTime: this.fromTimePickup,
                        stepDate: this.stepDatePickup,
                    }
                }
            }, 1000)
        },
        async submitModal(copyToNext) {
            // if (!this.isOdd(this.newStep.StepNumber)) {
            this.badTimeRangeSteps = false

            if (
                ((this.pickupMoment === 'from' ||
                    this.pickupMoment === 'between') &&
                    this.deliveryMoment === 'before' &&
                    this.fromTimePickup >= this.beforeTimeDeliv) ||
                (this.pickupMoment === 'from' &&
                    this.deliveryMoment === 'between' &&
                    this.fromTimePickup >= this.beforeTimeDeliv) ||
                (this.pickupMoment === 'between' &&
                    this.deliveryMoment === 'between' &&
                    ((this.fromTimePickup >= this.beforeTimeDeliv &&
                        this.fromTimePickup >= this.fromTimeDeliv) ||
                        this.fromTimePickup >= this.beforeTimeDeliv))
            ) {
                this.badTimeRangeSteps = true
            }
            if (this.badTimeRangeSteps === false) {
                this.resetFormsValidations()

                if (this.containerCreation === true) {
                    this.containerNameError = this.containerName === ''
                    if (this.containerNameError === false) {
                        this.createContainer(
                            this.formData.OrderPackages[
                                this.packageInCreationIndex
                            ]
                        )
                    } else {
                        this.isCopying = false
                        this.creating = false

                        this.$toast.error(this.$t('bad_packages'))
                        return false
                    }
                }
                const interval = setInterval(() => {
                    if (this.containerCreation === false) {
                        clearInterval(interval)

                        this.formData.OrderPackages[
                            this.packageInCreationIndex
                        ].IdContainer = +this.pickedContainerId
                        if (
                            this.isEditPackage === false &&
                            this.currentDelivery
                        ) {
                            this.saveNewOrderToDelivery(copyToNext)
                        } else if (
                            !this.currentDelivery ||
                            this.currentDelivery.orderClient[
                                this.orderIndexInUpdate
                            ].OrderPackages.length === 0
                        ) {
                            this.createDelivery(copyToNext)
                        } else {
                            this.updateOrderClient(copyToNext)
                        }
                    }
                }, 100)
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        closeModal(fromSave = false, copyToNext = false) {
            if (copyToNext === false) {
                this.deliveryStepToUpdate = null
                this.pickUpStepToUpdate = null
                this.pickUpAddressOptions = []
                this.deliveryStep = null
                this.pickUpStep = null
                this.creating = false
                if (this.isEditPackage === false && fromSave === false) {
                    this.formData.OrderPackages.pop()
                }
                this.pickUpAddressId = null
                this.deliveryAddressId = null
                this.pickedContainerId = null
                this.packageInCreationIndex = null
                this.addressOptions = []
                this.isEditPackage = false
                this.orderIndexInUpdate = null
                this.containerName = ''
                this.clientStore.$patch({
                    currrentContainer: null,
                    currentAddress: null,
                })

                this.packageQuantity = 1
                this.packageHeight = 0
                this.formData = {}
                this.beforeTimeDeliv = '18:00'
                this.fromTimeDeliv = '08:00'
                this.beforeTimePickup = '18:00'
                this.fromTimePickup = '08:00'
                this.stepDateDeliv = ''
                this.stepDatePickup = new Date().toISOString().substr(0, 10)
            } else if (copyToNext === true && this.duplication === true) {
                this.deliveryStep = null
                this.pickUpStep = null
            } else if (copyToNext === true) {
                this.isCopying = true
            }
            this.isEditPackage = false

            this.addressCreation = false
            this.show = false
            this.adding = false
            this.duplication = false
            this.resetFormsValidations()
        },
        resetFormsValidations() {
            this.packageTypeErrors = []
            this.packageValueErrors = []
            this.packageWeightErrors = []
            this.packageReferenceErrors = []
            this.packageWidthErrors = []
            this.packageLengthErrors = []
            this.packageHeightErrors = []
            this.containerNameError = false

            this.packageQuantityErrors = []
            this.v$.deliveryStep.$reset()
            this.v$.pickUpStep.$reset()
            this.v$.formData.$reset()
        },
        addPackage(
            copyToNext = false,
            packageToDuplicateIndex = null,
            orderIndex = null
        ) {
            this.adding = true
            this.resetFormsValidations()
            const date = new Date()

            const realRef = `${
                this.prefix.package
            }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`

            if (copyToNext === false) {
                this.selectedTemp = 'none'

                this.formData.OrderPackages.push({
                    PackageNumber: 1,
                    // Reference: realRef,
                    Width: 0,
                    Length: 0,
                    Height: this.packageHeight,
                    Weight: 0,
                    Value: 0,
                    Quantity: this.packageQuantity,
                    TemperatureOption: this.selectedTemp,
                    IdContainer: '',
                    Id: '',
                    CreationUserId: this.user.id,
                    ModificatioUserId: this.user.id,
                })
                this.packageInCreationIndex =
                    this.formData.OrderPackages.length - 1
            } else {
                const indexToUse =
                    packageToDuplicateIndex !== null
                        ? packageToDuplicateIndex
                        : this.packageInCreationIndex

                this.packageQuantity =
                    this.currentDelivery.orderClient[orderIndex].NbPackages
                this.packageHeight =
                    +this.currentDelivery.orderClient[orderIndex].OrderPackages[
                        indexToUse
                    ].Height
                this.formData.OrderPackages.push({
                    PackageNumber: 1,
                    // Reference: realRef,
                    Width: +this.currentDelivery.orderClient[orderIndex]
                        .OrderPackages[indexToUse].Width,
                    Length: +this.currentDelivery.orderClient[orderIndex]
                        .OrderPackages[indexToUse].Length,
                    Height: this.packageHeight,
                    Weight: +this.currentDelivery.orderClient[orderIndex]
                        .OrderPackages[indexToUse].Weight,
                    Value: +this.currentDelivery.orderClient[orderIndex]
                        .OrderPackages[indexToUse].Value,
                    Quantity: +this.packageQuantity,
                    IdContainer:
                        +this.currentDelivery.orderClient[orderIndex]
                            .OrderPackages[indexToUse].IdContainer,
                    TemperatureOption:
                        this.currentDelivery.orderClient[orderIndex]
                            .OrderPackages[indexToUse].TemperatureOption,
                    Id: '',
                    CreationUserId: this.user.id,
                    ModificatioUserId: this.user.id,
                })
            }
        },

        back() {
            this.adding = false

            this.resetFormsValidations()

            this.packageQuantity = 1
            this.packageHeight = 0
            if (this.isB2C === false) {
                this.$router.push(this.$router.options.history.state.back)
            } else {
                this.$router.push({ name: 'orders' })
            }
            this.$emit('back', 'orders')
        },
        fillInAddress(autocomplete, modal = false) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address

            this.deliveryStep.Address2 = ''
            this.deliveryStep.PostalCode = ''
            this.deliveryStep.City = ''

            const userInput = this.deliveryStep.Address1
            this.deliveryStep.Latitude = place.geometry.location.lat()
            this.deliveryStep.Longitude = place.geometry.location.lng()
            this.deliveryStep.Address1 = ''

            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.deliveryStep.PostalCode = val
                } else if (addressType === 'locality') {
                    this.deliveryStep.City = val
                } else if (addressType === 'street_number') {
                    this.deliveryStep.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.deliveryStep.Address1 =
                        this.deliveryStep.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.deliveryStep.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.deliveryStep.Address2 = `${val}`
                }
                // if (
                //     (place.types[0] !== 'street_number' ||
                //         place.types[0] !== 'route') &&
                //     addressType === 'locality' &&
                //     this.deliveryStep.Address1 === userInput
                // ) {
                //     this.deliveryStep.Address1 = ` ${val}`
                // }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    this.deliveryStep.Address2 === '' &&
                    place.types[0] !== 'premise'
                ) {
                    this.deliveryStep.Address2 = place.name
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.deliveryStep.Name === ''
                ) {
                    if (!modal) {
                        this.deliveryStep.Name = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.deliveryStep.AddressName === ''
                ) {
                    if (modal) {
                        this.deliveryStep.AddressName = place.name
                    }
                }
            })
        },
        setValidatedSteps() {
            let from = `${this.pickUpStep.stepDate} ${this.pickUpStep.fromTime}`

            let before = `${this.pickUpStep.stepDate} ${this.pickUpStep.beforeTime}`

            if (this.pickUpStep.Moment === 'between') {
                this.pickUpStep.FromDateTime = from
                this.pickUpStep.BeforeDateTime = before
            } else if (this.pickUpStep.Moment === 'from') {
                this.pickUpStep.FromDateTime = from
                this.pickUpStep.BeforeDateTime = ''
            } else if (this.pickUpStep.Moment === 'before') {
                this.pickUpStep.FromDateTime = ''
                this.pickUpStep.BeforeDateTime = before
            }
            // delete this.pickUpStep.stepDate
            // delete this.pickUpStep.fromTime
            // delete this.pickUpStep.beforeTime

            if (this.pickUpStepToUpdate !== null) {
                this.pickUpStep.Id =
                    +this.formData.OrderSteps[this.pickUpStepToUpdate].Id
                this.formData.OrderSteps[this.pickUpStepToUpdate] =
                    this.pickUpStep
            } else {
                this.formData.OrderSteps.push(this.pickUpStep)
            }
            if (this.formData.OrderSteps.length === 1) {
                this.formData.DateBegin = this.pickUpStep.stepDate
                this.formData.DateEnd = this.pickUpStep.stepDate
            }

            from = `${this.deliveryStep.stepDate} ${this.deliveryStep.fromTime}`

            before = `${this.deliveryStep.stepDate} ${this.deliveryStep.beforeTime}`

            if (this.deliveryStep.Moment === 'between') {
                this.deliveryStep.FromDateTime = from
                this.deliveryStep.BeforeDateTime = before
            } else if (this.deliveryStep.Moment === 'from') {
                this.deliveryStep.FromDateTime = from
                this.deliveryStep.BeforeDateTime = ''
            } else if (this.deliveryStep.Moment === 'before') {
                this.deliveryStep.FromDateTime = ''
                this.deliveryStep.BeforeDateTime = before
            }
            if (this.deliveryStepToUpdate !== null) {
                this.deliveryStep.Id =
                    +this.formData.OrderSteps[this.deliveryStepToUpdate].Id

                this.formData.OrderSteps[this.deliveryStepToUpdate] =
                    this.deliveryStep
            } else {
                this.formData.OrderSteps.push(this.deliveryStep)
            }
            if (this.formData.OrderSteps.length === 1) {
                this.formData.DateBegin = this.deliveryStep.stepDate
                this.formData.DateEnd = this.deliveryStep.stepDate
            } else {
                this.formData.DateEnd = this.deliveryStep.stepDate
            }
        },
        async createDelivery(copyToNext = false) {
            this.creating = true
            this.resetFormsValidations()

            this.formData.IdClient = this.currentClient.id
            this.formData.OrderPackages[this.packageInCreationIndex].Quantity =
                this.packageQuantity
            if (this.isPackageValid() === true) {
                this.formData.CreationUserId = this.user.id
                this.formData.PacketNumber = this.formData.OrderPackages.length
                // todo voir ici car step date va cahnger a chaque création de new step faire un tableau ? mais pb si update du step avec un tableau à remettre a jour si le step cahnge de place
                const areStepsValid = await this.areStepsValid(copyToNext)
                if (areStepsValid === true) {
                    this.setValidatedSteps()
                    const isValid = await this.v$.formData.$validate()
                    const hasName = await this.v$.newDeliveryName.$validate()
                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (isValid && hasName) {
                        const deliveryForm = {
                            // Reference: `${
                            //     this.prefix.delivery
                            // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                            CreationUserId: this.user.id,
                            IdClient: this.formData.IdClient,
                            Name: this.newDeliveryName,
                            CreationDate: '',
                            OrdersClient: [
                                {
                                    Orders: [
                                        {
                                            IdClient: this.formData.IdClient,
                                            IdUserContact:
                                                this.formData.IdUserContact ??
                                                this.user.id,
                                            IdDriver:
                                                this.formData.IdDriver ?? '',
                                            IdInvoice:
                                                this.formData.IdInvoice ?? '',
                                            Reference: this.formData.Reference,
                                            Status: this.formData.Status,
                                            Method: this.formData.Method,
                                            Type: this.formData.Type,
                                            VoucherNumber:
                                                this.formData.VoucherNumber ??
                                                0,
                                            CommentInternal:
                                                this.formData.CommentInternal ??
                                                '',
                                            CommentClient:
                                                this.formData.CommentClient ??
                                                '',
                                            OrderStepsHTML: null,
                                            CreationUserId: this.user.id,
                                            OrderSteps:
                                                this.formData.OrderSteps,
                                            OrderPackages: [
                                                this.formData.OrderPackages[0],
                                            ],
                                            DateBegin: this.formData.DateBegin,
                                            DateEnd: this.formData.DateEnd,
                                            OrderOptions: null,
                                            OrderOption: [],
                                        },
                                    ],
                                },
                            ],
                        }
                        try {
                            const response =
                                await this.orderStore.createDelivery(
                                    deliveryForm
                                )
                            if (response) {
                                this.orderStore
                                    .fetchOneDelivery(+response.data.Id)
                                    .then(() => {
                                        this.$router
                                            .push({
                                                name: 'update_order',
                                                params: {
                                                    id: +response.data.Id,
                                                },
                                            })
                                            .then(() => {
                                                if (copyToNext === true) {
                                                    this.duplication = false
                                                    this.duplicate(0, 1, 0, 0)
                                                } else {
                                                    this.closeModal(
                                                        true,
                                                        copyToNext
                                                    )
                                                    this.orderStore.fetchOneDelivery(
                                                        +response.data.Id
                                                    )
                                                }
                                                this.$toast.success(
                                                    this.$t(
                                                        'delivery_create_ok'
                                                    )
                                                )
                                            })
                                    })
                            }
                        } catch (err) {
                            console.log(err)
                            this.creating = false
                            this.isCopying = false

                            this.$toast.error(
                                `${this.$t('delivery_create_ko')}`
                            )
                        }
                    } else {
                        this.creating = false
                        this.isCopying = false

                        this.$toast.error(this.$t('form_errors'))
                    }
                } else {
                    this.creating = false
                    this.isCopying = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.isCopying = false
                this.creating = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async saveNewOrderToDelivery(copyToNext = false) {
            this.creating = true
            this.resetFormsValidations()

            if (this.isPackageValid() === true) {
                const areStepsValid = await this.areStepsValid(copyToNext)
                if (areStepsValid === true) {
                    this.setValidatedSteps()
                    this.formData.ModificationUserId = this.user.id
                    if (this.formData.IdClient === '') {
                        this.formData.IdClient = this.user.idClient
                    }
                    this.formData.PacketNumber = this.packageQuantity
                    const isValid = await this.v$.formData.$validate()

                    if (isValid === true) {
                        const OrderCLientForm = {
                            CreationUserId: this.user.id,
                            IdDelivery: this.currentDelivery.id,
                            IdClient: this.formData.IdClient,
                            CreationDate: '',
                            Orders: [
                                {
                                    IdClient: this.formData.IdClient,
                                    IdUserContact:
                                        this.formData.IdUserContact ??
                                        this.user.id,
                                    IdDriver: this.formData.IdDriver ?? '',
                                    IdInvoice: this.formData.IdInvoice ?? '',
                                    Reference: this.formData.Reference,
                                    Status: this.formData.Status,
                                    Method: this.formData.Method,
                                    Type: this.formData.Type,
                                    VoucherNumber:
                                        this.formData.VoucherNumber ?? 0,
                                    CommentInternal:
                                        this.formData.CommentInternal ?? '',
                                    CommentClient:
                                        this.formData.CommentClient ?? '',
                                    OrderStepsHTML: null,
                                    CreationUserId: this.user.id,
                                    OrderSteps: this.formData.OrderSteps,
                                    OrderPackages: [
                                        this.formData.OrderPackages[0],
                                    ],
                                    DateBegin: this.formData.DateBegin,
                                    DateEnd: this.formData.DateEnd,
                                    OrderOptions: null,
                                    OrderOption: [],
                                },
                            ],
                        }
                        try {
                            const response =
                                await this.orderStore.createOrderClient(
                                    OrderCLientForm
                                )
                            if (response.status === 200) {
                                this.orderStore
                                    .fetchOneDelivery(this.$route.params.id)
                                    .then((res) => {
                                        if (copyToNext === true) {
                                            this.duplication = false
                                            this.orderIndexInUpdate =
                                                res.data.OrdersClient.length - 1

                                            this.duplicate(
                                                this.pickUpStep.StepNumber - 1,
                                                this.deliveryStep.StepNumber -
                                                    1,
                                                this.packageInCreationIndex,
                                                this.orderIndexInUpdate
                                            )
                                        } else {
                                            this.closeModal(true, copyToNext)
                                            this.orderStore.fetchOneDelivery(
                                                this.$route.params.id
                                            )
                                        }
                                        this.creating = false
                                        this.orderIndexInUpdate = null

                                        this.$toast.success(
                                            this.$t('delivery_create_ok')
                                        )
                                    })
                            } else {
                                this.$toast.error(this.$t('delivery_create_ko'))
                                this.$toast.error(response.data.message)
                                this.isCopying = false
                                this.creating = false
                            }
                        } catch (err) {
                            this.$toast.error(
                                `${this.$t('delivery_create_ko')}`
                            )
                            this.$toast.error(err)
                            this.isCopying = false
                            this.creating = false
                        }
                    } else {
                        this.creating = false
                        this.isCopying = false

                        this.$toast.error(this.$t('form_errors'))
                    }
                } else {
                    this.creating = false
                    this.isCopying = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false
                this.isCopying = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async updateOrderClient(copyToNext = false) {
            this.creating = true
            this.resetFormsValidations()

            if (this.isPackageValid() === true) {
                const areStepsValid = await this.areStepsValid(copyToNext)
                if (areStepsValid === true) {
                    this.setValidatedSteps()
                    this.formData.ModificationUserId = this.user.id
                    this.formData.PacketNumber =
                        this.formData.OrderPackages.Quantity

                    const isValid = await this.v$.formData.$validate()
                    if (isValid === true) {
                        const order =
                            this.currentDelivery.orderClient[
                                this.orderIndexInUpdate
                            ].Orders[0]
                        // const form = []
                        // this.currentDelivery.orderClient[
                        //     this.orderIndexInUpdate
                        // ].Orders.forEach((order) => {
                        this.deliveryStep.IdOrder = this.formData.Id
                        this.pickUpStep.IdOrder = this.formData.Id
                        const packages = []
                        this.formData.OrderPackages.forEach((pack) => {
                            const packageForm = {
                                IdOrder: this.formData.Id,
                                // IdVehicleType:
                                //     this.formData.OrderPackages[
                                //         this.packageInCreationIndex
                                //     ].IdVehicleType,
                                Reference: pack.Reference,
                                PackageNumber: pack.PackageNumber,
                                Width: this.formData.OrderPackages[
                                    this.packageInCreationIndex
                                ].Width,
                                Length: this.formData.OrderPackages[
                                    this.packageInCreationIndex
                                ].Length,
                                Height: this.formData.OrderPackages[
                                    this.packageInCreationIndex
                                ].Height,
                                Weight: this.formData.OrderPackages[
                                    this.packageInCreationIndex
                                ].Weight,
                                Value: this.formData.OrderPackages[
                                    this.packageInCreationIndex
                                ].Value,
                                Price: this.formData.OrderPackages[
                                    this.packageInCreationIndex
                                ].Price,
                                Id: pack.Id,
                                IdContainer: this.pickedContainerId,
                                LabelFile:
                                    this.formData.OrderPackages[
                                        this.packageInCreationIndex
                                    ].LabelFile,
                                LastDeliveryCode:
                                    this.formData.OrderPackages[
                                        this.packageInCreationIndex
                                    ].LastDeliveryCode,
                                LastDeliveryCodeDate:
                                    this.formData.OrderPackages[
                                        this.packageInCreationIndex
                                    ].LastDeliveryCodeDate,
                                TemperatureOption:
                                    this.formData.OrderPackages[
                                        this.packageInCreationIndex
                                    ].TemperatureOption,
                                ModificationUserId: this.user.id,
                                CreationUserId:
                                    this.formData.OrderPackages[
                                        this.packageInCreationIndex
                                    ].CreationUserId,
                            }
                            packages.push(packageForm)
                        })

                        if (this.packageQuantity !== packages.length) {
                            if (this.packageQuantity > packages.length) {
                                const diff =
                                    this.packageQuantity - packages.length
                                for (let index = 0; index < diff; index += 1) {
                                    const refPackage =
                                        packages[packages.length - 1]
                                    const newPackage = {
                                        IdOrder: refPackage.IdOrder,
                                        // IdVehicleType: refPackage.IdVehicleType,
                                        Width: refPackage.Width,
                                        Length: refPackage.Length,
                                        Height: refPackage.Height,
                                        Weight: refPackage.Weight,
                                        Value: refPackage.Value,
                                        Price: refPackage.Price,
                                        IdContainer: refPackage.IdContainer,
                                        LabelFile: refPackage.LabelFile,
                                        LastDeliveryCode:
                                            refPackage.LastDeliveryCode,
                                        LastDeliveryCodeDate:
                                            refPackage.LastDeliveryCodeDate,
                                        TemperatureOption:
                                            refPackage.TemperatureOption,
                                        ModificationUserId:
                                            refPackage.ModificationUserId,
                                        CreationUserId:
                                            refPackage.CreationUserId,
                                    }
                                    packages.push(newPackage)
                                }
                            } else {
                                const diff =
                                    packages.length - this.packageQuantity
                                for (let index = 0; index < diff; index += 1) {
                                    packages.pop()
                                }
                            }
                        }

                        const form = {
                            IdDelivery: this.currentDelivery.id,
                            ModificationUserId: this.user.id,

                            Orders: [
                                {
                                    Id: this.formData.Id,
                                    OrderOptions: [],

                                    IdDelivery: this.currentDelivery.id,
                                    IdOrderClient:
                                        this.currentDelivery.orderClient[
                                            this.orderIndexInUpdate
                                        ].Id,
                                    IdClient: this.formData.IdClient,
                                    IdUserContact: order.IdUserContact,
                                    IdDriver: order.IdDriver,
                                    IdInvoice: order.IdInvoice,
                                    DateBegin: this.formData.DateBegin,
                                    DateEnd: this.formData.DateEnd,
                                    DateReception: order.DateReception,
                                    Reference: order.Reference,
                                    Status: order.Status,
                                    Method: this.formData.Method,
                                    Type: order.Type,
                                    VoucherNumber: order.VoucherNumber,
                                    CommentInternal: order.CommentInternal,
                                    CommentClient: order.CommentClient,
                                    Duration: order.Duration,
                                    PacketNumber: null,
                                    Weight: order.Weight,
                                    PacketValue: null,
                                    ModificationUserId: this.user.id,

                                    OrderPackages: packages,
                                    OrderSteps: [
                                        this.pickUpStep,
                                        this.deliveryStep,
                                    ],
                                },
                            ],
                        }
                        const params = {
                            id: this.currentDelivery.orderClient[
                                this.orderIndexInUpdate
                            ].Id,
                            form,
                        }
                        try {
                            const response =
                                await this.orderStore.updateOrderClient(params)
                            if (response.status === 200) {
                                this.orderStore
                                    .fetchOneDelivery(this.$route.params.id)
                                    .then(() => {
                                        if (copyToNext === true) {
                                            this.duplication = false
                                            this.isEditPackage = false
                                            this.duplicate(
                                                this.pickUpStep.StepNumber - 1,
                                                this.deliveryStep.StepNumber -
                                                    1,
                                                this.packageInCreationIndex,
                                                this.orderIndexInUpdate
                                            )
                                        } else {
                                            this.closeModal(true, copyToNext)
                                            this.orderStore.fetchOneDelivery(
                                                this.$route.params.id
                                            )
                                        }
                                        this.creating = false
                                        this.orderIndexInUpdate = null
                                        this.$toast.success(
                                            this.$t('delivery_update_ok')
                                        )
                                    })
                            } else {
                                this.isCopying = false
                                this.$toast.error(this.$t('delivery_update_ko'))
                                this.$toast.error(response.data.message)
                                this.creating = false
                            }
                        } catch (err) {
                            this.isCopying = false

                            this.$toast.error(
                                `${this.$t('delivery_update_ko')}`
                            )
                            this.$toast.error(err.data.message)
                            this.creating = false
                        }
                    } else {
                        this.isCopying = false

                        this.$toast.error(this.$t('form_errors'))
                    }
                } else {
                    this.creating = false
                    this.isCopying = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.creating = false
                this.isCopying = false

                this.$toast.error(this.$t('bad_packages'))
            }
        },
        async deletePackage(id) {
            const response = await this.orderStore.deleteOrderClient(id)
            if (response.status === 200) {
                this.orderStore.fetchOneDelivery(this.$route.params.id)
                this.$toast.success(this.$t('delivery_delete_ok'))
            } else {
                this.$toast.error(this.$t('delivery_delete_ko'))
            }
        },
    },
    beforeUnmount() {
        clearInterval(this.currentDeliveryInterval)
    },
}
</script>
