import { defineStore } from 'pinia'
import colors from '@/resources/colors'

const useTourStore = defineStore('tour', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        tours: null,
        daily: null,
        dailyTours: null,
        unaffected: null,
        countCalendarUnaffected: null,
        dailyUnaffected: null,
        countDailyUnaffected: null,
        showStoreUnaffected: null,
        openStoreUnaffected: null,
        dailyUnaffectedHasIncompleteSteps: false,
        tourUnaffectedHasIncompleteSteps: false,
    }),
    actions: {
        search(payload) {
            this.loading = true

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                    // if (q.dbField === 'Address1') {
                    //     query.push(
                    //         `"Address2":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    //     query.push(
                    //         `"Address3":[{"operator":"LIKE","value":"${q.value}"}]`
                    //     )
                    // }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/tourcalendar/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/tourcalendar/search?query={"Query":[{${query}]}}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        // console.log(res)
                        if (res.status === 200) {
                            const tours = []
                            res.data.Records.forEach((tour) => {
                                // const totalMs = tour.Duration * 1000
                                // const formattedDuration = new Date(totalMs)
                                //     .toISOString()
                                //     .slice(11, 19)
                                // formatDuration(duration) {
                                const seconds = Number(tour.Duration)
                                const d = Math.floor(seconds / (3600 * 24))
                                const h = Math.floor(
                                    (seconds % (3600 * 24)) / 3600
                                )
                                const m = Math.floor((seconds % 3600) / 60)
                                // const s = Math.floor(seconds % 60)

                                const dDisplay =
                                    d > 0
                                        ? d +
                                          (d === 1
                                              ? ` ${this.i18n.global.tc(
                                                    'day',
                                                    1
                                                )}, `
                                              : ` ${this.i18n.global.tc(
                                                    'day',
                                                    2
                                                )}, `)
                                        : ''
                                const hDisplay =
                                    h > 0
                                        ? h +
                                          (h === 1
                                              ? ` ${this.i18n.global.tc(
                                                    'hour',
                                                    1
                                                )}, `
                                              : ` ${this.i18n.global.tc(
                                                    'hour',
                                                    2
                                                )}, `)
                                        : ''
                                const mDisplay =
                                    m > 0
                                        ? m +
                                          (m === 1
                                              ? ` ${this.i18n.global.tc(
                                                    'minute',
                                                    1
                                                )} `
                                              : ` ${this.i18n.global.tc(
                                                    'minute',
                                                    2
                                                )} `)
                                        : ''
                                // const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
                                const formattedDuration =
                                    dDisplay + hDisplay + mDisplay // + sDisplay
                                // const totalMs = duration * 1000
                                // const result = new Date(totalMs).toISOString()

                                // return result
                                // },
                                let formattedDistance = (
                                    tour.Distance / 1000
                                ).toString()
                                formattedDistance = formattedDistance.replace(
                                    '.',
                                    ','
                                )
                                formattedDistance = formattedDistance.substring(
                                    0,
                                    formattedDistance.indexOf(',') + 3
                                )
                                const formattedTour = {
                                    id: +tour.Id,
                                    tours_date: tour.Date,
                                    date: tour.date,
                                    result: tour.Result,
                                    steps_count: tour.NbSteps,
                                    tours_count: tour.NbTours,
                                    distance: formattedDistance,
                                    duration: formattedDuration,
                                    report: tour.Report,
                                    creation_date: tour.CreationDate,
                                }
                                tours.push(formattedTour)
                            })

                            this.all = tours
                            this.count = +res.data.TotalResults
                            this.totalPages = +res.data.TotalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        fetchOneTour(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/tourcalendar/${payload.id}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.unaffected = []
                        this.countCalendarUnaffected = null
                        this.fetchUnaffectedTours(payload.id)
                        this.current = res.data
                        const formattedTours = []
                        let colorIndex = 0
                        this.current.Name = new Date(
                            this.current.Date
                        ).toLocaleString(this.i18n.locale, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })

                        if (this.current.Tours) {
                            this.current.Tours.forEach((tour, index) => {
                                let vehicleTypeIcon = tour.TypeVehicle
                                if (tour.TypeVehicle === 'bike') {
                                    vehicleTypeIcon = 'person-biking'
                                }
                                if (tour.TypeVehicle === 'boat') {
                                    vehicleTypeIcon = 'ship'
                                }
                                if (formattedTours.length > 0) {
                                    const lastCard =
                                        formattedTours[
                                            formattedTours.length - 1
                                        ]
                                    const lastColorIndex = colors.indexOf(
                                        lastCard.color
                                    )
                                    colorIndex =
                                        lastColorIndex === colors.length - 1
                                            ? 0
                                            : lastColorIndex + 1
                                }
                                const newTour = {
                                    GeoJSON: JSON.parse(tour.GeoJSON),
                                    driver: {
                                        name: tour.Driver
                                            ? `${tour.Driver.FirstName} ${tour.Driver.LastName}`
                                            : '',
                                        id: +tour.IdDriver,
                                    },
                                    vehicle: {
                                        name: tour.Vehicle
                                            ? `${tour.Vehicle.Name}`
                                            : '',
                                        type: vehicleTypeIcon,
                                        id: +tour.IdVehicle,
                                    },
                                    co_driver: { id: +tour.IdCoDriver },
                                    start_hour_option: tour.StartHourOption,
                                    distance: tour.Distance,
                                    pts: `${tour.NbPoints} pts`,
                                    // weight: '34kg',
                                    duration: tour.Duration,

                                    id: +tour.Id,
                                    show: !!(
                                        payload.shownTours &&
                                        payload.shownTours.includes(+tour.Id)
                                    ),
                                    open: !!(
                                        payload.openedTours &&
                                        payload.openedTours.includes(+tour.Id)
                                    ),
                                    color: colors[colorIndex],
                                    steps: {
                                        infos: tour.TourDetails,
                                        list: `steps-tour-${index}`,
                                    },
                                }

                                formattedTours.push(newTour)
                            })
                            this.tours = formattedTours
                        }

                        // console.log(this.tours)
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        fetchDaily(payload) {
            return new Promise((resolve, reject) => {
                const now = new Date().toISOString().slice(0, 10)
                this.axios
                    .get(
                        `/api/v1/tourcalendar/search?query={"Query":[{"Date":[{"operator":"=","value":"${now}"}]}]}`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            this.dailyUnaffected = []
                            this.countDailyUnaffected = null

                            this.daily = res.data.Records[0]

                            // {
                            this.daily.Name = `${this.i18n.global.t(
                                'daily'
                            )}  ${new Date().toLocaleString(this.i18n.locale, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })}`
                            this.daily.DateBegin = now

                            this.daily.DateEnd = now
                            const formattedTours = []
                            let colorIndex = 0
                            if (this.daily.Tours) {
                                this.daily.Tours.forEach((tour, index) => {
                                    let vehicleTypeIcon = tour.TypeVehicle
                                    if (tour.TypeVehicle === 'bike') {
                                        vehicleTypeIcon = 'person-biking'
                                    }
                                    if (tour.TypeVehicle === 'boat') {
                                        vehicleTypeIcon = 'ship'
                                    }
                                    if (formattedTours.length > 0) {
                                        const lastCard =
                                            formattedTours[
                                                formattedTours.length - 1
                                            ]
                                        const lastColorIndex = colors.indexOf(
                                            lastCard.color
                                        )
                                        colorIndex =
                                            lastColorIndex === colors.length - 1
                                                ? 0
                                                : lastColorIndex + 1
                                    }
                                    const newTour = {
                                        GeoJSON: JSON.parse(tour.GeoJSON),
                                        driver: {
                                            name: tour.Driver
                                                ? `${tour.Driver.FirstName} ${tour.Driver.LastName}`
                                                : '',
                                            id: +tour.IdDriver,
                                        },
                                        vehicle: {
                                            name: tour.Vehicle
                                                ? `${tour.Vehicle.Name}`
                                                : '',
                                            type: vehicleTypeIcon,
                                            id: +tour.IdVehicle,
                                        },
                                        co_driver: { id: +tour.IdCoDriver },
                                        start_hour_option: tour.StartHourOption,
                                        distance: tour.Distance,
                                        pts: `${tour.NbPoints} pts`,
                                        // weight: '34kg',
                                        duration: tour.Duration,

                                        id: +tour.Id,
                                        show: !!(
                                            payload &&
                                            payload.shownTours &&
                                            payload.shownTours.includes(
                                                +tour.Id
                                            )
                                        ),
                                        open: !!(
                                            payload &&
                                            payload.openedTours &&
                                            payload.openedTours.includes(
                                                +tour.Id
                                            )
                                        ),
                                        color: colors[colorIndex],
                                        steps: {
                                            infos: tour.TourDetails,
                                            list: `steps-tour-${index}`,
                                        },
                                    }

                                    formattedTours.push(newTour)
                                })
                                this.dailyTours = formattedTours
                            }

                            this.fetchDailyUnaffected({
                                tourId: res.data.Records[0].Id,
                                shown: !!(
                                    payload &&
                                    payload.shownTours &&
                                    payload.shownTours.includes('unaffected')
                                ),
                                opened: !!(
                                    payload &&
                                    payload.openedTours &&
                                    payload.openedTours.includes('unaffected')
                                ),
                            })
                        } else {
                            console.log('no daily Oo')
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        fetchDailyUnaffected(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/tourcalendar/${payload.tourId}/unaffected`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status !== 204) {
                            this.dailyUnaffectedHasIncompleteSteps = false
                            res.data.forEach((unaffected) => {
                                if (
                                    !this.dailyUnaffected.includes(
                                        this.unaffected
                                    )
                                ) {
                                    if (
                                        this.dailyUnaffected.length === 0 ||
                                        this.dailyUnaffected[
                                            this.dailyUnaffected.length - 1
                                        ].IdOrder !== unaffected.IdOrder
                                    ) {
                                        if (!unaffected.length) {
                                            if (
                                                !unaffected.Latitude &&
                                                !unaffected.Longitude
                                            ) {
                                                this.dailyUnaffectedHasIncompleteSteps = true
                                                unaffected.incomplete = true
                                            }
                                        } else {
                                            unaffected.forEach((u) => {
                                                if (
                                                    !unaffected.Latitude &&
                                                    !unaffected.Longitude
                                                ) {
                                                    this.dailyUnaffectedHasIncompleteSteps = true

                                                    u.incomplete = true
                                                }
                                            })
                                        }
                                        this.dailyUnaffected.push(unaffected)
                                    } else if (
                                        this.dailyUnaffected[
                                            this.dailyUnaffected.length - 1
                                        ].IdOrder === unaffected.IdOrder
                                    ) {
                                        if (
                                            !unaffected.Latitude &&
                                            !unaffected.Longitude
                                        ) {
                                            this.dailyUnaffectedHasIncompleteSteps = true
                                            unaffected.incomplete = true
                                        }
                                        this.dailyUnaffected[
                                            this.dailyUnaffected.length - 1
                                        ] = [
                                            this.dailyUnaffected[
                                                this.dailyUnaffected.length - 1
                                            ],
                                            unaffected,
                                        ]
                                    }
                                }
                            })
                            this.showStoreUnaffected = payload.shown
                            this.openStoreUnaffected = payload.opened

                            this.countDailyUnaffected = res.data.length
                            this.daily.NbStepsUnaffected =
                                this.countDailyUnaffected
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        // err.toJSON()
                        // const error = {
                        //     data: {
                        //         status: err.response.status,
                        //         message: err.response.data.message,
                        //     },
                        // }
                        reject(err)
                    })
            })
        },
        // todo same as above
        fetchUnaffectedTours(tourId) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/tourcalendar/${tourId}/unaffected`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            this.tourUnaffectedHasIncompleteSteps = false
                            res.data.forEach((unaffected) => {
                                if (
                                    this.unaffected.length === 0 ||
                                    this.unaffected[this.unaffected.length - 1]
                                        .IdOrder !== unaffected.IdOrder
                                ) {
                                    if (
                                        !unaffected.Latitude &&
                                        !unaffected.Longitude
                                    ) {
                                        this.tourUnaffectedHasIncompleteSteps = true
                                        unaffected.hasIncompleteSteps = true
                                        unaffected.forEach((u) => {
                                            u.incomplete = true
                                        })
                                    }
                                    this.unaffected.push(unaffected)
                                } else if (
                                    this.unaffected[this.unaffected.length - 1]
                                        .IdOrder === unaffected.IdOrder
                                ) {
                                    if (
                                        !unaffected.Latitude &&
                                        !unaffected.Longitude
                                    ) {
                                        this.tourUnaffectedHasIncompleteSteps = true
                                        unaffected.incomplete = true
                                    }

                                    this.unaffected[
                                        this.unaffected.length - 1
                                    ] = [
                                        this.unaffected[
                                            this.unaffected.length - 1
                                        ],
                                        unaffected,
                                    ]
                                }
                            })
                            this.countCalendarUnaffected = res.data.length
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        addTourToDaily(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(
                        `/api/v1/tourcalendar/${payload.IdTour}/addTour`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        affectToTour(payload) {
            const formattedPayload = {
                IdDriver: payload.IdDriver,
                IdCoDriver: payload.IdCoDriver,
                IdVehicle: payload.IdVehicle,
                // Option: payload.Option,
                // Hour: payload.Hour,
                ModificationUserId: payload.ModificationUserId,
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .patch(`/api/v1/tour/${payload.IdTour}`, formattedPayload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        moveDailySteps(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/tourcalendar/${payload.IdTour}/moveOrderStep`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                        }
                    )
                    .then((res) => {
                        // this.current = null
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        delete(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/tourcalendar/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deleteDaily(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/tour/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
    },
})
export { useTourStore }
