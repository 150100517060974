import Wrapper from '../views/tours/Wrapper.vue'
import TourSimulationsList from '../views/tours/SimulationsList.vue'
import SimulationGeneration from '../views/tours/SimulationGeneration.vue'
import SimulationDetail from '../views/tours/SimulationDetail.vue'
import TourDetail from '../views/tours/TourDetail.vue'
import MapView from '../views/tours/MapView.vue'

const tours = [
    {
        path: '/tours',
        name: 'tours',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'TOURS_FULL', 'TOURS_LIST'],
        },

        children: [
            // {
            //     path: 'detail/:id',
            //     name: 'tour_detail',
            //     component: TourDetail,
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'TOURS_FULL'],

            //         // userStore.userRoles.includes('BIIIM_ADMIN') ||
            //         // userStore.userRoles.includes('AREA_CREATE'),
            //     },
            // },
            {
                path: 'view/:id',
                name: 'tour_view',
                component: MapView,
                meta: {
                    map: true,
                    isTour: true,
                    daily: false,
                    authorisations: [
                        'FULL_ACCESS',
                        'TOURS_FULL',
                        'TOURS_MAPVIEW',
                    ],
                },
            },
            {
                path: 'view/live',
                name: 'daily_view',
                component: MapView,
                meta: {
                    map: true,
                    isTour: false,
                    daily: true,
                    authorisations: [
                        'FULL_ACCESS',
                        'DAILY_FULL',
                        'DAILY_MAPVIEW',
                    ],
                },
            },
            {
                path: 'simulations',
                name: 'simulations',
                component: TourSimulationsList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SIMULATIONS_FULL',
                        'SIMULATIONS_LIST',
                    ],

                    // userStore.userRoles.includes('BIIIM_ADMIN') ||
                    // userStore.userRoles.includes('AREA_CREATE'),
                },
                children: [
                    {
                        path: 'generate_simulation',
                        name: 'simulation_generation',
                        component: SimulationGeneration,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'SIMULATIONS_FULL',
                                'SIMULATIONS_GENERATE',
                            ],

                            // userStore.userRoles.includes('BIIIM_ADMIN') ||
                            // userStore.userRoles.includes('AREA_CREATE'),
                        },
                    },
                    // {
                    //     path: 'detail/:id',
                    //     name: 'simulation_detail',
                    //     component: SimulationDetail,
                    //     meta: {
                    //         authorisations: ['FULL_ACCESS', 'SIMULATIONS_FULL'],

                    //         // userStore.userRoles.includes('BIIIM_ADMIN') ||
                    //         // userStore.userRoles.includes('AREA_CREATE'),
                    //     },
                    // },
                    {
                        path: 'view/:id',
                        name: 'simulation_view',
                        component: MapView,
                        meta: {
                            map: true,
                            authorisations: [
                                'FULL_ACCESS',
                                'SIMULATIONS_FULL',
                                'SIMULATIONS_MAPVIEW',
                            ],

                            isTour: false,
                            daily: false,
                        },
                    },
                ],
            },
        ],
    },
]

export default tours
