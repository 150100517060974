<template>
    <component :is="layout">
        <router-view />
    </component>
</template>
<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/userStore'

export default {
    computed: {
        ...mapStores(useUserStore),

        layout() {
            return this.$route.meta.layout || 'full-layout'
        },
    },
    created() {
        this.userStore.userFromStorage()
        if (this.userStore.current) {
            this.userStore.$patch({
                isB2C: this.userStore.current.type === 'client',
            })
        }
    },

    mounted() {
        const logoToUse =
            window.location.hostname === 'localhost' ||
            window.location.host.includes('dev') ||
            window.location.host.includes('preprod')
                ? 'biiimSmall'
                : window.location.host.includes('lium')
                ? 'lium'
                : window.location.host.substring(
                      0,
                      window.location.host.indexOf('.')
                  )
        this.userStore.$patch({ logo: logoToUse })
        this.$cookies.set('logo', logoToUse)
        const currentLocale =
            window.navigator.languages[0].length > 2
                ? window.navigator.languages[0].substring(0, 2)
                : window.navigator.languages[0]
        this.$cookies.set(
            'locale',
            this.$cookies.get('locale') ||
                currentLocale.charAt(0).toUpperCase() + currentLocale.slice(1)
        )
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css">
#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* max-width: 100vw; */
}
</style>
