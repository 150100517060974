const translations = {
    En: {
        password: 'Password',
        home: 'Home',
        En: 'En',
        Fr: 'Fr',
        login: 'Email address or login',
        email_address: 'Email address',
        pw_notice:
            'The password requires at least seven characters, an uppercase, a lowercase, a number and a special character. You must at least reach green to be able to validate your password.',
        my_account: 'My account',
        confirm_password: 'Confirm password',
        firstname: 'Firstname',
        lastname: 'Lastname',
        phone: 'Phone number',
        logout: 'Logout',
        notifications: 'Notifications',
        open_menu: 'Open menu',
        forgot_password: 'Forgot password ?',
        connect: 'Log In',
        show: 'Show',
        destination: 'Destination',
        origin: 'Origin',
        hide: 'Hide',
        nightmode: 'Night mode',
        lang_switch: 'Switch language',
        company_name: 'Company name',
        address: 'Address',
        postal_code: 'Postal code',
        city: 'City',
        registration_number: 'Registration number',
        client_type: 'Client type',
        payment_type: 'Payment type',
        search: 'Search',
        new_client: 'New client',
        clients: 'Clients',
        client: 'Client',
        billings: 'Billings',
        vehicles: 'Vehicles',
        orders: 'Orders | Deliveries',
        becycle_orders: 'Orders | Deliveries',

        new_order: 'New order | Plan my delivery',
        import_orders: 'Import orders from file',
        imported_orders: 'Imported orders',
        tours: 'Tours',
        suppliers: 'Suppliers',
        areas: 'Areas',
        users: 'Users',
        drivers: 'Drivers',
        settings: 'Settings',
        login_error: 'Incorrect login or password',
        delivery_addresses: 'Addresses',
        mass_update: 'Mass updates',
        loading: 'Loading...',
        name: 'Name',
        missing_password: 'Password required.',
        missing_login: 'Email address or login required.',
        reference: 'Reference',
        full_order: 'Check order',
        update_ref_address: 'Apply changes to the reference postal address',
        number_of_parcels: 'Number of parcels',
        delivery_mode: 'Delivery mode',
        id: 'Id',
        weight: 'Weight (kg)',
        unit_weight: 'Unit weight (kg)',
        worth: 'Worth (€)',
        driver: 'Driver',
        reset_pw_ok:
            'If the account entered is valid, an email will be sent with instructions to update the password.',
        reset_pw_instructions:
            'The password reset instructions will be sent to your registered email address.',
        reset_pw_modal_title: 'Reset your password',
        send: 'Send',
        cancel: 'Cancel',
        creation_date: 'Creation date',
        modification_date: 'Update date',

        contact_information: 'Contact information',
        client_information: 'Client information',
        driver_information: 'Personnal informations',
        work_information: 'Work informations',

        password_strength: {
            start: 'Start typing to check your password strength',
            weak: 'Weak',
            better: 'Still weak, keep on trying!',
            nice: 'We are getting there...',
            good: 'Nice, but you can still do better',
            perfect: 'Congratulations, you made it!',
        },
        passwords_dont_match: "Passwords don't match",
        client_create_ok: 'Client successfully created',
        client_create_ko: 'Client creation failed ',
        search_address:
            'Search for address. Address fields will automatically be filled.',
        no_records_found: 'No records found',
        order: 'Sort order',
        sort_by: 'Sort by',
        items_per_page: 'Records per page',
        voucher_book: 'Voucher book',
        voucherbook: 'Voucher book',
        occasionnal: 'Occasionnal',
        month_invoice: 'Month invoice',
        monthinvoice: 'Month invoice',
        filters: 'Filters',
        notselected: 'Not selected',
        check: 'Check',
        by_name: 'by name',
        page: 'Page',
        of: 'of',
        results: '{n} Result | {n} Results',
        no_filter: 'No filter',
        steps: 'Steps',
        step: 'Step',

        status: 'Status',
        in_progress: 'In progress',
        finished: 'Finished',
        uploaded: 'Uploaded',
        inProgress: 'In progress',
        errors: 'Errors',
        by_client: 'by client',
        by_reference: 'by reference',
        delivery_type: {
            express: 'Express',
            fast: 'Fast',
            normal: 'Normal',
        },
        express: 'Express',
        fast: 'Fast',
        normal: 'Normal',
        delivery_status: {
            in_progress: 'In progress',
            finished: 'Finished',
            errors: 'Errors',
        },
        uploads: {
            in_progress: 'In progress',
            finished: 'Finished',
            uploaded: 'Uploaded',
            errors: 'Errors',
        },
        simulation_status: {
            errors: 'Errors',
            archived: 'Archived',
            requested: 'Requested',
            in_progress: 'In progress',
            finished: 'Finished',
        },
        delete: 'Delete',
        edit: 'Edit',
        action: 'Actions',
        search_name: 'Name',
        all: 'All',
        transfer: 'Transfer',
        to: 'to',
        optionnal: 'optionnal',
        client_update_ok: 'Client successfuly updated',
        client_update_ko: 'Client update failed ',
        address_update_ok: 'Address successfuly updated',
        address_update_ko: 'Address update failed ',
        address_create_ok: 'Address successfuly created',
        address_create_ko: 'Address creation failed ',
        client_delete_ok: 'Client successfuly deleted',
        client_delete_ko: 'Client deletion failed',
        user_delete_ok: 'User successfuly deleted',
        user_delete_ko: 'User deletion failed',
        address_delete_ok: 'Delivery address successfuly deleted',
        address_delete_ko: 'Delivery address deletion failed',
        select_one: 'Select one',
        update_client: 'Client update',
        contacts: 'Contacts',
        new_item: 'Create',
        delivery_addresses_for: 'Delivery adresses for',
        for: 'for',
        update_address: 'Address update',
        contact_and_wait: 'Contact and waiting time',
        delivery_hours: 'Delivery hours',
        driver_info: 'Driver information',
        additional_info: 'Additional information',
        waiting_time: 'Waiting time',
        contact_name: 'Contact name',
        contact_name_imported: 'Imported contact name',
        phone_imported: 'Imported phone',
        waiting_time_info:
            'The waiting time is expressed in minutes. The default value is 5 minutes.',
        opening_time: 'Opening time',
        closing_time: 'Closing time',
        open: 'Open',
        closed: 'Closed',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        delete_selection: 'Delete selected elements',
        save: 'Save',
        business_hour_updated: 'Business hours updated',
        business_hour_update_error: 'Error when updating business hours',
        order_step_correction: 'Delivery addresses correction',
        update_order: 'Order update',
        order_step: 'Order step',
        generate_invoice: 'Generate invoice',
        inprogress: 'In progress',
        new_address: 'New delivery address',
        address_type: 'Address type',
        back: 'Back',
        bad_zip_format: 'Bad postal code format',
        bad_phone_format: 'Bad phone number format',
        bad_time_format: 'Bad time format',
        bad_hour_format: 'Bad hour format',
        bad_date_format: 'Bad date format',
        select_client_info: 'Please select a client',
        select_driver_info: 'Please select a driver',
        select_contact_info: 'Please select a contact',
        select_vehicle_info: 'Please select a vehicle',
        required_field: 'Value is required',
        weekly_planning: 'Weekly planning',
        reference_postal_address: 'Reference postal address',
        no_ref_address: 'No reference postal address found',
        incomplete_order: 'Incomplete order',
        add_contact: 'Add contact',
        new_contact: 'New contact',
        update_contact: 'Update contact',
        order_delete_ok: 'Order successfuly deleted',
        order_delete_ko: 'Order deletion failed',
        delivery_delete_ok: 'Delivery successfuly deleted',
        delivery_delete_ko: 'Delivery deletion failed',
        vehicle_type: 'Vehicle type',
        vehicleType: 'Vehicle type',
        price_charged: 'Charged price',
        add_steps: 'Add steps',
        add_step: 'Add step',

        contact: 'Contact',
        packages: 'Packages',
        OrderPackages: 'Packages',
        package: 'Package',

        references: 'References',
        comments: 'Comments',
        number: 'Number',
        package_type: 'Package type',
        width: 'Width (cm)',
        length: 'Length (cm)',
        height: 'Height (cm)',
        package_add_not_allowed: 'Add a step before adding a package',
        between: 'Between',
        from: 'From',
        before: 'Before',
        pick_up_label: 'Pick up',
        pick_up: 'Pick up on',

        delivery_label: 'Delivery',
        delivery: 'Delivery on',

        on: 'On',
        bad_time_range:
            'The value of the first time selected must be before the second one. ',
        can_select_address: 'Pick an existing address or fill in form',
        can_select_address_v1: 'Pick an address',
        order_create_ok: 'Order successfully created',
        order_create_ko: 'Order creation failed ',
        order_update_ok: 'Order successfuly updated',
        order_update_ko: 'Order update failed',
        delivery_create_ok: 'Delivery successfully created',
        delivery_create_ko: 'Delivery creation failed ',
        delivery_update_ok: 'Delivery successfuly updated',
        delivery_update_ko: 'Delivery update failed',
        and: 'and',
        form_errors: 'Form contains errors',
        check_last_package: 'The last package you entered is not valid',
        bad_packages: 'Some packages are not complete',
        package_reference_missing: 'Package reference is required.',
        package_type_missing: 'Pick a package type.',
        simulations: 'Simulations',
        steps_count: 'Orders count',
        tours_count: 'Tours count',
        distance: 'Distance (km)',
        distance_nokm: 'Distance',
        duration: 'Duration',
        not_affected: 'Unaffected step | Unaffected steps',
        report: 'Report',
        new_driver: 'New driver',
        update_driver: 'Update driver',
        driver_update_ok: 'driver successfuly updated',
        driver_update_ko: 'driver update failed',
        driver_create_ok: 'driver successfully created',
        driver_create_ko: 'driver creation failed ',
        driver_delete_ok: 'driver successfully deleted',
        driver_delete_ko: 'driver deletion failed ',
        tour_delete_ok: 'Tour successfully deleted',
        tour_delete_ko: 'Tour deletion failed ',
        localisation: 'Localisation',
        address_name: 'Address name',
        phone_pro: 'Professional phone',
        phone_perso: 'Personnal phone',
        emergency_contact: 'Emergency contact',
        phone_secondary: 'Secondary phone',
        area: 'Area',
        vehicle: 'Vehicle',
        mobile_account: 'Mobile app account',
        select_account: 'Pick a user ',
        select_vehicle: 'Pick a vehicle',
        select_area: 'Pick an area',
        supplier: 'Supplier',
        select_supplier: 'Pick a supplier',
        subcontractor: 'Subcontractor',
        start_end_pos: 'Start and end locations',
        last_known_pos: 'Last known location',
        start: 'Start',
        end: 'End',
        latitude: 'Latitude',
        longitude: 'Longitude',
        mobile: 'Mobile phone',
        contact_update_ok: 'Contact successfuly updated',
        contact_update_ko: 'Contact update failed',
        contact_create_ok: 'Contact successfuly created',
        contact_create_ko: 'Contact creation failed',
        contact_delete_ok: 'Contact successfuly deleted',
        contact_delete_ko: 'Contact deletion failed',
        no_contact_found_for_client: 'No contact found for this client',
        new_simulation: 'New simulation',
        params: 'Parameters',
        requirements: 'Requirements',
        generate: 'Generate',
        tour_simulation: 'Tour simulation',
        date: 'Date',
        simulation_name: 'Simulation name',
        drivers_requirement: "Take driver's schedule into account",
        vehicles_requirement: 'Take vehicles into account',
        areas_requirement: 'Take areas into account',
        co2_requirement: 'Take CO2 consumption into account',
        price_requirement: 'Take delivery price into account',
        simulation_delete_ok: 'Simulation successfuly deleted',
        simulation_delete_ko: 'Simulation deletion failed',
        vehicle_types: 'Vehicle types',
        registration: 'Registration / serial number',
        brand: 'Brand',
        model: 'Model',
        update_vehicle: 'Vehicle update',
        update_vehicle_type: 'Vehicle type update',
        powered: 'Powered by',

        new_vehicle: 'New vehicle',
        new_vehicle_type: 'New vehicle type',

        vehicle_information: 'Vehicle informations',
        select_type: 'Pick a vehicle type',
        vehicle_delete_ok: 'Vehicle successfuly deleted',
        vehicle_delete_ko: 'Vehicle deletion failed',
        vehicle_update_ok: 'Vehicle successfuly updated',
        vehicle_update_ko: 'Vehicle update failed',
        vehicle_create_ok: 'Vehicle successfuly created',
        vehicle_create_ko: 'Vehicle creation failed',
        associated_driver: 'Associated driver',
        select_driver: 'Pick a driver',
        vehicle_specifications: 'Vehicle specifications',
        package_specifications: 'Packages specifications',
        volume: 'Vehicle volume (m³)',
        max_volume: 'Max. volume capacity (%)',
        volume_info:
            'In m³ (Set the value to 0 on Width - Length - Height to enter the volume manually)',
        in_percent: 'In %',
        max_load: 'Maximum load (kg)',
        package_max_height: 'Package maximum height (cm)',
        package_max_length: 'Package max. length (cm)',
        package_max_width: 'Package max. width (cm)',

        add_maintenance: 'Add maintenance',
        update_maintenance: 'Update maintenance',

        maintenance: 'maintenance | Maintenances',
        start_date: 'Start date',
        end_date: 'End date',
        informations: 'Informations',
        title: 'Title',
        maintenance_create_ok: 'Maintenance successfuly added',
        maintenance_create_ko: 'Maintenance creation failed',
        maintenance_delete_ok: 'Maintenance successfuly deleted',
        maintenance_delete_ko: 'Maintenance deletion failed',
        active: 'Active',
        deactivated: 'Not active',
        deactivate: 'Deactivate',
        activate: 'Activate',

        deactivate_selection: 'Deactivate selected elements',
        driver_deactivate_ok: 'Driver successfuly deactivated',
        driver_deactivate_ko: 'Driver deactivation failed',
        driver_activate_ok: 'Driver successfuly activated',
        driver_activate_ko: 'Driver activation failed',
        container_deactivate_ok: 'Package format successfuly deactivated',
        container_deactivate_ko: 'Package format deactivation failed',
        container_activate_ok: 'Package format successfuly activated',
        container_activate_ko: 'Package format activation failed',
        vehicle_deactivate_ok: 'Vehicle successfuly deactivated',
        vehicle_deactivate_ko: 'Vehicle deactivation failed',
        vehicle_activate_ok: 'Vehicle successfuly activated',
        vehicle_activate_ko: 'Vehicle activation failed',
        refresh: 'Refresh',
        planning: 'Planning',
        driver_planning: "Driver's planning -",
        planning_create_ok: 'Planning created',
        planning_create_ko: 'Planning creation failed',
        simulation_generation_ok: 'Simulation generation launched',
        simulation_generation_ko: 'Simulation generation was not launched',
        morning: 'Morning',
        afternoon: 'Afternoon',
        add: 'Add',
        planning_update_ok: "Driver's planning successfully updated",
        planning_update_ko: "Driver's planning update failed",
        tours_date: 'Tours',
        grouping: 'Grouping of deliveries',
        ref_address_update_ok: 'Reference address successfully updated',
        ref_address_update_ko: 'Reference address update failed',
        fix_lat_lng_info_1:
            'Move the "?" marker on the map  or refill the address 1 field to correct the GPS coordinates of the order step.',
        fix_lat_lng_info_2:
            'Additional address information can be entered in the address 2 field.',
        step_needs_correction: 'Click to update this step',
        relaunch: 'Relaunch',
        relaunch_selection: 'Relaunch generation for selection',
        simulation_merge_ok: 'Simulations merge successfuly launched',
        simulation_merge_ko: 'Simulations merge failed',
        file: 'File',
        lines_to_import: 'Lines to import',
        imported_lines: 'Imported lines',
        order_file_title: 'Orders file -',
        download: 'Download',
        new_area: 'New area',
        update_area: 'Update area -',
        area_type: 'Area type',
        area_by_bike: 'Bicycle delivery zone',
        area_error: 'Please modify the area to save.',
        area_create_ok: 'Area successfully created',
        area_create_ko: 'Area creation failed',
        area_update_ok: 'Area successfully updated',
        area_update_ko: 'Area update failed',
        area_deactivate_ok: 'Area successfuly deactivated',
        area_deactivate_ko: 'Area deactivation failed',
        area_activate_ok: 'Area successfuly activated',
        area_activate_ko: 'Area activation failed',
        activate_area: 'Active area',
        activate_vehicle_type: 'Active vehicle',

        activate_driver: 'Active driver',
        deactivated_area: 'Deactivated area',
        new_supplier: 'New supplier',
        update_supplier: 'Supplier update',
        supplier_create_ok: 'Supplier successfully created',
        supplier_create_ko: 'Supplier creation failed',
        supplier_update_ok: 'Supplier successfully updated',
        supplier_update_ko: 'Supplier update failed',
        billing: 'Billing',
        postal_address: 'Postal address',
        software_type: "Orders' software type",
        api_url: 'API URL',
        login_only: 'Login',
        pw_reset: 'password reset',
        prov_of_service: 'Provision of services : Hourly rate',
        eur_per_h: '€ / h',
        eur_per_km: '€ / km',
        eur_per_step: '€ / delivery point',
        pricing_per_km: 'Pricing per kilometer',
        pricing_per_tour: 'Tour: Cost of the delivery point',
        user_update_ok: 'Personnal informations successfully updated',
        user_update_ko: 'Personnal informations update failed',
        pricing: 'Pricing',
        tracking: 'Track package',
        tracking_all: 'Track packages',

        print_label: 'Print package label',
        print_all_labels: 'Print all labels',

        account_informations: 'User credentials',
        personnal_infos: 'Postal informations',
        sign_up: 'Create account',
        email: 'Email address',
        welcome: 'Welcome',
        user_create_ko: 'Account creation failed',
        user_create_ok: 'Account successfully created',
        email_used_for_login: 'The email you will use to login to your account',

        next: 'Next',
        // stop_tour: 'Stop tour',
        my_addresses: 'My addreses',
        my_orders: 'My deliveries',
        my_bills: 'My bills',
        shepherd: {
            final_button: 'Finish',
            no_thanks: 'No thanks',
            ok_go: 'Ok give me a tour !',
            restart_tour: 'Relaunch tour',
            stop_tour: 'Stop tour',
            create_account: {
                start: {
                    title: 'Welcome',
                    text: 'Here is a tutorial to help you create an account. This account will allow you to create new orders and track their delivery in real time.',
                },
                step_1: {
                    title: 'Dark mode',
                    text: 'This button allows you to switch to dark mode. This mode displays your home page, toolbar, settings and some other pages in dark tones.',
                },
                step_2: {
                    title: 'Switch language',
                    text: 'This button allows you to change the language in which the site will be displayed.',
                },
                step_3: {
                    title: 'Relaunch guide',
                    text: 'This button allows you to restart the tutorial for the current page.',
                },
                step_4: {
                    title: 'Login',
                    text: 'This Email field will be used as your login when you connect to the application. It is this email that will be used to receive notifications on the progress of your deliveries.',
                },
                step_5: {
                    title: 'Password check',
                    text: 'This button makes the current password visible.',
                },
                step_6: {
                    title: 'Password strength',
                    text: 'This gauge tells you the strength of the password you want to use. You will need at least 8 characters, 1 Capital letter, 1 Number and 1 Special character (!,*,?,%, ...).',
                },
                step_7: {
                    title: 'Company name',
                    text: 'Enter your company name here. If you leave this field blank, it will automatically be filled with your first and last names.',
                },
                step_8: {
                    title: 'Billing address',
                    text: 'Enter your billing address here. Start typing a few characters, and the engine will give you address suggestions. You can also ignore these suggestions and type in your address manually.',
                },
                step_9: {
                    title: 'Cancel account creation',
                    text: 'This button will take you back to the login screen.',
                },
                step_10: {
                    title: 'Create my account',
                    text: 'This button allows you to validate your account creation request. Once your account is created you will receive an email to validate your email address.',
                },
            },
        },
        bill_number: 'Invoice number',
        end_month: 'End of month invoice',
        bad_credentials: 'Wrong login or password',
        user_already_exists: 'A user already exists with these credentials',
        weekly_planning_management: 'Weekly planning management',
        today: 'Today',
        new_order_B2C: 'Plan my delivery',
        update_order_B2C: 'Update my delivery',
        duplicate: 'Duplicate',
        add_package: 'Add package',
        update_package: 'Update package',

        package_info: 'Package informations',
        price: 'My price',
        lxwxh: '(L x W x H)',
        value: 'Value',
        container_type: 'Container type (optionnal)',
        no_temp_specified: 'No temperature specified for this package',
        frozen: 'This package must be processed at a temperature lower than -18°',
        hot: 'This package must be processed at a temperature higher than 30°',
        cool: 'This package must be processed at a temperature between 0° and 6°',
        temperature: 'Temperature',
        no_address_selected: 'No delivery address selected yet',
        no_package_selected: 'No package format selected yet',

        pallet: 'Pallet | Pallets',
        half_pallet: 'Half pallet | Half pallets',
        flat_wine_box: 'Flat wine box (6 btls) | Flat wine boxes (6 btls)',
        wine_box_down: 'Side wine box (6 btls) | Side wine boxes (6 btls)',
        wine_box_up: 'Up wine box (6 btls) | Up wine boxes (6 btls)',
        standard_cheese_box: 'Standard cheese box | Standard cheese boxes',
        select_package_format: 'Select a package format',
        gros_colis: 'Big Package',
        quantity: 'Quantity',
        no_package_type_error: 'Please select a package format.',
        update_and_copy: 'Update and create identical package',
        save_and_copy: 'Save and create identical package',

        create_address: 'I do not find my delivery address',
        create_container: 'I do not find my package format',
        create_my_address: 'Delivery address creation',
        create_my_container: 'Create custom package format',
        back_to_predefined_addresses: 'Go back to preexisting addresses',
        back_to_predefined_containers: 'Go back to preexisting package formats',
        package_delete_ok: 'Package successfuly deleted',
        package_delete_ko: 'Package deletion failed',
        package_name: 'Package format name',
        description: 'Description',
        value_required: 'This field cannot be blank',
        containers: 'Package formats',
        my_containers: 'My package formats',
        package_format_delete_ok: 'Package format successfuly deleted',
        package_format_delete_ko: 'Package format deletion failed',
        size: 'Size (L x W x H)',
        new_container: 'New package format',
        update_container: 'Update package format',
        container_update_ok: 'Package format successfuly updated',
        container_update_ko: 'Package format update failed',
        container_create_ok: 'Package format successfully created',
        container_create_ko: 'Package format creation failed',
        container_not_editable: 'This package format is not editable',
        send_rp_link: 'Send reset password link',
        operator: 'Operator',
        default_header: 'Title',
        contact_support:
            'Please contact BiiimSolutions support for more informations.',
        package_format_not_found: 'Package format cannot be found',
        no_driver_selected: 'No driver selected',
        no_vehicle_type_selected: 'No vehicle type selected',
        update: 'Update',
        create: 'Create',
        type: 'Type',
        consumption: 'consumption',
        annual_cost: 'Annual cost',
        legs: 'Legs',
        gas: 'Gas',
        gasoline: 'Gasoline',
        diesel: 'Diesel',
        electric: 'Electricity',
        hybrid_gasoline: 'Hybrid gasoline',
        hybrid_diesel: 'Hybrid diesel',
        plugin_hybrid_gasoline: 'Plugin hybrid gasoline',
        plugin_hybrid_diesel: 'Plugin hybrid diesel',
        bike: 'Bike',
        cargo_bike: 'Cargo bike',
        cargoBike: 'Cargo bike',
        deliveryTricycle: 'Delivery Tricycle',
        delivery_tricycle: 'Delivery Tricycle',
        car: 'Car',
        van: 'Van',
        boat: 'Boat',
        truck: 'Truck',
        not_specified: 'Not specified',
        delivery_name: 'My delivery name',
        delivery_title: 'Delivery',
        delivery_name_info:
            'Give a name to your delivery to find it more easily!',
        delivery_name_info_operator:
            'Give a name to your delivery for your client to find it more easily!',
        affected: 'Step affected to a tour',
        new: 'Step not affected to a tour yet',
        executed: 'Finished',
        affectationError: 'Affectation error',
        no_status_found: 'Undefined status for this step',
        steps_errors: 'Some steps are incomplete',
        finished_steps: 'Finished steps',
        delivery_start_end: 'Pickup & Delivery',
        break: 'Break',
        hydrogen: 'Hydrogen',
        client_ref: 'Client reference',
        last_step_finished: 'Package delivered',
        delivery_finished: 'Delivered',
        delivery_new: 'Waiting to be taken in charge',

        edit_activity: 'Edit activity',
        new_activity: 'Create activity',

        start_time: 'Start time',
        end_time: 'End time',
        pick_color: 'Pick a color',
        short_from: 'From',
        short_before: 'To',
        operator_ref: 'Delivery reference',
        copy_to_clipboard: 'Copy to clipboard',
        copied_to_clipboard: 'Copied to clipboard',
        activity_create_ko: 'Error while creating activity',
        atwork: 'Work ',
        off_paid: 'Off in contract (calculated in worked hours)',
        off_not_paid: 'Off out of contract (not calculated in worked hours)',

        activity_type: 'Activity type',
        show_disabled_activities: 'Show disabled activities',
        previous: 'Previous',
        update_user: 'Update user',
        new_user: 'Create user',
        create_and_send_mail: 'Create and send password setting link to user',
        user_type: 'User type',
        own_account_btn: 'I will be using this account',
        for_other_account_btn: 'This account is for someone else',
        create_matching_driver: 'Create matching driver',
        create_matching_client: 'Create matching client',

        do_not_create_matching_driver: 'Do not create matching driver',
        do_not_create_matching_client: 'Do not create matching client',
        create_user_account: 'Create mobile app and website account',
        or: 'Or',
        create_user_rp_info:
            "An email will be send to this email address to set account's password",
        contract_hour: 'Contract hour',
        week: 'Week',
        PDF: 'PDF file',
        map: 'Map',
        packages_status: 'Package status| Packages statuses',
        orders_settings: 'Deliveries settings',
        notifications_settings: 'Notifications settings',
        billings_settings: 'Billings settings',
        status_subtext_pt_1:
            'You can here set the packages statuses you need and that will be displayed to your clients.',
        status_subtext_pt_2:
            'For example, you can create a specific status for when your client creates a delivery and you have not collected the package yet : "Not picked up" or "Awaiting pick up".',
        status_subtext_pt_3:
            'You can also create specific refused delivery statuses : "Refused torn package", "Refused cold chain integrity compromised".',

        new_package_status: 'New package status',
        new_authorisation: 'New authorisation',
        new_role: 'New role',

        status_name: 'Status name',
        status_name_info:
            'Text that will be displayed to your client and your lists',
        deleted_ok: 'Successfull deletion',
        deleted_ko: 'Deletion failed',
        create_ok: 'Successfull creation',
        create_ko: 'Creation failed',
        update_ok: 'Successfull update',
        update_ko: 'Update failed',
        update_package_status: 'Update package status',
        code_already_exists: 'The code you picked is already used',
        status_code: 'Status code',
        auth_code: 'Authorisation code',
        role_code: 'Role code',

        code: 'Code',
        error_occured: 'An error occured.',
        overlap_modal_header: 'Overlapping activities',
        overlap_modal_button: 'Continue adding activity',
        overlap_activity:
            'The activity you want to add has schedules that overlap with an existing activity.',
        overlap_activity2: 'Do you want to add it anyway ?',
        action_canceled: 'Action canceled by user',
        status_code_not_editable: 'Status code is not editable',
        auth_code_not_editable: 'Authorisation code is not editable',
        role_code_not_editable: 'Role code is not editable',

        pin_drag_info: 'Click on upper panel driver to reassign this step.',
        upload_file: 'Add file | Add files',
        file_type: 'File type',
        wrong_file_type: ' is not a csv file, therefore cannot be uploaded.',
        error: 'Error',
        active_file_drop_zone_1: 'Drop them here',
        active_file_drop_zone_2: ' to add them',
        inactive_file_drop_zone_1:
            'Drag your files here or click the drop zone to select them.',
        new_file_type: 'New orders file type',
        file_type_name: 'File type name',
        create_new_file_type: 'New file type',
        validate_simulation: 'Validate simulation',
        add_tour: 'Add tour',
        sim_validated_ok: 'Simulation successfully validated',
        sim_validated_ko: 'An error occured during simulation validation',
        assign_driver: 'Assign driver',
        assign_other_driver: 'Assign another driver',
        assign_vehicle: 'Assign vehicle',
        change_start_time: 'Change start time',
        time_determined_by_software:
            'Time schedule determined by the software.',
        time_determined_by_driver: 'Time schedule determined by the driver.',
        time_determined_custom:
            'Custom time schedule (select time here below).',
        end_with: 'End tour with this step',
        start_with: 'Start tour with this step',
        B2B: 'B2B',
        B2C: 'B2C',
        required_fields: 'Fields with * are required.',
        map_loading: 'Loading map',
        save_and_relaunch: 'Save and recalculate',
        recalculation_in_progress: 'Tours optimisation in progress',
        daily_view: "Live Today's tours",
        date_error_before:
            "Invalid date : chosen date is before previous step's date or in the past. | Invalid date : chosen date is before pick up date. ",
        date_error_after:
            "Invalid date : chosen date is after next step's date. | Invalid date : chosen date is after delivery date.",
        past_date: 'Invalid date: chosen date is in the past or malformed.',
        my_package_format: 'This package format belongs to me',
        mine_only: 'Mine only',
        global_only: 'Globals only',

        global_pack_format: 'Global package format',
        belongs_to_other_user: 'This package format belongs to another user',
        hour: 'hour | hours',
        day: 'day | days',
        minute: 'minute | minutes',
        daily: 'Daily live',
        pickup: 'Pick up',
        old_map: 'Old Map',
        package_format: 'package formats',
        error_while_loading: 'Error while loading ',
        show_today_only: 'Today',
        delete_many_ok: 'Elements successfully deleted',
        delete_many_ko: 'An error occured while deleting elements',
        no_available_for_date: 'No result for chosen date',
        undefined_address: 'Undefined address',
        trackings: 'Packages trackings',
        trackings_documents: 'Documents',
        details: 'Detail',
        tracking_step: 'Tracking step',
        delivery_code: 'Delivery code',
        package_reference: 'Package reference',
        completion_date: 'Completion date',
        transit: 'Transit',
        // pickup: 'Pick up',
        redelivery: 'Redelivery',
        collect: 'Collect',
        pictures: 'Pictures',
        signatures: 'Signatures',
        // delivery: 'Delivery',
        actions: 'Actions',
        view_file: 'View file',
        no_tracking_data_available: 'No tracking data available yet',
        trackings_and_proofs: 'Package tracking & documents',
        external_ref: 'External reference',
        unaffect: 'Remove from tour',
        steps_coordinates_errors_1: 'Some steps are not',
        steps_coordinates_errors_2: ' properly geolocated.',
        step_coordinates_error: 'Not properly geolocated',
        solo_step: 'Orphan step',
        check_order: 'Please check order',
        incomplete_step_not_affectable:
            'This step cannot be affected to a tour',
        hide_show_google_pois: 'Hide / show Google points of interest',
        create_container_for_all: 'Create package format for all clients',
        all_clients: 'Glocal package format for all clients',
        delete_tour_header: 'Tour deletion',
        confirm: 'Confirm',
        delete_tour_text: 'Are you sure you want to delete this tour ? ',
        unaffect_step_header: 'Unasign step',
        unaffect_step_text:
            'Are you sure you want to unasign this step from tour ? ',
        regular_activity: 'Regular',
        contract_hours: 'Contract hours',
        weekly_hours: 'Weekly total',
        hours_done: 'Effective hours',
        hours_supp: 'Overtime',
        hours_miss: 'Missing hours',
        merge: 'Merge',
        no_delivery_name: 'No delivery name',

        distance_last_step: 'Distance from previous step',
        duration_last_step: 'Duration from previous step',
        dur_n_dist_last_step: 'Distance & duration from previous step',

        bad_time_range_steps:
            'Chosen delivery time is incompatible with pick up chosen time',
        bad_time_range_steps_2:
            'Chosen pick up time is incompatible with delivery chosen time',
        ms_status: 'Status | Statuses',
        ms_option: 'Option | Options',
        force_finish: 'Switch step to "finished"',
        force_inprogress: 'Switch step to "in progress"',
        authorisations: 'Authorisations',
        translations: 'Translations',
        biiim_admin_interface: 'Biiim admin interface',
        update_authorisation: 'Update authorisation',
        update_role: 'Update role',
        logs: 'Logs',
        google_api_use: 'Google API use',
        Name: 'Name',
        Description: 'Description',
        ModificationDate: 'Modification date',
        Id: 'Id',
        HttpCode: 'HTTP code',
        EntityObject: 'Entity object',
        EntityName: 'Entity name',
        Function: 'Function',
        Response: 'Response',
        mail_templates: 'Email templates',
        roles: 'Roles',
        is_used_modal_header: 'Activity is used in a schedule',
        is_used_modal_button_update_all: 'Update activity and schedules',
        is_used_activity:
            'This activity is already used in one or more schedules.',
        is_used_activity2:
            'Would you like to update this activity and all existing activities in schedules,',
        is_used_activity3:
            'or create a new one that will not affect existing schedules ?',
        create_new_activity: 'Create new activity',
        is_used_container_modal_header: 'Package format is used in an order',
        is_used_container_modal_button_update_all:
            'Update package format and orders',
        is_used_container:
            'This package format is already used in one or more orders.',
        is_used_container2:
            'Would you like to update this package format and all existing package formats in orders,',
        is_used_container3:
            'or create a new one that will not affect existing orders ?',
        create_new_container: 'Create new package format',
        add_to_role: 'Add to role',
        search_by_name: 'By name',
        search_by_code: 'By code',

        search_role: 'Search roles',
        role_already_has_auth: 'This role already has this authorisation',
        action_not_allowed: 'Action not allowed',
        add_to_roles: 'Add to roles',
        add_roles: ' Add role to users | Add roles to users',
        admin: 'Admin',
        super_admin: 'SuperAdmin',

        add_role_to_users: 'Add role to users',

        selected_users: 'user selected | users selected',
        selected_roles: 'role selected | roles selected',

        roles_list: 'Users roles',
        remove: 'Remove',
        add_authorisations: 'Add authorisations to roles',
        already_selected_info:
            'Selection by user type will not remove already selected users',
        operators: 'Operators',
        admins: 'Administrators',
        visitors: 'Visitors',
        add_roles_to_user: 'Add roles to user',
        not_authorised:
            'You do not have the authorisation to access this resource.',
        opening_hours: 'Opening hours',
        delete_modal_generic_header: ' Deletion confirmation',
        delete_modal_generic_text:
            'Are you sure you want to delete this element ?',
        delete_modal_generic_text_2:
            'All depending elements will be updated (users, authorisations, roles).',

        role: 'Role',
        user: 'User',
        authorisation: 'Authorisation',
        estimated: 'Estimated',
        real: 'Real',
        software_calculated_time: 'Software calculated delivery time',
        software_calculated_time_new:
            'Updated software calculated delivery time',

        real_deliv_time: 'Real delivery time',
        picked_up: 'Package picked up',
        new_address_generic: 'New address',
        previous_step_incomplete: 'Previous step is incomplete',
        same_address: 'Pick up and delivery have the same address',
        delete_steps_pair_info:
            'The pick up / delivery pair will be removed from the order.',
        delete_all_unsaved_steps:
            'Are you sure you want to delete all unsaved steps ?',
        delete_all_unsaved_steps_info:
            'To remove only a specific pick up / delivery pair, click on the trash can on top of it.',
        update_and_copy_order: 'Update and create identical order',
        save_and_copy_order: 'Save and create identical order',
    },
    Fr: {
        update_and_copy_order: 'Mettre à jour et créer une commande similaire',
        save_and_copy_order: 'Sauvegarder et créer une commande similaire',
        delete_steps_pair_info:
            'Le couple enlèvement / livraison sera supprimé de la commande.',
        delete_all_unsaved_steps:
            'Êtes vous sûr(e) de vouloir supprimer toutes les étapes non sauvegardées ?',
        delete_all_unsaved_steps_info:
            'Pour supprimer uniquement un couple enlèvement / livraison, cliquez sur la corbeille située au dessus.',
        same_address: "L'enlèvement et la livraion ont la même adresse",

        previous_step_incomplete: "L'étape précédente est incomplète",

        new_address_generic: 'Nouvelle adresse',

        picked_up: 'Colis récupéré',

        software_calculated_time: 'Heure de livraison calculée par le logiciel',
        software_calculated_time_new:
            'Heure de livraison calculée par le logiciel mise à jour',

        real_deliv_time: 'Heure réelle de livraison',
        estimated: 'Estimée',
        real: 'Réelle',
        role: 'Rôle',
        user: 'Utilisateur',
        authorisation: 'Autorisation',
        delete_modal_generic_text_2:
            'Tous les éléments dépendants seront mis à jour (utilisateurs, autorisations, rôles).',

        delete_modal_generic_header: 'Confirmation de la suppression',
        delete_modal_generic_text:
            'Êtes-vous sûr(e) de vouloir supprimer cet élément ?',

        opening_hours: "Horaires d'ouverture",

        not_authorised:
            'Vous ne possédez pas les autorisations nécessaires pour accéder à cette ressource.',

        add_roles_to_user: "Ajout de rôles à l'utilisateur",
        operators: 'Opérateurs',
        admins: 'Administrateurs',
        visitors: 'Visiteurs',
        already_selected_info:
            "L'ajout par type d'utilisateur ne supprimera pas les utilisateurs déjà sélectionnés",
        add_role_to_users: ' Ajout du rôle aux utilisateurs',

        remove: 'Retirer',
        add_authorisations: 'Ajouter des autorisations aux rôles',

        roles_list: 'Rôles utilisateurs',

        selected_users: 'utilisateur selectionné | utilisateurs sélectionnés',
        selected_roles: 'rôle selectionné | rôles sélectionnés',

        admin: 'Admin',
        super_admin: 'SuperAdmin',

        add_roles:
            'Ajout du rôle aux utilisateurs | Ajouter des rôles aux utilisateurs',

        add_to_roles: 'Ajouter aux rôles',

        action_not_allowed: 'Action non autorisée',

        role_already_has_auth: 'Ce rôle possède déjà cette autorisation',

        new_role: 'Nouveau rôle',
        update_role: 'Mise à jour du rôle',

        search_by_name: 'Par nom',
        search_by_code: 'Par code',

        search_role: 'Rechercher des rôles',

        add_to_role: 'Ajouter à un rôle',

        is_used_modal_header: 'Activité déjà utilisée',

        is_used_modal_button_update_all:
            "Mettre à jour l'activité et les agendas",
        is_used_activity:
            'Cette activité est déjà utilisée sur un ou plusieurs agendas.',
        is_used_activity2:
            "Souhaitez-vous mettre à jour cette activité et l'ensemble des activités existantes dans les agendas,",
        is_used_activity3:
            "ou en créer une nouvelle qui n'affectera pas les agendas existants ?",
        create_new_activity: 'Créer une nouvelle activité',
        is_used_container_modal_header: 'Colisage déjà utilisé',

        is_used_container_modal_button_update_all:
            'Mettre à jour le colisage et les commandes',
        is_used_container:
            'Ce colisage est déjà utilisé sur une ou plusieurs commandes.',
        is_used_container2:
            "Souhaitez-vous mettre à jour ce colisage et l'ensemble des colisages existants dans les commandes,",
        is_used_container3:
            "ou en créer un nouveau qui n'affectera pas les commandes existantes ?",
        create_new_container: 'Créer un nouveau colisage',
        roles: 'Rôles',
        mail_templates: "Templates d'emails",
        Name: 'Nom',
        Description: 'Description',
        ModificationDate: 'Date de Modification',
        Id: 'Id',
        HttpCode: 'Code HTTP',
        EntityObject: 'Objet entité ',
        EntityName: 'Nom entité',
        Function: 'Fonction',
        Response: 'Réponse',
        google_api_use: 'Utilisation API Google',

        logs: 'Logs',

        new_authorisation: 'Nouvelle autorisation',
        update_authorisation: "Mise à jour de l'autorisation",

        biiim_admin_interface: "Interface d'administration Biiim",

        authorisations: 'Autorisations',
        translations: 'Traductions',

        force_finish: "Passer l'étape en 'terminé'",
        force_inprogress: "Passer l'étape en 'en cours'",
        ms_status: 'Statut | Statuts',
        ms_option: 'Option | Options',

        distance_last_step: "Distance avec l'étape précédente",
        duration_last_step: "Durée par rapport à l'étape précédente",
        dur_n_dist_last_step:
            "Distance & durée par rapport à l'étape précédente",

        bad_time_range_steps:
            "L'heure choisie pour la livraison est incompatible avec l'heure choisie pour l'enlèvement",
        bad_time_range_steps_2:
            "L'heure choisie pour l'enlèvement est incompatible avec l'heure choisie pour la livraison",

        co2_requirement: 'Prendre en compte la consommation de CO2',
        price_requirement: 'Prendre en compte le prix des courses',
        no_delivery_name: "Cette livraison n'a pas de nom",

        merge: 'Fusionner',
        contract_hours: 'Heures contractuelles',
        hours_done: 'Heures effectuées',
        hours_supp: 'Heures supplémentaires',
        hours_miss: 'Heures manquantes',
        weekly_hours: 'Total semaine',
        regular_activity: 'Régulier',

        delete_tour_header: 'Suppression de tournée',
        confirm: 'Confirmer',
        delete_tour_text:
            'Êtes-vous sûr(e) de vouloir supprimer cette tournée ? ',
        unaffect_step_header: "Retirer l'étape de la tournée",
        unaffect_step_text:
            'Êtes-vous sûr(e) de vouloir retirer cette étape de la tournée ? ',
        all_clients: 'Colisage général pour tous les clients',

        hide_show_google_pois: "Afficher / masquer les points d'intêret Google",
        create_container_for_all: 'Créer le colisage pour tous les clients',

        incomplete_step_not_affectable:
            'Cette étape ne peut pas être affectée à une tournée.',

        check_order: 'Veuillez vérifier la commande',

        solo_step: 'Étape orpheline',

        steps_coordinates_errors_1: 'Toutes les étapes ne sont pas',
        steps_coordinates_errors_2: ' correctement géolocalisées.',
        step_coordinates_error: 'Étape mal géolocalisée',

        unaffect: 'Retirer de la tournée',

        external_ref: 'Référence externe',
        trackings_and_proofs: 'Suivi colis et documents',

        no_tracking_data_available:
            'Aucune donnée de suivi disponible pour le moment',

        view_file: 'Voir le document',
        actions: 'Actions',

        pictures: 'Photos',
        signatures: 'Signatures',
        transit: 'En transit',
        // pickup: 'Pick up',
        redelivery: 'Relivraison',
        collect: 'Collecte',
        // delivery: 'Delivery',
        completion_date: 'Date de réalisation',
        tracking_step: 'Étape du suivi',

        package_reference: 'Référence colis',

        detail: 'Détail',

        trackings: 'Suivi des colis',
        tracking_documents: 'Documents',
        undefined_address: 'Adresse non définie.',

        no_available_for_date: 'Aucun résultat pour la date choisie',

        delete_many_ok: 'Eléments supprimés avec succès.',
        delete_many_ko:
            "Une erreur s'est produite lors de la suppression des éléments",
        show_today_only: "Aujourd'hui",

        error_while_loading: 'Erreur de chargement ',

        package_format: 'colisages',

        old_map: 'Ancienne carte',

        pickup: 'Enlèvement',

        daily: 'Live du jour',

        hour: 'heure | heures',
        day: 'jour | jours',
        minute: 'minute | minutes',
        belongs_to_other_user: 'Ce colisage appartient à un autre utilisateur',

        mine_only: 'Seulement les miens',
        global_only: 'Genéraux',
        my_package_format: "Ce colisage m'appartient",
        global_pack_format: 'Ce colisage est général',
        past_date:
            'Date invalide: la date choisie est dans le passé ou mal formée.',
        date_error_before:
            "Date invalide: La date choisie est antérieure à celle de l'étape précédente ou est dans le passé. | Date invalide: La date choisie est antérieure à celle de la date d'enlèvement.",
        date_error_after:
            "Date invalide: La date choisie est postérieure à celle de l'étape suivante. | Date invalide: La date choisie est postérieure à la date de livraison.",
        daily_view: 'LIVE Tournées du jour',
        save_and_relaunch: 'Sauvegarder et recalculer',
        recalculation_in_progress: 'Optimisation des tournées en cours',

        map_loading: 'Chargement de la carte',

        required_fields: "Les champs marqués d'une * sont requis.",

        B2B: 'B2B',
        B2C: 'B2C',
        end_with: 'Finir avec cette étape',
        start_with: 'Commencer avec cettte étape',
        assign_driver: 'Affecter un coursier',
        assign_other_driver: 'Affecter un autre coursier',
        assign_vehicle: 'Affecter un véhicule',
        change_start_time: "Modifier l'heure de départ",
        time_determined_by_software: 'Horaire déterminé par le logiciel.',
        time_determined_by_driver: 'Horaire du coursier.',
        time_determined_custom: 'Horaire personnalisé (choisir ci-dessous).',
        sim_validated_ok: 'Simulation validée avec succès',
        sim_validated_ko: "Une erreur s'est produite durant la validation",
        add_tour: 'Ajouter une tournée',
        validate_simulation: 'Valider la simulation',

        create_new_file_type: 'Nouveau type de fichier',
        file_type_name: 'Nom du type de fichier',

        new_file_type: 'Nouveau type de fichier de commandes',

        error: 'Erreur',
        active_file_drop_zone_1: 'Déposez vos fichiers ici',
        active_file_drop_zone_2: ' pour les ajouter.',
        inactive_file_drop_zone_1:
            'Faites glisser vos fichiers ici ou cliquez dans la zone de dépôt pour les ajouter.',

        wrong_file_type:
            " n'est pas au format csv et ne peut donc pas être chargé.",

        errors: 'Erreurs',
        uploaded: 'Chargé',
        inProgress: 'En cours',
        upload_file: 'Ajouter un fichier | Ajouter les fichiers',
        file_type: 'Type de fichier',
        pin_drag_info:
            'Cliquez sur un coursier présent dans le paneau du haut pour réassigner cette étape.',

        status_code_not_editable: "Le code de statut n'est pas modifiable",
        auth_code_not_editable:
            "Le code de l'autorisation n'est pas modifiable",
        role_code_not_editable: "Le code du rôle n'est pas modifiable",
        action_canceled: "Action annulée par l'utilisateur",

        overlap_modal_header: "Chevauchement d'activités",
        overlap_modal_button: "Continuer l'ajout d'activité",
        overlap_activity:
            "L'activité que vous souhaitez ajouter comporte des horaires qui chevauchent ceux d'une activité existante.",
        overlap_activity2: 'Souhaitez-vous la sauvegarder quand même ?',

        code: 'Code',
        error_occured: "Une erreur s'est produite.",

        status_code: 'Code du statut',
        auth_code: "Code de l'autorisation",
        role_code: 'Code du rôle',

        code_already_exists: 'Le code choisi est déjà utilisé',
        deleted_ok: 'Supprimé avec succès',
        create_ok: 'Créé avec succès',
        create_ko: 'Echec lors de la création',
        update_ok: 'Mis à jour avec succès',
        update_ko: 'Echec lors de la mise à jour',
        deleted_ko: 'Echec lors de la suppression',
        update_package_status: 'Mise à jour du statut de colis',
        new_package_status: 'Nouveau statut de colis',
        status_name: 'Nom du statut',
        status_name_info:
            "Texte qui sera affiché à vos clients sur l'application et dans vos listings",
        packages_status: 'Statut du colis | Statuts des colis',

        orders_settings: 'Paramètres des livraisons',
        notifications_settings: 'Paramètres des notifications',
        billings_settings: 'Paramètres de la facturation',
        status_subtext_pt_1:
            'Vous pouvez ici créer et gérer les status des colis dont vous avez besoin et qui seront affichés à vos clients.',
        status_subtext_pt_2:
            "Vous pouvez par exemple créer un statut spécifique pour le cas où une commande a été passée mais que le colis n'a pas encore été récupéré par vos équipes : 'Pas encore récupéré' ou 'En attente chez le client'. ",
        status_subtext_pt_3:
            "Ou bien encore créer des statuts de refus de colis spécifiques : 'Refusé non commandé', 'Refusé colis déchiré', 'Refusé chaîne du froid rompue'.",

        map: 'Plan',

        PDF: 'Fichier PDF',

        week: 'Semaine',

        contract_hour: 'Heures contractuelles',

        or: 'Ou',
        create_user_rp_info:
            'Un courriel sera envoyé à cette adresse email pour initialiser le mot de passe du compte',

        create_user_account: "Créer un compte pour l'application et le site",

        create_matching_driver: 'Créer le coursier correspondant',
        create_matching_client: 'Créer le client correspondant',
        do_not_create_matching_driver: 'Ne pas créer le coursier correspondant',
        do_not_create_matching_client: 'Ne pas créer le client correspondant',
        own_account_btn: "Je serai l'utilisateur de ce compte",
        for_other_account_btn: 'Ce compte est pour un autre utilisateur',

        user_type: "Type d'utilisateur",

        create_and_send_mail:
            "Créer et envoyer le courriel d'initialisation de mot de passe à l'utilisateur",

        update_user: 'Mise à jour utilisateur',
        new_user: 'Nouvel utilisateur',

        previous: 'Précédent',

        show_disabled_activities: 'Afficher les activités désactivées',

        activity_type: "Type d'activité",

        atwork: 'Travaillé',
        off_paid: 'Chomé contractuel (calculé dans les heures effectuées)',
        off_not_paid:
            'Chomé hors contrat de travail (non calculé dans les heures effectuées)',

        activity_create_ko: "Erreur lors de la création de l'activité",

        vehicle_deactivate_ok: 'Véhicule désactivé avec succès',
        vehicle_deactivate_ko: 'Erreur lors de la désactivation du véhicule',
        vehicle_activate_ok: 'Véhicule activé avec succès',
        vehicle_activate_ko: "Erreur lors de l'activation du Véhicule",
        copy_to_clipboard: 'Copier dans le presse papier',
        copied_to_clipboard: 'Copié dans le presse papier',
        operator_ref: 'Référence livraison',

        short_from: 'De',
        short_before: 'À',
        pick_color: 'Choisissez une couleur',

        start_time: 'Heure de début',
        end_time: 'Heure de fin',
        edit_activity: "Modifier l'activité",
        new_activity: 'Créer une activité',

        last_step_finished: 'Colis livré',
        delivery_finished: 'Livré',
        delivery_new: 'En attente de prise en charge',
        client_ref: 'Référence client',
        break: 'Rupture',
        hydrogen: 'Hydrogène',

        delivery_start_end: 'Enlèvement & Destination',

        steps_errors: 'Certaines étapes sont incomplètes',
        finished_steps: 'Étapes terminées',
        no_status_found: "Statut de l'étape non defini",
        delivery_name_info_operator:
            'Donnez un nom à votre livraison pour que votre client la retrouve plus facilement!',
        affected: 'Étape affectée à une tournée',
        new: 'Étape pas encore affectée à une tournée',
        executed: 'Terminée',
        affectationError: "Erreur d'affectation",

        delivery_title: 'Livraison du',
        delivery_name_info:
            'Donnez un nom à votre livraison pour la retrouver plus facilement!',

        delivery_name: 'Nom de ma livraison',

        not_specified: 'Non renseigné',
        bike: 'Vélo',
        cargo_bike: 'Vélo cargo',
        cargoBike: 'Vélo cargo',
        deliveryTricycle: 'Tricycle',
        delivery_tricycle: 'Tricycle',
        car: 'Voiture',
        van: 'Fourgon / van',
        boat: 'Bateau',
        truck: 'Camion',
        legs: 'Jambes',
        gas: 'Gaz',
        gasoline: 'Essence',
        diesel: 'Diesel',
        electric: 'Electricité',
        hybrid_gasoline: 'Hybride essence',
        hybrid_diesel: 'Hybride diesel',
        plugin_hybrid_gasoline: 'Hybride essence rechargeable',
        plugin_hybrid_diesel: 'Hybride diesel rechargeable',
        consumption: 'Consommation',
        annual_cost: 'Coût annuel',
        type: 'Type',

        powered: 'Energie',

        update: 'Mettre à jour',
        create: 'Créer',

        no_driver_selected: 'Aucun coursier choisi',
        no_vehicle_type_selected: 'Aucun type de véhicule choisi',

        OrderPackages: 'Colis',

        package_format_not_found: 'Colisage introuvable',

        incomplete_order: 'Commande incomplète.',
        contact_support:
            "Veuillez contacter le support BiiimSolutions pour plus d'informations.",
        default_header: 'Titre',

        operator: 'Opérateur',

        container_not_editable: "Ce colisage n'est pas modifiable",
        send_rp_link: 'Envoyer un email de réinitialisation de mot de passe',

        container_update_ok: 'Colisage mis à jour',
        container_update_ko: 'Erreur lors de la mise à jour du colisage',
        container_create_ok: 'Colisage créé',
        container_create_ko: 'Erreur lors de la creation du colisage',
        new_container: 'Nouveau colisage',
        update_container: 'Mise à jour du colisage',
        size: 'Dimensions (L x l x H)',

        package_format_delete_ok: 'Colisage supprimé avec succès',
        package_format_delete_ko:
            'Une erreur est survenue lors de la suppression du colisage',
        value_required: 'Ce champ ne peut pas être vide',
        containers: 'Colisages',
        my_containers: 'Mes colisages',

        description: 'Description',

        package_name: 'Appellation',
        package_delete_ok: 'Colis supprimé avec succès',
        package_delete_ko: 'Erreur lors de la suppression du colis',
        back_to_predefined_addresses:
            'Retourner à la liste des adresses existantes',
        back_to_predefined_containers:
            'Retourner à la liste des colisages existants',
        create_my_address: 'Je créé mon adresse de livraison',
        create_my_container: 'Je créé mon colisage',

        create_address: 'Je ne trouve pas mon adresse de livraison',
        create_container: 'Je ne trouve pas mon colisage',

        update_and_copy: 'Mettre à jour et créer un colis similaire',
        save_and_copy: 'Sauvegarder et créer un colis similaire',

        quantity: 'Quantité',
        no_package_type_error: 'Veuillez sélectionner un colisage.',

        select_package_format: 'Choisissez un colisage',
        gros_colis: 'Gros colis',

        pallet: 'Palette | Palettes',
        half_pallet: 'Demi-palette | Demi-palettes ',
        flat_wine_box:
            'Carton 6 bouteilles à plat | Cartons 6 bouteilles à plat ',
        wine_box_down:
            'Carton 6 bouteilles couchées | Cartons 6 bouteilles couchées',
        wine_box_up:
            'Carton 6 bouteilles debouts | Cartons 6 bouteilles debout',
        standard_cheese_box:
            'Carton fromage standard | Cartons fromage standard',
        no_address_selected: 'Aucune adresse de livraison sélectionnée',
        no_package_selected: 'Aucun colisage sélectionné',

        temperature: 'Température',
        no_temp_specified: 'Aucune température spécifique pour ce colis',
        frozen: 'Ce colis doit être maintenu à une température inférieure -18°',
        hot: 'Ce colis doit être maintenu à une température supérieure à 30°',
        cool: 'Ce colis doit être maintenu à une température comprise entre 0° et 6°',
        value: 'Montant',
        container_type: 'Type de conteneur (optionnel)',

        lxwxh: '(L x l x H)',

        price: 'Mon tarif',
        package_info: 'Informations sur le colis',

        add_package: "J'ajoute un colis",
        update_package: 'Je mets à jour mon colis',

        duplicate: 'Dupliquer',
        update_order_B2C: 'Mettre à jour ma livraison',
        new_order_B2C: 'Je programme ma livraison',
        today: "Aujourd'hui",
        weekly_planning_management: 'Gestion du planning hebdomadaire',
        user_already_exists: 'Un utilisateur existe déjà avec ces identifiants',

        bad_credentials: "L'identifiant et/ou le mot de passe sont erronés",
        end_month: 'Fin de mois',
        bill_number: 'Numéro de facture',

        next: 'Suivant',
        shepherd: {
            final_button: 'Terminer',
            ok_go: 'Ok guidez moi !',
            restart_tour: 'Relancer le guide',
            no_thanks: 'Non merci',
            stop_tour: 'Arreter le guide',
            create_account: {
                start: {
                    title: 'Bienvenue',
                    text: "Vous trouverez ici un didacticiel pour vous aider dans la création d'un compte. Ce compte va vous permettre de créer de nouvelles commandes et de suivre leurs livraisons en temps reel.",
                },
                step_1: {
                    title: 'Mode Sombre',
                    text: "Ce bouton vous permet de passer en mode sombre. Ce mode permet d'afficher votre page d'accueil, votre barre d'outils, vos paramètres et certaines autres pages dans des tons sombres. ",
                },
                step_2: {
                    title: 'Changement Langue',
                    text: "Ce bouton permet de changer la langue dans laquelle va s'afficher le site.",
                },
                step_3: {
                    title: 'Relancer le guide',
                    text: 'Ce bouton permet de relancer le didacticiel pour la page en cours.',
                },
                step_4: {
                    title: 'Identifiant',
                    text: "Ce champ Email vous servira d'identifiant lors de vos prochaines connexions à l'application. C'est cet email qui servira à recevoir des notifications sur l'avancement de vos livraisons.",
                },
                step_5: {
                    title: 'Contrôle du mot de passe',
                    text: 'Ce bouton permet de rendre visible le mot de passe en cours de saisie.',
                },
                step_6: {
                    title: 'Force du mot de passe',
                    text: 'Cette jauge vous indique la force du mot de passe que vous souhaitez utiliser. Il faudra au moins 8 caracteres, 1 Majuscule, 1 Chiffre et 1 Caractère special (!,*,?,%, ...)',
                },
                step_7: {
                    title: 'Raison Sociale',
                    text: 'Entrez ici le nom de votre entreprise. Si vous laissez ce champ vide, il sera automatiquement rempli avec vos noms et prénoms.',
                },
                step_8: {
                    title: 'Adresse de facturation',
                    text: "Entrez ici votre adresse de facturation. Commencez à taper quelques caractères, et le moteur vous fera des suggestions d'adresses. Vous pouvez également ignorer ces suggestions et taper votre adresse manuellement.",
                },
                step_9: {
                    title: 'Annuler la création de compte',
                    text: 'Ce bouton permet de retourner sur l’écran de connexion.',
                },
                step_10: {
                    title: 'Je créé mon compte',
                    text: 'Ce bouton permet de valider votre demande de création de compte. Une fois votre compte créé vous recevrez un email afin de valider votre adresse email.',
                },
            },
        },
        my_bills: 'Mes factures',
        my_orders: 'Mes livraisons',
        my_addresses: 'Mes adresses',
        email_used_for_login:
            "L'adresse email que vous utiliserez pour vous connecter",
        user_create_ko: 'Erreur lors de la création du compte',
        user_create_ok: 'Compte créé avec succès!',

        welcome: 'Bienvenue ',
        email: 'Adresse email',
        sign_up: 'Je créé mon compte',
        account_informations: 'Références du compte',
        personnal_infos: 'Informations postales',
        print_label: "Imprimer l'étiquette du colis",
        print_all_labels: 'Imprimer toutes les étiquettes',
        tracking: 'Suivre le colis',
        tracking_all: 'Suivre les colis',

        pricing: 'Mon tarif',
        my_account: 'Mon compte',
        user_update_ok: 'Données personnelles mises à jour avec succès',
        user_update_ko:
            'Erreur lors de la mise à jour des données personnelles',
        pricing_per_tour: 'Tournée : Coût du point de livraison',
        eur_per_h: '€ / h',
        eur_per_km: '€ / km',
        eur_per_step: '€ / point de livraison',
        pricing_per_km: 'Tarification au kilomètre',

        prov_of_service: 'Mise à disposition : Taux horaire',
        login_only: 'Identifiant de connexion',
        api_url: 'URL API',
        software_type: 'Type de logiciel de commande',
        billing: 'Facturation',
        postal_address: 'Adresse postale',
        new_supplier: 'Nouveau fournisseur',
        update_supplier: 'Mise à jour fournisseur',
        supplier_create_ok: 'Fournisseur créé avec succès',
        supplier_create_ko: 'Erreur lors de la création du fournisseur',
        supplier_update_ok: 'Fournisseur mis à jour avec succès',
        supplier_update_ko: 'Erreur lors de la mise à jours du fournisseur',
        deactivated_area: 'Secteur désactivé',
        activate_area: 'Secteur actif',
        activate_vehicle_type: 'Type de véhicule actif',

        activate_driver: 'Coursier actif',
        area_deactivate_ok: 'Secteur désactivé avec succès',
        area_deactivate_ko: 'Erreur lors de la désactivation du secteur',
        area_activate_ok: 'Secteur activé avec succès',
        area_activate_ko: 'Erreur lors de la activation du secteur',
        area_create_ok: 'Secteur créé avec succès',
        area_create_ko: 'Erreur lors de la création du secteur',
        area_update_ok: 'Secteur mis à jour avec succès',
        area_update_ko: 'Erreur lors de la mise à jour du secteur',
        area_error: 'Veuillez modifier le secteur pour sauvegarder.',
        area_type: 'Type de secteur',
        area_by_bike: 'Zone de livraison à vélo',
        download: 'Télécharger',
        new_area: 'Nouveau secteur',
        update_area: 'Mise à jour secteur -',
        order_file_title: 'Fichier de commandes -',
        file: 'Fichier',
        lines_to_import: 'Lignes à importer',
        imported_lines: 'Lignes importées',
        simulation_merge_ok: 'Fusion des simulations lancée avec succès',
        simulation_merge_ko: 'Erreur lors de la fusion des simulations',
        relaunch: 'Recalculer',
        relaunch_selection: 'Relancer la génération pour la selection',
        step_needs_correction: "Cliquez pour mettre à jour l'étape",
        ref_address_update_ok: 'Adresse de référence correctement mise à jour',
        ref_address_update_ko:
            "Erreur lors de la mise à jour de l'adresse de référence",
        grouping: 'Regroupement de livraisons',
        fix_lat_lng_info_1:
            "Déplacez le marqueur '?' sur la carte ou renseignez à nouveau le champ adresse 1 pour corriger les coordonnées GPS de l'étape.",
        fix_lat_lng_info_2:
            "Les compléments d'adresse peuvent être renseignés dans le champ adresse 2",
        tours_date: 'Tournées',
        driver: 'Coursier',
        add: 'Ajouter',
        morning: 'Matin',
        afternoon: 'Après-midi',
        planning_update_ok: 'Planning correctement mis à jour',
        planning_update_ko: 'Erreur lors de la mise à jour du planning',
        simulation_generation_ok: 'La génération de la simulation a commencé',
        simulation_generation_ko:
            'Erreur lors de la génération de la simulation',
        planning_create_ok: 'Planning mis à jour avec succès',
        planning_create_ko: 'Erreur lors de la mise à jour du planning',
        planning: 'Planning',
        driver_planning: 'Planning coursier -',
        refresh: 'Rafraîchir',
        deactivate: 'Désactiver',
        activate: 'Activer',

        driver_deactivate_ok: 'Coursier désactivé avec succès',
        driver_deactivate_ko: 'Erreur lors de la désactivation du coursier',
        driver_activate_ok: 'Coursier activé avec succès',
        driver_activate_ko: "Erreur lors de l'activation du coursier",
        container_deactivate_ok: 'Colisage désactivé avec succès',
        container_activate_ko: "Erreur lors de l'activation du colisage",
        container_activate_ok: 'Colisage activé avec succès',
        container_deactivate_ko: 'Erreur lors de la désactivation du colisage',
        vehicler_deactivate_ok: 'Véhicule désactivé avec succès',
        vehicler_activate_ko: "Erreur lors de l'activation du véhicule",
        vehicler_activate_ok: 'Véhicule activé avec succès',
        vehicler_deactivate_ko: 'Erreur lors de la désactivation du véhicule',
        deactivate_selection: 'Désactiver les elements selectionnés',
        maintenance_delete_ok: 'Maintenance supprimée avec succès',
        maintenance_delete_ko:
            'Erreur lors de la suppression de la maintenance',
        title: 'Titre',
        informations: 'Informations',
        active: 'Actif',
        deactivated: 'Désactivé',
        start_date: 'Date de début',
        end_date: 'Date de fin',
        maintenance: 'maintenance | Maintenances',
        add_maintenance: 'Ajouter une maintenance',
        update_maintenance: 'Mise à jour de la maintenance',
        max_load: 'Charge maximale (kg)',
        package_max_height: "Hauteur maximale d'un colis (cm)",
        package_max_length: "Longueur max. d'un colis (cm)",
        package_max_width: "Largeur max. d'un colis (cm)",

        volume: 'Volume du véhicule (m³)',
        volume_info:
            'En m³ (Mettre la valeur à 0 sur Largeur - Longueur - Hauteur pour saisir manuellement le volume)',
        in_percent: 'En %',
        max_volume: 'Capacité max. du volume (%)',
        package_specifications: 'Caractéristiques des colis',
        vehicle_specifications: 'Caractéristiques du véhicule',
        associated_driver: 'Coursier associé',
        select_driver: 'Choix du coursier',
        maintenance_create_ok: 'Maintenance ajoutée avec success',
        maintenance_create_ko: "Erreur lors de l'ajout de la maintenance",
        vehicle_create_ok: 'Véhicule créé avec succès',
        vehicle_create_ko: 'La création du véhicule a échouée',
        vehicle_update_ok: 'Véhicule modifié avec succès',
        vehicle_update_ko: 'La modification du véhicule a échouée',
        vehicle_delete_ok: 'Véhicule supprimé avec succès',
        vehicle_delete_ko: 'Erreur lors de la suppression du véhicule',
        registration: 'Immat. / n° de série',
        brand: 'Marque',
        model: 'Modèle',
        update_vehicle: 'Mise à jour véhicule',
        update_vehicle_type: 'Mise à jour type de véhicule',

        new_vehicle: 'Nouveau véhicule',
        new_vehicle_type: 'Nouveau type de véhicule',

        vehicle_information: 'Informations véhicule',
        vehicle_types: 'Types de véhicules',
        simulation_delete_ok: 'Simulation supprimée avec succès',
        simulation_delete_ko: 'Erreur lors de la suppression de la simulation',
        drivers_requirement: 'Prendre en compte le calendrier des coursiers',
        vehicles_requirement: 'Prendre en compte les véhicules',
        areas_requirement: 'Prendre en compte les secteurs',
        date: 'Date',
        simulation_name: 'Nom de la simulation',
        generate: 'Générer',
        tour_simulation: 'Simulation de tournée',
        params: 'Paramètres',
        requirements: 'Critères',
        no_contact_found_for_client: 'Aucun contact trouvé pour ce client',
        new_simulation: 'Nouvelle simulation',
        address_create_ok: 'Adresse créée avec succès',
        address_create_ko: "La création de l'adresse a échouée",
        contact_create_ok: 'Contact créé avec succès',
        contact_create_ko: 'La création du contact a échouée',
        contact_update_ok: 'Contact créée avec succès',
        contact_update_ko: 'La création du contact a échouée',
        contact_delete_ok: 'Contact supprimé avec succès',
        contact_delete_ko: 'La suppression du contact a échouée',
        driver_delete_ok: 'Coursier supprimé avec succès',
        driver_delete_ko: 'La suppression du coursier a échouée',
        tour_delete_ok: 'Tournée supprimée avec succès',
        tour_delete_ko: 'La suppression de la tournée a échouée',
        mobile: 'Téléphone portable',
        latitude: 'Latitude',
        longitude: 'Longitude',
        start: 'Départ',
        end: 'Arrivée',
        last_known_pos: 'Dernière position connue',
        start_end_pos: 'Positions de départ et arrivée',
        subcontractor: 'Sous traitant',
        supplier: 'Fournisseur',
        select_supplier: 'Choix du fournisseur',
        area: 'Secteur',
        vehicle: 'Véhicule',
        mobile_account: 'Compte pour application mobile',
        select_account: "Choix du compte pour l'application mobile",
        select_vehicle: 'Choix du véhicule',
        select_area: 'Choix du secteur',
        select_type: 'Choix du type de véhicule',
        work_information: 'Informations professionnelles',
        phone_secondary: 'Téléphone secondaire',
        emergency_contact: "Contact en cas d'urgence",
        phone_pro: 'Téléphone professionnel',
        phone_perso: 'Téléphone personnel',
        address_name: "Nom de l'adresse",
        localisation: 'Localisation',
        driver_information: 'Informations personnelles',
        new_driver: 'Nouveau coursier',
        update_driver: 'Mise à jour coursier',
        driver_update_ok: 'Coursier mis à jour avec succès',
        driver_update_ko: 'Erreur lors de la mise à jour du coursier',
        driver_create_ok: 'Coursier créé avec succès !',
        driver_create_ko: 'Echec lors de la création du coursier',
        simulation_status: {
            errors: 'Erreurs',
            archived: 'Archivée',
            requested: 'Demandée',
            in_progress: 'En cours',
            finished: 'Terminée',
        },
        simulations: 'Simulations',
        steps_count: 'Nombre commandes',
        tours_count: 'Nombre tournées',
        distance: 'Distance (km)',
        distance_nokm: 'Distance',

        duration: 'Durée',
        not_affected: 'Étape non affectée | Étapes non affectées',
        report: 'Rapport',
        package_reference_missing: 'Votre colis doit avoir une référence.',
        package_type_missing: 'Choisissez un type de colis.',
        bad_packages: 'Certaines informations sur le colis sont manquantes',
        check_last_package: "Le dernier colis entré n'est pas valide",
        form_errors: 'Le formulaire comporte des erreurs',
        order_update_ok: 'Commande mise à jour avec succès',
        order_update_ko: 'Erreur lors de la mise à jour de la commande ',
        order_create_ok: 'Commande créée avec succès !',
        order_create_ko: 'Echec lors de la création de la commande',
        delivery_update_ok: 'Livraison mise à jour avec succès',
        delivery_update_ko: 'Erreur lors de la mise à jour de la livraison ',
        delivery_create_ok: 'Livraison créée avec succès !',
        delivery_create_ko: 'Echec lors de la création de la livraison',
        and: 'et',
        can_select_address: 'Choisissez une adresse existante.',
        can_select_address_v1: 'Choisissez une adresse',

        bad_time_range:
            'La valeur de la première heure choisie doit être inférieure à la seconde.',
        on: 'Le',
        pick_up_label: 'Enlèvement',
        pick_up: 'Enlèvement du',

        delivery_label: 'Destination',
        delivery: 'Livraison du',

        between: 'Entre',
        from: 'À partir de ',
        before: "Jusqu'à",
        package_add_not_allowed:
            'Ajoutez une étape pour pouvoir ajouter un colis',
        number: 'Numéro',
        package_type: 'Type de colis',
        width: 'Largeur (cm)',
        length: 'longueur (cm)',
        height: 'Hauteur (cm)',
        packages: 'Colis',
        package: 'Colis',

        references: 'Réferences',
        comments: 'Commentaires',
        add_steps: 'Ajouter des étapes',
        add_step: 'Ajouter une étape',

        contact: 'Contact',
        new_contact: 'Nouveau contact',
        update_contact: 'Mise à jour du contact',
        update_ref_address:
            "Reporter les modifications sur l'adresse postale de référence",
        full_order: 'Voir la commande',
        order_step: 'Etape de commande',
        reference_postal_address: 'Adresse postale de référence',
        no_ref_address: 'Aucune adresse postale de référence trouvée',
        weekly_planning: 'Planning hebdomadaire',
        required_field: 'Ce champ doit être rensseigné',
        select_client_info: 'Veuillez selectionner un client',
        select_driver_info: 'Veuillez selectionner un coursier',
        select_vehicle_info: 'Veuillez selectionner un véhicule',

        select_contact_info: 'Veuillez selectionner un contact',
        bad_zip_format: "Le code postal n'est pas au bon format",
        bad_phone_format: "Le numéro de téléphone n'est pas au bon format",
        bad_time_format: "L'heure renseignée n'est pas au bon format",
        bad_hour_format:
            "Le nombre d'heures au contrat n'a pas le bon format xx:xx",
        bad_date_format: "La date renseignée n'est pas au bon format",
        back: 'Retour',
        address_type: "Type d'adresse",
        destination: 'Destination',
        origin: 'Origine',
        new_address: 'Nouvelle adresse de livraison',
        order_step_correction: 'Correction des adresses de livraison',
        generate_invoice: 'Générer facture',
        business_hour_updated: 'Horaires mis à jour',
        business_hour_update_error:
            'Erreur lors de la mise à jour des horaires',
        save: 'Sauvegarder',
        contact_name: 'Nom du contact',
        contact_name_imported: 'Nom du contact importé',
        open: 'Ouvert',
        closed: 'Fermé',
        waiting_time: "Temps d'attente",
        waiting_time_info:
            "Le temps d'attente est exprimé en minutes. La valeur prise par défaut est 5 minutes.",
        driver_info: 'Informations coursier',
        additional_info: 'Informations complémentaires',
        contacts: 'Contacts',
        new_item: 'Créer',
        select_one: 'Sélectionner',
        contact_and_wait: "Contact & temps d'attente",
        delivery_hours: 'Horaires',
        add_contact: 'Ajouter un contact',
        client_update_ok: 'Client mis à jour avec succès',
        client_update_ko: 'Erreur lors de la mise à jour ',
        client_delete_ok: 'Client supprimé avec succès',
        client_delete_ko: 'Erreur lors de la suppression du client',
        user_delete_ok: 'Utilisateur supprimé avec succès',
        user_delete_ko: "Erreur lors de la suppression de l'utilisateur",
        order_delete_ok: 'Commande supprimée avec succès',
        order_delete_ko: 'Erreur lors de la suppression de la commande',
        delivery_delete_ok: 'Livraison supprimée avec succès',
        delivery_delete_ko: 'Erreur lors de la suppression de la livraison',
        address_delete_ok: 'Adresse de livraison supprimé avec succès',
        address_delete_ko:
            "Erreur lors de la suppression de l'adresse de livraison",
        address_update_ok: 'Adresse mise à jour avec succès',
        address_update_ko: 'Erreur lors de la mise à jour ',
        update_address: "Mise à jour d'une adresse",
        to: 'à',
        firstname: 'Prénom',
        lastname: 'Nom',
        phone: 'Téléphone',
        phone_imported: 'Téléphone importé',
        transfer: 'Virement',
        all: 'Tous',
        search_name: 'Nom',
        action: 'Actions',
        home: 'Accueil',
        En: 'En',
        Fr: 'Fr',
        by_name: 'par nom',
        optionnal: 'facultatif',
        contact_information: 'Informations du contact',
        client_information: 'Informations du client',
        password: 'Mot de passe',
        login: "Adresse email ou nom d'utilisateur",
        email_address: 'Adresse email',
        confirm_password: 'Confirmez le mot de passe',
        order: 'Ordre de tri',
        pw_notice:
            'Le mot de passe doit contenir au moins sept caractères et comporter une majuscule, une minuscule, un chiffre et un caractère spécial. Vous devez au moins atteindre le niveau vert pour valider votre mot de passe',
        logout: 'Déconnexion',
        notifications: 'Notifications',
        open_menu: 'Ouvrir le menu',
        forgot_password: 'Mot de passe oublié ?',
        connect: 'Connexion',
        show: 'Afficher',
        hide: 'Masquer',
        nightmode: 'Mode nuit',
        lang_switch: 'Changer la langue',
        company_name: 'Raison sociale',
        address: 'Adresse',
        postal_code: 'Code postal',
        city: 'Ville',
        registration_number: 'SIRET',
        client_type: 'Type de client',
        payment_type: 'Type de paiement',
        search: 'Rechercher',
        new_client: 'Nouveau client',
        update_order: 'Mise à jour commande',
        clients: 'Clients',
        client: 'Client',
        creation_date: 'Date de création',
        modification_date: 'Date de mise à jour',

        billings: 'Facturation',
        vehicles: 'Véhicules',
        vehicle_type: 'Type de véhicule',
        vehicleType: 'Type de véhicule',
        price_charged: 'Prix facturé',
        orders: 'Commandes | Livraisons',
        becycle_orders: 'Commandes | Livraisons',

        new_order: 'Nouvelle commande | Je programme ma livraison',
        import_orders: 'Importer un fichier de commandes',
        imported_orders: 'Fichiers importés',
        tours: 'Tournées',
        suppliers: 'Fournisseurs',
        areas: 'Secteurs',
        users: 'Utilisateurs',
        drivers: 'Coursiers',
        settings: 'Paramétrage',
        login_error: "Nom d'utilisateur ou mot de passe incorrect",
        delivery_addresses: 'Adresses',
        mass_update: 'Mises à jour en masse',
        loading: 'Chargement...',
        name: 'Nom',
        missing_login:
            "Merci de renseigner une adresse email ou un nom d'utilisateur valide.",
        missing_password: 'Merci de renseigner un  mot de passe.',
        reference: 'Référence',
        number_of_parcels: 'Nombre de colis',
        delivery_mode: 'Mode de livraison',
        id: 'Id',
        weight: 'Poids (kg)',
        unit_weight: 'Poids unitaire (kg)',

        worth: 'Valeur (€)',
        reset_pw_ok:
            'Si le compte renseigné est valide, un courriel sera envoyé avec les instructions pour mettre à jour le mot de passe.',
        reset_pw_instructions:
            'Les instructions de réinitialisation du mot de passe seront envoyées à votre adresse de courriel enregistrée.',
        reset_pw_modal_title: 'Réinitialiser votre mot de passe',
        pw_reset: 'la réinitialisation du mot de passe',
        send: 'Envoyer',
        cancel: 'Annuler',
        password_strength: {
            start: 'Commencez à taper pour vérifier la sécurité de votre mot de passe',
            weak: 'Très faible',
            better: 'faible ',
            nice: 'Moyen',
            good: 'Bon',
            perfect: 'Fort',
        },
        passwords_dont_match: 'Les mots de passe ne correspondent pas',
        client_create_ok: 'Client créé avec succès !',
        client_create_ko: 'Echec lors de la création du client ',
        search_address:
            'Rechercher une adresse. Les champs adresse seront automatiquement remplis.',
        no_records_found: 'Aucun enregistrement trouvé',
        sort_by: 'Trier par',
        items_per_page: 'Enregistrements par page',
        occasionnal: 'Course occasionnelle',
        voucher_book: 'Bon de commande',
        month_invoice: 'Facturation au mois',
        voucherbook: 'Bon de commande',
        monthinvoice: 'Facturation au mois',
        notselected: 'Non sélectionné',
        check: 'Chèque',
        filters: 'Filtres',
        page: 'Page',
        of: 'sur',
        results: '{n} Résultat | {n} Résultats',
        no_filter: 'Aucun filtre',
        steps: 'Étapes',
        step: 'Étape',

        status: 'Statut',
        in_progress: 'En cours',
        inprogress: 'En cours',
        finished: 'Terminé(e)',
        by_client: 'par client',
        by_reference: 'par réference',
        delivery_type: {
            express: 'Express',
            fast: 'Rapide',
            normal: 'Normal',
        },
        express: 'Express',
        fast: 'Rapide',
        normal: 'Normal',
        delivery_status: {
            in_progress: 'En cours',
            finished: 'Terminée',
            errors: 'Erreurs',
        },
        uploads: {
            in_progress: 'En cours',
            finished: 'Terminé',
            uploaded: 'Chargé',
            errors: 'Erreurs',
        },
        delete: 'Supprimer',
        edit: 'Modifier',
        update_client: 'Mise à jour client',
        for: 'pour',
        opening_time: 'Ouverture',
        closing_time: 'fermeture',
        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
        delete_selection: 'Supprimer les éléments sélectionnés',
    },
}
export default translations
