<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="wait === true ? 'cursor-wait' : ''"
    >
        <div class="mt-14">
            <drop-zone
                v-if="canAddNew()"
                :showZone="selectedFileType !== '' && selectedClient !== null"
                :okFilesToRemove="okFilesToRemove"
                @uploadFiles="uploadFiles($event)"
                @fileRemoved="removeFileFromOk($event)"
                :wait="wait"
                :clearFileList="clearFileList"
                @resetClearFileList="clearFileList = false"
                @resetWrongFiles="wrongFileTypes = []"
                @removeFile="removeFromWrong"
            >
                <template #extraHeader>
                    <div
                        class="flex items-center justify-start"
                        id="select-file-type"
                    >
                        <label
                            class="font-semibold text-gray-600 whitespace-nowrap text-xs pb-1 ml-6 mr-2 block"
                            >{{ $t('file_type') }}</label
                        >
                        <Multiselect
                            class="border darky rounded px-3 py-2 mt-1 w-1"
                            v-model="selectedFileType"
                            :options="fileTypesOptions"
                            :searchable="true"
                            :allow-empty="false"
                            :can-deselect="false"
                            :can-clear="false"
                            label="name"
                            track-by="name"
                        ></Multiselect>
                        <label
                            class="font-semibold text-gray-600 whitespace-nowrap text-xs pb-1 ml-6 mr-2 block"
                            >{{ $t('client') }}</label
                        >
                        <Multiselect
                            class="border darky rounded px-3 py-2 mt-1 w-1"
                            v-model="selectedClient"
                            :options="clientsOptions"
                            :searchable="true"
                            :allow-empty="false"
                            :can-deselect="false"
                            :can-clear="false"
                            label="name"
                            track-by="name"
                        ></Multiselect>
                        <fa-icon
                            v-if="
                                selectedFileType !== '' &&
                                selectedClient !== null
                            "
                            :title="$t('cancel')"
                            @click.prevent="resetUpload(true)"
                            class="ml-3 fa-lg cursor-pointer error-msg"
                            :icon="'xmark'"
                        ></fa-icon>
                    </div>
                    <div
                        class="flex items-center text-xs mt-1 justify-start custom-link"
                    >
                        <div
                            class="ml-32 cursor-pointer"
                            @click="showFileTypeModal = true"
                        >
                            <fa-icon :icon="'plus'" class="fa-xs mr-1">
                            </fa-icon>
                            {{ $t('create_new_file_type') }}
                        </div>
                        <!-- <router-link :to="{ name: 'new_client' }" class="ml-32">
                            <fa-icon :icon="'plus'" class="fa-xs mr-1">
                            </fa-icon>
                            {{ $t('new_client') }}
                        </router-link> -->
                    </div>
                </template>
                <template #errors>
                    <span v-if="wrongFileTypes.length > 0">
                        <div
                            class="error-msg text-sm flex justify-center items-center italic mt-1 mb-2"
                            v-for="(wrong, ind) in wrongFileTypes"
                            :key="ind"
                        >
                            <strong class="mr-1">{{ wrong }}</strong>
                            {{ ` ${$t('wrong_file_type')}` }}
                            <fa-icon
                                v-if="ind === 0"
                                :icon="'xmark'"
                                class="ml-5 fa-lg cursor-pointer error-msg"
                                @click="wrongFileTypes = []"
                            ></fa-icon>
                        </div>
                    </span>
                </template>
            </drop-zone>

            <fetch-data-error v-if="fetchError" :dataType="'orders'" />
            <filtrable-table
                v-else
                id="importedOrdersList"
                :items="importedOrders"
                :columns="columns"
                :storeToSort="'orders'"
                :smallMT="true"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :canAddNew="false"
                :refresh="refresh"
                :itemActions="itemActions"
                @download="downloadFile($event)"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="updateFilter($event)"
                @create="$emit('importFile')"
                @clear="clearFilter($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="showFileTypeModal"
            :headerText="'new_file_type'"
            :buttonText="'save'"
            :creating="wait"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="createNewFileType()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-3">
                    <label class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('file_type_name') + ' *' }} <br />
                    </label>
                    <input
                        v-model="fileTypeForm.Name"
                        autocomplete="name"
                        maxlength="100"
                        @blur="v$.fileTypeForm.Name.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.fileTypeForm.Name.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
                <span
                    class="flex mt-1 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Multiselect from '@vueform/multiselect'
import Modal from '@/components/atoms/Modal.vue'
import FiltrableTable from '@/components/FiltrableTable.vue'
import DropZone from '@/components/elements/DropZone.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import { useOrderStore } from '@/stores/orderStore'
import { useUserStore } from '@/stores/userStore'
import { useClientStore } from '@/stores/clientStore'
import filterList from '@/mixins/filterList'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'

export default {
    name: 'ImportedOrders',
    mixins: [filterList],
    components: {
        FiltrableTable,
        DropZone,
        Modal,
        Multiselect,
        FetchDataError,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            wait: false,
            clearFileList: false,
            selectedFileType: '',
            wrongFileTypes: [],
            refresh: 0,
            searchQuery: '',
            showFileTypeModal: false,
            fileTypesOptions: [],
            clientsOptions: [],
            okFilesToRemove: [],
            fileTypeForm: {
                Name: '',
                Active: 1,
                CreationUserId: null,
                ModificationUserId: null,
            },
            selectedClient: null,
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            importedOrdersInterval: null,

            sortableFields: [
                { name: this.$t('status'), value: 'Status' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    validations() {
        return {
            fileTypeForm: {
                Name: { required },
            },
        }
    },
    watch: {
        orders() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    mounted() {
        window.scrollTo(0, 0)

        this.searchOrderFileTypes({
            query: [],
            filter: [{ dbField: 'Active', value: 1 }],
            sortDirection: 'ASC',
            sortField: 'Name',
            perPage: 100,
            page: 1,
        }).then(() => {
            const typesInterval = setInterval(() => {
                if (this.orderFileTypes) {
                    clearInterval(typesInterval)
                    this.orderFileTypes.forEach((type) => {
                        const option = {
                            name: `${type.Name}`,
                            value: type.Id,
                        }
                        this.fileTypesOptions.push(option)
                    })
                }
            }, 200)
        })
        this.searchClients({
            query: [],
            filter: [],
            sortDirection: 'ASC',
            sortField: 'Name',
            perPage: 10000,
            page: 1,
        }).then(() => {
            const clientInterval = setInterval(() => {
                if (this.clients) {
                    clearInterval(clientInterval)
                    this.clients.forEach((client) => {
                        const option = {
                            name: `${client.company_name}`,
                            value: client.id,
                        }
                        this.clientsOptions.push(option)
                    })
                }
            }, 200)
        })
        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        }
        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }

        this.searchImportedOrders(realSearch)
        this.importedOrdersInterval = setInterval(() => {
            this.searchImportedOrders(realSearch)
        }, 20000)
    },

    beforeUnmount() {
        clearInterval(this.importedOrdersInterval)
    },
    computed: {
        importsActionsAccess() {
            return actionsAccess.orders.imports
        },
        ...mapStores(useUserStore),
        ...mapState(useOrderStore, {
            importedOrders: 'importedOrders',
            count: 'importedOrdersCount',
            fetchError: 'fetchError',
            totalPages: 'importedOrdersTotalPages',
            loading: 'loading',
            orderFileTypes: 'orderFileTypes',
        }),

        ...mapState(useClientStore, {
            clients: 'all',
        }),
        columns() {
            return [
                {
                    name: 'title',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'file_type',
                    filtrable: false,
                    sortable: false,
                },

                {
                    name: 'status',
                    filtrable: true,
                    type: 'select',
                    translate: true,
                    dbField: 'Status',
                    sortable: false,
                    options: [
                        {
                            name: 'uploads.in_progress',
                            value: 'inProgress',
                            dbField: 'Status',
                        },
                        {
                            name: 'uploads.errors',
                            value: 'errors',
                            dbField: 'Status',
                        },
                        {
                            name: 'uploads.uploaded',
                            value: 'uploaded',
                            dbField: 'Status',
                        },
                        {
                            name: 'uploads.finished',
                            value: 'finished',
                            dbField: 'Status',
                        },
                    ],
                },

                {
                    name: 'imported_lines',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'lines_to_import',
                    filtrable: false,
                    sortable: false,
                },
                {
                    name: 'file',
                    filtrable: false,
                    isFileDownload:
                        true &&
                        checkAccess(
                            this.userStore,
                            this.importsActionsAccess.download
                        ),
                    sortable: false,
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []

            if (checkAccess(this.userStore, this.importsActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.importsActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
    },
    methods: {
        canAddNew() {
            return checkAccess(this.userStore, this.importsActionsAccess.create)
        },
        ...mapActions(useOrderStore, [
            'deleteImportedOrder',
            'searchImportedOrders',
            'searchOrderFileTypes',
            'uploadOrderFile',
            'createOrderFileType',
        ]),
        ...mapActions(useClientStore, { searchClients: 'search' }),
        resetUpload(clearFiles = false) {
            if (clearFiles === true) {
                this.clearFileList = true
                this.wrongFileTypes = []
                this.selectedFileType = ''
                this.selectedClient = null
            } else if (this.wrongFileTypes.length === 0) {
                this.selectedFileType = ''
                this.selectedClient = null
            }
        },
        removeFileFromOk(file) {
            this.okFilesToRemove.splice(this.okFilesToRemove.indexOf(file))
        },
        removeFromWrong(file) {
            URL.revokeObjectURL(file.url)
            this.wrongFileTypes.splice(this.wrongFileTypes.indexOf(file))
        },
        async uploadFile(file) {
            if (file.file.type !== 'text/csv') {
                this.wrongFileTypes.push(file.file.name)
                file.status = false
                return false
            }
            let response = false
            const blob = await fetch(file.url).then((r) => r.blob())
            URL.revokeObjectURL(file.url)
            const reader = new FileReader()
            reader.readAsBinaryString(blob)
            reader.onloadend = async () => {
                const fileToUpload = {
                    csvFile: btoa(reader.result), // btoa(encodeURI(reader.result).replace('20%', ' ')),
                    Name: file.file.name,
                    CreationUserId: this.userStore.current.id,
                    IdClient: this.selectedClient,
                    IdOrderFileType: this.selectedFileType,
                }
                file.status = 'loading'
                response = await this.uploadOrderFile(fileToUpload)

                if (response && response.status === 200) {
                    file.status = true
                    this.searchImportedOrders({
                        query: this.search,
                        filter: this.filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                    })

                    setTimeout(() => {
                        this.okFilesToRemove.push(file)
                        this.resetUpload()
                    }, 2000)
                }
            }

            return response
        },
        async uploadFiles(files) {
            this.wrongFileTypes = []
            this.wait = true

            const res = await Promise.all(
                files.map((file) => this.uploadFile(file))
            )
            if (res) this.wait = false
        },
        async deleteOne(id) {
            const response = await this.deleteImportedOrder(id)
            if (response.status === 200) {
                this.$toast.success(this.$t('deleted_ok'))
                this.setResults()
            } else {
                this.$toast.error(this.$t('deleted_ko'))
            }
        },
        async createNewFileType() {
            this.wait = true
            const isValid = await this.v$.$validate()
            if (isValid === true) {
                this.fileTypeForm.CreationUserId = this.userStore.current.id
                this.fileTypeForm.ModificationUserId = this.userStore.current.id
                const response = await this.createOrderFileType(
                    this.fileTypeForm
                )
                if (response.status === 200) {
                    this.$toast.success(this.$t('created_ok'))
                    this.searchOrderFileTypes({
                        query: [],
                        filter: [{ dbField: 'Active', value: 1 }],
                        sortDirection: 'ASC',
                        sortField: 'Name',
                        perPage: 100,
                        page: 1,
                    }).then(() => {
                        const typesInterval = setInterval(() => {
                            if (this.orderFileTypes) {
                                clearInterval(typesInterval)
                                this.orderFileTypes.forEach((type) => {
                                    const option = {
                                        name: `${type.Name}`,
                                        value: type.Id,
                                    }
                                    this.fileTypesOptions.push(option)
                                })
                            }
                        }, 200)
                    })
                    this.closeModal()
                } else {
                    this.$toast.error(this.$t('created_ko'))
                }
            } else {
                this.wait = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
        closeModal() {
            this.wait = false
            this.v$.$reset()
            this.showFileTypeModal = false
            this.fileTypeForm = {
                Name: '',
                CreationUserId: '',
                ModificationUserId: '',
                Active: 1,
            }
        },
        deleteAll(elToDelete) {
            this.wait = true

            elToDelete.forEach((el, index) => {
                this.deleteImportedOrder(el)
                if (index === elToDelete.length - 1) {
                    this.wait = false
                }
            })
        },
        downloadFile(file) {
            this.axios
                .get(`/uploads/orderFiles/${file}`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session'
                        )}`,
                    },
                })
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url

                    link.setAttribute('download', file)

                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(url)
                })
        },
        setPage(isNext) {
            this.wait = true
            clearInterval(this.importedOrdersInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.wait = true

            clearInterval(this.importedOrdersInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)

            this.searchImportedOrders(search).then(() => {
                this.wait = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.importedOrdersInterval = setInterval(() => {
                this.searchImportedOrders(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.wait = true

            clearInterval(this.importedOrdersInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
<style lang="scss" scoped>
#select-file-type ::v-deep(.multiselect) {
    font-size: small !important;
    height: auto;
    margin-left: unset;
    margin-right: unset;

    width: 12rem;
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: small !important;
    }
    .multiselect-tag {
        font-size: small !important;
    }
}
</style>
