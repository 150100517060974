import { defineStore } from 'pinia'
import { markRaw } from 'vue'

const useSettingStore = defineStore('setting', {
    state: () => ({
        packagesStatuses: null,
        countPackagesStatuses: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        currentPackageStatus: null,
    }),
    actions: {
        searchPackagesStatuses(payload) {
            this.loading = true
            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'Active') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/paramdeliverystatus/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            } else {
                apiUrl = `/api/v1/paramdeliverystatus/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults

                            this.packagesStatuses = res.data.Records
                            this.countPackagesStatuses = count
                            this.loading = false
                        } else {
                            this.packagesStatuses = null
                            this.countPackagesStatuses = 0
                            this.loading = false
                        }

                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createPackageStatus(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/paramdeliverystatus', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentPackageStatus = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        updatePackageStatus(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/paramdeliverystatus/${payload.id}`,
                        payload.form,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session'
                                )}`,
                            },
                        }
                    )
                    .then((res) => {
                        this.currentPackageStatus = null

                        resolve(res)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        fetchOnePackageStatus(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/paramdeliverystatus/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentPackageStatus = res.data

                        resolve(res.data)
                    })
                    .catch((err) => {
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
        deletePackageStatus(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/paramdeliverystatus/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session'
                            )}`,
                        },
                    })
                    .then((res) => {
                        this.currentPackageStatus = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)
                        err.toJSON()
                        const error = {
                            data: {
                                status: err.response.status,
                                message: err.response.data.message,
                            },
                        }
                        reject(error)
                    })
            })
        },
    },
})
export { useSettingStore }
