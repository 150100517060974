import Wrapper from '../views/drivers/Wrapper.vue'
import WeeklyPlanning from '../views/drivers/WeeklyPlanning.vue'
import CreateOrUpdate from '../views/drivers/CreateOrUpdate.vue'
import DriverPlanning from '../views/drivers/DriverPlanning.vue'

const tours = [
    {
        path: '/drivers',
        name: 'drivers',
        // redirect: '/login',https://{{APIURL}}/v1/clientaddress/1/hours
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'DRIVERS_FULL', 'DRIVERS_LIST'],
        },
        children: [
            {
                path: 'new',
                name: 'new_driver',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'DRIVERS_FULL',
                        'DRIVERS_CREATE',
                    ],
                },
            },
            {
                path: 'update/:id',
                name: 'update_driver',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'DRIVERS_FULL',
                        'DRIVERS_UPDATE',
                    ],
                },
            },

            {
                path: 'weekly-planning',
                name: 'weekly_planning',
                component: WeeklyPlanning,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'DRIVERS_FULL',
                        'WEEKLY_FULL',
                        'WEEKLY_READ',

                    ],
                },
            },
            {
                path: 'planning/:id',
                name: 'driver_planning',
                component: DriverPlanning,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'DRIVERS_FULL',
                        'PLANNING-BASE_READ',
                    ],
                },
            },
        ],
    },
]

export default tours
