<template>
    <div
        id="header"
        class="fixed top-0 z-30 w-full px-2 py-1 bg-white sm:px-4 shadow-xl"
    >
        <div class="flex items-center h-12 justify-between mx-auto">
            <img
                alt="company logo"
                class="w-auto h-11 sm"
                :src="`/logo/${userStore.logo}header-nobg.png`"
            />
            <div class="flex items-center space-x-1">
                <ul class="hidden space-x-2 md:inline-flex">
                    <li>
                        <icon-button
                            :title="$t('notifications')"
                            icon="bell"
                            :withNotificationBadge="true"
                        />
                    </li>
                    <li>
                        <icon-button
                            icon="user"
                            @click="myAccount()"
                            :title="$t('my_account')"
                        ></icon-button>
                    </li>
                    <li>
                        <icon-button
                            icon="right-from-bracket"
                            :title="$t('logout')"
                            @click="logout()"
                        ></icon-button>
                    </li>
                    <li>
                        <night-mode-switch />
                    </li>
                    <li>
                        <language-switcher />
                    </li>
                    <li
                        v-if="
                            userStore.current &&
                            userStore.current.type === 'superadmin'
                        "
                        class="theme-color"
                    >
                        <!-- (+user.id === 5 || +user.id === 4 || +user.id === 3) -->
                        <icon-button
                            icon="screwdriver-wrench"
                            :title="$t('biiim_admin_interface')"
                            @click="adminPanel()"
                        ></icon-button>
                    </li>
                </ul>
                <dropdown-menu
                    ref="smallHeaderDD"
                    class="md:hidden inline-flex"
                >
                    >

                    <template #buttonContent>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 8h16M4 16h16"
                            />
                        </svg>
                        <span class="sr-only">{{ $t('open_menu') }}</span>
                    </template>
                    <template #menu>
                        <ul
                            class="text-zinc-700 rounded hover:bg-zinc-100 block px-4 py-2 text-sm dd-menu"
                        >
                            <li>
                                <icon-button
                                    :title="$t('notifications')"
                                    icon="bell"
                                    :withNotificationBadge="true"
                                />
                            </li>
                            <li>
                                <icon-button
                                    icon="user"
                                    @click="myAccount()"
                                    :title="$t('my_account')"
                                ></icon-button>
                            </li>
                            <li>
                                <icon-button
                                    icon="right-from-bracket"
                                    :title="$t('logout')"
                                    @click="logout()"
                                ></icon-button>
                            </li>

                            <li
                                class="text-zinc-700 hover:bg-zinc-100 block px-4 py-2 text-sm dd-menu"
                            >
                                <night-mode-switch
                                    role="menuitem"
                                    id="menu-item-night"
                                />
                            </li>
                            <li
                                class="text-zinc-700 hover:bg-zinc-100 block py-2 text-sm dd-menu"
                            >
                                <language-switcher
                                    role="menuitem"
                                    id="menu-item-lang"
                                    :inner="true"
                                />
                            </li>
                            <li
                                v-if="
                                    userStore.current &&
                                    userStore.current.type === 'superadmin'
                                "
                                color="theme-color"
                            >
                                <icon-button
                                    icon="screwdriver-wrench"
                                    :title="$t('biiim_admin_interface')"
                                    @click="adminPanel()"
                                ></icon-button>
                            </li>
                        </ul>
                    </template>
                </dropdown-menu>
            </div>
        </div>
    </div>
</template>
<script>
import { mapStores } from 'pinia'
import { useUserStore } from '@/stores/userStore'

import IconButton from '@/components/atoms/IconButton.vue'
import NightModeSwitch from './elements/NightModeSwitch.vue'
import LanguageSwitcher from './elements/LanguageSwitcher.vue'
import DropdownMenu from '@/components/atoms/DropdownMenu.vue'

export default {
    name: 'MainHeader',
    components: {
        IconButton,
        NightModeSwitch,
        LanguageSwitcher,
        DropdownMenu,
    },
    data() {
        return { langs: ['En', 'Fr'], open: false }
    },
    computed: {
        ...mapStores(useUserStore),
    },
    methods: {
        toggle() {
            this.open = !this.open
        },
        logout() {
            this.userStore.$patch({
                current: null,
                isLoggedIn: false,
                isB2C: null,
            })
            this.$cookies.keys().forEach((cookie) => {
                if (
                    cookie !== 'logo' &&
                    cookie !== 'nightMode' &&
                    cookie !== 'locale'
                ) {
                    this.$cookies.remove(cookie)
                }
            })
            this.userStore.logout()
        },
        adminPanel() {
            this.$router.push({ name: 'backoffice' })
        },
        myAccount() {
            this.userStore.fetchOne(this.userStore.current.id)

            this.$router.push({
                name: 'my_account',
                params: { id: this.userStore.current.id },
            })
        },
        changeLocale(newLang) {
            this.$i18n.locale = newLang
            this.$root.$i18n.locale = newLang
            this.userStore.$patch({ locale: newLang })

            this.toggle()
        },
        action(item) {
            if (item === 'logout') this.logout()
        },
    },
}
</script>
