import { isArray } from 'lodash'

export default {
    data() {
        return { searchingCursor: false }
    },
    methods: {
        setRealSelected(dbField, index, selectedIsArray = false) {
            if (!selectedIsArray) {
                const selected = this.filter.find(
                    (filt) => filt.dbField === dbField
                )
                if (selected) {
                    this.columns[index].selected = selected
                    this.columns[index].selected.name = this.$t(
                        this.columns[index].selected.name
                    )
                } else {
                    this.columns[index].selected = null
                }
            } else {
                const selected = this.filter.filter(
                    (filt) => filt.dbField === dbField
                )

                if (selected) {
                    this.columns[index].selected = selected
                } else {
                    this.columns[index].selected = []
                }
            }
            this.refresh += 1
        },
        setRealSearch(search) {
            search.forEach((s) => {
                this.columns.forEach((col) => {
                    if (col.dbField === s.dbField) {
                        col.term = s.term
                    }
                })
            })

            this.refresh += 1
        },

        updateFilter(filter) {
            this.page = 1

            if (isArray(filter)) {
                const existingF = this.filter.filter(
                    (f) => f.dbField === filter[0].dbField
                )
                if (existingF) {
                    existingF.forEach((f) => {
                        const i = this.filter.findIndex((fuck) => fuck === f)
                        this.filter.splice(i, 1)
                    })
                }

                filter.forEach((filt) => {
                    this.filter.push(filt)
                })

                const colIndex = this.columns.findIndex(
                    (col) => col.dbField === filter[0].dbField
                )
                this.setRealSelected(filter[0].dbField, colIndex, true)
            } else {
                if (!this.filter.some((f) => f.dbField === filter.dbField)) {
                    this.filter.push(filter)
                } else if (
                    this.filter.some((f) => f.dbField === filter.dbField)
                ) {
                    const index = this.filter.findIndex(
                        (fil) => fil.dbField === filter.dbField
                    )
                    this.filter[index] = filter
                }
                const colIndex = this.columns.findIndex(
                    (col) => col.dbField === filter.dbField
                )
                this.setRealSelected(filter.dbField, colIndex)
            }

            this.setResults()
        },
        clearFilter(filterToRemove) {
            if (!filterToRemove.multi) {
                const index = this.filter.findIndex(
                    (f) => f.dbField === filterToRemove
                )
                this.filter.splice(index, 1)
                const colIndex = this.columns.findIndex(
                    (col) => col.dbField === filterToRemove
                )
                this.setRealSelected(filterToRemove, colIndex)
            } else {
                const existingF = this.filter.filter(
                    (f) => f.dbField === filterToRemove.dbField
                )
                existingF.forEach((f) => {
                    const i = this.filter.findIndex((fl) => fl === f)
                    this.filter.splice(i, 1)
                })
                const colIndex = this.columns.findIndex(
                    (col) => col.dbField === filterToRemove.dbField
                )
                this.setRealSelected(filterToRemove.dbField, colIndex, true)
            }

            this.page = 1

            this.setResults()
        },
        updateSortField(sortField) {
            this.page = 1

            this.sortField = sortField
            this.setResults()
        },
        updateSortDirection(sort) {
            this.page = 1

            this.sortDirection = sort.direction
            this.sortField = sort.field
            this.setResults()
        },
        debounceSearch(query) {
            this.page = 1

            this.searchingCursor = true

            this.search.forEach((s, index) => {
                if (s.dbField === query.dbField) {
                    this.search.splice(index, 1)
                }
            })
            this.search.push(query)

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setResults()
            }, 400)
        },
    },
}
