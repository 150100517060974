<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <div v-if="clientId && currentClient">
                <back-to
                    @back="back()"
                    :text="
                        $t('contacts') +
                        ' ' +
                        $t('for') +
                        ' ' +
                        currentClient.company_name
                    "
                />
                <!-- <div class="mt-20 flex mb-3 items-center">
                    <div class="text-3xl ml-2">
                        {{
                            $t('contacts') +
                            ' ' +
                            $t('for') +
                            ' ' +
                            currentClient.company_name
                        }}
                    </div>
                </div> -->
            </div>
            <fetch-data-error v-if="fetchError" :dataType="'contacts'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="contacts"
                :items="contacts"
                :columns="columns"
                :storeToSort="'clients'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearchCustom"
                @deleteAll="deleteAll($event)"
                @sortDirectionChanged="updateSortDirectionCustom($event)"
                @selected="updateFilter($event)"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @delete="deleteOne($event)"
                @create="$emit('createContact', $route.params.client_id)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import { mapState, mapStores } from 'pinia'
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import BackTo from '@/components/elements/BackTo.vue'
import { useClientStore } from '@/stores/clientStore'
import filterList from '@/mixins/filterList'
import actionsAccess from '@/resources/actionsAccess'
import checkAccess from '@/resources/accessChecker'
import { useUserStore } from '@/stores/userStore'

export default {
    name: 'ClientContacts',
    mixins: [filterList],

    components: {
        FiltrableTable,
        FetchDataError,
        IconButton,
        BackTo,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            contactsInterval: null,
            filter: [],

            sortableFields: [
                // { name: this.$t('client'), value: 'Client' },
                { name: this.$t('name'), value: 'LastName' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('email'), value: 'Email' },
            ],

            multiActions: [
                // {
                //     name: 'delete_selection',
                //     action: 'deleteAll',
                //     icon: 'trash-can',
                // },
            ],
        }
    },
    watch: {
        contacts() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    created() {
        window.scrollTo(0, 0)

        const filteredList = this.$cookies.get('filtered_clients_contacts')
        if (filteredList) {
            this.search = filteredList.query

            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        }
        const realSearch = {
            clientId: this.clientId,
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }

        this.clientStore.fetchClientContacts(realSearch).then(() => {
            this.contactsInterval = setInterval(() => {
                this.clientStore.fetchClientContacts(realSearch)
            }, 20000)
        })
    },

    beforeUnmount() {
        clearInterval(this.contactsInterval)
        this.clientStore.$patch({ current: null })
    },
    computed: {
        ...mapStores(useClientStore, useUserStore),
        ...mapState(useClientStore, {
            contacts: 'currentContacts',
            count: 'currentContactsCount',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            currentClient: 'current',
            loading: 'loading',
            currentClientContactIds: 'currentClientContactIds',
        }),
        clientId() {
            return this.$route.params.client_id
                ? this.$route.params.client_id
                : this.currentClient
                ? this.currentClient.id
                : null
        },
        contactsActionsAccess() {
            return actionsAccess.clients.contacts
        },

        columns() {
            return [
                {
                    name: 'name',
                    filtrable: true,
                    dbField: 'LastName',
                    isLink: checkAccess(
                        this.userStore,
                        this.contactsActionsAccess.update
                    ),
                    type: 'search',
                    sortable: true,
                },

                {
                    name: 'email_address',
                    filtrable: true,
                    dbField: 'Email',
                    type: 'search',
                    sortable: true,
                },
                {
                    name: 'phone',
                    dbField: 'Phone',
                },
                {
                    name: 'mobile',
                    dbField: 'MobilePhone',
                },
                {
                    name: 'creation_date',
                    sortable: true,
                    dbField: 'CreationDate',
                },
            ]
        },
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.contactsActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            // if (checkAccess(this.userStore, this.contactsActionsAccess.delete)) {
            //     actions.push({
            //         name: 'delete',
            //         action: 'delete',
            //         icon: 'times',
            //     })
            // }

            return actions
        },
    },

    methods: {
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.contactsActionsAccess.create
            )
        },
        back() {
            this.$router.push({ name: 'clients' })
            this.$emit('back', 'clients')
        },
        goToUpdateOne(id) {
            this.$emit('updateContact', id)
        },
        async deleteOne(id) {
            // ATTENTION PAS LE BON DELETE SI REACTIVATION DU CALL
            this.searchingCursor = true

            const response = await this.clientStore.deleteOneDeliveryAddress(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$emit('addressDeleted')
                this.$toast.success(this.$t('deleted_ok'))

                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('address_delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.$clientStore.deleteOneDeliveryAddress(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.contactsInterval)
            this.$cookies.remove('filtered_clients_contacts')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.contactsInterval)
            this.$cookies.remove('filtered_clients_contacts')
            this.search = search.query
            search.clientId = this.clientId

            this.setRealSearch(this.search)
            this.clientStore.fetchClientContacts(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.contactsInterval = setInterval(() => {
                this.clientStore.fetchClientContacts(search)
            }, 20000)
            this.$cookies.set('filtered_clients_contacts', search)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.contactsInterval)
            this.$cookies.remove('filtered_clients_contacts')
            this.perPage = perPage
            this.page = 1
            this.setResults()
            this.refresh += 1
        },
        updateSortDirectionCustom(sort) {
            this.searchingCursor = true

            this.page = 1

            this.sortDirection = sort.direction
            this.sortField = sort.field === 'client' ? '' : sort.field

            this.setResults()
        },
        // updateFilter(filter) {
        //     if (!this.filter.some((f) => f.dbField === filter.dbField)) {
        //         this.filter.push(filter)
        //     } else if (this.filter.some((f) => f.dbField === filter.dbField)) {
        //         const index = this.filter.findIndex(
        //             (fil) => fil.dbField === filter.dbField
        //         )
        //         this.filter[index] = filter
        //     }
        //     this.setResults()
        // },
        // clearFilter(filterToRemove) {
        //     const index = this.filter.findIndex(
        //         (f) => f.dbField === filterToRemove
        //     )
        //     this.filter.splice(index, 1)
        //     this.setResults()
        // },
        // updateSortField(sortField) {
        //     this.sortField = sortField === 'client' ? '' : sortField
        //     this.setResults()
        // },
        debounceSearchCustom(query) {
            this.searchingCursor = true

            this.page = 1

            this.search.forEach((s, index) => {
                if (s.dbField !== 'client' && s.dbField === query.dbField) {
                    this.search.splice(index, 1)
                }
            })
            this.search.push(query)

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setResults()
            }, 400)
        },
        setResults() {
            const search = {
                clientId: this.clientId,
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
